import { ReactNode } from 'react';
import { Layout } from 'antd';
import { styled } from 'styled-components';

import { BrandConfigs } from '../../types';
import { AppFooter } from '../AppFooter';
import { useAuth } from '../../hooks';
import { useBrandConfigs } from '../../contexts';

type ErrorPageProps = {
  code: ReactNode;
  title?: string;
  subtitle: string;
  button: ReactNode;
  disableFooter?: boolean;
};

export const TextPrimary = styled.span<{ $brandConfigs: BrandConfigs }>`
  color: ${(props) => props.$brandConfigs.primaryColor};
`;

export const TextSecondary = styled.span<{ $brandConfigs: BrandConfigs }>`
  color: ${(props) => props.$brandConfigs.bgColor};
`;

export const ErrorPage = ({
  code,
  title,
  subtitle,
  button,
  disableFooter = false
}: ErrorPageProps) => {
  const { brandConfigs } = useBrandConfigs();
  const { isUserSignedIn } = useAuth();

  return (
    <Layout className="justify-content-between mh-100vh error-layout-page">
      <section className="error-page">
        <img src={brandConfigs.logo?.url} alt={brandConfigs.logo?.alt} className="logo" />
        <h1>{code}</h1>
        {title && <h2>{title}</h2>}
        <p>{subtitle}</p>

        {button}
      </section>

      {!disableFooter && isUserSignedIn && <AppFooter brandConfigs={brandConfigs} />}
    </Layout>
  );
};
