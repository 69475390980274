import React from 'react';
import PropTypes from 'prop-types';
import { Divider } from 'antd';

const InnerBox = ({ title, children, className, noLine }) => (
  <section className={`inner-box ${className}`}>
    {title &&
      (noLine ? (
        <h4 className="inner-box-title">{title}</h4>
      ) : (
        <Divider orientation="left">{title}</Divider>
      ))}

    {children}
  </section>
);

InnerBox.defaultProps = {
  title: null,
  className: '',
  noLine: false
};

InnerBox.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  children: PropTypes.node.isRequired,
  className: PropTypes.string,
  noLine: PropTypes.bool
};

export default InnerBox;
