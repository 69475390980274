import { camelCase } from 'change-case';
import useClaim from '../../../../hooks/useClaim';
import CreateClaimForm from '../CreateClaimForm';
import CreateSection from './Section';

const CreateSections = () => {
  const { claim } = useClaim();

  const nestedModels = claim.structure.sections.map((sectionStructure) => [
    'sections',
    camelCase(sectionStructure.slug)
  ]);

  return (
    <CreateClaimForm claim={claim} nestedModels={nestedModels}>
      {claim.structure.sections.map((sectionStructure) => (
        <CreateSection
          sectionStructure={sectionStructure}
          section={claim.sections[camelCase(sectionStructure.slug)]}
          key={sectionStructure.slug}
        />
      ))}
    </CreateClaimForm>
  );
};

export default CreateSections;
