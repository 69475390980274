import React from 'react';
import PropTypes from 'prop-types';
import { Space, Typography } from 'antd';
import { Link } from 'react-router-dom';
import UserAvatar from './UserAvatar';

const AvatarAndName = ({
  user,
  defaultText,
  avatarSize,
  linked,
  hideAvatar,
  showRole,
  TooltipComponent
}) => {
  if (!user || !user.id) {
    return (
      <Typography.Text type="secondary">
        <em>{defaultText}</em>
      </Typography.Text>
    );
  }

  const content = (
    <Space>
      {!hideAvatar && <UserAvatar user={user} size={avatarSize} />}
      <TooltipComponent title={`${user.name}${showRole && user.role ? ` - ${user.role}` : ''}`}>
        <div>
          <span>{user.name}</span>
          {showRole && user.role && (
            <Typography.Text type="secondary">
              <span> - </span>
              <small>
                <em>{user.role}</em>
              </small>
            </Typography.Text>
          )}
        </div>
      </TooltipComponent>
    </Space>
  );

  if (linked && user.permissions?.show) {
    return <Link to={`/users/${user.id}`}>{content}</Link>;
  }

  return content;
};

AvatarAndName.defaultProps = {
  defaultText: '',
  user: {},
  avatarSize: 'small',
  linked: false,
  hideAvatar: false,
  showRole: false,
  // Wrapper to avoid props warning because Fragment does not have title
  // eslint-disable-next-line react/prop-types
  TooltipComponent: ({ children }) => <>{children}</>
};

AvatarAndName.propTypes = {
  user: PropTypes.object,
  defaultText: PropTypes.string,
  avatarSize: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  linked: PropTypes.bool,
  hideAvatar: PropTypes.bool,
  showRole: PropTypes.bool,
  TooltipComponent: PropTypes.func
};

export default AvatarAndName;
