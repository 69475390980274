import { Col, Form, Row } from 'antd';
import { t } from '@gowgates/utils';
import { Field, FieldOption } from '../../../../types';
import { useFormBuilder } from '../../../../hooks/useFormBuilder';
import { FormItem } from '../../../forms';
import { ConditionInput } from './ConditionInput';
import { useConditionInput } from './hooks/useConditionInput';

const optionTransformer = ({ key, value, label }: FieldOption) =>
  label ? { label, value } : { value: key, label: String(value) };

type ConditionProps = {
  field: Partial<Field>;
  resettableFields?: string[];
};

export const Condition = ({ field, resettableFields = [] }: ConditionProps) => {
  const { fields } = useFormBuilder();
  const { conditionQuery, setConditionQuery } = useConditionInput({
    inputName: 'conditionExpression',
    resettableFields
  });

  if (!fields) return null;

  const filteredFields: Field[] = (
    field ? fields.filter((f) => f.name !== field.name) : fields
  ).map((f) => ({
    ...f,
    options: f.options?.map(optionTransformer),
    children: f.children?.map((c) => ({
      ...c,
      options: c.options?.map(optionTransformer)
    }))
  }));

  return (
    <div className="field-condition">
      <Form.Item
        label={t('formBuilder.showOnlyIf')}
        labelCol={{ span: 24 }}
        wrapperCol={{ span: 24 }}
        className="no-margin"
      >
        <FormItem name="conditionExpression">
          <Row gutter={10}>
            <Col span={24}>
              <ConditionInput
                fields={filteredFields}
                value={conditionQuery}
                onChange={setConditionQuery}
              />
            </Col>
          </Row>
        </FormItem>
      </Form.Item>
    </div>
  );
};
