import React from 'react';
import PropTypes from 'prop-types';
import { ConfigProvider, Menu } from 'antd';
import { Link } from 'react-router-dom';
import { t } from '@gowgates/utils';
import { useAuth } from '@gowgates/core';
import AvatarAndName from '../../components/AvatarAndName';

const UserMenu = ({ theme }) => {
  const { currentUser, clearUserFromStorage } = useAuth();

  const handleLogout = () => {
    clearUserFromStorage();
    window.location = '/login';
  };

  const items = [
    {
      key: 'name',
      label: <AvatarAndName user={currentUser} />,
      theme: 'light',
      children: [
        { key: 'profile', label: <Link to="/profile">{t('account.title')}</Link> },
        { key: 'logout', label: t('auth.logout'), onClick: handleLogout }
      ]
    }
  ];

  return (
    <ConfigProvider theme={theme}>
      <Menu
        data-testid="account-menu"
        theme="dark"
        mode="horizontal"
        items={items}
        className="main-menu right-menu"
      />
    </ConfigProvider>
  );
};

UserMenu.defaultProps = {
  theme: {}
};

UserMenu.propTypes = {
  theme: PropTypes.object
};

export default UserMenu;
