import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { t } from '@gowgates/utils';

const statuses = {
  open: 'processing',
  closed: 'green',
  draft: 'warning'
};

const ClaimStatusTag = ({ claim, TooltipComponent }) => (
  <Tag color={statuses[claim.status]}>
    <TooltipComponent title={t(`claim.statuses.${claim.status}`)}>
      {t(`claim.statuses.${claim.status}`)}
    </TooltipComponent>
  </Tag>
);

ClaimStatusTag.defaultProps = {
  // Wrapper to avoid props warning because Fragment does not have title
  // eslint-disable-next-line react/prop-types
  TooltipComponent: ({ children }) => <>{children}</>
};

ClaimStatusTag.propTypes = {
  claim: PropTypes.object.isRequired,
  TooltipComponent: PropTypes.func
};

export default ClaimStatusTag;
