import { apiConfigs, extractData } from '@gowgates/api-client';
import { useQuery } from '@tanstack/react-query';
import { UrlId } from '@gowgates/utils';
import { Field } from '../../types';

type UseFieldsQueryProps = {
  fieldableId: UrlId;
  fieldableType: string;
};

const client = () => apiConfigs().clientInstance;

const queryFn = ({ fieldableId, fieldableType }: UseFieldsQueryProps) =>
  client()
    .get('/fields', { params: { fieldable_id: fieldableId, fieldable_type: fieldableType } })
    .then(extractData<Field[]>);

export const queryKey = ({ fieldableId, fieldableType }: UseFieldsQueryProps) => [
  'fields',
  fieldableType,
  fieldableId
];

export const useFieldsQuery = (fieldableParams: UseFieldsQueryProps) => {
  const query = useQuery({
    queryKey: queryKey(fieldableParams),
    queryFn: () => queryFn(fieldableParams),
    staleTime: 120 * 1000 // refetch every 2 minutes
  });

  return {
    fields: query.data,
    isLoading: query.isLoading
  };
};
