import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate, useSearchParams } from 'react-router-dom';
import { TableProps } from 'antd';
import { Task, Item } from '../../../../types';
import usePermissions from '../../../../hooks/usePermissions';
import useClaim from '../../../../hooks/useClaim';
import NewTaskModal from './NewTaskModal';
import ClaimTasksTable from './ClaimTasksTable';
import TableHeader from './TableHeader';
import ShowTaskModal from './ShowTaskModal';

export type CloseTaskModalType = (task?: Task) => void;

type TaskListProps = {
  tasks: Array<Task>;
  item?: Item;
};

const TaskList = ({ tasks, item }: TaskListProps) => {
  const [searchParams] = useSearchParams();
  const navigate = useNavigate();
  const location = useLocation();

  const [isNewModalOpen, setNewModalOpen] = useState(false);
  const [isShowModalOpen, setShowModalOpen] = useState(false);
  const [selectedRows, setSelectedRows] = useState<React.Key[]>([]);
  const [currentTask, setCurrentTask] = useState<Task>();
  const { permissions } = usePermissions();
  const { claim } = useClaim();

  const taskId = searchParams.get('id');

  const navigateToTask = (task?: Task) => {
    const nextSearchParams = new URLSearchParams(location.search);
    if (task?.id) {
      nextSearchParams.set('id', String(task.id));
    } else if (nextSearchParams.has('id')) {
      nextSearchParams.delete('id');
    }

    navigate(`${location.pathname}?${nextSearchParams.toString()}`, { preventScrollReset: true });
  };

  const showTask = (task?: Task) => {
    navigateToTask(task);
  };

  const rowSelectionParams: TableProps<Task>['rowSelection'] = {
    type: 'checkbox',
    selectedRowKeys: selectedRows,
    onChange: (selectedRowKeys) => {
      setSelectedRows(selectedRowKeys);
    },
    getCheckboxProps: (record: Task) => ({
      disabled: !record.open
    })
  };

  const rowSelection =
    permissions.assignTasksBulk || permissions.assignTasksToMeBulk ? rowSelectionParams : undefined;

  // needed to update task data in case something is changed about task while showing modal (e.g. resend email)
  useEffect(() => {
    setCurrentTask(
      (prevCurrentTask) =>
        tasks.find((t) => prevCurrentTask && t.id === prevCurrentTask.id) || prevCurrentTask
    );
  }, [tasks]);

  useEffect(() => {
    const taskToShow = tasks?.find(({ id }) => String(id) === taskId);

    if (taskToShow) setCurrentTask({ ...taskToShow });
    setShowModalOpen(!!taskToShow);
  }, [taskId, tasks]);

  return (
    <>
      <TableHeader claim={claim} selectedRows={selectedRows} setModalOpen={setNewModalOpen} />

      <ClaimTasksTable tasks={tasks} rowSelection={rowSelection} showTask={showTask} />

      <NewTaskModal claim={claim} isOpen={isNewModalOpen} setOpen={setNewModalOpen} item={item} />

      <ShowTaskModal
        isOpen={isShowModalOpen}
        closeModal={() => navigateToTask()}
        task={currentTask}
        setTask={setCurrentTask}
      />
    </>
  );
};

export default TaskList;
