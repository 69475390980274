import { createContext, useState } from 'react';
import { UrlId } from '@gowgates/utils';
import { useFieldsQuery } from '../../hooks/fields/useFieldsQuery';
import { Field } from '../../types';

type FormBuilderContextType = {
  fieldableId: UrlId;
  fieldableType: string;
  isNested: boolean;
  disabled: boolean;
  fields: Field[];
  isLoading: boolean;
  newField?: Partial<Field>;
  addBlankField: () => void;
  startEditing: (id: number) => void;
  endEditing: () => void;
  addTemplateField: (field: Field) => void;
  currentFieldId?: number;
  parentFieldableId?: UrlId;
  parentFieldableType?: string;
};

export const FormBuilderContext = createContext<FormBuilderContextType>(
  {} as FormBuilderContextType
);

type FormBuilderContextProviderProps = {
  fieldableId: UrlId;
  fieldableType: string;
  parentFieldableId?: UrlId;
  parentFieldableType?: string;
  disabled?: boolean;
  children: React.ReactNode;
};

export const FormBuilderContextProvider = ({
  fieldableId,
  fieldableType,
  parentFieldableId,
  parentFieldableType,
  disabled = false,
  children
}: FormBuilderContextProviderProps) => {
  const { fields, isLoading } = useFieldsQuery({ fieldableId, fieldableType });
  const [currentFieldId, setCurrentFieldId] = useState<number>();
  const [newField, setNewField] = useState<FormBuilderContextType['newField']>();

  const startEditing = (fieldId: number) => {
    setCurrentFieldId(fieldId);
    setNewField(undefined);
  };

  const addBlankField = () => {
    setCurrentFieldId(undefined);
    setNewField({ type: 'string', patternTemplate: 'no' });
  };

  const addTemplateField = (field: Field) => {
    setCurrentFieldId(undefined);
    setNewField(field);
  };

  const endEditing = () => {
    setCurrentFieldId(undefined);
    setNewField(undefined);
  };

  const value: FormBuilderContextType = {
    fieldableId,
    fieldableType,
    isNested: !!parentFieldableId && !!parentFieldableType,
    disabled,
    fields: fields || [],
    isLoading,
    startEditing,
    endEditing,
    currentFieldId,
    newField,
    addBlankField,
    addTemplateField,
    parentFieldableId,
    parentFieldableType
  };

  return <FormBuilderContext.Provider value={value}>{children}</FormBuilderContext.Provider>;
};
