import React from 'react';
import PropTypes from 'prop-types';
import { Alert, ConfigProvider, theme } from 'antd';
import { t, tEnum } from '@gowgates/utils';

const ClaimWithdrawn = ({ claim }) => {
  const { token } = theme.useToken();

  if (claim.liability !== 'withdrawn' || !claim.withdrawReason) {
    return null;
  }

  return (
    <ConfigProvider theme={{ token: { colorWarning: token.orange6 } }}>
      <Alert
        message={t('claim.withdrawnBecause', {
          reason: tEnum('claim.withdrawReasons', claim.withdrawReason)
        })}
        type="warning"
      />
    </ConfigProvider>
  );
};

ClaimWithdrawn.propTypes = {
  claim: PropTypes.object.isRequired
};

export default ClaimWithdrawn;
