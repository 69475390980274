import { scroller } from 'react-scroll';

export const scrollToFirstError = () => {
  // This is in a timeout to give time to render the updated form with the errors
  setTimeout(() => {
    scroller.scrollTo('ant-form-item-has-error', {
      duration: 800,
      smooth: 'easeInOutQuart',
      offset: -20
    });
  }, 100);
};
