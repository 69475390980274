import { Spin } from 'antd';

import styles from '../../styles/loaders.module.scss';

type LoaderProps = {
  delay?: number;
};

export const Loader = ({ delay }: LoaderProps) => (
  <div className={styles['loader']}>
    <Spin size="large" delay={delay} />
  </div>
);
