import { QueryBuilderAntD } from '@react-querybuilder/antd';
import { AppWrapper } from '@gowgates/core';

import TenantProvider from './TenantProvider';

import 'react-quill/dist/quill.snow.css';
import './stylesheets/app.scss';

export const App = () => (
  <AppWrapper>
    <QueryBuilderAntD>
      <TenantProvider />
    </QueryBuilderAntD>
  </AppWrapper>
);
