import React from 'react';
import { Outlet } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import { getMe } from '../../api/endpoints';
import Page from '../../components/Page';
import LinkButton from '../../components/LinkButton';
import FullLoader from '../../components/FullLoader';
import ProfileSummary from './components/ProfileSummary';
import ProfileNav from './components/ProfileNav';

const Profile = () => {
  const { isFetched, data } = useQuery({ queryKey: ['users', 'me'], queryFn: getMe });

  if (!isFetched) {
    return <FullLoader />;
  }

  return (
    <Page
      title={t('account.title')}
      actions={
        !data.otpRequiredForLogin && (
          <LinkButton to="/profile/otp">{t('user.otp.enable')}</LinkButton>
        )
      }
      summary={<ProfileSummary user={data} />}
      nav={<ProfileNav />}
    >
      <Outlet />
    </Page>
  );
};

export default Profile;
