import PropTypes from 'prop-types';
import { ConditionalDescription } from '@gowgates/dynamic-fields';

const SectionDescription = ({ sectionStructure, section }) => (
  <dl className="cg-descriptions">
    {sectionStructure.fields.map((field) => (
      <ConditionalDescription model={section} field={field} key={field.name} labelSpan={16} />
    ))}
  </dl>
);

SectionDescription.propTypes = {
  sectionStructure: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired
};

export default SectionDescription;
