import { Skeleton } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { getClaimTasks } from '../../../../api/endpoints';
import TaskList from './TaskList';

const Tasks = () => {
  const claimId = Number(useParams().claimId);
  usePageTitle(`${t('claim.id', { id: claimId })} - ${t('activerecord.models.task.other')}`);

  const { isLoading, data } = useQuery({
    queryKey: ['claim', claimId, 'tasks'],
    queryFn: () => getClaimTasks(claimId)
  });

  if (isLoading) {
    return (
      <>
        <Skeleton avatar paragraph={{ rows: 3 }} active />
        <Skeleton avatar paragraph={{ rows: 3 }} active />
      </>
    );
  }

  return <TaskList tasks={data} />;
};

export default Tasks;
