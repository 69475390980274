import { Form, FormInstance } from 'antd';
import { useCallback, useEffect, useRef, useState } from 'react';
import transform from 'lodash.transform';
import isPlainObject from 'lodash.isplainobject';

import { AnyObject } from '@gowgates/utils';

import { INITIAL_CONDITION_QUERY } from '../../../../../utils';
import { FieldConditionQuery } from '../../../../../types';

type ResettableArg = {
  form?: FormInstance;
  resettableFields?: string[];
};

const prepareConditionQuery = (object: object) =>
  transform(object, (acc: AnyObject, val, key) => {
    if (isPlainObject(val) || Array.isArray(val)) {
      acc[key] = prepareConditionQuery(val);
    } else if (!['id', 'not', 'valueSource'].includes(key)) {
      acc[key] = val;
    }
  });

const isResettable = ({ form, resettableFields }: ResettableArg) =>
  Boolean(form && resettableFields?.length);
const generateResettableFields = ({ form, resettableFields }: ResettableArg) => {
  if (!isResettable({ form, resettableFields })) return {};

  const resettableFieldsValues: { [fieldName: string]: unknown } = {};
  resettableFields?.forEach((field) => {
    resettableFieldsValues[field] = form?.getFieldValue(field);
  });

  return resettableFieldsValues;
};

export const useConditionInput = ({
  inputName,
  resettableFields = []
}: { inputName?: string; resettableFields?: string[] } = {}) => {
  const form = Form.useFormInstance();

  const resettableFieldsValuesRef = useRef(generateResettableFields({ form, resettableFields }));
  const [conditionQuery, setConditionQuery] = useState<FieldConditionQuery>(
    (inputName && form && form.getFieldValue(inputName)) || INITIAL_CONDITION_QUERY
  );

  const resetCondition = useCallback(() => {
    setConditionQuery(INITIAL_CONDITION_QUERY);

    if (form && inputName) {
      form.setFieldValue(inputName, null);
    }
  }, [form, inputName]);

  useEffect(() => {
    if (form && inputName) {
      form.setFieldValue(inputName, prepareConditionQuery(conditionQuery));
    }
  }, [inputName, conditionQuery, form]);

  // this check execute on all renders
  useEffect(() => {
    if (!isResettable({ form, resettableFields })) return;

    // check if should reset based on field change
    if (
      resettableFields.some((f) => resettableFieldsValuesRef.current[f] !== form.getFieldValue(f))
    ) {
      resetCondition();
    }

    resettableFieldsValuesRef.current = generateResettableFields({ form, resettableFields });
  });

  return {
    resetCondition,
    conditionQuery,
    setConditionQuery
  };
};
