import React, { useCallback, useEffect, useState } from 'react';
import { DatePicker } from 'antd';
import { parseDate, inputFormats, dbFormats, DateFormatNames } from '@gowgates/utils';
import { Dayjs } from 'dayjs';

type Enumerate<N extends number, Acc extends number[] = []> = Acc['length'] extends N
  ? Acc[number]
  : Enumerate<N, [...Acc, Acc['length']]>;
type IntRange<F extends number, T extends number> = Exclude<Enumerate<T>, Enumerate<F>>;

type AntDatePickerProps = React.ComponentProps<typeof DatePicker>;
export type CgDatePickerProps = Omit<AntDatePickerProps, 'onChange' | 'showTime' | 'picker'> & {
  onChange?: (date?: string) => void;
  showTime?: boolean;
  level?: DateFormatNames;
} & { value?: Dayjs };

const MINUTE_STEPS: IntRange<1, 59> = 5;

export const CgDatePicker = ({ value, onChange, level = 'day', ...rest }: CgDatePickerProps) => {
  const picker = level === 'month' || level === 'year' ? level : undefined;
  const showTime = level === 'minute' ? { format: dbFormats[level] } : false;

  const [localValue, setLocalValue] = useState(parseDate(value, level));

  useEffect(() => {
    setLocalValue(parseDate(value, level));
  }, [value, level]);

  const onChangeDate = useCallback((newDate: any) => {
    setLocalValue(newDate);
    onChange && onChange(newDate?.format(dbFormats[level]));
  }, []);

  return (
    <DatePicker
      value={localValue}
      onChange={onChangeDate}
      format={inputFormats[level]}
      placeholder={inputFormats[level]}
      showTime={showTime}
      minuteStep={MINUTE_STEPS}
      style={{ width: '100%' }}
      picker={picker}
      {...rest}
    />
  );
};
