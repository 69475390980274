import { useState } from 'react';
import { Button, Dropdown, Space } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { FieldBuilder } from '../FieldBuilder';
import { EditField } from '../FieldBuilder/edit';
import { useFormBuilder } from '../../hooks/useFormBuilder';
import { FieldTemplateModal } from './FieldTemplateModal';

export const FormBuilderContent = () => {
  const { fields, newField, addBlankField, isNested, disabled } = useFormBuilder();
  const [isModalOpen, setModalOpen] = useState(false);

  if (!fields) {
    return;
  }

  const openModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const items = [
    { key: 'free-field', label: 'Blank', onClick: addBlankField },
    { key: 'template-field', label: 'From template', onClick: openModal }
  ];

  return (
    <Space direction="vertical" size="middle" className="form-builder w-100">
      <div>
        {fields.map((field) => (
          <FieldBuilder key={field.id} field={field} />
        ))}
      </div>

      {newField && <EditField field={newField} />}

      <div className="d-flex justify-content-end">
        {isNested ? (
          <Button
            type="primary"
            icon={<PlusOutlined />}
            onClick={addBlankField}
            disabled={disabled}
          >
            {t('field.add')}
          </Button>
        ) : (
          <Dropdown menu={{ items }} trigger={['click']} disabled={disabled}>
            <Button type="primary" icon={<PlusOutlined />}>
              {t('field.add')}
            </Button>
          </Dropdown>
        )}
      </div>

      <FieldTemplateModal isOpen={isModalOpen} closeModal={closeModal} />
    </Space>
  );
};
