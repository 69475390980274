import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Row, Col, Space, Typography } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useTenant } from '../hooks/useTenant';

const PageHeader = ({ title, backUrl, titleWidth, contextMenu, children }) => {
  const { tenant } = useTenant();

  return (
    <header className="page-header" style={{ background: tenant.bgColor }}>
      <div className="container">
        <Row>
          <Col span={titleWidth}>
            <Typography.Title className="page-title">
              <Space>
                {backUrl && (
                  <Link to={backUrl} className="back-btn">
                    <ArrowLeftOutlined />
                  </Link>
                )}
                <span>{title}</span>
                {contextMenu}
              </Space>
            </Typography.Title>
          </Col>

          <Col span={24 - titleWidth} className="d-flex justify-content-end">
            <Space>{children}</Space>
          </Col>
        </Row>
      </div>
    </header>
  );
};

PageHeader.defaultProps = {
  title: '',
  backUrl: '',
  titleWidth: 12,
  contextMenu: null,
  children: null
};

PageHeader.propTypes = {
  title: PropTypes.string,
  backUrl: PropTypes.string,
  titleWidth: PropTypes.number,
  contextMenu: PropTypes.node,
  children: PropTypes.node
};

export default PageHeader;
