import React from 'react';
import PropTypes from 'prop-types';
import PageHeader from './PageHeader';
import PageContent from './PageContent';
import PageContentWithSummary from './PageContentWithSummary';

const Page = ({ title, overlap, actions, backUrl, contextMenu, nav, summary, children }) => (
  <section>
    <PageHeader title={title} backUrl={backUrl} contextMenu={contextMenu}>
      {actions}
    </PageHeader>

    {summary ? (
      <PageContentWithSummary nav={nav} summary={summary}>
        {children}
      </PageContentWithSummary>
    ) : (
      <PageContent overlap={overlap}>{children}</PageContent>
    )}
  </section>
);

Page.defaultProps = {
  title: '',
  overlap: false,
  actions: null,
  backUrl: '',
  contextMenu: null,
  nav: null,
  summary: null
};

Page.propTypes = {
  title: PropTypes.string,
  overlap: PropTypes.bool,
  actions: PropTypes.node,
  backUrl: PropTypes.string,
  children: PropTypes.node.isRequired,
  contextMenu: PropTypes.node,
  nav: PropTypes.node,
  summary: PropTypes.node
};

export default Page;
