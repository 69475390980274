import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

const ActiveSwitch = ({ activeKey, active, updateActive }) => {
  if (activeKey == null) {
    return <Switch checked disabled />;
  }

  return <Switch checked={active} onChange={updateActive} />;
};

ActiveSwitch.defaultProps = {
  activeKey: null,
  active: false
};

ActiveSwitch.propTypes = {
  activeKey: PropTypes.string,
  active: PropTypes.bool.isRequired,
  updateActive: PropTypes.func.isRequired
};

export default ActiveSwitch;
