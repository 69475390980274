import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { isUnprocessableEntity } from '@gowgates/api-client';
import { DangerPopconfirm } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';
import { deleteDocumentStructure } from '../../../api/endpoints';

const DeleteDocumentStructure = ({ id, claimStructureId }) => {
  const onError = (error) => {
    if (isUnprocessableEntity(error) && error.response.data.errors.base) {
      message.error(error.response.data.errors.base);
    }
  };

  const onSuccess = () => {
    message.success(t('documentStructure.deletedMessage'));
  };

  return (
    <div className="d-flex justify-content-end">
      <DangerPopconfirm
        deleteFn={() => deleteDocumentStructure(claimStructureId, id)}
        invalidateQueries={['documentStructures', claimStructureId]}
        permission
        onSuccess={onSuccess}
        onError={onError}
      />
    </div>
  );
};

DeleteDocumentStructure.propTypes = {
  id: PropTypes.number.isRequired,
  claimStructureId: PropTypes.number.isRequired
};

export default DeleteDocumentStructure;
