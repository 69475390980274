import React from 'react';
import PropTypes from 'prop-types';
import { useQuery } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import { getClaimDocuments } from '../../../api/endpoints';
import DocumentDescription from './Document';

const DocumentsDescription = ({ claim }) => {
  const { data: documents } = useQuery({
    queryKey: ['claim', claim.id, 'documents'],
    queryFn: () => getClaimDocuments(claim.id)
  });

  return (
    <dl className="cg-descriptions">
      {claim.structure.documents.map((documentStructure) => (
        <DocumentDescription
          documents={documents}
          structure={documentStructure}
          key={documentStructure.name}
        />
      ))}

      <dt>{t('document.other')}</dt>

      <dd>
        {documents
          ?.filter((document) => !document.type)
          .map((document) => (
            <p key={document.id}>
              <span>{document.name}</span>
              <span> - </span>
              <a href={document.file.url} target="_blank" rel="noopener noreferrer">
                {document.file.name}
              </a>
            </p>
          ))}
      </dd>
    </dl>
  );
};

DocumentsDescription.propTypes = {
  claim: PropTypes.object.isRequired
};

export default DocumentsDescription;
