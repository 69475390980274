import PropTypes from 'prop-types';
import { Table } from 'antd';
import { t } from '@gowgates/utils';
import { DateFormatter } from '@gowgates/dynamic-fields';
import { useTable } from '@gowgates/core';
import ClaimStatusTag from '../claim/components/ClaimStatusTag';
import AvatarAndName from '../../components/AvatarAndName';
import StructureName from '../../components/StructureName';
import useCaseManagers from '../../hooks/useCaseManagers';
import { claimRowClassName, convertToFilter } from '../../utils/tables';
import useAppConfigs from '../../hooks/useAppConfigs';
import ClaimRowIcons from './ClaimRowIcons';
import ClaimCol from './ClaimCol';

const { Column } = Table;

const ClaimsTable = ({
  claims,
  loading,
  pagination,
  sorter,
  filters,
  structures,
  rowSelection
}) => {
  const { handleTableChange } = useTable();
  const { appConfigs } = useAppConfigs();

  const { userFilters } = useCaseManagers();
  const structureFilters = structures.map((structure) => ({
    text: structure.name,
    value: structure.claimStructureId
  }));

  return (
    <Table
      dataSource={claims}
      rowKey={(record) => record.id}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      rowSelection={rowSelection}
      rowClassName={claimRowClassName}
    >
      <Column
        title={t('activerecord.attributes.claim.id')}
        dataIndex="id"
        sorter
        sortOrder={sorter.field === 'id' && sorter.order}
        render={(text, record) => <ClaimCol claim={record}>{text}</ClaimCol>}
      />

      <Column
        title={t('activerecord.attributes.claim.status')}
        dataIndex="status"
        sorter
        sortOrder={sorter.field === 'status' && sorter.order}
        filters={convertToFilter(appConfigs.claim.statuses)}
        filteredValue={filters.status || null}
        render={(text, record) => (
          <ClaimCol claim={record}>
            <ClaimStatusTag claim={record} />
          </ClaimCol>
        )}
      />

      <Column
        title={t('activerecord.models.client.one')}
        dataIndex="clientName"
        sorter
        sortOrder={sorter.field === 'clientName' && sorter.order}
        render={(_text, record) => <ClaimCol claim={record}>{record.clientName}</ClaimCol>}
      />

      <Column
        title={t('activerecord.models.claimant.one')}
        dataIndex="claimantName"
        sorter
        sortOrder={sorter.field === 'claimantName' && sorter.order}
        render={(_text, record) => <ClaimCol claim={record}>{record.claimantName}</ClaimCol>}
      />

      <Column
        title={t('activerecord.attributes.claim.claimedAt')}
        dataIndex="claimedAt"
        sorter
        sortOrder={sorter.field === 'claimedAt' && sorter.order}
        render={(_text, record) => (
          <ClaimCol claim={record}>
            <DateFormatter value={record.claimedAt} />
          </ClaimCol>
        )}
      />

      <Column
        title={t('activerecord.attributes.claim.assignee')}
        dataIndex="assigneeId"
        sorter
        sortOrder={sorter.field === 'assigneeId' && sorter.order}
        filters={userFilters}
        filteredValue={filters.assigneeId || null}
        render={(_text, record) => (
          <ClaimCol claim={record}>
            <AvatarAndName user={record.assignee} />
          </ClaimCol>
        )}
      />

      <Column
        title={t('activerecord.attributes.claim.structureId')}
        dataIndex="structureId"
        sorter
        sortOrder={sorter.field === 'structureId' && sorter.order}
        filters={structureFilters}
        filteredValue={filters.structureId || null}
        render={(_text, record) => (
          <ClaimCol claim={record}>
            <StructureName claim={record} />
          </ClaimCol>
        )}
      />

      <Column
        title={t('activerecord.attributes.claim.classOfRisk')}
        dataIndex="classOfRisk"
        render={(_text, record) => <ClaimCol claim={record}>{record.classOfRisk}</ClaimCol>}
      />

      <Column
        className="text-right claim-info-icons"
        render={(_text, record) => (
          <ClaimCol claim={record}>
            <ClaimRowIcons record={record} />
          </ClaimCol>
        )}
      />
    </Table>
  );
};

ClaimsTable.defaultProps = {
  claims: [],
  rowSelection: false
};

ClaimsTable.propTypes = {
  claims: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  sorter: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  structures: PropTypes.array.isRequired,
  rowSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.bool])
};

export default ClaimsTable;
