import { GlobalToken } from 'antd';

export const quillRules = (token: GlobalToken, hasError = false) => `
  border: 1px solid ${hasError ? token.colorError : token.colorBorder};

  &:hover {
    border-color: ${hasError ? token.colorErrorBorderHover : token.colorPrimaryHover};
  }

  &:focus-within {
    border-color: ${hasError ? token.colorError : token.colorPrimary};
    box-shadow: 0 0 0 2px ${hasError ? token.colorErrorOutline : token.controlOutline};
  }

  .ql-toolbar.ql-snow button:hover .ql-stroke, .ql-toolbar.ql-snow button:focus .ql-stroke {
    stroke: ${token.colorLinkHover};
  }

  .ql-toolbar.ql-snow button:hover .ql-fill, .ql-toolbar.ql-snow button:focus .ql-fill {
    stroke: ${token.colorLinkHover};
  }

  .ql-toolbar.ql-snow button.ql-active .ql-stroke {
    stroke: ${token.colorLink};
  }

  .ql-toolbar.ql-snow button.ql-active .ql-fill {
    stroke: ${token.colorLink};
  }

  .ql-formats:empty {
    margin: 0;
    padding: 0;
  }
`;

export const quillWithSendRules = (token: GlobalToken, hasError: boolean) => `
  ${quillRules(token, hasError)}

  .ql-toolbar.ql-snow button.ant-btn-primary {
    background-color: ${token.colorPrimary};
  }

  .ql-toolbar.ql-snow button.ant-btn-primary:hover {
    background-color: ${token.colorPrimaryHover};
  }

  .ql-toolbar.ql-snow button.ant-btn-primary:active {
    background-color: ${token.colorPrimaryActive};
  }

  .ql-toolbar.ql-snow .ql-formats.submit-btn .ant-btn-default:hover {
    color: ${token.colorPrimary};
  }
`;
