import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, InputNumber, Select, Switch } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { FormItem } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';
import useAppConfigs from '../../../hooks/useAppConfigs';
import Condition from './Condition';
import ButtonActions from './ButtonActions';

const TaskTriggerForm = ({ form, taskStructure }) => {
  const journeyId = Number(useParams().journeyId);
  const queryClient = useQueryClient();
  const { appConfigs } = useAppConfigs();
  const taskStructures = queryClient.getQueryData(['taskStructures', journeyId]);

  const typeUpdated = (prevValues, currentValues) => prevValues.type !== currentValues.type;
  const event = form.getFieldValue('event');

  const updateType = () => {
    form.setFieldsValue({
      action: null,
      relatedTaskStructureId: null,
      buttonLabel: null,
      buttonColor: null,
      buttonActions: []
    });
  };

  const conditionalUpdated = (prevValues, currentValues) =>
    prevValues.conditional !== currentValues.conditional;

  return (
    <>
      <Form.Item name="event" hidden>
        <Input />
      </Form.Item>

      <FormItem name="type" model="taskTrigger">
        <Select options={appConfigs.taskTrigger.types[event]} onChange={updateType} />
      </FormItem>

      <Form.Item noStyle shouldUpdate={typeUpdated}>
        {({ getFieldValue }) =>
          getFieldValue('type') === 'system' && (
            <FormItem name="action" model="taskTrigger">
              <Select
                options={(() => {
                  const actions = appConfigs.taskTrigger.actions[taskStructure.entity];
                  const shouldGroup = actions?.every(({ groupLabel }) => !!groupLabel);

                  if (!shouldGroup) return actions;

                  const groups = [];
                  actions.forEach(({ groupLabel, ...actionOption }) => {
                    const group = groups.find(({ label }) => groupLabel === label);
                    if (group) {
                      group.options.push(actionOption);
                    } else {
                      groups.push({
                        label: groupLabel,
                        options: [actionOption]
                      });
                    }
                  });

                  return groups;
                })()}
              />
            </FormItem>
          )
        }
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeUpdated}>
        {({ getFieldValue }) =>
          ['closed_task', 'not_completed_in_time'].includes(getFieldValue('type')) && (
            <FormItem name="relatedTaskStructureId" model="taskTrigger">
              <Select options={taskStructures} fieldNames={{ value: 'id', label: 'name' }} />
            </FormItem>
          )
        }
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeUpdated}>
        {({ getFieldValue }) =>
          getFieldValue('type') === 'button' && (
            <>
              <FormItem name="buttonLabel" model="taskTrigger">
                <Input />
              </FormItem>

              <FormItem name="buttonColor" model="taskTrigger">
                <Select options={appConfigs.taskTrigger.buttonColors} />
              </FormItem>

              <ButtonActions taskStructure={taskStructure} />
            </>
          )
        }
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeUpdated}>
        {({ getFieldValue }) =>
          getFieldValue('type') === 'automatic' && (
            <ButtonActions
              taskStructure={taskStructure}
              title={t('taskTrigger.automaticActions')}
            />
          )
        }
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeUpdated}>
        {({ getFieldValue }) =>
          getFieldValue('type') === 'frontoffice' && (
            <ButtonActions
              taskStructure={taskStructure}
              title={t('taskTrigger.frontofficeFields')}
            />
          )
        }
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeUpdated}>
        {({ getFieldValue }) =>
          getFieldValue('type') === 'not_completed_in_time' && (
            <FormItem name="days" model="taskTrigger">
              <InputNumber
                min={1}
                parser={(value) => {
                  if (!value) return; // needed to not define a value when form is initialized

                  const intValue = parseInt(value, 10);
                  return isNaN(intValue) ? 1 : intValue;
                }}
              />
            </FormItem>
          )
        }
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeUpdated}>
        {({ getFieldValue }) =>
          event === 'completion' &&
          getFieldValue('type') !== 'automatic' &&
          getFieldValue('type') !== 'frontoffice' && (
            <FormItem name="requiresComment" model="taskTrigger" valuePropName="checked">
              <Switch />
            </FormItem>
          )
        }
      </Form.Item>

      <FormItem name="conditional" model="taskTrigger" valuePropName="checked">
        <Switch />
      </FormItem>

      <Form.Item noStyle shouldUpdate={conditionalUpdated}>
        {({ getFieldValue }) =>
          getFieldValue('conditional') && <Condition entity={taskStructure.entity} />
        }
      </Form.Item>
    </>
  );
};

TaskTriggerForm.propTypes = {
  form: PropTypes.object.isRequired,
  taskStructure: PropTypes.object.isRequired
};

export default TaskTriggerForm;
