import React from 'react';
import { Col, Row } from 'antd';
import { booleanOptions, t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { SelectInput, TextInput, DateInput, InlineOptions } from '@gowgates/dynamic-fields';
import useIntegrations from '../../hooks/useIntegrations';
import InlineInput from './components/InlineInput';
import { useFileConfig } from './hooks/useFileConfig';
import { SectionDivider } from './components/SectionDivider';

const FileFields = () => {
  const { hasInsightIntegrations } = useIntegrations();
  const { localFileConfig, updateFileConfigVal } = useFileConfig();
  usePageTitle(`${t('activerecord.models.file.one')} - ${t('backoffice.fileConfig')}`);

  const yesNoOptions = booleanOptions({ valueKey: 'key', labelKey: 'value' });

  return (
    <div className="form-builder inline-layout">
      <SectionDivider
        labelKey="fileDetailsLabel"
        fileConfig={localFileConfig}
        updateFileConfigVal={updateFileConfigVal}
      />

      <Row gutter={10} align="middle">
        <Col span={2} offset={20}>
          {t('globals.enabled')}
        </Col>
        <Col span={2}>{t('globals.required')}</Col>
      </Row>

      {hasInsightIntegrations && (
        <>
          <InlineInput fieldLabel={t('activerecord.attributes.claim.integrationId')}>
            <SelectInput />
          </InlineInput>

          <InlineInput fieldLabel={t('activerecord.attributes.claim.insightPolicyTransactionId')}>
            <TextInput />
          </InlineInput>
        </>
      )}

      <InlineInput
        labelKey="notifiedAtLabel"
        activeKey="notifiedAtActive"
        requiredKey="notifiedAtRequired"
      >
        <DateInput />
      </InlineInput>

      <InlineInput labelKey="assigneeLabel" editable>
        <SelectInput />
      </InlineInput>

      <InlineInput
        labelKey="conflictFileLabel"
        activeKey="conflictFileActive"
        requiredKey="conflictFileRequired"
      >
        <InlineOptions options={yesNoOptions} />
      </InlineInput>

      <InlineInput
        labelKey="vulnerableCustomerLabel"
        activeKey="vulnerableCustomerActive"
        requiredKey="vulnerableCustomerRequired"
      >
        <InlineOptions options={yesNoOptions} />
      </InlineInput>
    </div>
  );
};

export default FileFields;
