import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Button, ConfigProvider, Form, message, Modal, theme, ThemeConfig } from 'antd';
import { useNavigate } from 'react-router';
import { AxiosError } from 'axios';
import { isUnprocessableEntity } from '@gowgates/api-client';
import {
  addErrorsFromAPIInForm,
  addErrorsFromModelInForm,
  AnyModelWithData,
  AnyObject
} from '@gowgates/utils';
import { DynamicHardcodedField, FormItem } from '@gowgates/dynamic-fields';
import { camelCase, capitalCase } from 'change-case';
import { CgwQuill } from '@gowgates/core';
import { performButtonAction, performButtonActionInBulk } from '../../../../api/endpoints';
import { ButtonAction, Item, TaskStructureEntity, TaskStructureRecord } from '../../../../types';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import HoverLoader from '../../../../components/HoverLoader';
import useClaim from '../../../../hooks/useClaim';
import useAppConfigs from '../../../../hooks/useAppConfigs';

type ActionButtonProps = {
  record?: TaskStructureRecord;
  action: ButtonAction;
  recordsToBulk?: Pick<Item, 'id'>[];
  onSuccess?: (data: any) => void;
  entity: TaskStructureEntity;
};
const ActionButton = ({
  record,
  action,
  recordsToBulk,
  onSuccess = () => {},
  entity
}: ActionButtonProps) => {
  const [isFieldsModalOpen, setFieldsModalOpen] = useState(false);
  const [isCommentModalOpen, setCommentModalOpen] = useState(false);
  const navigate = useNavigate();
  const [fieldsForm] = Form.useForm();
  const [commentForm] = Form.useForm();
  const { refreshClaim } = useClaim();
  const queryClient = useQueryClient();
  const { token } = theme.useToken();
  const { appConfigs } = useAppConfigs();
  const { claim } = useClaim();

  const { isPending, mutate } = useMutation({
    mutationFn: (values: { action: number; params?: AnyObject; commentParams?: AnyObject }) => {
      if (recordsToBulk) {
        return performButtonActionInBulk({
          taskTriggerId: values.action,
          records: recordsToBulk,
          entity,
          params: values.params,
          commentParams: values.commentParams
        });
      }

      return performButtonAction({
        recordId: record?.id,
        entity,
        taskTriggerId: values.action,
        params: values.params,
        commentParams: values.commentParams
      });
    },
    onSuccess: (data: any) => {
      closeModal();

      // TODO: Maybe the responsability for this should be on the caller
      if (!recordsToBulk && !data.permissions.show) {
        navigate('/claims');
      } else {
        queryClient.invalidateQueries({ queryKey: ['payments'] });
        if (data) {
          refreshClaim(data);
          queryClient.invalidateQueries({ queryKey: ['claim', claim.id, 'tasks'] });
        }
      }

      onSuccess(data);
    },
    onError: (error: AxiosError<{ errors: { base: string[] }; comment: AnyModelWithData }>) => {
      if (isUnprocessableEntity(error) && isFieldsModalOpen) {
        addErrorsFromAPIInForm({ error, form: fieldsForm });
        return;
      }

      if (isUnprocessableEntity(error) && error.response?.data?.comment?.errors) {
        addErrorsFromModelInForm({
          model: error.response.data.comment,
          form: commentForm
        });
        return;
      }

      closeModal();

      if (isUnprocessableEntity(error) && error.response?.data?.errors?.base) {
        message.error(error.response.data.errors?.base?.join(', '));
      }
    }
  });

  const closeModal = () => {
    setFieldsModalOpen(false);
    setCommentModalOpen(false);
    fieldsForm.resetFields();
    commentForm.resetFields();
  };

  const handleOk = () => {
    mutate({
      action: action.id,
      params: fieldsForm.getFieldsValue(),
      commentParams: commentForm.getFieldsValue()
    });
  };

  const onClick = () => {
    if (action.fields.length > 0) {
      setFieldsModalOpen(true);
    } else if (action.requiresComment) {
      setCommentModalOpen(true);
    } else {
      mutate({ action: action.id });
    }
  };

  const colorPrimaryKey = `color${capitalCase(action.buttonType)}`;
  const buttonColor = (token as any)[colorPrimaryKey] as string | undefined;
  const customTheme: ThemeConfig = {
    token: {
      colorPrimary: buttonColor || token.colorPrimary
    }
  };

  if (recordsToBulk && !recordsToBulk.length) return null;
  if (!recordsToBulk && !record) return null;

  return (
    <>
      {isPending && !isFieldsModalOpen && !isCommentModalOpen && <HoverLoader />}

      <ConfigProvider theme={customTheme}>
        <Button type="primary" onClick={onClick}>
          {action.label}
        </Button>
      </ConfigProvider>

      {action.fields.length > 0 && (
        <Modal title={action.label} open={isFieldsModalOpen} onCancel={closeModal} footer={null}>
          <Form
            form={fieldsForm}
            initialValues={record}
            layout="vertical"
            onFinish={handleOk}
            disabled={isPending}
          >
            {action.fields.map((field) => {
              const modelFields = appConfigs[entity]?.staticFields;

              return (
                <DynamicHardcodedField
                  key={field.name}
                  modelName={entity}
                  name={camelCase(field.name)}
                  fieldName={field.name}
                  modelFields={modelFields}
                />
              );
            })}
            <ModalFormFooter closeModal={closeModal} isLoading={isPending} />
          </Form>
        </Modal>
      )}

      {action.requiresComment && (
        <Modal title={action.label} open={isCommentModalOpen} onCancel={closeModal} footer={null}>
          <Form form={commentForm} layout="vertical" onFinish={handleOk} disabled={isPending}>
            <FormItem name="text">
              <CgwQuill hasError={commentForm.getFieldError('comment').length > 0} />
            </FormItem>

            <ModalFormFooter closeModal={closeModal} isLoading={isPending} />
          </Form>
        </Modal>
      )}
    </>
  );
};

export default ActionButton;
