import { Typography } from 'antd';
import { Field } from '../../../types';

export const Description = ({ field }: { field: Field }) => {
  if (!field.description) {
    return null;
  }

  return (
    <span>
      <Typography.Text type="secondary">
        <span dangerouslySetInnerHTML={{ __html: field.description }} />
      </Typography.Text>
    </span>
  );
};
