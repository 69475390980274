import { TableColumnsType, TableProps } from 'antd';
import { useModelAttributeLabel } from '@gowgates/dynamic-fields';
import { Link } from 'react-router-dom';

type OnChange<T> = NonNullable<TableProps<T>['onChange']>;
type GetSingle<T> = T extends (infer U)[] ? U : never;
export type Sorts<T> = GetSingle<Parameters<OnChange<T>>[2]>;

type ColumnType<T> = TableColumnsType<T>[0];
type ColumnDataType<T> = Omit<ColumnType<T>, 'sortOrder'> & {
  dataIndex?: string;
  sortOrder?: (sorter: Sorts<T>) => ColumnType<T>['sortOrder'];
};

export type UseTableProps<T> =
  | {
      model?: string;
      rowUrlPattern?: string;
      columns?: ColumnDataType<T>[];
      frontendSort?: boolean;
    }
  | undefined;

export function columnTitle<T>(column: ColumnDataType<T>, model?: string) {
  if (column.title) return column.title;
  if (!column.dataIndex || !model) return;

  return useModelAttributeLabel.labelForAttributeModel({
    name: column.dataIndex,
    model
  });
}

export function columnKey<T>(column: ColumnDataType<T>, index: number) {
  return column.key || column.dataIndex || index;
}

export function columnSortOrder<T>(column: ColumnDataType<T>, sorter: Sorts<T>) {
  if (column.sortOrder) {
    return column.sortOrder(sorter);
  }

  if (typeof column.sorter === 'boolean' && column.sorter && column.dataIndex) {
    return sorter.field === column.dataIndex ? sorter.order : undefined;
  }

  return column.sortOrder;
}

export function recordToUrlParams(record: object): { [key: string]: string | number } {
  const params: { [key: string]: string | number } = {};

  Object.entries(record).forEach(([key, value]) => {
    if (typeof value === 'string' || typeof value === 'number') params[key] = value;
  });

  return params;
}

function replaceUrlParams(url: string, record: { [key: string]: string | number }) {
  const result = url.replace(/:([a-zA-Z]+)/g, (match, p1) =>
    record[p1] !== undefined ? String(record[p1]) : match
  );

  // empty string if some placeholder was not replaced
  if (result.match(/:[a-zA-Z]+/)) {
    return '';
  }

  return result;
}

export function columnWithLinkRender<T extends object>(
  column: ColumnDataType<T>,
  urlPattern: string
): ColumnDataType<T> {
  const className = 'col-clickable';
  if (column.render) {
    return {
      ...column,
      render: (value, record, index) => {
        const rowUrl = replaceUrlParams(urlPattern, recordToUrlParams(record));
        const content = column.render ? column.render(value, record, index) : undefined;

        return rowUrl ? (
          <Link className={className} to={rowUrl}>
            {content as React.ReactNode}
          </Link>
        ) : (
          content
        );
      }
    };
  }

  return {
    ...column,
    render: (value, record) => {
      const rowUrl = replaceUrlParams(urlPattern, recordToUrlParams(record));

      return rowUrl ? (
        <Link className={className} to={rowUrl}>
          {value}
        </Link>
      ) : (
        value
      );
    }
  };
}
