import React from 'react';
import { Outlet } from 'react-router';
import { t } from '@gowgates/utils';
import Page from '../../components/Page';
import FileConfigNav from './components/FileConfigNav';
import FileConfigSummary from './components/FileConfigSummary';

const FileConfig = () => (
  <Page title={t('backoffice.fileConfig')} nav={<FileConfigNav />} summary={<FileConfigSummary />}>
    <Outlet />
  </Page>
);

export default FileConfig;
