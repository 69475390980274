import { useQuery } from '@tanstack/react-query';
import { apiConfigs, extractData } from '@gowgates/api-client';
import { ListItem } from '../types';

export const getResourceLists: () => Promise<ListItem[]> = () =>
  apiConfigs().clientInstance.get('resources/lists').then(extractData);

export const useLists = () => {
  const { isLoading, data: lists } = useQuery({
    queryKey: ['resourceLists'],
    queryFn: getResourceLists,
    placeholderData: [],
    staleTime: 60 * 60 * 1000 // stores cache for 1 hour
  });

  return {
    isLoading,
    lists
  };
};
