import { useQuery } from '@tanstack/react-query';
import { getFileConfig } from '../api/endpoints';

const useFileConfig = () => {
  const query = useQuery({
    queryKey: ['fileConfig'],
    queryFn: () => getFileConfig(),
    placeholderData: { claimant: [], client: [], cover: [] },
    staleTime: Infinity
  });

  return {
    ...query,
    claimantStructure: query.data.claimant,
    clientStructure: query.data.client,
    coverStructure: query.data.cover
  };
};

export default useFileConfig;
