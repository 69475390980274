import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';

const statuses = {
  paid: 'green',
  declined: 'error',
  withdrawn: 'warning'
};

const icons = {
  paid: <CheckCircleOutlined />,
  declined: <CloseCircleOutlined />
};

const ItemStatusTag = ({ item }) => (
  <Tag icon={icons[item.status]} color={statuses[item.status]}>
    {t(`item.statuses.${item.status}`)}
  </Tag>
);

ItemStatusTag.propTypes = {
  item: PropTypes.object.isRequired
};

export default ItemStatusTag;
