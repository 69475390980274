import { Tooltip } from 'antd';
import { useLists } from '../../hooks';
import { Field, ListItem } from '../../types';

type ChoiceFromListFormatterProps = {
  field: Field;
  fieldValue: number[];
  TooltipComponent?: typeof Tooltip;
};

export const ChoiceFromListFormatter = ({
  field,
  fieldValue,
  TooltipComponent
}: ChoiceFromListFormatterProps) => {
  const { lists } = useLists();

  const root = lists?.find((list) => list.id === field.listItemId);

  const fullPath = (
    currentPath: string[],
    remainingValues: number[],
    node: ListItem | undefined
  ): string[] => {
    const thisNode = node?.children.find((option) => option.id === remainingValues[0]);

    if (!thisNode) {
      return currentPath;
    }

    const newPath = [...currentPath, thisNode.name];

    if ((thisNode?.children?.length || 0) > 0) {
      return fullPath(newPath, remainingValues.slice(1, remainingValues.length), thisNode);
    }

    return newPath;
  };

  if (!root) {
    return null;
  }

  const out = fullPath([], fieldValue, root);
  const text = out.join(' / ');

  if (TooltipComponent) {
    return <TooltipComponent title={text}>{text}</TooltipComponent>;
  }

  return text;
};
