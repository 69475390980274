import { useQuery } from '@tanstack/react-query';
import { AnyObject } from '@gowgates/utils';
import { getResourceUsers } from '../api/endpoints';

const useResourceUsers = (filters: AnyObject = {}) => {
  const flatFilters = Object.keys(filters)
    .map((k) => [k, filters[k]])
    .flat();

  const { isLoading, data: users } = useQuery({
    queryKey: ['resourceUsers', ...flatFilters],
    queryFn: () => getResourceUsers(filters),
    placeholderData: [],
    staleTime: Infinity
  });

  const userFilters = users?.map((u) => ({ text: u.name, value: u.id }));

  return {
    isLoading,
    users,
    userFilters
  };
};

export default useResourceUsers;
