import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { getClaimNotes } from '../../../../api/endpoints';
import useClaim from '../../../../hooks/useClaim';
import NotesTable from './Table';
import NewNoteModal from './NewNoteModal';
import NotesHeader from './Header';

const NotesList = () => {
  const { claim } = useClaim();
  const [isModalOpen, setModalOpen] = useState(false);

  const { data: notes } = useQuery({
    queryKey: ['claim', claim.id, 'notes'],
    queryFn: () => getClaimNotes(claim.id)
  });

  const showModal = () => {
    setModalOpen(true);
  };

  return (
    <>
      <NotesHeader claim={claim} notes={notes} showModal={showModal} />

      <NotesTable notes={notes} />

      <NewNoteModal claimId={claim.id} isOpen={isModalOpen} setOpen={setModalOpen} />
    </>
  );
};

export default NotesList;
