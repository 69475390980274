import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';

const UserBlockedTag = ({ status }) => {
  if (status === 'active') {
    return null;
  }

  return (
    <Tag icon={<CloseCircleOutlined />} color="error">
      {t('user.statuses.blocked')}
    </Tag>
  );
};

UserBlockedTag.propTypes = {
  status: PropTypes.string.isRequired
};

export default UserBlockedTag;
