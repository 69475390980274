import { useEffect, useState } from 'react';

export const useCountries = () => {
  const [countries, setCountries] = useState<{ value: string; label: string; code: string }[]>();

  useEffect(() => {
    import('../resources/countries.json').then((currenciesModule) => {
      setCountries(currenciesModule.default);
    });
  }, []);

  return { countries };
};
