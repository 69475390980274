import React, { useContext } from 'react';
import PropTypes from 'prop-types';
import { useNavigate } from 'react-router-dom';
import { Steps } from 'antd';
import { t } from '@gowgates/utils';
import { CreateStepsContext } from '../../../contexts/CreateStepsContext';
import { claimDetailsLabel } from '../utils/claim';
import Box from '../../../components/Box';

const CreateClaimNav = ({ claim }) => {
  const { getTabByIndex, isTabDisabled, currentTabIndex } = useContext(CreateStepsContext);
  const navigate = useNavigate();

  const changeStep = (clickedStep) => {
    const tab = getTabByIndex(clickedStep);

    navigate(tab.path);
  };

  const stepTitle = (step) => {
    if (step === 'claim_details' && claim.structure.claimName) {
      return claimDetailsLabel(claim.structure);
    }

    return t(`claim.backofficeCreateStatuses.${step}`);
  };

  if (claim.justSubmitted) {
    return null;
  }

  const items = claim.structure.createSteps.map((step, index) => ({
    title: stepTitle(step),
    disabled: isTabDisabled(index),
    key: step
  }));

  return (
    <Box>
      <Steps current={currentTabIndex} size="small" onChange={changeStep} items={items} />
    </Box>
  );
};

CreateClaimNav.propTypes = {
  claim: PropTypes.object.isRequired
};

export default CreateClaimNav;
