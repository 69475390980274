import { ModelWithData } from '@gowgates/utils';
import { ExclamationCircleFilled } from '@ant-design/icons';
import { Space, Tooltip, Typography } from 'antd';
import { camelCase } from 'change-case';
import { DynamicFormatter } from '../../formatters/DynamicFormatter';
import { ConditionalField } from '../ConditionalField';
import { Field } from '../../types';

type ConditionalDescriptionProps = {
  model: ModelWithData;
  field: Field;
};

export const ConditionalDescription = ({ model, field }: ConditionalDescriptionProps) => {
  const errors = (model.data?.errors?.[camelCase(field.name)] || []).join(', ');

  return (
    <ConditionalField model={model} field={field}>
      <dt>
        <Space>
          <span>{field.label}</span>
          {errors && (
            <Tooltip title={errors}>
              <Typography.Text type="danger">
                <ExclamationCircleFilled />
              </Typography.Text>
            </Tooltip>
          )}
        </Space>
      </dt>
      <dd>
        <DynamicFormatter data={model.data} field={field} />
      </dd>
    </ConditionalField>
  );
};

export default ConditionalDescription;
