import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router';
import qs from 'query-string';
import { t, DEFAULT_PAGINATION } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import TableSearch from '../../components/TableSearch';
import usePermissions from '../../hooks/usePermissions';
import { getTasks } from '../../api/endpoints';
import { updatedFiltersFromParams, updatedSorterFromParams } from '../../utils/tables';
import Page from '../../components/Page';
import TasksTable from './Table';
import ReassignTasks from './ReassignTasks';
import TasksActions from './TasksActions';

const Tasks = () => {
  const { search: searchQuery } = useLocation();
  const search = useMemo(() => qs.parse(searchQuery), [searchQuery]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [sorter, setSorter] = useState({});
  usePageTitle(t('activerecord.models.task.other'));

  const { permissions } = usePermissions();
  const [selectedRows, setSelectedRows] = useState([]);

  const { isFetching, data: tasks } = useQuery({
    queryKey: ['tasks', search],
    queryFn: () => getTasks(search)
  });

  useEffect(() => {
    if (tasks) setPagination((prevPagination) => ({ ...prevPagination, ...tasks.pagination }));
  }, [tasks]);

  useEffect(() => {
    setSorter(updatedSorterFromParams(search));
    setFilters(updatedFiltersFromParams(search));
  }, [search]);

  const hasRowSelection = permissions.assignTasksBulk || permissions.assignTasksToMeBulk;
  const rowSelection = hasRowSelection ? { type: 'checkbox', onChange: setSelectedRows } : false;

  return (
    <Page title={t('activerecord.models.task.other')} overlap actions={<TasksActions />}>
      <TableSearch />

      <ReassignTasks selectedRows={selectedRows} cacheKey={['tasks']} />

      <TasksTable
        tasks={tasks?.rows}
        loading={isFetching}
        pagination={pagination}
        sorter={sorter}
        filters={filters}
        rowSelection={rowSelection}
      />
    </Page>
  );
};

export default Tasks;
