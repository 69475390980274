import { useState, useEffect, useRef } from 'react';
import { AxiosError } from 'axios';
import { Form, Button, InputRef, Space } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useSearchParams } from 'react-router-dom';

import { addErrorsFromAPIInForm, t } from '@gowgates/utils';

import { useAuth, usePageTitle } from '../../../hooks';
import { Loader } from '../../../components';
import { AuthContainer } from '../Container';
import { ActivateOtp } from '../Login/ActivateOtp';
import { Credentials } from '../Login/types';
import { AuthForm } from '../components/AuthForm';
import { PasswordInput } from '../components/PasswordInput';
import { confirmSelf, getConfirmationToken, login } from '../api/endpoints';
import { LoginLink } from '../components/LoginLink';
import { ResendConfirmationLink } from '../components/ResendConfirmationLink';
import { useBrandConfigs } from '../../../contexts';

export const Confirmation = () => {
  const inputRef = useRef<InputRef>(null);
  const [searchParams] = useSearchParams();
  const [activateOtp, setActivateOtp] = useState(false);
  const [isTokenInvalid, setTokenInvalid] = useState(false);
  const [form] = Form.useForm();
  const { addUserToStorage } = useAuth();
  usePageTitle(t('user.setMyPassword'));
  const [errorMessage, setErrorMessage] = useState<string>();
  const [credentials, setCredentials] = useState<Credentials>();
  const [qrCode, setQrCode] = useState<string>();

  const confirmationToken = searchParams.get('confirmation_token');

  const { brandConfigs } = useBrandConfigs();

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const loginMutation = useMutation({
    mutationFn: login,
    onError: (error: AxiosError<{ message: string }>) => {
      setErrorMessage(error.response?.data.message);
    },
    onSuccess: (data) => {
      addUserToStorage(data.user, data.authorization);
    }
  });

  const { isPending, mutate } = useMutation({
    mutationFn: (values) => confirmSelf(values, confirmationToken),

    onError: (error) =>
      addErrorsFromAPIInForm({
        error,
        form
      }),

    onSuccess: (data, variables: any) => {
      setErrorMessage(undefined);

      if (brandConfigs.forceOtp) {
        setQrCode(data.qrCode);
        setCredentials({ password: variables.password, email: data.email });
        setActivateOtp(true);
      } else {
        loginMutation.mutate({ password: variables.password, email: data.email });
      }
    }
  });

  const {
    isLoading: isGettingToken,
    data,
    isError
  } = useQuery({
    queryKey: ['confirmationToken'],
    queryFn: () => getConfirmationToken(confirmationToken),
    throwOnError: false
  });

  useEffect(() => {
    if (data?.pendingReconfirmation) mutate();
  }, [data, mutate]);

  useEffect(() => {
    if (isError) setTokenInvalid(true);
  }, [isError]);

  if (isGettingToken) {
    return <Loader />;
  }

  if (isTokenInvalid) {
    return (
      <AuthContainer
        title={t('user.confirm')}
        errorMessage={t('devise.confirmations.invalidToken')}
        footer={
          <Space direction="vertical" size="small">
            <ResendConfirmationLink />
            <LoginLink />
          </Space>
        }
      />
    );
  }

  if (activateOtp && credentials && qrCode && brandConfigs) {
    return <ActivateOtp credentials={credentials} qrCode={qrCode} />;
  }

  return (
    <AuthContainer title={t('user.setMyPassword')} errorMessage={errorMessage}>
      <AuthForm form={form} onFinish={mutate} disabled={isPending}>
        <PasswordInput />
        <PasswordInput confirmation />

        <Button type="primary" htmlType="submit" className="w-100" loading={isPending}>
          {t('user.setMyPassword')}
        </Button>
      </AuthForm>
    </AuthContainer>
  );
};

export default Confirmation;
