import { useQuery } from '@tanstack/react-query';
import { getMyPermissions } from '../api/endpoints';

const usePermissions = () => {
  const query = useQuery({
    queryKey: ['myPermissions'],
    queryFn: getMyPermissions,
    placeholderData: [],
    staleTime: Infinity
  });

  return { ...query, permissions: query.data };
};

export default usePermissions;
