import React from 'react';
import { Table } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { Link } from 'react-router-dom';
import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { getClaimStructures } from '../../api/endpoints';
import Page from '../../components/Page';
import ClaimStructureActions from './ClaimStructureActions';

const Structures = () => {
  usePageTitle(t('activerecord.models.claimStructure.other'));

  const { isFetching, data } = useQuery({
    queryKey: ['claimStructures'],
    queryFn: getClaimStructures
  });

  const columns = [
    {
      title: t('activerecord.attributes.account.name'),
      dataIndex: 'name',
      render: (text, record) => (
        <Link to={`/structures/${record.id}`} className="col-clickable">
          {text}
        </Link>
      )
    }
  ];

  return (
    <Page title={t('activerecord.models.claimStructure.other')} actions={<ClaimStructureActions />}>
      <Table
        dataSource={data}
        rowKey={(record) => record.id}
        className="page-table"
        loading={isFetching}
        pagination={false}
        columns={columns}
      />
    </Page>
  );
};

export default Structures;
