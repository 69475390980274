import { Typography } from 'antd';
import { useFormBuilder } from '../../../../hooks/useFormBuilder';

export const TextInput = ({ placeholder = '' }: { placeholder?: string }) => {
  const { disabled } = useFormBuilder();

  return (
    <Typography.Text type="secondary" className={`field-input${disabled ? ' disabled' : ''}`}>
      {placeholder}
    </Typography.Text>
  );
};
