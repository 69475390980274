import { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Row, Select, Switch } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { FormItem, useConditionInput, ConditionInput } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';
import { getFields } from '../../../../api/endpoints';
import FieldExpression from './FieldExpression';

const conditionalUpdated = (prevValues, currentValues) =>
  prevValues.conditional !== currentValues.conditional;

const resourceIdUpdated = (prevValues, currentValues) =>
  prevValues.resourceId !== currentValues.resourceId;

const DocumentDynamic = ({ documentStructures }) => {
  const structureId = Number(useParams().structureId);
  const form = Form.useFormInstance();
  const [documentStructureId, setDocumentStructureId] = useState(form.getFieldValue('resourceId'));

  const { conditionQuery, setConditionQuery } = useConditionInput({
    inputName: 'conditionExpression'
  });

  const { data } = useQuery({
    queryKey: ['fieldReports', 'documentStructures', documentStructureId],
    queryFn: () =>
      getFields(
        `claim_structures/${structureId}/document_structures/${documentStructureId}/fields`
      ),
    enabled: !!documentStructureId
  });

  return (
    <>
      <FormItem name="resourceId" model="reportField">
        <Select
          options={documentStructures}
          fieldNames={{ value: 'id', label: 'label' }}
          showSearch
          onChange={(value) => {
            setDocumentStructureId(value);
            form.setFieldValue('fieldName', null);
          }}
        />
      </FormItem>

      <FieldExpression fields={data} />

      <Form.Item noStyle shouldUpdate={resourceIdUpdated}>
        {({ getFieldValue }) =>
          getFieldValue('resourceId') && (
            <FormItem name="conditional" valuePropName="checked" model="reportField">
              <Switch />
            </FormItem>
          )
        }
      </Form.Item>

      <Form.Item noStyle shouldUpdate={conditionalUpdated}>
        {({ getFieldValue }) =>
          getFieldValue('conditional') && (
            <Form.Item
              label={t('formBuilder.showOnlyIf')}
              labelCol={{ span: 24 }}
              wrapperCol={{ span: 24 }}
              className="no-margin"
            >
              <FormItem name="conditionExpression">
                <Row gutter={10}>
                  <Col span={24}>
                    <ConditionInput
                      fields={data}
                      value={conditionQuery}
                      onChange={setConditionQuery}
                    />
                  </Col>
                </Row>
              </FormItem>
            </Form.Item>
          )
        }
      </Form.Item>
    </>
  );
};

DocumentDynamic.propTypes = {
  documentStructures: PropTypes.array.isRequired
};

export default DocumentDynamic;
