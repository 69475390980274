import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { isUnprocessableEntity } from '@gowgates/api-client';
import { DangerPopconfirm } from '@gowgates/dynamic-fields';
import { deleteTaskStructure } from '../../../api/endpoints';

const DeleteTaskStructure = ({ journeyId, taskStructure }) => {
  const onError = (error) => {
    if (isUnprocessableEntity(error) && error.response.data.errors.base) {
      message.error(error.response.data.errors.base);
    }
  };

  const onSuccess = () => {
    message.success('Task deleted');
  };

  return (
    <div className="d-flex justify-content-end">
      <DangerPopconfirm
        deleteFn={() => deleteTaskStructure(journeyId, taskStructure.id)}
        invalidateQueries={['taskStructures', journeyId]}
        permission
        onSuccess={onSuccess}
        onError={onError}
      />
    </div>
  );
};

DeleteTaskStructure.propTypes = {
  journeyId: PropTypes.number.isRequired,
  taskStructure: PropTypes.object.isRequired
};

export default DeleteTaskStructure;
