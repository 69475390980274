import { FormItem } from '@gowgates/dynamic-fields';
import { Input } from 'antd';
import { EmailTemplateEditor } from '../../components/EmailTemplateEditor';

export const EmailTemplateFormContent = () => (
  <>
    <FormItem name="name" model="emailTemplate" required>
      <Input />
    </FormItem>

    {/* TODO: remove -- leave it here, for now, as a reminder of how to use it as plain-text for subject */}
    {/* <FormItem name="subject" label="Subject" required>
      <EmailTemplateEditor id="subject" variant="plain-text" toolbarId="toolbar-subject" />
    </FormItem> */}

    <FormItem name="content" model="emailTemplate" required>
      <EmailTemplateEditor id="content" toolbarId="toolbar-content" />
    </FormItem>
  </>
);
