import { EyeOutlined, SendOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { HandPointerIcon } from '../icons';

const DELIVERED_STATUSES = ['delivered', 'open', 'clicked'];
const OPEN_STATUSES = ['open', 'clicked'];
const CLICKED_STATUSES = ['clicked'];
const FAILED_STATUSES = ['failed'];

const emailStatus = (email) => email?.status || '';

const isEmailDelivered = (email) => DELIVERED_STATUSES.includes(emailStatus(email));
const isEmailOpen = (email) => OPEN_STATUSES.includes(emailStatus(email));
const isEmailClicked = (email) => CLICKED_STATUSES.includes(emailStatus(email));
export const isEmailFailed = (email) => FAILED_STATUSES.includes(emailStatus(email));

const deliveryDetailsForEmail = (email) => {
  let color, text;

  if (isEmailDelivered(email)) {
    color = 'success';
    text = t('sent_email.delivered_message');
  } else if (isEmailFailed(email)) {
    color = 'danger';
    text = email.reason || t('sent_email.failed_message');
  } else {
    color = 'secondary';
    text = t('sent_email.not_delivered_message');
  }

  return {
    color,
    text,
    IconComponent: SendOutlined
  };
};

const openDetailsForEmail = (email) => {
  let color = 'secondary',
    text = t('sent_email.not_open_message');
  if (isEmailOpen(email)) {
    color = 'success';
    text = t('sent_email.open_message');
  }

  return {
    color,
    text,
    IconComponent: EyeOutlined
  };
};

const clickedDetailsForEmail = (email) => {
  let color = 'secondary',
    text = t('sent_email.not_clicked_message');
  if (isEmailClicked(email)) {
    color = 'success';
    text = t('sent_email.clicked_message');
  }

  return {
    color,
    text,
    IconComponent: HandPointerIcon
  };
};

export const processEmailDetails = (email) =>
  email && {
    delivery: deliveryDetailsForEmail(email),
    open: openDetailsForEmail(email),
    clicked: clickedDetailsForEmail(email)
  };
