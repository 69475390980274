import React, { useEffect } from 'react';
import { Form, Select, Spin } from 'antd';
import { AUSTRALIA, useCountries } from '@gowgates/utils';

type AntFormItemProps = React.ComponentProps<typeof Form.Item>;
type PhoneAddonProps = {
  name: AntFormItemProps['name'];
};

export const PhoneAddon = ({ name }: PhoneAddonProps) => {
  const composedName = new Array(name).flat();
  composedName[composedName.length - 1] = `${composedName[composedName.length - 1]}Country`;
  const form = Form.useFormInstance();

  const { countries } = useCountries();

  useEffect(() => {
    setTimeout(() => {
      if (!form.getFieldValue(composedName)) {
        form.setFieldValue(composedName, AUSTRALIA);
      }
    }, 100);
  }, [composedName, form]);

  if (!countries) return <Spin size="small" />;

  return (
    <Form.Item name={composedName} noStyle>
      <Select
        showSearch
        optionLabelProp="label"
        optionFilterProp="children"
        popupMatchSelectWidth={300}
      >
        {countries.map((country) => (
          <Select.Option value={country.value} label={`+${country.code}`} key={country.value}>
            {`+${country.code} - ${country.label}`}
          </Select.Option>
        ))}
      </Select>
    </Form.Item>
  );
};
