import { apiConfigs, extractData } from '@gowgates/api-client';
import { useQuery } from '@tanstack/react-query';
import { DynamicDataConfigs } from '../../types';

const client = () => apiConfigs().clientInstance;

export const queryFn = () =>
  client()
    .get('/dynamic_data_configs.json')
    .then(extractData<DynamicDataConfigs>);

export const queryKey = ['dynamicDataConfigs'];

export const useDynamicDataConfigsQuery = () => {
  const query = useQuery({
    queryKey,
    queryFn,
    staleTime: Infinity
  });

  return {
    allFieldTypes: query.data?.fieldTypes,
    nestedFieldTypes: query.data?.fieldTypes?.filter((type) => type.value !== 'array'),
    dateFieldLevels: query.data?.dateFieldLevels,
    isLoading: query.isLoading
  };
};
