import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { App, Button, Form, Space, Switch } from 'antd';
import qs from 'query-string';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useLocation, useNavigate } from 'react-router';
import { t } from '@gowgates/utils';
import { assignClaims, assignClaimsToMe } from '../../api/endpoints';
import ReassignBtn from '../claim/components/ReassignBtn';
import { updateSearch } from '../../utils/tables';
import usePermissions from '../../hooks/usePermissions';
import Box from '../../components/Box';

const TableHeader = ({ selectedRows }) => {
  const search = qs.parse(useLocation().search);
  const navigate = useNavigate();
  const queryClient = useQueryClient();
  const { permissions } = usePermissions();
  const [form] = Form.useForm();
  const { message } = App.useApp();

  const refreshClaims = () => queryClient.invalidateQueries({ queryKey: ['claims'] });

  const assignMutation = useMutation({
    mutationFn: (userId) => assignClaims(selectedRows, userId),
    onSuccess: (data) => {
      refreshClaims();
      message.success(t('claim.reassignedBulk', { count: data.count, name: data.name }));
    }
  });

  const assignToMeMutation = useMutation({
    mutationFn: () => assignClaimsToMe(selectedRows),
    onSuccess: (data) => {
      refreshClaims();
      message.success(t('claim.reassignedToMeBulk', { count: data.count }));
    }
  });

  const onChangeActionRequired = (checked) => {
    const newSearch = updateSearch(search, { requires_action: checked });

    navigate({ search: qs.stringify(newSearch) });
  };

  useEffect(() => {
    form.setFieldsValue({ requires_action: search.requires_action });
  }, [search.requires_action]);

  return (
    <Box>
      <Form form={form}>
        <Space>
          <Form.Item name="requires_action" valuePropName="checked" className="no-margin">
            <Switch onChange={onChangeActionRequired} />
          </Form.Item>
          <span>Show only claims that require action</span>

          {selectedRows.length > 0 && (
            <>
              {permissions.assignClaimsBulk && (
                <ReassignBtn
                  onChange={assignMutation.mutate}
                  label={t('claim.reassignSelected')}
                  dropdown="button"
                  filters={{ role: 'case_manager' }}
                />
              )}

              {permissions.assignClaimsToMeBulk && (
                <Button onClick={assignToMeMutation.mutate}>
                  {t('claim.reassignSelectedToMe')}
                </Button>
              )}
            </>
          )}
        </Space>
      </Form>
    </Box>
  );
};

TableHeader.propTypes = {
  selectedRows: PropTypes.array.isRequired
};

export default TableHeader;
