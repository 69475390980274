import { useEffect, useMemo, useRef, useState } from 'react';
import {
  Alert,
  Button,
  Checkbox,
  Col,
  Form,
  Input,
  Result,
  Row,
  Space,
  message,
  Modal
} from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { Link, useParams, useSearchParams } from 'react-router-dom';
import { useMutation, useQuery } from '@tanstack/react-query';
import { camelCase } from 'change-case';
import cloneDeep from 'lodash.clonedeep';
import { isUnprocessableEntity } from '@gowgates/api-client';
import { DisplayDynamicFields, FormItem, BankDetailsForm } from '@gowgates/dynamic-fields';
import { t, scrollToFirstError } from '@gowgates/utils';
import {
  getFrontOfficeTask,
  completeFrontOfficeTask,
  withdrawFrontOfficeTask
} from '../../../api/endpoints';
import { Layout } from '../Layout';
import Box from '../../../components/Box';

// Useful for testing. avoids to manually populate the form
const developmentInitialData = {
  client: { name: 'Client name', abn: '7634', itc: '100' },
  claim: {
    bankAccountType: 'australia', // australia to pass sanctions check, overseas to fail
    bankName: 'Bank',
    bankAccountHolderName: 'Holder',
    bsb: '123123',
    accountNumber: '123123123',
    swift: 'SW',
    iban: '1342',
    currency: 'EUR',
    data: { goodsCommodities: [{ type: 2, nameOfProduct: 'iPhone', modelOfProduct: '15' }] }
  },
  sections: {
    loss: { data: { description: 'the desc' } },
    damage: { data: { description: 'the desc' } }
  },
  item: {
    data: {
      suppliersAndSellingInvoices: true,
      sellingAmount: '100',
      suppliersAmount: 20,
      alternateBasisOfSettlement: 1,
      averageAmount: 50,
      labelCost: '4.49'
    }
  }
};

const ACTION_PARAM = 'action';
const WITHDRAW_ACTION = 'withdraw';

export const EditTask = () => {
  const taskId = useParams().taskUuid;
  const [searchParams, setSearchParams] = useSearchParams();

  const withdrawConfirmModalActiveRef = useRef(false);
  const [isEdited, setIsEdited] = useState(false);
  const [isCorrectInformationChecked, setCorrectInformationChecked] = useState(false);
  const [isPrivacyStatementRead, setPrivacyStatementRead] = useState(false);
  const [isPersonalInformationAllowed, setPersonalInformationAllowed] = useState(false);

  const [form] = Form.useForm();
  const {
    isLoading,
    isFetched,
    data: task
  } = useQuery({
    queryKey: ['frontOfficeTask', taskId],
    queryFn: () => getFrontOfficeTask(taskId)
  });

  const {
    isPending: isSubmitting,
    mutate,
    isSuccess: isSubmitted
  } = useMutation({
    mutationFn: (values) => completeFrontOfficeTask(taskId, values),
    onError: (error) => {
      if (isUnprocessableEntity(error)) {
        const { errors: serverErrors } = error.response.data;

        const fields = [];
        const addError = (namespaces, key, errors) =>
          fields.push({
            name: [...namespaces, ...key.split('.') /* for nested errors */].map(
              (k) => (isNaN(k) ? k : Number(k)) // array indexes need to be an integer for ant
            ),
            errors
          });

        const errorsFor = (errors, namespaces = []) =>
          Object.entries(errors || {}).forEach(([k, v]) => addError(namespaces, k, v));

        errorsFor(serverErrors.claim, ['claim']);
        errorsFor(serverErrors.claim.data, ['claim', 'data']);
        errorsFor(serverErrors.client, ['client']);
        errorsFor(serverErrors.item, ['item', 'data']);
        errorsFor(serverErrors.sections, ['sections', task.section.type, 'data']);

        if (fields.length) {
          form.setFields(fields);
          scrollToFirstError();
        } else {
          message.error(
            typeof serverErrors == 'string' ? serverErrors : t('globals.unexpected_error')
          );
        }
      }
    },
    onSuccess: () => setIsEdited(true)
  });

  const {
    isLoading: isSubmittingWithdraw,
    mutate: mutateWithdraw,
    isSuccess: isWithdrawn
  } = useMutation({
    mutationFn: withdrawFrontOfficeTask,
    onError: (error) => {
      const { errors: serverErrors } = error.response.data;
      message.error(
        typeof serverErrors === 'string' ? serverErrors : t('globals.unexpected_error')
      );
    },
    onSuccess: () => {
      setIsEdited(true);
    }
  });

  const actionParam = searchParams.get(ACTION_PARAM);
  useEffect(() => {
    const closeConfirmModal = () => {
      searchParams.delete(ACTION_PARAM);
      setSearchParams(searchParams);
      withdrawConfirmModalActiveRef.current = false;
    };

    const showWithdrawConfirmModal = actionParam === WITHDRAW_ACTION;
    if (showWithdrawConfirmModal && !withdrawConfirmModalActiveRef.current) {
      withdrawConfirmModalActiveRef.current = true;
      Modal.confirm({
        title: t('frontoffice.withdraw_modal_message'),
        icon: <ExclamationCircleOutlined />,
        okText: t('globals.yesNo.true'),
        okType: 'danger',
        cancelText: t('globals.yesNo.false'),
        onCancel: closeConfirmModal,
        onOk() {
          mutateWithdraw(taskId);
          closeConfirmModal();
        }
      });
    }
  }, [searchParams, actionParam, setSearchParams, mutateWithdraw, taskId]);

  const sectionStructure = task?.sectionStructure;
  const sectionStructureNamespace = sectionStructure
    ? ['sections', camelCase(sectionStructure.slug)]
    : [];

  const shouldLoadInitialDataInDevelopment =
    !import.meta.env.PROD && task && !task.claim.bankAccountType;
  useEffect(() => {
    const onCtrlShiftEnter = (e) => {
      if (!shouldLoadInitialDataInDevelopment) return;
      if (e.key === 'Enter' && (e.ctrlKey || e.metaKey) && e.shiftKey) {
        form.setFieldsValue(developmentInitialData);
      }
    };

    document.addEventListener('keydown', onCtrlShiftEnter);

    return () => {
      document.removeEventListener('keydown', onCtrlShiftEnter);
    };
  }, [shouldLoadInitialDataInDevelopment, form]);

  const renderAuthorizationCheckbox = ({ checked, setChecked, translationKey }) => (
    <Checkbox checked={checked} onChange={(e) => setChecked(e.target.checked)}>
      <i dangerouslySetInnerHTML={{ __html: t(translationKey) }} />
    </Checkbox>
  );

  let successMessage;
  if (isSubmitted) {
    successMessage = {
      title: t(
        task.inCollectingMoreInfoPhase
          ? 'frontoffice.taskBankDetailsUpdatedTitle'
          : 'frontoffice.taskSubmittedTitle'
      ),
      paragraphs: [
        t(
          task.inCollectingMoreInfoPhase
            ? 'frontoffice.taskBankDetailsUpdatedSubtitle_1'
            : 'frontoffice.taskSubmittedSubtitle_1'
        ),
        t(
          task.inCollectingMoreInfoPhase
            ? 'frontoffice.taskBankDetailsUpdatedSubtitle_2'
            : 'frontoffice.taskSubmittedSubtitle_2'
        ),
        t(
          task.inCollectingMoreInfoPhase
            ? 'frontoffice.taskBankDetailsUpdatedSubtitle_3'
            : 'frontoffice.taskSubmittedSubtitle_3'
        )
      ]
    };
  }

  if (isWithdrawn) {
    successMessage = {
      title: t('frontoffice.taskClaimWithdrawnTitle'),
      paragraphs: [
        t('frontoffice.taskClaimWithdrawnSubtitle_1'),
        t('frontoffice.taskClaimWithdrawnSubtitle_2'),
        t('frontoffice.taskClaimWithdrawnSubtitle_3')
      ]
    };
  }

  const initialValues = useMemo(() => {
    if (!task) return;

    const initialTask = cloneDeep(task);
    if (!initialTask.claim.data.goodsCommodities) {
      initialTask.claim.data.goodsCommodities = [{}];
    }

    return initialTask;
  }, [task]);

  return (
    <Layout isLoading={isLoading}>
      <div className="container mw-100 pi-8">
        {successMessage && (
          <>
            <Result status="success" title={successMessage.title} />
            <Row>
              <Col span={3} offset={10}>
                {t('frontoffice.aiapClaimNumber')}
              </Col>
              <Col span={3}>{task.claim.id}</Col>
            </Row>
            <Row>
              <Col span={3} offset={10}>
                {t('frontoffice.labelNumber')}
              </Col>
              <Col span={3}>{task.claim.labelNumber}</Col>
            </Row>

            <div className="text-center" style={{ marginTop: 50 }}>
              {successMessage.paragraphs.map((p) => (
                <p
                  key={p}
                  style={{ marginBottom: '.5em' }}
                  dangerouslySetInnerHTML={{ __html: p }}
                />
              ))}
            </div>
          </>
        )}

        {isFetched && task && !isEdited && (
          <div className="w-100">
            <Space direction="vertical">
              <Alert
                message={
                  <>
                    <span>
                      {t('frontoffice.withdrawInfoFrontendPart_1')}{' '}
                      <Link to={`?${ACTION_PARAM}=${WITHDRAW_ACTION}`}>
                        {t('frontoffice.withdrawClaimButtonTextFrontend')}
                      </Link>
                      .
                    </span>{' '}
                    <span>{t('frontoffice.withdrawInfoFrontendPart_2')}</span>
                  </>
                }
                type="info"
                showIcon
                closable
              />
              <Form
                form={form}
                labelCol={{
                  sm: { span: 11 },
                  xl: { span: 9 }
                }}
                wrapperCol={{
                  sm: { span: 11 },
                  xl: { span: 12 }
                }}
                labelWrap
                onFinish={mutate}
                disabled={isSubmitting || isSubmittingWithdraw}
                initialValues={initialValues}
              >
                {task.appConfigs.fileConfig.clientDetailsActive && (
                  <Box title={task.appConfigs.fileConfig.clientDetailsLabel}>
                    {task.clientStructure && (
                      <DisplayDynamicFields fields={task.clientStructure} namespace={['client']} />
                    )}
                  </Box>
                )}

                <Box title={t('frontoffice.bankForm.title')}>
                  <BankDetailsForm appConfigs={task.appConfigs} nested />
                </Box>

                <Box title={t(`frontoffice.sectionTitle.${sectionStructure.slug}`)}>
                  {task.claimStructure && (
                    <DisplayDynamicFields
                      fields={task.claimStructure}
                      namespace={['claim', 'data']}
                    />
                  )}

                  <section>
                    <FormItem name={[...sectionStructureNamespace, 'type']} hidden>
                      <Input />
                    </FormItem>

                    {sectionStructure?.fields && (
                      <DisplayDynamicFields
                        fields={sectionStructure.fields}
                        namespace={[...sectionStructureNamespace, 'data']}
                      />
                    )}
                  </section>

                  {sectionStructure.items && (
                    <DisplayDynamicFields
                      fields={sectionStructure.items}
                      namespace={['item', 'data']}
                      dynamicFormItemOverrideProps={{ updateRate: false }}
                    />
                  )}
                </Box>

                <Space direction="vertical" size="large">
                  <Space direction="vertical">
                    <h3>{t('frontoffice.declarationAndAuthorisation')}</h3>
                    {renderAuthorizationCheckbox({
                      checked: isCorrectInformationChecked,
                      setChecked: setCorrectInformationChecked,
                      translationKey: 'frontoffice.correctInformationDeclarationText'
                    })}
                    {renderAuthorizationCheckbox({
                      checked: isPrivacyStatementRead,
                      setChecked: setPrivacyStatementRead,
                      translationKey: 'frontoffice.privacyStatementDeclarationText'
                    })}
                    {renderAuthorizationCheckbox({
                      checked: isPersonalInformationAllowed,
                      setChecked: setPersonalInformationAllowed,
                      translationKey: 'frontoffice.personalInformationDeclarationText'
                    })}
                  </Space>

                  <Row className="d-flex justify-content-end">
                    <Col xs={24} sm={8} lg={4}>
                      <Button
                        disabled={
                          !isCorrectInformationChecked ||
                          !isPrivacyStatementRead ||
                          !isPersonalInformationAllowed ||
                          isSubmitting
                        }
                        type="primary"
                        htmlType="submit"
                        className="w-100"
                        loading={isLoading || isSubmitting}
                      >
                        {t('globals.submit')}
                      </Button>
                    </Col>
                  </Row>
                </Space>
              </Form>
            </Space>
          </div>
        )}
      </div>
    </Layout>
  );
};

export default EditTask;
