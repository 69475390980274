import { Layout } from 'antd';
import dayjs from 'dayjs';

import { t } from '@gowgates/utils';
import { BrandConfigs } from '../../types';

type AppFooterProps = {
  brandConfigs: BrandConfigs;
  overrideText?: string;
};

export const AppFooter = ({ overrideText, brandConfigs }: AppFooterProps) => (
  <Layout.Footer style={{ background: brandConfigs.bgColor }}>
    <div className="container">
      {overrideText || t('globals.footer_text', { year: dayjs().year() })}
    </div>
  </Layout.Footer>
);
