import { useRef } from 'react';
import dayjs from 'dayjs';
import { Col, DatePicker, Input, Row, Tooltip } from 'antd';
import { useLocation } from 'react-router';
import { snakeCase } from 'change-case';
import qs from 'query-string';
import pluralize from 'pluralize';
import { t, formatPrice } from '@gowgates/utils';
import { CustomTableFilter, HighlightText } from '@gowgates/core';
import { DateFormatter } from '@gowgates/dynamic-fields';

const useLegacyTableSearch = ({ useEllipsis = false, columnWidth } = {}) => {
  const search = qs.parse(useLocation().search);
  const searchInput = useRef(null);

  const TooltipComponent = useEllipsis
    ? ({ title, children, ...props }) => (
        <Tooltip placement="topLeft" title={title} {...props}>
          {children}
        </Tooltip>
      )
    : /* Wrapper to avoid props warning because Fragment does not have title */ ({ children }) => (
        <>{children}</>
      );

  const sortProps = (column, sorter) => ({
    sorter: true,
    sortOrder: sorter.field === column && sorter.order
  });

  const handleReset = (clearFilters, confirm) => {
    clearFilters();
    confirm();
  };

  const filteredValue = (dataIndex, filters) => filters[dataIndex] || null;

  const textSearchProps = (dataIndex, filters) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => (
      <CustomTableFilter
        clearFilters={clearFilters}
        confirm={confirm}
        handleReset={handleReset}
        disabled={!selectedKeys[0]}
      >
        <Input
          ref={searchInput}
          value={selectedKeys[0]}
          onChange={(e) => setSelectedKeys(e.target.value ? [e.target.value] : [])}
          onPressEnter={() => confirm()}
          placeholder={t('globals.textToSearch')}
        />
      </CustomTableFilter>
    ),
    filteredValue: filteredValue(dataIndex, filters),
    onFilterDropdownOpenChange: (visible) => {
      if (visible) {
        setTimeout(() => searchInput.current?.select(), 100);
      }
    },
    render: (_text, record) => (
      <HighlightText searchWords={[search[snakeCase(dataIndex)]]} text={record[dataIndex]} />
    )
  });

  const dateSearchProps = (dataIndex, filters) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const values =
        selectedKeys &&
        (selectedKeys.length !== 0 ? [dayjs(selectedKeys[0]), dayjs(selectedKeys[1])] : undefined);

      return (
        <CustomTableFilter
          clearFilters={clearFilters}
          confirm={confirm}
          handleReset={handleReset}
          disabled={selectedKeys && !selectedKeys[0] && !selectedKeys[1]}
        >
          <DatePicker.RangePicker
            onChange={(value, strValue) => setSelectedKeys(value ? strValue : null)}
            value={values}
          />
        </CustomTableFilter>
      );
    },
    filteredValue: filteredValue(dataIndex, filters)
  });

  const numberSearchProps = (dataIndex, filters) => ({
    filterDropdown: ({ setSelectedKeys, selectedKeys, confirm, clearFilters }) => {
      const setMin = (event) => {
        if (event.target.value) {
          setSelectedKeys([`min:${event.target.value}`, selectedKeys[1]]);
        } else {
          setSelectedKeys([null, selectedKeys[1]]);
        }
      };

      const setMax = (event) => {
        if (event.target.value) {
          setSelectedKeys([selectedKeys[0], `max:${event.target.value}`]);
        } else {
          setSelectedKeys([selectedKeys[0], null]);
        }
      };

      return (
        <CustomTableFilter
          clearFilters={clearFilters}
          confirm={confirm}
          handleReset={handleReset}
          disabled={selectedKeys && !selectedKeys[0] && !selectedKeys[1]}
        >
          <Row gutter={12}>
            <Col span={12}>
              <Input
                ref={searchInput}
                value={(selectedKeys && selectedKeys[0] && selectedKeys[0].split(':')[1]) || null}
                onChange={setMin}
                onPressEnter={() => confirm()}
                placeholder={t('globals.from')}
              />
            </Col>
            <Col span={12}>
              <Input
                ref={searchInput}
                value={(selectedKeys && selectedKeys[1] && selectedKeys[1].split(':')[1]) || null}
                onChange={setMax}
                onPressEnter={() => confirm()}
                placeholder={t('globals.until')}
              />
            </Col>
          </Row>
        </CustomTableFilter>
      );
    },
    filteredValue: filteredValue(dataIndex, filters)
  });

  const filterProps = (column, values, filters) => ({
    filters: values,
    filteredValue: filters[column] || null
  });

  const formatText = (type, dataIndex, modelName, text, record) => {
    if (type === 'date') {
      return <DateFormatter value={record[dataIndex]} TooltipComponent={TooltipComponent} />;
    }

    if (type === 'datetime') {
      return (
        <DateFormatter
          value={record[dataIndex]}
          TooltipComponent={TooltipComponent}
          format="minute"
        />
      );
    }

    if (type === 'money') {
      return (
        <TooltipComponent title={formatPrice(text, record[`${dataIndex}Currency`])}>
          {formatPrice(text, record[`${dataIndex}Currency`])}
        </TooltipComponent>
      );
    }

    if (type === 'enum') {
      return text ? (
        <TooltipComponent title={t(`${modelName}.${pluralize(dataIndex)}.${text}`)}>
          {t(`${modelName}.${pluralize(dataIndex)}.${text}`)}
        </TooltipComponent>
      ) : (
        ''
      );
    }

    return <TooltipComponent title={text}>{text}</TooltipComponent>;
  };

  const columnProps = (dataIndex, modelName, config = {}) => {
    const title = config.title || t(`activerecord.attributes.${modelName}.${dataIndex}`);
    let props = {
      title: <TooltipComponent title={title}>{title}</TooltipComponent>,
      dataIndex,
      showSorterTooltip: !useEllipsis,
      width: typeof columnWidth === 'undefined' ? undefined : columnWidth,
      ellipsis: useEllipsis
        ? {
            showTitle: true
          }
        : undefined
    };

    if (config.sorter) {
      props = { ...props, ...sortProps(dataIndex, config.sorter) };
    }

    if (config.filters && config.filterOptions) {
      props = { ...props, ...filterProps(dataIndex, config.filterOptions, config.filters) };
    }

    if (config.filters && config.textFilter) {
      props = { ...props, ...textSearchProps(dataIndex, config.filters) };
    }

    if (config.filters && config.type === 'date') {
      props = { ...props, ...dateSearchProps(dataIndex, config.filters) };
    }

    if (config.filters && config.type === 'money') {
      props = { ...props, ...numberSearchProps(dataIndex, config.filters) };
    }

    if (config.cellContainer) {
      const CellContainerComponent = config.cellContainer;
      props.render = (text, record) => (
        <CellContainerComponent record={record}>
          {formatText(config.type, dataIndex, modelName, text, record)}
        </CellContainerComponent>
      );
    } else {
      props.render =
        config.render ||
        ((text, record) => formatText(config.type, dataIndex, modelName, text, record));
    }

    return props;
  };

  return {
    sortProps,
    textSearchProps,
    filterProps,
    columnProps,
    TooltipComponent
  };
};

export default useLegacyTableSearch;
