import { Col, Row, Form, Input } from 'antd';
import { t } from '@gowgates/utils';
import { FormItem } from '../../forms';

export const NumberValidations = () => (
  <Form.Item label={t('field.limits')} className="no-margin">
    <Row gutter={10}>
      <Col span={12}>
        <FormItem name="minimum">
          <Input placeholder={t('activerecord.attributes.field.minimum')} />
        </FormItem>
      </Col>
      <Col span={12}>
        <FormItem name="maximum">
          <Input placeholder={t('activerecord.attributes.field.maximum')} />
        </FormItem>
      </Col>
    </Row>
  </Form.Item>
);
