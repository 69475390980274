import React from 'react';
import PropTypes from 'prop-types';
import { Form, message } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addErrorsFromAPIInForm } from '@gowgates/utils';
import { updateUser } from '../../api/endpoints';
import UserForm from './components/Form';

const EditUserForm = ({ user, onCancel, onSuccess }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => updateUser(user.id, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.setQueryData(['user', user.id], data);
      queryClient.invalidateQueries({ queryKey: ['resourceUsers'] });
      message.success('User updated');

      onSuccess();
    }
  });

  return (
    <Form form={form} onFinish={mutate} initialValues={user} layout="vertical" disabled={isLoading}>
      <UserForm user={user} onCancel={onCancel} />
    </Form>
  );
};

EditUserForm.defaultProps = {
  onCancel: null,
  onSuccess: () => {}
};

EditUserForm.propTypes = {
  user: PropTypes.object.isRequired,
  onCancel: PropTypes.func,
  onSuccess: PropTypes.func
};

export default EditUserForm;
