import React, { useContext } from 'react';
import { Button } from 'antd';
import { ArrowLeftOutlined } from '@ant-design/icons';
import { useNavigate } from 'react-router';
import { t } from '@gowgates/utils';
import { CreateStepsContext } from '../../../contexts/CreateStepsContext';

const PreviousStep = () => {
  const { previousTab } = useContext(CreateStepsContext);
  const navigate = useNavigate();

  const back = () => {
    navigate(`../${previousTab.path}`);
  };

  return (
    <Button type="link" onClick={back} icon={<ArrowLeftOutlined />} disabled={!previousTab}>
      {t('globals.back')}
    </Button>
  );
};

export default PreviousStep;
