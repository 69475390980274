import React from 'react';
import PropTypes from 'prop-types';
import { Timeline, Typography } from 'antd';
import { t } from '@gowgates/utils';
import TwoLineDate from './TwoLineDate';

const History = ({ history }) => {
  const items = history.map((entry) => ({
    label: <TwoLineDate date={entry.createdAt} />,
    children: (
      <p>
        {entry.name}
        <br />
        <Typography.Text type="secondary" italic>
          <small>{t('user.by', { name: entry.user ? entry.user.name : t('user.unknown') })}</small>
        </Typography.Text>
      </p>
    )
  }));

  return <Timeline mode="left" items={items} />;
};

History.defaultProps = {
  history: []
};

History.propTypes = {
  history: PropTypes.array
};

export default History;
