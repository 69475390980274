import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { updateTenant } from '../../api/endpoints';
import { useTenant } from '../../hooks/useTenant';
import Page from '../../components/Page';
import Box from '../../components/Box';
import EditableDescriptions from '../../components/EditableDescriptions';
import usePermissions from '../../hooks/usePermissions';
import FullLoader from '../../components/FullLoader';
import TenantDetailsForm from './forms/TenantDetails';
import TenantDescriptions from './descriptions/Tenant';

const GeneralConfig = () => {
  const title = t('globals.general');
  usePageTitle(title);

  const { permissions } = usePermissions();

  const queryClient = useQueryClient();
  const { isFetching, tenant } = useTenant();

  if (isFetching) {
    return <FullLoader />;
  }

  return (
    <Page backUrl="/" title={title}>
      <Box>
        <EditableDescriptions
          title={t('tenant.details')}
          showPermission={permissions.updateTenant}
          editPermission={permissions.updateTenant}
          descriptions={<TenantDescriptions tenant={tenant} />}
          form={<TenantDetailsForm />}
          model={tenant}
          updateModel={(values) => updateTenant(values)}
          onSuccess={() => {
            queryClient.invalidateQueries({ queryKey: ['tenant'] });
          }}
        />
      </Box>
    </Page>
  );
};

export default GeneralConfig;
