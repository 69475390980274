import React from 'react';
import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import SectionDetails from '../../components/Sections/Details';
import Sections from '../../components/Sections';
import useClaim from '../../../../hooks/useClaim';
import AddSection from './AddSection';

const ShowItems = () => {
  const { claim } = useClaim();
  usePageTitle(`${t('claim.id', { id: claim.id })} - ${t('activerecord.models.item.other')}`);

  if (!claim.permissions.showItems) {
    return null;
  }

  return (
    <div className="claim-items">
      <Sections claim={claim}>
        {(sectionStructure, section) => (
          <SectionDetails sectionStructure={sectionStructure} section={section} claim={claim} />
        )}
      </Sections>

      <AddSection claim={claim} />
    </div>
  );
};

export default ShowItems;
