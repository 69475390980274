import { client, extractData } from '@gowgates/api-client';
import { useQuery } from '@tanstack/react-query';

import { Role } from '../../types';

export const resourceRolesQuery = ['resourceRoles'];

const getResourceRoles = () =>
  client()
    .get('resources/roles')
    .then(extractData<Pick<Role, 'id' | 'name'>[]>);

export const useResourceRolesQuery = () => {
  const { data, ...query } = useQuery({
    queryKey: resourceRolesQuery,
    queryFn: getResourceRoles,
    placeholderData: [],
    staleTime: Infinity
  });

  const findRole = (roleId?: number) => data?.find((role) => role.id === roleId);
  const roleName = (roleId?: number, empty = '-') => findRole(roleId)?.name || empty;
  const roleFilters = data?.map((role) => ({ text: role.name, value: role.id }));

  return { ...query, findRole, roleName, roleFilters, roles: data };
};
