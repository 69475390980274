import React, { useEffect, useMemo, useState } from 'react';
import qs from 'query-string';
import { useLocation } from 'react-router';
import { useQuery } from '@tanstack/react-query';
import { t, DEFAULT_PAGINATION } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { getClaimProgresses } from '../../../api/endpoints';
import { updatedFiltersFromParams, updatedSorterFromParams } from '../../../utils/tables';
import Page from '../../../components/Page';
import AllPaymentsTable from './Table';
import ExportBtn from './ExportBtn';

const Reports = () => {
  const { search: searchQuery } = useLocation();
  const search = useMemo(() => qs.parse(searchQuery), [searchQuery]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [sorter, setSorter] = useState({});
  usePageTitle(t('activerecord.models.report.other'));

  const { isFetching, data: items } = useQuery({
    queryKey: ['claimsProgress', search],
    queryFn: () => getClaimProgresses(search)
  });

  useEffect(() => {
    if (items) setPagination((prevPagination) => ({ ...prevPagination, ...items.pagination }));
  }, [items]);

  useEffect(() => {
    setSorter(updatedSorterFromParams(search));
    setFilters(updatedFiltersFromParams(search));
  }, [search]);

  return (
    <Page title={t('activerecord.models.report.other')} actions={<ExportBtn />}>
      <AllPaymentsTable
        claims={items?.rows}
        loading={isFetching}
        pagination={pagination}
        sorter={sorter}
        filters={filters}
      />
    </Page>
  );
};

export default Reports;
