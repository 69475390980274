import { useRef, useState } from 'react';
import get from 'lodash.get';
import maxBy from 'lodash.maxby';
import { Form, Row, Col, Button, Select, Input, FormListFieldData, InputRef } from 'antd';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors,
  DragEndEvent
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';

import { t } from '@gowgates/utils';
import { Field, FieldOption } from '../../../types';
import { useLists } from '../../../hooks';
import { Option } from './Option';

const getLastIndex = (options?: FieldOption[]) => {
  if (!options) return -1;

  return get(maxBy(options, 'key'), 'key', -1) as number;
};

type DisplayOptionsKeys = 'select' | 'radio' | 'checkbox';

export const Choices = ({ field }: { field: Partial<Field> }) => {
  const form = Form.useFormInstance<Field>();
  // TODO: create another component to load lists only if list option is selected
  const { lists } = useLists();
  const refs = useRef<(InputRef | null)[]>([]);
  const [lastKey, setLastKey] = useState(getLastIndex(field.options));
  const displayOptions = [
    { key: 'radio', value: t('field.displayTypes.radio') },
    { key: 'checkbox', value: t('field.displayTypes.checkbox') },
    { key: 'select', value: t('field.displayTypes.selectBox') }
  ];
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );

  const addRef = (element: InputRef | null, index: number) => {
    refs.current[index] = element;
  };

  const focusElement = (index?: number) => {
    setTimeout(() => {
      const activeRefs = refs.current.filter((ref) => !!ref);
      const newIndex = typeof index === 'undefined' ? activeRefs.length - 1 : index;
      refs.current[newIndex]?.input?.focus();
    }, 10);
  };

  const findDisplayType = (): DisplayOptionsKeys => {
    if (field.displayType === 'select_box') {
      return 'select';
    }

    if (field.multiple) {
      return 'checkbox';
    }

    return 'radio';
  };

  const [formattedDisplayType, setFormattedDisplayType] = useState(findDisplayType());

  const onChangeDisplayType = (value: DisplayOptionsKeys) => {
    setFormattedDisplayType(value);
    if (value === 'select') {
      form.setFieldsValue({ displayType: 'select_box', multiple: false });
    } else if (value === 'radio') {
      form.setFieldsValue({ displayType: 'radio', multiple: false });
    } else if (value === 'checkbox') {
      form.setFieldsValue({ displayType: 'radio', multiple: true });
    }
  };

  const handleDragEnd = (
    event: DragEndEvent,
    options: FormListFieldData[],
    move: (from: number, to: number) => void
  ) => {
    const { active, over } = event;

    if (active.id !== over?.id) {
      /* @ts-expect-error id is not a UniqueIdentifier, but the moved object */
      const oldIndex = options.indexOf(active.id);
      /* @ts-expect-error id is not a UniqueIdentifier, but the moved object */
      const newIndex = options.indexOf(over.id);
      move(oldIndex, newIndex);
    }
  };

  const optionsTypeUpdated = (prev: Field, current: Field) =>
    prev.optionsType !== current.optionsType;

  return (
    <div className="field-choices">
      {/* The fields need to exist because otherwise form.setFieldsValue wont work */}
      <div className="d-none">
        <Form.Item name="displayType" hidden>
          <Input />
        </Form.Item>
        <Form.Item name="multiple" hidden>
          <Input />
        </Form.Item>
      </div>

      <Row align="middle" gutter={10} className="field-choices-footer">
        <Col span={3}>List type :</Col>
        <Col span={6}>
          <Form.Item name="optionsType">
            <Select>
              <Select.Option value="local" key="local">
                Local values
              </Select.Option>
              <Select.Option value="list" key="list">
                List
              </Select.Option>
            </Select>
          </Form.Item>
        </Col>
        <Col span={4} className="text-right">
          {t('formBuilder.displayChoicesAs')}:
        </Col>
        <Col span={6}>
          <Form.Item>
            <Select onChange={onChangeDisplayType} value={formattedDisplayType}>
              {displayOptions.map((displayOption) => (
                <Select.Option value={displayOption.key} key={displayOption.key}>
                  {displayOption.value}
                </Select.Option>
              ))}
            </Select>
          </Form.Item>
        </Col>
      </Row>

      <Form.Item noStyle shouldUpdate={optionsTypeUpdated}>
        {({ getFieldValue }) =>
          getFieldValue('optionsType') === 'local' ? (
            <Form.List name="options">
              {(options, { add, remove, move }) => (
                <>
                  <DndContext
                    sensors={sensors}
                    collisionDetection={closestCenter}
                    onDragEnd={(event) => handleDragEnd(event, options, move)}
                  >
                    {/* @ts-expect-error SortableContext accepts any array */}
                    <SortableContext items={options} strategy={verticalListSortingStrategy}>
                      {options.map((option) => (
                        <Option
                          key={option.key}
                          option={option}
                          add={add}
                          remove={remove}
                          form={form}
                          lastKey={lastKey}
                          setLastKey={setLastKey}
                          addRef={addRef}
                          focusElement={focusElement}
                        />
                      ))}
                    </SortableContext>
                  </DndContext>

                  <div className="field-choices-footer">
                    <Button
                      onClick={() => {
                        const key = lastKey + 1;
                        add({ key });
                        setLastKey(key);
                        focusElement();
                      }}
                    >
                      {t('formBuilder.addOption')}
                    </Button>
                  </div>
                </>
              )}
            </Form.List>
          ) : (
            <Form.Item name="listItemId" style={{ marginBottom: '24px' }}>
              <Select options={lists} fieldNames={{ label: 'name', value: 'id' }} />
            </Form.Item>
          )
        }
      </Form.Item>
    </div>
  );
};
