import { useQuery } from '@tanstack/react-query';
import { client, extractData } from '@gowgates/api-client';

export const queryFn = () => client().get('resources/app_configs').then(extractData);

export const queryKey = ['appConfigs'];

export const useAppConfigs = () => {
  const query = useQuery({
    queryKey,
    queryFn,
    placeholderData: {},
    staleTime: Infinity
  });

  return {
    appConfigs: query.data,
    isfetched: query.isFetched
  };
};
