import React from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Modal } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormItem, FilePicker } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createListItem } from '../../api/endpoints';
import ModalFormFooter from '../../components/ModalFormFooter';
import useAppConfigs from '../../hooks/useAppConfigs';

const NewListItemModal = ({ isOpen, setOpen }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const { appConfigs } = useAppConfigs();

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => createListItem(values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({ queryKey: ['listItems'] });
    }
  });

  return (
    <Modal title={t('list.new')} open={isOpen} onCancel={closeModal} footer={null}>
      <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
        <FormItem name="name" model="listItem" required>
          <Input />
        </FormItem>

        <FormItem name="file" model="listItem">
          <FilePicker accept={appConfigs.global?.allowedMimeTypes?.flat()?.join(',')} />
        </FormItem>

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

NewListItemModal.defaultProps = {
  isOpen: false
};

NewListItemModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired
};

export default NewListItemModal;
