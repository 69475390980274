import { useEffect, useRef } from 'react';
import { Button, Form, InputRef, Space, message } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';

import { t } from '@gowgates/utils';

import { EmailInput } from '../components/EmailInput';
import { AuthForm } from '../components/AuthForm';
import { resendUnlock } from '../api/endpoints';

type UnlockFormProps = { showInstructions?: boolean };

export const UnlockForm = ({ showInstructions = false }: UnlockFormProps) => {
  const inputRef = useRef<InputRef>(null);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const { isPending, mutate } = useMutation({
    mutationFn: resendUnlock,
    onSuccess: () => {
      message.success(t('devise.unlocks.sendInstructions'), 10);
      navigate('/login');
    }
  });

  return (
    <Space direction="vertical" size="large" className="w-100">
      <AuthForm form={form} onFinish={mutate} disabled={isPending}>
        {showInstructions && <p>{t('devise.unlocks.enterMailBelow')}</p>}

        <EmailInput ref={inputRef} />

        <Button type="primary" htmlType="submit" className="w-100" loading={isPending}>
          {t('devise.unlocks.new.resendUnlockInstructions')}
        </Button>
      </AuthForm>
    </Space>
  );
};
