import { ModelWithData } from '@gowgates/utils';
import { evalConditionalField } from '../../utils';
import { Field } from '../../types';

type ConditionalFieldProps = {
  model: ModelWithData;
  field: Field;
  excludeReadonly?: boolean;
  children: React.ReactNode;
};

export const ConditionalField = ({
  model,
  field,
  excludeReadonly = false,
  children
}: ConditionalFieldProps) => {
  if (excludeReadonly && field.readonly) {
    return null;
  }

  if (!evalConditionalField({ field, currentValues: model.data })) {
    return null;
  }

  return children;
};
