import { Space, Typography } from 'antd';
import { t } from '@gowgates/utils';
import { Field } from '../../../types';

export const Question = ({ field }: { field: Field }) => {
  if (!field.label) {
    return (
      <span className="field-label">
        <Typography.Text type="secondary" italic>
          {t('formBuilder.clickToEdit')}
        </Typography.Text>
      </span>
    );
  }

  return (
    <div>
      <Space className="field-label">
        {field.heading && (
          <Typography.Text type="secondary">{`${field.heading}. `}</Typography.Text>
        )}

        <span>{field.label}</span>

        {field.required && <Typography.Text type="danger">*</Typography.Text>}
      </Space>
    </div>
  );
};
