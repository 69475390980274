import React from 'react';
import PropTypes from 'prop-types';

const ModalFooter = ({ children, spaceBetween }) => (
  <footer className={`d-flex ${spaceBetween ? 'justify-content-between' : 'justify-content-end'}`}>
    {children}
  </footer>
);

ModalFooter.defaultProps = {
  spaceBetween: false
};

ModalFooter.propTypes = {
  children: PropTypes.node.isRequired,
  spaceBetween: PropTypes.bool
};

export default ModalFooter;
