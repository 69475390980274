import { useAppConfigs } from '../../hooks';
import { FieldConditionQuery } from '../../types';
import { ConditionRulesDescription } from '../ConditionRulesDescription';

type ConditionExpressionDescriptionProps = {
  model?: string;
  conditionExpression?: FieldConditionQuery;
};

export const ConditionExpressionDescription = ({
  model,
  conditionExpression
}: ConditionExpressionDescriptionProps) => {
  const { appConfigs } = useAppConfigs();
  const fields = model ? appConfigs[model]?.staticFields : [];

  if (!conditionExpression) {
    return null;
  }

  return (
    <ConditionRulesDescription
      rules={conditionExpression.rules}
      combinator={conditionExpression.combinator}
      fields={fields}
    />
  );
};
