import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router';
import qs from 'query-string';
import { t, DEFAULT_PAGINATION } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import usePermissions from '../../hooks/usePermissions';
import { updatedFiltersFromParams, updatedSorterFromParams } from '../../utils/tables';
import Page from '../../components/Page';
import { getClaims } from '../../api/endpoints';
import useStructures from '../../hooks/useStructures';
import ClaimsTable from './Table';
import TableHeader from './TableHeader';
import ClaimsActions from './Actions';

const Claims = () => {
  const { search: searchQuery } = useLocation();
  const search = useMemo(() => qs.parse(searchQuery), [searchQuery]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [sorter, setSorter] = useState({});
  usePageTitle(t('activerecord.models.claim.other'));

  const { permissions } = usePermissions();
  const [selectedRows, setSelectedRows] = useState([]);

  const { activeStructures } = useStructures();

  const { isFetching, data: claims } = useQuery({
    queryKey: ['claims', search],
    queryFn: () => getClaims(search)
  });

  useEffect(() => {
    if (claims) setPagination((prevPagination) => ({ ...prevPagination, ...claims.pagination }));
  }, [claims]);

  useEffect(() => {
    setSorter(updatedSorterFromParams(search));
    setFilters(updatedFiltersFromParams(search));
  }, [search]);

  const hasRowSelection = permissions.assignClaimsBulk || permissions.assignClaimsToMeBulk;
  const rowSelection = hasRowSelection ? { type: 'checkbox', onChange: setSelectedRows } : false;

  return (
    <Page
      title={t('activerecord.models.claim.other')}
      actions={<ClaimsActions structures={activeStructures} />}
      overlap
    >
      <TableHeader selectedRows={selectedRows} />

      <div className="page-table">
        <ClaimsTable
          claims={claims?.rows}
          loading={isFetching}
          pagination={pagination}
          sorter={sorter}
          filters={filters}
          structures={activeStructures}
          rowSelection={rowSelection}
        />
      </div>
    </Page>
  );
};

export default Claims;
