import React, { useEffect, useRef, useState } from 'react';
import PropTypes from 'prop-types';
import { Form, Input, Space, Tooltip, Typography } from 'antd';
import { EditOutlined, EnterOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { sendClaimAction } from '../../../../api/endpoints';
import useClaim from '../../../../hooks/useClaim';

const ClaimEditableField = ({
  claim,
  field,
  prefix,
  defaultValue,
  permission,
  formatWith,
  action,
  infoElement
}) => {
  const inputRef = useRef(null);
  const [isEditing, setEditing] = useState(false);
  const [form] = Form.useForm();
  const { refreshClaim } = useClaim();

  const { mutate } = useMutation({
    mutationFn: (values) => sendClaimAction(claim.id, action, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      setEditing(false);
      refreshClaim(data);
    }
  });

  useEffect(() => {
    if (isEditing) {
      inputRef?.current?.focus();
    }
  }, [isEditing]);

  const checkEsc = (event) => {
    if (event.keyCode === 27) {
      setEditing(false);
    }
  };

  if (isEditing) {
    return (
      <Form form={form} initialValues={{ [field]: claim[field] }} onFinish={mutate}>
        <FormItem name={field} className="no-margin">
          <Input
            onKeyUp={checkEsc}
            addonBefore={prefix}
            id={`editable-${field}`}
            ref={inputRef}
            suffix={<EnterOutlined />}
          />
        </FormItem>
      </Form>
    );
  }

  return (
    <div className="d-flex justify-content-between w-100">
      {formatWith(claim[field] || defaultValue)}

      <Space>
        {infoElement}
        {permission && (
          <Tooltip title={t('globals.edit')}>
            <Typography.Link onClick={() => setEditing(true)}>
              <EditOutlined />
            </Typography.Link>
          </Tooltip>
        )}
      </Space>
    </div>
  );
};

ClaimEditableField.defaultProps = {
  prefix: '',
  defaultValue: '',
  permission: false,
  formatWith: (val) => val,
  infoElement: null
};

ClaimEditableField.propTypes = {
  claim: PropTypes.object.isRequired,
  field: PropTypes.string.isRequired,
  prefix: PropTypes.string,
  defaultValue: PropTypes.string,
  permission: PropTypes.bool,
  formatWith: PropTypes.func,
  action: PropTypes.string.isRequired,
  infoElement: PropTypes.node
};

export default ClaimEditableField;
