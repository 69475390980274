import React, { useEffect } from 'react';
import { Divider, Modal, Table, message } from 'antd';
import { ExclamationCircleOutlined } from '@ant-design/icons';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { t } from '@gowgates/utils';
import { setPageTitle } from '@gowgates/core';
import { deleteRole, getRole, updateRole } from '../../api/endpoints';
import FullLoader from '../../components/FullLoader';
import Page from '../../components/Page';
import { showBaseErrors } from '../../utils/messages';
import PageContextMenu from '../../components/PageContextMenu';
import AvatarAndName from '../../components/AvatarAndName';
import EditableDescriptions from '../../components/EditableDescriptions';
import UserCol from '../users/UserCol';
import RoleDetailsFormContent from './components/RoleDetailsFormContent';
import RoleDescriptions from './components/RoleDetails';

const Role = () => {
  const roleId = Number(useParams().roleId);
  const queryClient = useQueryClient();
  const navigate = useNavigate();

  const { mutate: mutateDestroy } = useMutation({
    mutationFn: () => deleteRole(roleId),
    onError: (error) => {
      showBaseErrors(error.response.data);
    },
    onSuccess: () => {
      message.success(`${t('globals.deleted')}!`);
      queryClient.invalidateQueries({ queryKey: ['roles'] }).then(() => {
        navigate('/roles');
      });
    }
  });

  const { isLoading, data: role } = useQuery({
    queryKey: ['role', roleId],
    queryFn: () => getRole(roleId)
  });

  useEffect(() => {
    if (!role) return;
    setPageTitle(`${role.name} | ${t('activerecord.models.role.other')}`);
  }, [role]);

  if (isLoading) {
    return <FullLoader />;
  }

  const destroy = () => {
    Modal.confirm({
      title: t('globals.areYouSure'),
      icon: <ExclamationCircleOutlined />,
      okText: t('globals.yesNo.true'),
      okType: 'danger',
      cancelText: t('globals.yesNo.false'),
      onOk() {
        mutateDestroy();
      }
    });
  };

  const contextMenuItems = [];
  if (role.permissions.destroy) {
    contextMenuItems.push({ key: 'delete', label: t('globals.delete'), onClick: destroy });
  }

  return (
    <Page
      title={role.name}
      backUrl="/roles"
      summary={<></>}
      contextMenu={<PageContextMenu items={contextMenuItems} />}
    >
      <EditableDescriptions
        title={t('globals.details')}
        showPermission
        editPermission={role.permissions.update}
        descriptions={<RoleDescriptions role={role} />}
        form={<RoleDetailsFormContent />}
        model={role}
        updateModel={(values) => updateRole(roleId, values)}
        onSuccess={(data) => {
          queryClient.setQueryData(['role', roleId], data);
        }}
      />

      <Divider orientation="left">{t('activerecord.models.user.other')}</Divider>

      <Table
        dataSource={role.members}
        rowKey={(record) => record.id}
        loading={isLoading}
        pagination={false}
        className="box-table"
      >
        <Table.Column
          title={t('activerecord.attributes.user.name')}
          key="name"
          render={(record) => (
            <UserCol user={record}>
              <AvatarAndName user={record} />
            </UserCol>
          )}
        />
        <Table.Column
          title={t('activerecord.attributes.user.email')}
          dataIndex="email"
          render={(text, record) => <UserCol user={record}>{text}</UserCol>}
        />
      </Table>
    </Page>
  );
};

export default Role;
