import { t } from '@gowgates/utils';
import { Form, GetProps } from 'antd';

type FormItemProps = GetProps<typeof Form.Item>;
type ModelAttributeLabelProps = Pick<FormItemProps, 'label' | 'name'> & { model?: string };

const labelForAttributeModel = ({ label, name, model }: ModelAttributeLabelProps) => {
  let finalLabel = null;

  if (label) {
    finalLabel = label;
  } else if (model) {
    if (typeof name === 'string') {
      finalLabel = t(`activerecord.attributes.${model}.${name}`);
    } else {
      const names = [name].flat();
      finalLabel = t(`activerecord.attributes.${model}.${names[names.length - 1]}`);
    }
  }

  return finalLabel;
};

export const useModelAttributeLabel = (props: ModelAttributeLabelProps) => ({
  label: labelForAttributeModel(props)
});

useModelAttributeLabel.labelForAttributeModel = labelForAttributeModel;
