import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { FrownOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';

const ClaimComplaint = ({ claim }) => {
  if (!claim.complaint) {
    return null;
  }

  return (
    <Alert
      message={t('claim.complaintExplanation', {
        type: t(`claim.complaints.${claim.complaint}`).toLowerCase()
      })}
      type="error"
      showIcon
      icon={<FrownOutlined />}
    />
  );
};

ClaimComplaint.propTypes = {
  claim: PropTypes.object.isRequired
};

export default ClaimComplaint;
