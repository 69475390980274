import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Outlet, useParams } from 'react-router';
import { getReportStructure } from '../../../api/endpoints';
import FullLoader from '../../../components/FullLoader';
import Page from '../../../components/Page';
import PublishBtn from '../components/PublishBtn';
import ReportStructureNav from './components/ReportStructureNav';
import ReportStructureSummary from './components/ReportStructureSummary';

const StructureReport = () => {
  const structureId = Number(useParams().structureId);
  const reportId = Number(useParams().reportId);

  const { isLoading, data } = useQuery({
    queryKey: ['reportStructure', reportId],
    queryFn: () => getReportStructure(structureId, reportId)
  });

  if (isLoading) {
    return <FullLoader />;
  }

  return (
    <Page
      title={data.name}
      backUrl={`/structures/${structureId}/reports`}
      actions={<PublishBtn claimStructure={data} />}
      summary={<ReportStructureSummary />}
      nav={<ReportStructureNav />}
    >
      <Outlet />
    </Page>
  );
};

export default StructureReport;
