import {
  FileExcelTwoTone,
  FileImageTwoTone,
  FileTwoTone,
  FilePdfTwoTone,
  FilePptTwoTone,
  FileTextTwoTone,
  FileWordTwoTone,
  FileZipTwoTone
} from '@ant-design/icons';

const mappings: { [key: string]: string } = {
  'image/apng': 'image',
  'image/bmp': 'image',
  'image/gif': 'image',
  'image/x-icon': 'image',
  'image/jpeg': 'image',
  'image/png': 'image',
  'image/svg+xml': 'image',
  'image/tiff': 'image',
  'image/webp': 'image',
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet': 'excel',
  'application/vnd.ms-excel': 'excel',
  'text/csv': 'excel',
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document': 'word',
  'application/msword': 'word',
  'application/vnd.openxmlformats-officedocument.presentationml.presentation': 'ppt',
  'application/vnd.ms-powerpoint': 'ppt',
  'application/pdf': 'pdf',
  'application/zip': 'zip',
  'application/vnd.rar': 'zip',
  'application/x-tar': 'zip',
  'application/gzip': 'zip',
  'text/plain': 'txt',
  'application/rtf': 'txt'
};

const components: { [key: string]: { component: typeof FileImageTwoTone; color?: string } } = {
  image: { component: FileImageTwoTone },
  excel: { component: FileExcelTwoTone, color: '#15a556' },
  word: { component: FileWordTwoTone, color: '#0067b8' },
  ppt: { component: FilePptTwoTone, color: '#c43e1c' },
  pdf: { component: FilePdfTwoTone, color: '#bf0c00' },
  zip: { component: FileZipTwoTone, color: '#e2c844' },
  txt: { component: FileTextTwoTone, color: '#999999' }
};

type FileIconProps = {
  type: string;
};

export const FileIcon = ({ type }: FileIconProps) => {
  if (components[mappings[type]]) {
    const Component = components[mappings[type]].component;
    const { color } = components[mappings[type]];

    return <Component className="file-icon" twoToneColor={color} />;
  }

  return <FileTwoTone className="file-icon" />;
};
