import React, { useContext, useEffect } from 'react';
import PropTypes from 'prop-types';
import last from 'lodash.last';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { t } from '@gowgates/utils';
import { CreateStepsContext } from '../../../contexts/CreateStepsContext';
import ClaimContextMenu from '../show/components/ClaimContextMenu';
import Page from '../../../components/Page';
import CreateClaimNav from './Nav';

const CreateClaim = ({ claim }) => {
  const { claimTab, setCurrentPath } = useContext(CreateStepsContext);
  const { pathname } = useLocation();
  const navigate = useNavigate();

  useEffect(() => {
    // Updates the selected tab when the location changes
    const currentPath = last(pathname.split('/'));
    setCurrentPath(currentPath);

    if (pathname === `/claims/${claim.id}` && claimTab.path) {
      navigate(claimTab.path, { replace: true });
    }
  }, [pathname, claimTab]);

  return (
    <Page title={t('claim.new')} contextMenu={<ClaimContextMenu claim={claim} />} overlap>
      <CreateClaimNav claim={claim} />

      <Outlet />
    </Page>
  );
};

CreateClaim.propTypes = {
  claim: PropTypes.object.isRequired
};

export default CreateClaim;
