import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Modal, Space, message } from 'antd';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import { createStructure, updateStructure } from '../../../api/endpoints';
import useStructures from '../../../hooks/useStructures';

const PublishBtn = ({ claimStructure }) => {
  const queryClient = useQueryClient();
  const { structures: allStructures } = useStructures();
  const [isModalOpen, setModalOpen] = useState(false);

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (fn) => fn(claimStructure.id),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['claimStructure', claimStructure.id] });
      queryClient.invalidateQueries({ queryKey: ['structures'] });
      message.success(t('structure.saved'));
      closeModal();
    }
  });

  if (!claimStructure.dirty) {
    return null;
  }

  const structures = allStructures.filter(
    (structure) => structure.claimStructureId === claimStructure.id
  );

  const showModal = () => {
    setModalOpen(true);
  };

  const closeModal = () => {
    setModalOpen(false);
  };

  const handleCreate = () => {
    mutate(createStructure);
  };

  const handleUpdate = () => {
    mutate(updateStructure);
  };

  const onClick = () => {
    if (structures.length === 0) {
      handleCreate();
    } else {
      showModal();
    }
  };

  return (
    <>
      <Button type="primary" onClick={onClick}>
        {t('globals.publish')}
      </Button>

      <Modal
        title={t('claimStructure.publish')}
        open={isModalOpen}
        onCancel={closeModal}
        footer={null}
        width={800}
      >
        {/* eslint-disable react/no-danger */}
        <p dangerouslySetInnerHTML={{ __html: t('claimStructure.usedInClaims') }} />
        <p dangerouslySetInnerHTML={{ __html: t('claimStructure.updateExplanation') }} />
        <div dangerouslySetInnerHTML={{ __html: t('claimStructure.updateRules') }} />
        <p dangerouslySetInnerHTML={{ __html: t('claimStructure.newVersionExplanation') }} />
        <p
          className="spacer-bottom"
          dangerouslySetInnerHTML={{ __html: t('claimStructure.futureClaimsExplanation') }}
        />

        <footer className="d-flex justify-content-between">
          <Button onClick={closeModal} disabled={isLoading}>
            {t('globals.close')}
          </Button>
          <div>
            <Space>
              <Button type="primary" disabled={isLoading} onClick={handleUpdate}>
                {t('structure.updateExisting')}
              </Button>
              <Button type="primary" disabled={isLoading} onClick={handleCreate}>
                {t('structure.newVersion')}
              </Button>
            </Space>
          </div>
        </footer>
      </Modal>
    </>
  );
};

PublishBtn.propTypes = {
  claimStructure: PropTypes.object.isRequired
};

export default PublishBtn;
