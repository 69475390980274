import React from 'react';
import NextStep from './NextStep';
import PreviousStep from './PreviousStep';

const Footer = () => (
  <div className="d-flex justify-content-between">
    <PreviousStep />

    <NextStep />
  </div>
);

export default Footer;
