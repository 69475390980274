import React, { useEffect, useMemo, useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router';
import qs from 'query-string';
import { t, DEFAULT_PAGINATION } from '@gowgates/utils';
import { useAuth } from '@gowgates/core';
import { usePageTitle } from '@gowgates/core';
import { getStatistics, getTasks } from '../../api/endpoints';
import TasksTable from '../tasks/Table';
import { updatedFiltersFromParams, updatedSorterFromParams } from '../../utils/tables';
import Page from '../../components/Page';
import Statistics from './Statistics';

const Home = () => {
  const { search: searchQuery } = useLocation();
  const urlSearch = useMemo(() => qs.parse(searchQuery), [searchQuery]);
  const { currentUser } = useAuth();
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [sorter, setSorter] = useState({});
  const search = useMemo(
    () => ({ user_id: currentUser.id, ...urlSearch }),
    [currentUser.id, urlSearch]
  );
  usePageTitle('Dashboard');

  const { isFetching: isFetchingStats, data: statistics } = useQuery({
    queryKey: ['statistics'],
    queryFn: getStatistics
  });

  const { isFetching: isFetchingTasks, data: tasks } = useQuery({
    queryKey: ['tasks', search],
    queryFn: () => getTasks(search)
  });

  useEffect(() => {
    if (tasks) setPagination((prevPagination) => ({ ...prevPagination, ...tasks.pagination }));
  }, [tasks]);

  useEffect(() => {
    setSorter(updatedSorterFromParams(search));
    setFilters(updatedFiltersFromParams(search));
  }, [search]);

  return (
    <Page title={t('statistics.mine')} overlap>
      <Statistics statistics={statistics} loading={isFetchingStats} />

      <h3>{t('statistics.openTasks')}</h3>

      <div className="page-table">
        <TasksTable
          tasks={tasks?.rows}
          loading={isFetchingTasks}
          pagination={pagination}
          sorter={sorter}
          filters={filters}
          rowSelection={false}
          showAssignee={false}
        />
      </div>
    </Page>
  );
};

export default Home;
