import { Form, Button } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { camelCase } from 'change-case';
import { t, AUSTRALIA, AUSTRALIAN_DOLLAR } from '@gowgates/utils';

import { isAddressField, isMoneyField, isPhoneField } from '../../../utils';
import { Field } from '../../../types';
import { DynamicFormItem } from '../DynamicFormItem';

import styles from './ArrayChildren.module.scss';

type ArrayChildrenProps = {
  field: Field;
  fieldName: (string | number)[];
};

export const ArrayChildren = ({ field, fieldName }: ArrayChildrenProps) => {
  const readOnly = field.readOnly || false;
  const addEntry = (add: (initialData: { [key: string]: string }) => void) => {
    const moneyFields = field.children.filter((child) => isMoneyField(child));
    const phoneFields = field.children.filter((child) => isPhoneField(child));
    const addressFields = field.children.filter((child) => isAddressField(child));
    const initialData: { [key: string]: string } = {};
    moneyFields.forEach(
      (field) => (initialData[`${camelCase(field.name)}Currency`] = AUSTRALIAN_DOLLAR)
    );
    phoneFields.forEach((field) => (initialData[`${camelCase(field.name)}Country`] = AUSTRALIA));
    addressFields.forEach((field) => (initialData[`${camelCase(field.name)}Country`] = AUSTRALIA));

    add(initialData);
  };

  return (
    <div className={styles['array-field-children']}>
      <Form.List name={fieldName}>
        {(fields, { add, remove }) => (
          <>
            {fields.map(({ name }) => (
              <div className={styles['array-field-child']} key={name}>
                {field.children?.map((field) => (
                  <DynamicFormItem
                    key={field.name}
                    field={{
                      ...field,
                      /* In case array is readonly, all children should be readonly */
                      readOnly: readOnly || field.readOnly
                    }}
                    namespace={[name]}
                    fullNamespace={[...fieldName, name]}
                  />
                ))}
                <div className="d-flex justify-content-end">
                  <Button danger ghost onClick={() => remove(name)} disabled={readOnly}>
                    Remove this entry
                  </Button>
                </div>
              </div>
            ))}
            <Form.Item>
              <Button
                type="dashed"
                onClick={() => addEntry(add)}
                block
                icon={<PlusOutlined />}
                disabled={readOnly}
              >
                {t('field.addSomething', { something: field.label })}
              </Button>
            </Form.Item>
          </>
        )}
      </Form.List>
    </div>
  );
};
