import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown } from 'antd';
import { MoreOutlined } from '@ant-design/icons';

const PageContextMenu = ({ items }) =>
  items?.length ? (
    <Dropdown menu={{ items }} trigger={['click']}>
      <MoreOutlined />
    </Dropdown>
  ) : null;

PageContextMenu.propTypes = {
  items: PropTypes.arrayOf(PropTypes.object)
};

PageContextMenu.defaultProps = {
  items: []
};

export default PageContextMenu;
