import PropTypes from 'prop-types';
import { Form, Input, Select, Radio, Spin } from 'antd';
import { FormItem, CgDatePicker } from '@gowgates/dynamic-fields';
import { booleanOptions, useCountries } from '@gowgates/utils';
import useCaseManagers from '../../../hooks/useCaseManagers';
import useAppConfigs from '../../../hooks/useAppConfigs';
import useIntegrations from '../../../hooks/useIntegrations';
import SearchClaims from './SearchClaims';
import SearchInsight from './SearchInsight';

const FileDetailsForm = ({ setUpdating, showCaseManager }) => {
  const { appConfigs } = useAppConfigs();
  const { users } = useCaseManagers();
  const { hasInsightIntegrations } = useIntegrations();
  const { countries } = useCountries();

  const shouldUpdateConflictFileType = (prevValues, currentValues) =>
    prevValues.conflictFile !== currentValues.conflictFile;

  const shouldUpdateConflictFileDetails = (prevValues, currentValues) =>
    prevValues.conflictFileType !== currentValues.conflictFileType ||
    prevValues.conflictFile !== currentValues.conflictFile;

  const vulnerableCustomerUpdated = (prevValues, currentValues) =>
    prevValues.vulnerableCustomer !== currentValues.vulnerableCustomer;

  if (!appConfigs) return null;
  const fileConfig = appConfigs.fileConfig;

  if (!countries)
    return (
      <div className="d-flex justify-content-center">
        <Spin size="small" />
      </div>
    );

  return (
    <>
      {hasInsightIntegrations && <SearchInsight setUpdating={setUpdating} countries={countries} />}

      {fileConfig.notifiedAtActive && (
        <FormItem
          name="notifiedAt"
          model="claim"
          label={fileConfig.notifiedAtLabel}
          required={fileConfig.notifiedAtRequired}
        >
          <CgDatePicker />
        </FormItem>
      )}

      {showCaseManager && (
        <FormItem name="assigneeId" model="claim" label={fileConfig.assigneeLabel} required>
          <Select options={users} fieldNames={{ value: 'id', label: 'name' }} />
        </FormItem>
      )}

      {fileConfig.conflictFileActive && (
        <>
          <FormItem
            name="conflictFile"
            model="claim"
            label={fileConfig.conflictFileLabel}
            required={fileConfig.conflictFileRequired}
          >
            <Radio.Group options={booleanOptions()} />
          </FormItem>

          <Form.Item noStyle shouldUpdate={shouldUpdateConflictFileType}>
            {({ getFieldValue }) =>
              getFieldValue('conflictFile') && (
                <FormItem name="conflictFileType" model="claim" required>
                  <Radio.Group options={appConfigs.claim.conflictFileTypes} />
                </FormItem>
              )
            }
          </Form.Item>

          <Form.Item noStyle shouldUpdate={shouldUpdateConflictFileDetails}>
            {({ getFieldValue }) =>
              getFieldValue('conflictFile') &&
              getFieldValue('conflictFileType') === 'cgw' && <SearchClaims />
            }
          </Form.Item>

          <Form.Item noStyle shouldUpdate={shouldUpdateConflictFileDetails}>
            {({ getFieldValue }) =>
              getFieldValue('conflictFile') &&
              getFieldValue('conflictFileType') === 'external' && (
                <>
                  <FormItem name="conflictFileNumber" model="claim" required>
                    <Input />
                  </FormItem>

                  <FormItem name="conflictCaseManagerId" model="claim" required>
                    <Select options={users} fieldNames={{ value: 'id', label: 'name' }} />
                  </FormItem>
                </>
              )
            }
          </Form.Item>
        </>
      )}

      {fileConfig.vulnerableCustomerActive && (
        <>
          <FormItem
            name="vulnerableCustomer"
            model="claim"
            label={fileConfig.vulnerableCustomerLabel}
            required={fileConfig.vulnerableCustomerRequired}
          >
            <Radio.Group options={booleanOptions()} />
          </FormItem>

          <Form.Item noStyle shouldUpdate={vulnerableCustomerUpdated}>
            {({ getFieldValue }) =>
              getFieldValue('vulnerableCustomer') && (
                <FormItem name="vulnerabilityReason" model="claim" required>
                  <Input.TextArea />
                </FormItem>
              )
            }
          </Form.Item>
        </>
      )}
    </>
  );
};

FileDetailsForm.defaultProps = {
  setUpdating: () => {},
  showCaseManager: true
};

FileDetailsForm.propTypes = {
  setUpdating: PropTypes.func,
  showCaseManager: PropTypes.bool
};

export default FileDetailsForm;
