import React, { useRef } from 'react';
import PropTypes from 'prop-types';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { App, Button, Empty, Form, Space } from 'antd';
import { useParams } from 'react-router';
import {
  DndContext,
  closestCenter,
  KeyboardSensor,
  PointerSensor,
  useSensor,
  useSensors
} from '@dnd-kit/core';
import {
  SortableContext,
  sortableKeyboardCoordinates,
  verticalListSortingStrategy
} from '@dnd-kit/sortable';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { getListItem, updateListItem } from '../../api/endpoints';
import Page from '../../components/Page';
import FullLoader from '../../components/FullLoader';
import ListItemSummary from './Summary';
import Option from './Option';

const ListItemsContainer = () => {
  usePageTitle(t('backoffice.lists'));
  const listItemId = parseInt(useParams().listItemId, 10);

  const { isFetching, data } = useQuery({
    queryKey: ['listItem', listItemId],
    queryFn: () => getListItem(listItemId)
  });

  if (isFetching) {
    return <FullLoader />;
  }

  return data && <ListItems data={data} />;
};

// splitting components because this way ListItem will have a new form that is fastly changed for the user, for big lists
const ListItems = ({ data }) => {
  const { message } = App.useApp();
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const sensors = useSensors(
    useSensor(PointerSensor),
    useSensor(KeyboardSensor, {
      coordinateGetter: sortableKeyboardCoordinates
    })
  );
  const refs = useRef([]);

  const addRef = (element, id) => {
    refs.current[id] = element;
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => updateListItem(data.id, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      form.setFieldsValue(data);
      message.success(t('backoffice.listUpdated'));
      queryClient.setQueryData(['listItem', data.id], data);
    }
  });

  const handleDragEnd = (event, children, move) => {
    const { active, over } = event;

    if (active.id !== over.id) {
      const oldIndex = children.indexOf(active.id);
      const newIndex = children.indexOf(over.id);

      move(oldIndex, newIndex);

      // resets positions
      form.getFieldValue('children').forEach((_val, index) => {
        form.setFieldValue(['children', index, 'position'], index + 1);
      });
    }
  };

  const focusElement = (index = null) => {
    setTimeout(() => {
      const activeRefs = refs.current.filter((ref) => !!ref);
      refs.current[index || activeRefs.length - 1]?.input?.focus();
    }, [10]);
  };

  const addOption = (add) => {
    add({});
    focusElement();
  };

  return (
    <Page title={data.name} summary={<ListItemSummary />} backUrl={`/lists/${data.parentId || ''}`}>
      <div className="form-builder">
        <Form
          className="field-choices"
          form={form}
          onFinish={mutate}
          initialValues={data}
          disabled={isLoading}
        >
          <Form.List name="children">
            {(children, { add, move }) => (
              <>
                <DndContext
                  sensors={sensors}
                  collisionDetection={closestCenter}
                  onDragEnd={(event) => handleDragEnd(event, children, move)}
                >
                  <SortableContext items={children} strategy={verticalListSortingStrategy}>
                    {children.length ? (
                      children.map((listItem) => (
                        <Option
                          key={listItem.key}
                          listItem={listItem}
                          add={add}
                          form={form}
                          addRef={addRef}
                          focusElement={focusElement}
                        />
                      ))
                    ) : (
                      <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} />
                    )}
                  </SortableContext>
                </DndContext>

                <Space>
                  <Button htmlType="button" onClick={() => addOption(add)}>
                    {t('formBuilder.addOption')}
                  </Button>

                  <Button type="primary" htmlType="submit" loading={isLoading}>
                    {t('globals.save')}
                  </Button>
                </Space>
              </>
            )}
          </Form.List>
        </Form>
      </div>
    </Page>
  );
};

ListItems.propTypes = {
  data: PropTypes.object.isRequired
};

export default ListItemsContainer;
