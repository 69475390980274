import React from 'react';
import { Loader } from '@gowgates/core';

const HoverLoader = () => (
  <div className="hover-loader-backdrop">
    <Loader />
  </div>
);

export default HoverLoader;
