import { DownOutlined } from '@ant-design/icons';
import { Typography } from 'antd';
import { useFormBuilder } from '../../../../hooks/useFormBuilder';

export const SelectInput = ({ placeholder = 'Choose an option' }: { placeholder?: string }) => {
  const { disabled } = useFormBuilder();

  return (
    <span className={`field-input${disabled ? ' disabled' : ''} select`}>
      <Typography.Text type="secondary">{placeholder}</Typography.Text>
      <DownOutlined />
    </span>
  );
};
