import { Form, GetProps, Space } from 'antd';

type FormProps = GetProps<typeof Form<any>>;

export const AuthForm = ({ children, ...props }: FormProps) => (
  <Form requiredMark={false} layout="vertical" {...props}>
    <Space direction="vertical" size="middle" className="w-100">
      {children}
    </Space>
  </Form>
);
