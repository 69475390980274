import { Field } from '../../types';

import { DynamicFormItem } from './DynamicFormItem';

type DisplayDynamicFieldsProps = {
  fields: Field[];
  namespace: string[];
  dynamicFormItemOverrideProps?: {
    updateRate?: boolean;
  };
};

export const DisplayDynamicFields = ({
  fields = [],
  namespace,
  dynamicFormItemOverrideProps = {}
}: DisplayDynamicFieldsProps) =>
  fields.map((field) => (
    <DynamicFormItem
      key={field.name}
      field={field}
      namespace={namespace}
      fullNamespace={namespace}
      {...dynamicFormItemOverrideProps}
    />
  ));
