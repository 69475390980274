import { useQuery } from '@tanstack/react-query';
import { getAppConfigs } from '../api/endpoints';

const useAppConfigs = () => {
  const query = useQuery({
    queryKey: ['appConfigs'],
    queryFn: getAppConfigs,
    placeholderData: {},
    staleTime: Infinity
  });

  return { ...query, appConfigs: query.data };
};

export default useAppConfigs;
