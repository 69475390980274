import React from 'react';
import PropTypes from 'prop-types';
import { Typography, Skeleton, Divider, Space } from 'antd';
import { File } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';

const ItemDocuments = ({ documentsCount, documents }) => {
  // No documents in item
  if (documentsCount === 0) {
    return (
      <>
        <Divider orientation="left">{t('item.documents')}</Divider>

        <Typography.Text type="secondary" italic>
          {t('item.noDocuments')}
        </Typography.Text>
      </>
    );
  }

  // item has documents but they are not loaded
  if (documentsCount !== 0 && documents.length === 0) {
    return (
      <>
        <Divider orientation="left">{t('item.documents')}</Divider>

        <Space direction="vertical" className="w-100">
          {Array.from(Array(documentsCount).keys()).map((i) => (
            <Skeleton.Input active block key={i} />
          ))}
        </Space>
      </>
    );
  }

  return (
    <>
      <Divider orientation="left">{t('item.documents')}</Divider>

      {documents.map((document) => (
        <File
          key={document.id}
          url={document.file.url}
          type={document.file.type}
          name={document.file.name}
        />
      ))}
    </>
  );
};

ItemDocuments.defaultProps = {
  documents: []
};

ItemDocuments.propTypes = {
  documentsCount: PropTypes.number.isRequired,
  documents: PropTypes.array
};

export default ItemDocuments;
