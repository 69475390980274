import React, { useEffect } from 'react';
import { useQuery } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { t } from '@gowgates/utils';
import { setPageTitle, Loader } from '@gowgates/core';
import { getTaskStructure, updateTaskStructure } from '../../../api/endpoints';
import TaskStructureForm from '../TaskStructure/Form';
import TaskStructureDescriptions from '../TaskStructure/Descriptions';
import EditableDescriptions from '../../../components/EditableDescriptions';
import TaskTriggers from './Triggers';

const TaskStructure = () => {
  const journeyId = Number(useParams().journeyId);
  const taskId = Number(useParams().taskId);

  const { isLoading, data: taskStructure } = useQuery({
    queryKey: ['taskStructure', taskId],
    queryFn: () => getTaskStructure(journeyId, taskId)
  });

  useEffect(() => {
    if (!taskStructure) return;

    setPageTitle(`${taskStructure.name} | ${t('activerecord.models.journey.other')}`);
  }, [taskStructure]);

  if (isLoading) {
    return <Loader />;
  }

  return (
    <>
      <EditableDescriptions
        title={taskStructure.name}
        showPermission
        editPermission
        descriptions={<TaskStructureDescriptions taskStructure={taskStructure} />}
        form={<TaskStructureForm />}
        model={taskStructure}
        updateModel={(values) => updateTaskStructure(journeyId, taskId, values)}
        queryDataKey={['taskStructure', taskId]}
      />

      <TaskTriggers taskStructure={taskStructure} event="creation" />
      <TaskTriggers taskStructure={taskStructure} event="completion" />
    </>
  );
};

export default TaskStructure;
