import { useEffect, useRef } from 'react';
import { Form, Button, message, InputRef } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { t } from '@gowgates/utils';
import { usePageTitle } from '../../../hooks';
import { AuthContainer } from '../Container';
import { AuthForm } from '../components/AuthForm';
import { EmailInput } from '../components/EmailInput';
import { recoverPassword } from '../api/endpoints';
import { LoginLink } from '../components/LoginLink';

export const ForgotPassword = () => {
  const inputRef = useRef<InputRef>(null);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  usePageTitle(t('devise.passwords.new.forgotYourPassword'));

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const { isPending, mutate } = useMutation({
    mutationFn: recoverPassword,
    onSuccess: () => {
      message.success(t('devise.passwords.sendInstructions'), 10);
      navigate('/login');
    }
  });

  return (
    <AuthContainer title={t('devise.passwords.new.forgotYourPassword')} footer={<LoginLink />}>
      <AuthForm form={form} onFinish={mutate} disabled={isPending}>
        <EmailInput ref={inputRef} />

        <Button type="primary" htmlType="submit" className="w-100" loading={isPending}>
          {t('devise.passwords.new.sendMeResetPasswordInstructions')}
        </Button>
      </AuthForm>
    </AuthContainer>
  );
};
