import { ThemeConfig } from 'antd';
import { createContext } from 'react';

import { InputFormItemProps } from '../components/InputFormItem';

export type AuthConfigs =
  | {
      theme?: ThemeConfig;
      title?: {
        overrides?: {
          className?: string;
        };
      };
      footer?: {
        overrides?: {
          className?: string;
        };
      };
      forms?: {
        formItem?: {
          mode?: InputFormItemProps['mode'];
        };
      };
    }
  | undefined;

export const defaultAuthConfigs: Exclude<AuthConfigs, undefined> = {
  forms: { formItem: {} }
};
export const AuthContext = createContext<AuthConfigs>(defaultAuthConfigs);
