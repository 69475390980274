import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';

const UserStatusTag = ({ status }) => {
  if (status === 'active') {
    return (
      <Tag icon={<CheckCircleOutlined />} color="green">
        {t('user.statuses.active')}
      </Tag>
    );
  }

  return (
    <Tag icon={<CloseCircleOutlined />} color="error">
      {t('user.statuses.blocked')}
    </Tag>
  );
};

UserStatusTag.propTypes = {
  status: PropTypes.string.isRequired
};

export default UserStatusTag;
