import Icon from '@ant-design/icons';
import React from 'react';
import { PrioritySvg } from './svgs/Priority';
import { VulnerabilitySvg } from './svgs/Vulnerability';
import { AtSvg } from './svgs/At';
import { HandPointer } from './svgs/HandPointer';

export const PriorityIcon = (props) => <Icon component={PrioritySvg} {...props} />;
export const VulnerabilityIcon = (props) => <Icon component={VulnerabilitySvg} {...props} />;
export const AtIcon = (props) => <Icon component={AtSvg} {...props} />;
export const HandPointerIcon = (props) => <Icon component={HandPointer} {...props} />;

export default {};
