import { booleanOptions } from '@gowgates/utils';
import { Field, FieldOption } from '../../../../types';
import {
  isAddressField,
  isArrayField,
  isBooleanField,
  isChoiceField,
  isDateField,
  isDisplayingAsSelect,
  isFileField,
  isMoneyField,
  isPercentField,
  isPhoneField,
  isTextField
} from '../../../../utils';
import { TextAreaInput } from './TextAreaInput';
import { TextInput } from './TextInput';
import { SelectInput } from './SelectInput';
import { InlineOptions } from './InlineOptions';
import { InputWithPrefix } from './InputWithPrefix';
import { InputWithSuffix } from './InputWithSuffix';
import { DateInput } from './DateInput';
import { AddressInput } from './AddressInput';
import { ArrayInput } from './ArrayInput';
import { FileInput } from './FileInput';

export const Input = ({ field }: { field: Field }) => {
  if (isTextField(field)) {
    return <TextAreaInput />;
  }

  if (isDisplayingAsSelect(field)) {
    return <SelectInput />;
  }

  if (isBooleanField(field) && !isDisplayingAsSelect(field)) {
    const options = booleanOptions({
      valueKey: 'key',
      labelKey: 'value',
      trueLabel: field.trueLabel,
      falseLabel: field.falseLabel
    });

    return <InlineOptions options={options as FieldOption[]} />;
  }

  if (isChoiceField(field) && !isDisplayingAsSelect(field)) {
    return <InlineOptions options={field.options || []} multiple={field.multiple} />;
  }

  if (isMoneyField(field)) {
    return <InputWithPrefix prefix="AUD" />;
  }

  if (isPhoneField(field)) {
    return <InputWithPrefix prefix="+61" />;
  }

  if (isPercentField(field)) {
    return <InputWithSuffix suffix="%" />;
  }

  if (isDateField(field)) {
    return <DateInput format={field.level} />;
  }

  if (isAddressField(field)) {
    return <AddressInput />;
  }

  if (isArrayField(field)) {
    return <ArrayInput fields={field?.children} />;
  }

  if (isFileField(field)) {
    return <FileInput />;
  }

  return <TextInput />;
};
