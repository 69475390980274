import React from 'react';
import PropTypes from 'prop-types';
import qs from 'query-string';
import { Link } from 'react-router-dom';
import { Skeleton } from 'antd';
import Box from '../../components/Box';

const StatisticBox = ({ value, label, params, path, loading, numberClassName }) => (
  <Link to={`/${path}?${qs.stringify(params)}`}>
    <Box className="statistic-box">
      <div className={`statistic-count ${numberClassName}`}>
        {loading ? <Skeleton.Avatar active shape="square" /> : value}
      </div>
      <h4 className="statistic-title">{label}</h4>
    </Box>
  </Link>
);

StatisticBox.defaultProps = {
  value: 0,
  label: '',
  params: {},
  path: 'tasks',
  loading: false,
  numberClassName: ''
};

StatisticBox.propTypes = {
  value: PropTypes.number,
  label: PropTypes.string,
  params: PropTypes.object,
  path: PropTypes.string,
  loading: PropTypes.bool,
  numberClassName: PropTypes.string
};

export default StatisticBox;
