import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Modal, Button, Form, message, Space } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { updateSection } from '../../../../api/endpoints';
import SectionDescription from '../../descriptions/Section';
import SectionForm from '../../forms/Section';
import ModalFooter from '../../../../components/ModalFooter';
import DeleteSectionBtn from './DeleteSectionBtn';

const SectionAttributesModal = ({ isOpen, setOpen, structure, section }) => {
  const claimId = Number(useParams().claimId);
  const queryClient = useQueryClient();
  const [isEditing, setEditing] = useState(false);
  const [form] = Form.useForm();

  const startEdit = () => {
    setTimeout(() => {
      form.resetFields();
    }, 50);
    setEditing(true);
  };

  const stopEdit = () => {
    setEditing(false);
  };

  const closeModal = () => {
    setOpen(false);
    stopEdit();
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => updateSection(claimId, section.id, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      queryClient.invalidateQueries({ queryKey: ['claim', claimId] });
      message.success(t('section.saved'));
      stopEdit();
    }
  });

  return (
    <Modal title={structure.name} open={isOpen} onCancel={closeModal} footer={null}>
      {isEditing ? (
        <Form
          form={form}
          initialValues={section}
          layout="vertical"
          onFinish={mutate}
          disabled={isLoading}
        >
          <SectionForm sectionStructure={structure} />

          <ModalFooter spaceBetween>
            <div>
              <DeleteSectionBtn section={section} />
            </div>
            <Space>
              <Button onClick={stopEdit}>{t('globals.cancel')}</Button>
              <Button type="primary" htmlType="submit" loading={isLoading}>
                {t('globals.save')}
              </Button>
            </Space>
          </ModalFooter>
        </Form>
      ) : (
        <>
          <SectionDescription sectionStructure={structure} section={section} />

          {section.permissions.update && (
            <ModalFooter>
              <Button onClick={startEdit} key="start-edit">
                {t('globals.edit')}
              </Button>
            </ModalFooter>
          )}
        </>
      )}
    </Modal>
  );
};

SectionAttributesModal.defaultProps = {
  isOpen: false
};

SectionAttributesModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  structure: PropTypes.object.isRequired,
  section: PropTypes.object.isRequired
};

export default SectionAttributesModal;
