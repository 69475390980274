import { snakeCase } from 'change-case';

// eslint-disable-next-line @typescript-eslint/no-explicit-any
export const railsSnake = (data: any) => {
  if (data === '_destroy') {
    return data;
  }

  return snakeCase(data);
};
