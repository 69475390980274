import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { getListItems } from '../../api/endpoints';
import Page from '../../components/Page';
import ListActions from './ListActions';
import DeleteListItem from './DeleteListItem';

const ListItems = () => {
  usePageTitle(t('backoffice.lists'));

  const { isFetching, data } = useQuery({ queryKey: ['listItems'], queryFn: () => getListItems() });

  const columns = [
    {
      title: t('activerecord.attributes.listItem.name'),
      dataIndex: 'name',
      render: (text, record) => (
        <Link to={`/lists/${record.id}`} className="col-clickable">
          {text}
        </Link>
      )
    },
    {
      render: (text, record) => <DeleteListItem data={record} />
    }
  ];

  return (
    <Page title={t('backoffice.lists')} actions={<ListActions />}>
      <Table
        dataSource={data}
        rowKey={(record) => record.id}
        className="page-table"
        loading={isFetching}
        columns={columns}
      />
    </Page>
  );
};

export default ListItems;
