import React, { useEffect, useMemo, useState } from 'react';
import qs from 'query-string';
import { useQuery } from '@tanstack/react-query';
import { useLocation } from 'react-router';
import { t, DEFAULT_PAGINATION } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { getUsers } from '../../api/endpoints';
import TableSearch from '../../components/TableSearch';
import { updatedFiltersFromParams, updatedSorterFromParams } from '../../utils/tables';
import Page from '../../components/Page';
import UsersActions from './Header';
import UsersTable from './Table';

const Users = () => {
  const { search: searchQuery } = useLocation();
  const search = useMemo(() => qs.parse(searchQuery), [searchQuery]);
  const [filters, setFilters] = useState({});
  const [pagination, setPagination] = useState(DEFAULT_PAGINATION);
  const [sorter, setSorter] = useState({});
  usePageTitle(t('activerecord.models.user.other'));

  const { isFetching, data } = useQuery({
    queryKey: ['users', search],
    queryFn: () => getUsers(search)
  });

  useEffect(() => {
    if (data) setPagination((prevPagination) => ({ ...prevPagination, ...data.pagination }));
  }, [data]);

  useEffect(() => {
    setSorter(updatedSorterFromParams(search));
    setFilters(updatedFiltersFromParams(search));
  }, [search]);

  return (
    <Page title={t('activerecord.models.user.other')} actions={<UsersActions />} overlap>
      <TableSearch />

      <UsersTable
        users={data?.rows}
        loading={isFetching}
        pagination={pagination}
        sorter={sorter}
        filters={filters}
      />
    </Page>
  );
};

export default Users;
