import React from 'react';
import PropTypes from 'prop-types';
import { DynamicFormItem } from '@gowgates/dynamic-fields';
import useFileConfig from '../../../hooks/useFileConfig';

const PolicyDetailsForm = ({ nested }) => {
  const { coverStructure } = useFileConfig();
  const namespace = nested ? ['cover', 'data'] : ['data'];

  return (
    <>
      {coverStructure.map((field) => (
        <DynamicFormItem
          key={field.name}
          field={field}
          namespace={namespace}
          fullNamespace={namespace}
        />
      ))}
    </>
  );
};

PolicyDetailsForm.defaultProps = {
  nested: false
};

PolicyDetailsForm.propTypes = {
  nested: PropTypes.bool
};

export default PolicyDetailsForm;
