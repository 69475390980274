import { AxiosInstance } from 'axios';
import { initializeClientInstance } from '../client';

export type APIClientConfigsType = {
  baseURL: string;
  domain: string;
  authTokenKey: string;
  clientInstance?: AxiosInstance;
};

let apiConfigsInstance: Required<APIClientConfigsType> | undefined;

export const initAPIConfigs = (configs: APIClientConfigsType) => {
  if (!apiConfigsInstance) {
    const clientInstance = initializeClientInstance(configs);
    apiConfigsInstance = {
      ...configs,
      clientInstance
    };
  }

  return apiConfigsInstance;
};

export const apiConfigs = () => {
  if (!apiConfigs) throw new Error('API not configured');

  return apiConfigsInstance as Required<APIClientConfigsType>;
};

export const client = () => apiConfigs().clientInstance;
