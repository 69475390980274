import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { t } from '@gowgates/utils';
import { PriorityIcon } from '../../../../icons';
import { isImportant } from '../../utils/claim';

const ClaimImportance = ({ claim }) => {
  if (!isImportant(claim)) {
    return null;
  }

  return (
    <Alert
      message={t(`claim.isImportant.${claim.importance}`)}
      type="info"
      showIcon
      icon={<PriorityIcon />}
    />
  );
};

ClaimImportance.propTypes = {
  claim: PropTypes.object.isRequired
};

export default ClaimImportance;
