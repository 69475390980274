import React from 'react';
import PropTypes from 'prop-types';

const DocumentDescription = ({ documents, structure }) => {
  const document = documents.find((doc) => doc.type === structure.name);

  return (
    <>
      <dt>{structure.label}</dt>
      <dd>
        {document ? (
          <a href={document.file.url} target="_blank" rel="noopener noreferrer">
            {document.file.name}
          </a>
        ) : (
          '-'
        )}
      </dd>
    </>
  );
};

DocumentDescription.defaultProps = {
  documents: []
};

DocumentDescription.propTypes = {
  documents: PropTypes.array,
  structure: PropTypes.object.isRequired
};

export default DocumentDescription;
