import React from 'react';
import PropTypes from 'prop-types';
import { Button, Space } from 'antd';
import { t } from '@gowgates/utils';
import ModalFooter from './ModalFooter';

const ModalFormFooter = ({ closeModal, isLoading, disabled }) => (
  <ModalFooter>
    <Space>
      <Button onClick={closeModal}>{t('globals.cancel')}</Button>
      <Button type="primary" htmlType="submit" loading={isLoading} disabled={disabled}>
        {t('globals.save')}
      </Button>
    </Space>
  </ModalFooter>
);

ModalFormFooter.defaultProps = {
  isLoading: false,
  disabled: false
};

ModalFormFooter.propTypes = {
  closeModal: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  disabled: PropTypes.bool
};

export default ModalFormFooter;
