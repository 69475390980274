import { t } from '@gowgates/utils';
import { usePageTitle } from '../../../hooks';
import { AuthContainer } from '../Container';
import { UnlockForm } from '../Unlock/UnlockForm';
import { LoginLink } from '../components/LoginLink';

export const ResendUnlock = () => {
  usePageTitle(t('devise.unlocks.new.resendUnlockInstructions'));

  return (
    <AuthContainer title={t('devise.unlocks.new.resendUnlockInstructions')} footer={<LoginLink />}>
      <UnlockForm />
    </AuthContainer>
  );
};

export default ResendUnlock;
