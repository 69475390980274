import React from 'react';
import { Space } from 'antd';
import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import useClaim from '../../../../hooks/useClaim';
import { DocumentsFormLazy } from '../../forms/Documents/DocumentsFormLazy';
import DocumentReports from './DocumentReports';

const ClaimDocuments = () => {
  const { claim } = useClaim();
  usePageTitle(`${t('claim.id', { id: claim.id })} - ${t('activerecord.models.document.other')}`);

  return (
    <Space direction="vertical" size="large" className="w-100 documents-container">
      <DocumentsFormLazy claim={claim} />

      <DocumentReports claim={claim} />
    </Space>
  );
};

export default ClaimDocuments;
