import { useEffect, useState } from 'react';
import cloneDeep from 'lodash.clonedeep';
import { Button, Space, Tooltip, Typography, message } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { t, displayText } from '@gowgates/utils';
import { resendCompleteClaimantTaskEmail } from '../../../../../api/endpoints';
import { Task } from '../../../../../types';
import useClaim from '../../../../../hooks/useClaim';
import { isEmailFailed, processEmailDetails } from '../../../../../utils/sentEmail';
import TaskStatus from '../TaskStatus';
import { CloseTaskModalType } from '../TaskList';
import DueDate from './DueDate';
import EditDueDate from './EditDueDate';
import TaskModalFooter from './TaskModalFooter';
import Description from './Description';

type TaskModalProps = {
  task: Task;
  setTask: (task: Task) => void;
  closeModal: CloseTaskModalType;
};

const ShowTaskModalDetails = ({ task, setTask, closeModal }: TaskModalProps) => {
  const [isEditingDueDate, setEditingDueDate] = useState(false);
  const { claim } = useClaim();
  const queryClient = useQueryClient();
  const { mutate: resendEmail, isPending: isLoading } = useMutation({
    mutationFn: () => resendCompleteClaimantTaskEmail(task.id),
    onSuccess: (taskData) => {
      message.success(t('sentEmail.resentSuccessMessage'));

      const tasksQueryId = ['claim', claim.id, 'tasks'];

      // putting task to initial state because email scheduled to be sent later
      const taskUpdated = cloneDeep(taskData);
      taskUpdated.lastSentEmail.status = 'sent';

      const updatedTasks: Task[] = cloneDeep(queryClient.getQueryData(tasksQueryId) || []);
      updatedTasks[updatedTasks.findIndex((t) => t.id === taskData.id)] = taskUpdated;
      queryClient.setQueryData(tasksQueryId, updatedTasks);
    },
    onError: () => {
      message.error(t('globals.unexpected_error'));
    }
  });

  useEffect(() => {
    setEditingDueDate(false);
  }, [task]);

  const emailDetails = processEmailDetails(task?.lastSentEmail);

  return (
    <>
      <dl className="cg-descriptions">
        {task.linkId && (
          <>
            <dt>Claimant link</dt>
            <dd>
              <a href={`/frontoffice/tasks/${task.linkId}`} target="_blank" rel="noreferrer">
                {`${window.location.protocol}//${window.location.host}/frontoffice/tasks/${task.linkId}`}
              </a>
            </dd>
          </>
        )}

        {emailDetails && (
          <>
            <dt>{t('activerecord.attributes.sentEmail.status')}</dt>
            <dd>
              <Space>
                <>
                  {['delivery', 'open', 'clicked'].map((key) => {
                    const IconComponent = emailDetails[key].IconComponent;
                    return (
                      <Tooltip key={key} title={emailDetails[key].text}>
                        <Typography.Text type={emailDetails[key].color}>
                          <IconComponent />
                        </Typography.Text>
                      </Tooltip>
                    );
                  })}

                  {claim?.permissions?.resendEmails && isEmailFailed(task.lastSentEmail) && (
                    <Button
                      type="link"
                      loading={isLoading}
                      onClick={() => resendEmail()}
                      size="small"
                    >
                      {t('globals.resend_email')}
                    </Button>
                  )}
                </>
              </Space>
            </dd>
          </>
        )}

        {task.sanctionsCheckUrls && (
          <>
            <dt>Sanctions Check URL</dt>
            <dd>
              {task.sanctionsCheckUrls.map((url) => (
                <p>
                  <a href={url} target="_blank" rel="noreferrer">
                    {url}
                  </a>
                </p>
              ))}
            </dd>
          </>
        )}

        {task.sanctionsCheckError && (
          <>
            <dt>Sanctions Check errors</dt>
            <dd>{task.sanctionsCheckError}</dd>
          </>
        )}

        <dt>{t('activerecord.attributes.task.description')}</dt>
        <dd>
          <Description task={task} setTask={setTask} />
        </dd>

        <dt>{t('activerecord.attributes.task.user')}</dt>
        <dd>{displayText(task.user?.name)}</dd>

        <dt>{t('activerecord.attributes.task.status')}</dt>
        <dd>
          <TaskStatus task={task} />
        </dd>

        {task.open && (
          <>
            <dt>{t('activerecord.attributes.task.dueAt')}</dt>
            <dd>
              {isEditingDueDate ? (
                <EditDueDate task={task} setTask={setTask} setEditing={setEditingDueDate} />
              ) : (
                <DueDate task={task} setEditing={setEditingDueDate} />
              )}
            </dd>
          </>
        )}
      </dl>

      <TaskModalFooter task={task} setTask={setTask} closeModal={closeModal} />
    </>
  );
};

export default ShowTaskModalDetails;
