import { useQuery } from '@tanstack/react-query';
import { getResourceIntegrations } from '../api/endpoints';

const useIntegrations = () => {
  const { isLoading, data: integrations } = useQuery({
    queryKey: ['resourceIntegrations'],
    queryFn: getResourceIntegrations,
    placeholderData: [],
    staleTime: 60 * 60 * 1000 // stores cache for 1 hour
  });

  const insightIntegrations = integrations?.filter(
    (integration) => integration.provider === 'insight'
  );

  const hasInsightIntegrations = Boolean(insightIntegrations && insightIntegrations.length > 0);

  const insightIntegrationsForSelect = insightIntegrations?.map((integration) => ({
    key: integration.id,
    value: integration.id,
    label: integration.settings?.ledger
  }));

  return {
    isLoading,
    integrations,
    insightIntegrations,
    hasInsightIntegrations,
    insightIntegrationsForSelect
  };
};

export default useIntegrations;
