import { Col, Row, Select } from 'antd';
import { useDynamicDataConfigsQuery } from '../../../hooks/dynamic-data-configs/useDynamicDataConfigsQuery';
import { FormItem } from '../../forms';

export const Dates = () => {
  const { dateFieldLevels } = useDynamicDataConfigsQuery();

  return (
    <Row align="middle" className="field-choices-footer">
      <Col span={12}>
        <FormItem name="level" model="field">
          <Select options={dateFieldLevels} />
        </FormItem>
      </Col>
    </Row>
  );
};
