import React from 'react';
import PropTypes from 'prop-types';
import { Avatar } from 'antd';

const UserAvatar = ({ user, size }) => {
  if (!user) {
    return null;
  }

  if (user.avatar) {
    return <Avatar size={size} src={user.avatar.url} />;
  }

  return (
    <Avatar size={size}>
      {user.firstName[0]}
      {user.lastName[0]}
    </Avatar>
  );
};

UserAvatar.defaultProps = {
  size: 'small',
  user: null
};

UserAvatar.propTypes = {
  user: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    avatar: PropTypes.object
  }),
  size: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
};

export default UserAvatar;
