import React from 'react';
import { Form, Row, Col, Button, message, Input } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { useAuth } from '@gowgates/core';
import { usePageTitle } from '@gowgates/core';
import { updateUser } from '../../api/endpoints';

const ProfileEmail = () => {
  const queryClient = useQueryClient();
  const user = queryClient.getQueryData(['users', 'me']);
  const { updateUserInStorage } = useAuth();
  const [form] = Form.useForm();
  usePageTitle(t('account.changeEmail'));

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => updateUser(user.id, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      message.success({
        content: t('account.changeEmailSuccessMessage'),
        duration: 10
      });
      updateUserInStorage(data);
    }
  });

  return (
    <Form form={form} layout="vertical" onFinish={mutate} initialValues={user} disabled={isLoading}>
      <Row>
        <Col span={16} offset={4}>
          <FormItem name="email" model="user" required>
            <Input />
          </FormItem>
        </Col>
      </Row>

      <footer className="d-flex justify-content-end">
        <Button type="primary" htmlType="submit">
          {t('globals.save')}
        </Button>
      </footer>
    </Form>
  );
};

export default ProfileEmail;
