import { useEffect } from 'react';
import { t } from '@gowgates/utils';

type UsePageTittleHook = (title?: string, { appName }?: { appName?: string }) => void;

export const setPageTitle: UsePageTittleHook = (title, { appName } = {}) => {
  document.title = `${title} | ${appName || t('app.name')}`;
};

export const usePageTitle: UsePageTittleHook = (title, { appName } = {}) => {
  useEffect(() => {
    if (!title) return;

    setPageTitle(title, { appName });
  }, [title, appName]);
};
