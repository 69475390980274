import { useEffect, useMemo, useRef, useState } from 'react';
import { Button, Form, GlobalToken, Modal, Select, Tooltip, theme } from 'antd';
import ReactQuill from 'react-quill';
import { PlusOutlined } from '@ant-design/icons';
import { styled } from 'styled-components';
import { quillRules } from '@gowgates/core';
import { t } from '@gowgates/utils';
import { FormItem } from '@gowgates/dynamic-fields';
import ModalFormFooter from './ModalFormFooter';

type EditorVariant = 'custom' | 'plain-text';
type EmailTemplateEditorProps = {
  id: string;
  toolbarId?: string;
  variant?: EditorVariant;
  value?: string;
  onChange?: () => void;
};

type PlaceholderForm = {
  model?: string;
  attribute?: string;
};

const StyledQuill = styled.div<{ $token: GlobalToken; $hasError: boolean }>`
  ${(props) => quillRules(props.$token, props.$hasError)}
`;

const CUSTOM_PLACEHOLDER_FORMAT_ID = 'placeholder';
const FORMATS = [
  'header',
  'bold',
  'italic',
  'underline',
  'align',
  'strike',
  'script',
  'blockquote',
  'background',
  'direction',
  'list',
  'bullet',
  'indent',
  'link',
  'image',
  'color'
];

const FORMATS_BY_VARIANT: { [key in EditorVariant]: string[] } = {
  'plain-text': [CUSTOM_PLACEHOLDER_FORMAT_ID],
  custom: [...FORMATS, CUSTOM_PLACEHOLDER_FORMAT_ID]
};

// TODO: this should come from server -- hard coding for prototype and testing
// TODO: it should be passed has prop
const MODEL_OPTIONS = [
  {
    value: 'claim',
    label: 'Claim'
  },
  {
    value: 'client',
    label: 'Client'
  },
  {
    value: 'claimant',
    label: 'Claimant'
  }
];

const ATTRIBUTE_OPTIONS_FOR_MODELS: { [key: string]: { value: string; label: string }[] } = {
  claim: [
    {
      value: 'name',
      label: 'Name'
    },
    {
      value: 'status',
      label: 'Status'
    }
  ],
  client: [
    {
      value: 'first_name',
      label: 'First name'
    },
    {
      value: 'last_name',
      label: 'Last name'
    },
    {
      value: 'address',
      label: 'Address'
    }
  ],
  claimant: [
    {
      value: 'first_name',
      label: 'First name'
    },
    {
      value: 'last_name',
      label: 'Last name'
    }
  ]
};

export const EmailTemplateEditor = ({
  id,
  toolbarId = 'toolbar',
  value = '',
  onChange = () => {},
  variant = 'custom'
}: EmailTemplateEditorProps) => {
  const customClipboardIntervalIdRef = useRef<NodeJS.Timer>();
  const form = Form.useFormInstance();
  const { token } = theme.useToken();
  const formats = FORMATS_BY_VARIANT[variant];

  const [showQuillEditor, setShowQuillEditor] = useState(false);
  const quillRef = useRef<ReactQuill>(null);
  const editorPositionRef = useRef(0);
  const [showPlaceholderModal, setShowPlaceholderModal] = useState(false);

  const [placeholderForm] = Form.useForm();

  const shouldUpdateModel = (prevValues: PlaceholderForm, currentValues: PlaceholderForm) =>
    prevValues.model !== currentValues.model;
  const shouldUpdateAttribute = (prevValues: PlaceholderForm, currentValues: PlaceholderForm) =>
    prevValues.attribute !== currentValues.attribute;
  const shouldUpdateRequiredAttributes = (
    prevValues: PlaceholderForm,
    currentValues: PlaceholderForm
  ) =>
    shouldUpdateModel(prevValues, currentValues) ||
    shouldUpdateAttribute(prevValues, currentValues);

  const closePlaceholderModal = () => {
    placeholderForm.resetFields();

    setShowPlaceholderModal(false);
  };

  const modules = useMemo(
    () => ({
      ...(variant === 'plain-text'
        ? {
            clipboard: {
              matchVisual: false
            },
            keyboard: {
              bindings: {
                shift_enter: {
                  key: 13,
                  shiftKey: true,
                  handler: () => false
                },
                enter: {
                  key: 13,
                  handler: () => false
                }
              }
            }
          }
        : {}),
      toolbar: {
        container: `#${toolbarId}`,
        handlers: {
          [CUSTOM_PLACEHOLDER_FORMAT_ID]: () => {
            if (!quillRef.current) return;

            const editor = quillRef.current.getEditor();

            const range = editor.getSelection();
            editorPositionRef.current = (range && range.index) || 0;

            setShowPlaceholderModal(true);
          }
        }
      }
    }),
    [toolbarId, variant]
  );

  useEffect(() => {
    setTimeout(() => setShowQuillEditor(true), 1);
  }, []);

  useEffect(() => {
    if (variant !== 'plain-text') return;
    customClipboardIntervalIdRef.current = setInterval(function () {
      if (quillRef.current) {
        const editor = quillRef.current.getEditor();

        // removing breaking lines on paste -- idea from https://github.com/quilljs/quill/issues/1432#issuecomment-2019185706
        editor.clipboard.addMatcher(Node.ELEMENT_NODE, (node, delta) => {
          const newText = node.textContent?.replace(/\n/g, '') ?? '';
          delta.ops = [{ insert: newText }];
          return delta;
        });

        if (customClipboardIntervalIdRef.current) {
          clearInterval(customClipboardIntervalIdRef.current);
        }
      }
    }, 10);

    return () => {
      if (customClipboardIntervalIdRef.current) {
        clearInterval(customClipboardIntervalIdRef.current);
      }
    };
  }, [variant]);

  return (
    <div>
      <Modal
        title={t('globals.add_placeholder')}
        open={showPlaceholderModal}
        onCancel={closePlaceholderModal}
        footer={null}
      >
        <Form
          form={placeholderForm}
          layout="vertical"
          onFinish={(data) => {
            closePlaceholderModal();

            if (!quillRef.current) return;

            const editor = quillRef.current.getEditor();
            const placeholderText = `%{${data.model}.${data.attribute}}`.toUpperCase();
            editor.insertText(editorPositionRef.current, placeholderText);

            editor.setSelection(editorPositionRef.current + placeholderText.length, 0);
          }}
        >
          <FormItem name="model" required label="Model">
            <Select
              options={MODEL_OPTIONS}
              onChange={() => {
                placeholderForm.setFieldValue('attribute', null);
              }}
            />
          </FormItem>

          <Form.Item noStyle shouldUpdate={shouldUpdateModel}>
            {({ getFieldValue }) => {
              const model = getFieldValue('model');
              const attributes = ATTRIBUTE_OPTIONS_FOR_MODELS[model];

              if (!model || !attributes) return;

              return (
                <FormItem name="attribute" required label="Attribute">
                  <Select options={attributes} />
                </FormItem>
              );
            }}
          </Form.Item>

          <Form.Item noStyle shouldUpdate={shouldUpdateRequiredAttributes}>
            {({ getFieldValue }) => {
              const model = getFieldValue('model');
              const attribute = getFieldValue('attribute');

              return (
                <ModalFormFooter
                  closeModal={closePlaceholderModal}
                  disabled={!model || !attribute}
                />
              );
            }}
          </Form.Item>
        </Form>
      </Modal>
      <StyledQuill
        className="no-border"
        $token={token}
        $hasError={form && form.getFieldError(id).length > 0}
      >
        <div id={toolbarId}>
          <div className="d-flex justify-content-between">
            <div>
              <span className="ql-formats">
                {formats.includes('header') && (
                  <select className="ql-header" defaultValue="3">
                    <option value="1">Heading</option>
                    <option value="2">Subheading</option>
                    <option value="3">Normal</option>
                  </select>
                )}
              </span>

              <span className="ql-formats">
                {formats.includes('bold') && (
                  <button type="button" role="menu" className="ql-bold" aria-label="Bold" />
                )}
                {formats.includes('italic') && (
                  <button type="button" role="menu" className="ql-italic" aria-label="Italic" />
                )}
                {formats.includes('underline') && (
                  <button
                    type="button"
                    role="menu"
                    className="ql-underline"
                    aria-label="Underline"
                  />
                )}
                {formats.includes('strike') && (
                  <button type="button" role="menu" className="ql-strike" aria-label="Strike" />
                )}
              </span>

              <span className="ql-formats">
                {formats.includes('list') && (
                  <button
                    type="button"
                    role="menu"
                    className="ql-list"
                    value="ordered"
                    aria-label="Ordered"
                  />
                )}
                {formats.includes('list') && (
                  <button
                    type="button"
                    role="menu"
                    className="ql-list"
                    value="bullet"
                    aria-label="Bullet"
                  />
                )}
                {formats.includes('indent') && (
                  <button
                    type="button"
                    role="menu"
                    className="ql-indent"
                    value="-1"
                    aria-label="Indent less"
                  />
                )}
                {formats.includes('indent') && (
                  <button
                    type="button"
                    role="menu"
                    className="ql-indent"
                    value="+1"
                    aria-label="Indent more"
                  />
                )}
              </span>

              <span className="ql-formats">
                {formats.includes('align') && <select className="ql-align" aria-label="Align" />}
                {formats.includes('color') && <select className="ql-color" aria-label="Color" />}
                {formats.includes('background') && (
                  <select className="ql-background" aria-label="Background" />
                )}
              </span>

              <span className="ql-formats">
                {formats.includes('link') && (
                  <button type="button" role="menu" className="ql-link" aria-label="Link" />
                )}
                {formats.includes('image') && (
                  <button type="button" role="menu" className="ql-image" aria-label="Image" />
                )}
              </span>

              <span className="ql-formats">
                <Tooltip title={t('globals.add_placeholder')}>
                  <Button
                    className={`ql-${CUSTOM_PLACEHOLDER_FORMAT_ID}`}
                    icon={<PlusOutlined />}
                    aria-label={t('globals.add_placeholder')}
                  />
                </Tooltip>
              </span>
            </div>
          </div>
        </div>
        {showQuillEditor && (
          <ReactQuill
            ref={quillRef}
            modules={modules}
            formats={formats}
            value={value}
            onChange={onChange}
          />
        )}
      </StyledQuill>
    </div>
  );
};
