import { Cascader } from 'antd';

import { Field } from '../../types';
import { useLists } from '../../hooks';
import { FormItem, FormItemProps } from './FormItem';

type ChoiceFromListFieldProps = {
  name: FormItemProps['name'];
  field: Field;
  disabled?: boolean;
};

export const ChoiceFromListField = ({
  name,
  field,
  disabled = false
}: ChoiceFromListFieldProps) => {
  const { label, required, description } = field;
  const { lists } = useLists();

  const options = lists?.find((list) => list.id === field.listItemId)?.children;

  return (
    <FormItem name={name} label={label} required={required} extra={description}>
      <Cascader
        options={options}
        showSearch
        fieldNames={{ label: 'name', value: 'id' }}
        disabled={disabled}
      />
    </FormItem>
  );
};
