import React from 'react';
import Box from '../../../../components/Box';
import useClaim from '../../../../hooks/useClaim';
import ClaimDetailsForm from '../../forms/ClaimDetails';
import CreateClaimForm from '../CreateClaimForm';

const CreateClaimDetails = () => {
  const { claim } = useClaim();

  return (
    <CreateClaimForm claim={claim}>
      <Box>
        <ClaimDetailsForm claimStructure={claim.structure} />
      </Box>
    </CreateClaimForm>
  );
};

export default CreateClaimDetails;
