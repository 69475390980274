import { Table } from 'antd';
import { DynamicFormatter } from '../DynamicFormatter';
import { ArrayNode, Field } from '../../types';

export const ArrayFormatter = ({ values, field }: { values: ArrayNode[]; field: Field }) => {
  const columns = field.children.map((child) => ({
    title: child.label,
    key: child.name,
    render: (_text: unknown, record: ArrayNode) => <DynamicFormatter data={record} field={child} />
  }));

  return (
    <Table
      dataSource={values}
      columns={columns}
      rowKey={(record) => record.id}
      pagination={false}
      className="box-table"
    />
  );
};
