import forIn from 'lodash.forin';
import omit from 'lodash.omit';
import { camelCase } from 'change-case';
import { isImportant } from '../layouts/claim/utils/claim';

export const updateSearch = (baseParams, params) => {
  const newParams = { ...baseParams };

  newParams.page = undefined;

  forIn(params, (value, key) => {
    newParams[key] = value || undefined;
  });

  return newParams;
};

export const updatedFiltersFromParams = (params = {}) => {
  const paramsToConvert = omit(params, ['page', 'per_page', 'sort', 'q', 'requires_action']);
  const updatedFilters = {};

  // Converts every filter to array if param is string
  forIn(paramsToConvert, (value, key) => {
    if (typeof value === 'string') {
      updatedFilters[camelCase(key)] = new Array(value);
    } else {
      updatedFilters[camelCase(key)] = value;
    }
  });

  return updatedFilters;
};

export const updatedSorterFromParams = (params = {}) => {
  if (!params.sort) {
    return {};
  }

  if (params.sort.indexOf('-') === 0) {
    return { field: camelCase(params.sort.slice(1, params.sort.length)), order: 'descend' };
  }

  return { field: camelCase(params.sort), order: 'ascend' };
};

export const claimRowClassName = (record) => {
  const classNames = record.status === 'overdue' ? ['row-overdue'] : [];

  if (isImportant(record)) {
    classNames.push('row-info');
  } else if (record.vulnerableCustomer) {
    classNames.push('row-warning');
  } else if (record.complaint) {
    classNames.push('row-danger');
  }

  return classNames.join(' ');
};

export const convertToFilter = (object) =>
  object.map((entry) => ({ value: entry.value, text: entry.label }));

export default {};
