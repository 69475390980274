import React from 'react';
import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import EditableDescriptions from '../../../../components/EditableDescriptions';
import useClaim from '../../../../hooks/useClaim';
import ClaimDescriptions from '../../descriptions/Claim';
import ClaimDetailsForm from '../../forms/ClaimDetails';
import { claimDetailsLabel } from '../../utils/claim';
import { sendClaimAction } from '../../../../api/endpoints';
import FileDetailsDescriptions from '../../descriptions/FileDetails';
import FileDetailsForm from '../../forms/FileDetails';
import useAppConfigs from '../../../../hooks/useAppConfigs';

const ClaimDetails = () => {
  const { claim, refreshClaim } = useClaim();
  usePageTitle(`${t('claim.id', { id: claim.id })} - ${t('activerecord.models.claim.one')}`);
  const { appConfigs } = useAppConfigs();

  if (!appConfigs) return null;
  const fileConfig = appConfigs.fileConfig;

  return (
    <>
      <EditableDescriptions
        title={claimDetailsLabel(claim.structure)}
        showPermission={claim.permissions.showData}
        editPermission={claim.permissions.update}
        descriptions={<ClaimDescriptions />}
        form={<ClaimDetailsForm claimStructure={claim.structure} />}
        model={claim}
        updateModel={(values) => sendClaimAction(claim.id, 'update_data', values)}
        onSuccess={refreshClaim}
      />

      <EditableDescriptions
        title={fileConfig.fileDetailsLabel}
        showPermission
        editPermission={claim.permissions.update}
        descriptions={<FileDetailsDescriptions />}
        form={<FileDetailsForm showCaseManager={false} />}
        model={claim}
        updateModel={(values) => sendClaimAction(claim.id, 'update_file_details', values)}
        onSuccess={refreshClaim}
      />
    </>
  );
};

export default ClaimDetails;
