import React from 'react';
import PropTypes from 'prop-types';
import { Button, Empty, Form } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { t } from '@gowgates/utils';
import { createSection } from '../../../../../api/endpoints';
import useClaim from '../../../../../hooks/useClaim';

const AddSection = ({ sectionStructure }) => {
  const { refreshClaim } = useClaim();
  const claimId = Number(useParams().claimId);
  const form = Form.useFormInstance();

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: () => createSection(claimId, { type: sectionStructure.slug }),
    onSuccess: (data) => {
      refreshClaim(data);
      setTimeout(() => {
        form.resetFields();
      }, [100]);
    }
  });

  return (
    <Empty
      image={Empty.PRESENTED_IMAGE_SIMPLE}
      description={
        <>
          {/* eslint-disable-next-line react/no-danger */}
          <span
            dangerouslySetInnerHTML={{
              __html: t('section.claimantEmpty', { section: sectionStructure.name })
            }}
          />
        </>
      }
    >
      <Button type="primary" onClick={mutate} loading={isLoading}>
        {t('activerecord.models.claim.one')}
      </Button>
    </Empty>
  );
};

AddSection.propTypes = {
  sectionStructure: PropTypes.object.isRequired
};

export default AddSection;
