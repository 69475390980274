import { Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { DateFormatter } from '@gowgates/dynamic-fields';
import { Task } from '../../../../../types';

type DueDateProps = {
  task: Task;
  setEditing: (editing: boolean) => void;
};

const DueDate = ({ task, setEditing }: DueDateProps) => (
  <Space>
    <DateFormatter value={task.dueAt} />
    {task.permissions.update && (
      <Typography.Link onClick={() => setEditing(true)}>
        <EditOutlined />
      </Typography.Link>
    )}
  </Space>
);

export default DueDate;
