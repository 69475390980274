import React, { Fragment } from 'react';
import { useQuery } from '@tanstack/react-query';
import dayjs from 'dayjs';
import { Row, Col } from 'antd';
import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { getUsersStatistics } from '../../api/endpoints';
import FullLoader from '../../components/FullLoader';
import Page from '../../components/Page';
import StatisticBox from './StatisticBox';

const dateFormat = 'YYYY-MM-DD';

const UserStatistics = () => {
  const { isFetching, data } = useQuery({
    queryKey: ['userStatistics'],
    queryFn: getUsersStatistics,
    initialData: []
  });
  usePageTitle(t('statistics.byUser'));

  const tomorrow = dayjs().add(1, 'day').format(dateFormat);
  const dayAfterTomorrow = dayjs().add(2, 'day').format(dateFormat);

  if (isFetching) {
    return <FullLoader />;
  }

  return (
    <Page title={t('statistics.byUser')}>
      {data.map((user) => (
        <Fragment key={user.id}>
          <h3>{user.name}</h3>

          <Row gutter={30} className="home-statistics">
            <Col className="col-20-percent">
              <StatisticBox
                value={user.statistics.openTasks}
                label={t('statistics.openTasks')}
                params={{ user_id: user.id }}
                path="tasks"
              />
            </Col>
            <Col className="col-20-percent">
              <StatisticBox
                value={user.statistics.tomorrowTasks}
                label={t('statistics.tomorrowTasks')}
                params={{
                  status: 'incomplete',
                  due_at_from: dayAfterTomorrow,
                  due_at_until: dayAfterTomorrow,
                  user_id: user.id
                }}
                path="tasks"
                numberClassName="success"
              />
            </Col>
            <Col className="col-20-percent">
              <StatisticBox
                value={user.statistics.todayTasks}
                label={t('statistics.todayTasks')}
                params={{ status: 'incomplete', due_at_until: tomorrow, user_id: user.id }}
                path="tasks"
                numberClassName="warning"
              />
            </Col>
            <Col className="col-20-percent">
              <StatisticBox
                value={user.statistics.overdueTasks}
                label={t('statistics.overdueTasks')}
                params={{ status: 'overdue', user_id: user.id }}
                path="tasks"
                numberClassName="danger"
              />
            </Col>
            <Col className="col-20-percent">
              <StatisticBox
                value={user.statistics.openClaims}
                label={t('statistics.openClaims')}
                params={{ status: 'open', assignee_id: user.id }}
                path="claims"
              />
            </Col>
          </Row>
        </Fragment>
      ))}
    </Page>
  );
};

export default UserStatistics;
