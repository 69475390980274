import { message } from 'antd';
import { t } from '@gowgates/utils';

export const showSavedMessage = () => {
  message.success(`${t('globals.saved')}!`);
};

export const showBaseErrors = (data) => {
  message.error(data.errors?.base?.join(', '));
};

export default {
  showSavedMessage,
  showBaseErrors
};
