import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Outlet, useParams } from 'react-router';
import { getJourney } from '../../api/endpoints';
import FullLoader from '../../components/FullLoader';
import Page from '../../components/Page';
import JourneyNav from './components/JourneyNav';
import JourneySummary from './components/JourneySummary';

const Journey = () => {
  const journeyId = parseInt(useParams().journeyId, 10);

  const { isLoading, data: journey } = useQuery({
    queryKey: ['journey', journeyId],
    queryFn: () => getJourney(journeyId)
  });

  if (isLoading) {
    return <FullLoader />;
  }

  return (
    <Page
      title={journey.name}
      backUrl="/processes"
      summary={<JourneySummary />}
      nav={<JourneyNav />}
    >
      <Outlet />
    </Page>
  );
};

export default Journey;
