import React from 'react';
import PropTypes from 'prop-types';
import { Modal } from 'antd';
import { LoadingOutlined } from '@ant-design/icons';

const LoadingHover = ({ open }) => (
  <Modal open={open} footer={null} closable={false} centered className="loading-modal">
    <LoadingOutlined />
  </Modal>
);

LoadingHover.defaultProps = {
  open: false
};

LoadingHover.propTypes = {
  open: PropTypes.bool
};

export default LoadingHover;
