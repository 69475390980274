import PropTypes from 'prop-types';
import { useMutation } from '@tanstack/react-query';
import { Space, Typography } from 'antd';
import { t } from '@gowgates/utils';
import { DateFormatter } from '@gowgates/dynamic-fields';
import { updateUser } from '../../../api/endpoints';
import UserAvatar from '../../../components/UserAvatar';

const ProfileSummary = ({ user }) => {
  const { mutate } = useMutation({
    mutationFn: { mutationFn: (values) => updateUser(user.id, values) }
  });

  const uploadAvatar = (e) => {
    const file = e.target.files[0];
    mutate({ avatar: file });
  };

  return (
    <aside className="page-summary">
      <Space direction="vertical" size="middle" className="w-100">
        <Space direction="vertical" className="w-100 text-center">
          <UserAvatar size={60} user={user} />

          <div>
            <label htmlFor="user_avatar">
              <Typography.Link>{t('user.uploadAvatar')}</Typography.Link>
            </label>
            <input
              id="user_avatar"
              name="user_avatar"
              className="d-none"
              type="file"
              onChange={uploadAvatar}
              accept="image/*"
              aria-label="Upload"
            />
          </div>
        </Space>

        <dl>
          <dt>{t('activerecord.attributes.user.createdAt')}</dt>
          <dd>
            <DateFormatter value={user.createdAt} />
          </dd>
        </dl>
      </Space>
    </aside>
  );
};

ProfileSummary.propTypes = {
  user: PropTypes.object.isRequired
};

export default ProfileSummary;
