import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { CloseCircleOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';

const UserLoginLockedTag = ({ locked }) => {
  if (locked === false) {
    return null;
  }

  return (
    <Tag icon={<CloseCircleOutlined />} color="error">
      {t('user.locked')}
    </Tag>
  );
};

UserLoginLockedTag.propTypes = {
  locked: PropTypes.bool.isRequired
};

export default UserLoginLockedTag;
