import { snakeCase } from 'change-case';

export const subdomain = window.location.hostname.split('.')[0];
export const authTokenKey = `_cgw_${snakeCase(subdomain)}_backoffice_jwt`;
export const domain = `.${import.meta.env.VITE_CLAIMS_BACKOFFICE_DOMAIN}`;
export const protocol = import.meta.env.VITE_CLAIMS_BACKOFFICE_PROTOCOL || 'https';
export const port = import.meta.env.VITE_CLAIMS_BACKOFFICE_PORT
  ? `:${import.meta.env.VITE_CLAIMS_BACKOFFICE_PORT}`
  : '';
export const backendDomain = `cgw-api-${subdomain}.${import.meta.env.VITE_CLAIMS_BACKOFFICE_DOMAIN}`;
export const backendURL = `${protocol}://${backendDomain}${port}`;

export default {};
