import { Table, TableProps, Typography } from 'antd';
import { t } from '@gowgates/utils';
import { Task } from '../../../../types';
import useClaim from '../../../../hooks/useClaim';
import TaskAssignee from './TaskAssignee';
import TaskRole from './TaskRole';
import StatusCol from './StatusCol';
import { CloseTaskModalType } from './TaskList';

type ClaimTasksTableProps = {
  tasks: Array<Task>;
  rowSelection: TableProps<Task>['rowSelection'];
  showTask: CloseTaskModalType;
};

const ClaimTasksTable = ({ tasks, rowSelection, showTask }: ClaimTasksTableProps) => {
  const { claim } = useClaim();

  const columns: TableProps<Task>['columns'] = [
    {
      title: t('activerecord.attributes.task.name'),
      dataIndex: 'name',
      render: (_text, record) => (
        <Typography.Link onClick={() => showTask(record)} className="col-clickable">
          {record.name}
        </Typography.Link>
      )
    },
    {
      title: (
        <>
          <span>{t('activerecord.attributes.task.user')}</span>
          <br />
          <Typography.Text type="secondary" italic>
            <small>{t('task.assignedRole')}</small>
          </Typography.Text>
        </>
      ),
      dataIndex: 'user',
      render: (_text, record) => (
        <Typography.Link onClick={() => showTask(record)} className="col-clickable">
          <TaskAssignee task={record} claimant={claim.claimant} />
          <br />
          <Typography.Text type="secondary" italic>
            <small>
              <TaskRole task={record} />
            </small>
          </Typography.Text>
        </Typography.Link>
      )
    },
    {
      title: t('activerecord.attributes.task.status'),
      dataIndex: 'status',
      key: 'name',
      render: (_text, record) => (
        <Typography.Link onClick={() => showTask(record)} className="col-clickable">
          <StatusCol task={record} />
        </Typography.Link>
      )
    }
  ];

  return (
    <Table
      dataSource={tasks}
      columns={columns}
      rowKey={(record) => record.id}
      pagination={false}
      className="box-table"
      rowSelection={rowSelection}
      rowClassName={(record) => (record.status === 'overdue' ? 'row-overdue' : '')}
    />
  );
};

export default ClaimTasksTable;
