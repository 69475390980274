import React from 'react';
import { PlusOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import usePermissions from '../../hooks/usePermissions';
import LinkButton from '../../components/LinkButton';

const UsersActions = () => {
  const { permissions } = usePermissions();

  if (!permissions.createUser) {
    return null;
  }

  return (
    <LinkButton to="/users/new" icon={<PlusOutlined />}>
      {t('user.new')}
    </LinkButton>
  );
};

export default UsersActions;
