import { ConditionalDescription } from '@gowgates/dynamic-fields';
import useFileConfig from '../../../hooks/useFileConfig';
import useClaim from '../../../hooks/useClaim';

const PolicyDescriptions = () => {
  const { coverStructure } = useFileConfig();
  const { claim } = useClaim();

  return (
    <dl className="cg-descriptions">
      {coverStructure.map((field) => (
        <ConditionalDescription model={claim.cover} field={field} key={field.name} />
      ))}
    </dl>
  );
};

export default PolicyDescriptions;
