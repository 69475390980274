import React from 'react';
import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useLocation } from 'react-router';
import qs from 'query-string';
import { useQuery } from '@tanstack/react-query';
import { t, EMPTY_TEXT } from '@gowgates/utils';
import { DynamicFormatter, isArrayField } from '@gowgates/dynamic-fields';
import { HighlightText, useTable } from '@gowgates/core';
import { getFieldTemplates } from '../../../api/endpoints';
import ClaimStatusTag from '../../claim/components/ClaimStatusTag';
import AvatarAndName from '../../../components/AvatarAndName';
import StructureName from '../../../components/StructureName';
import useLegacyTableSearch from '../../../hooks/useLegacyTableSearch';
import useCaseManagers from '../../../hooks/useCaseManagers';
import ClaimLiabilityTag from '../../claim/components/ClaimLiabilityTag';
import useIntegrations from '../../../hooks/useIntegrations';
import useAppConfigs from '../../../hooks/useAppConfigs';
import { convertToFilter } from '../../../utils/tables';
import useFileConfig from '../../../hooks/useFileConfig';

const ClaimsTable = ({ claims, loading, pagination, sorter, filters, structures }) => {
  const { coverStructure, clientStructure, claimantStructure } = useFileConfig();
  const { data } = useQuery({
    queryKey: ['fieldTemplates'],
    queryFn: getFieldTemplates
  });
  const claimFieldTemplates = (data && data['claimStructure']) || [];

  const { userFilters } = useCaseManagers();
  const search = qs.parse(useLocation().search);
  const { insightIntegrations, hasInsightIntegrations } = useIntegrations();
  const { appConfigs } = useAppConfigs();

  const integrationFilters = insightIntegrations.map((integration) => ({
    text: integration.settings?.ledger,
    value: integration.settings?.ledger
  }));

  const structureFilters = structures.map((structure) => ({
    text: structure.name,
    value: structure.claimStructureId
  }));

  const uniqSelectedStructure =
    filters.structureId?.length === 1 &&
    structures.find(({ id }) => `${id}` === filters.structureId[0]);

  const useEllipsis = true;
  const { handleTableChange } = useTable();
  const { columnProps, TooltipComponent } = useLegacyTableSearch({
    useEllipsis,
    columnWidth: 150
  });

  const generateFileConfigurationColumns = ({ fields = [], model, label = null }) =>
    (fields || []).map((field) => ({
      ...columnProps('', '', {
        title: `${t('reports.composedColumn', {
          model: label || t(`activerecord.models.${model}.one`),
          column: field.label
        })}`,
        render: (_text, record) =>
          record[model]?.data ? (
            <DynamicFormatter
              key={`${model}-${field.name}`}
              data={record[model].data}
              field={field}
              TooltipComponent={TooltipComponent}
            />
          ) : (
            EMPTY_TEXT
          )
      })
    }));
  const coverColumns = appConfigs.fileConfig.policyDetailsActive
    ? generateFileConfigurationColumns({
        fields: coverStructure,
        model: 'cover',
        label: appConfigs.fileConfig.policyDetailsLabel
      })
    : [];
  const clientColumns = appConfigs.fileConfig.clientDetailsActive
    ? generateFileConfigurationColumns({
        fields: clientStructure,
        model: 'client',
        label: appConfigs.fileConfig.clientDetailsLabel
      })
    : [];
  const claimantColumns = generateFileConfigurationColumns({
    fields: claimantStructure,
    model: 'claimant',
    label: appConfigs.fileConfig.claimantDetailsLabel
  });

  const columns = [
    { ...columnProps('id', 'claim', { sorter }) },
    {
      ...columnProps('status', 'claim', {
        sorter,
        filters,
        filterOptions: convertToFilter(appConfigs.claim.statuses)
      }),
      render: (_text, record) => (
        <ClaimStatusTag claim={record} TooltipComponent={TooltipComponent} />
      )
    },
    {
      ...columnProps('structureId', 'claim', { sorter, filters, filterOptions: structureFilters }),
      render: (_text, record) => (
        <StructureName claim={record} TooltipComponent={TooltipComponent} />
      )
    },
    {
      ...columnProps('assigneeId', 'claim', { sorter, filters, filterOptions: userFilters }),
      render: (_text, record) => (
        <AvatarAndName user={record.assignee} TooltipComponent={TooltipComponent} />
      )
    },
    ...clientColumns,
    ...claimantColumns,
    ...coverColumns,
    {
      ...columnProps('createdAt', 'claim', {
        sorter,
        filters,
        type: 'date'
      })
    },
    {
      ...columnProps('notifiedAt', 'claim', {
        sorter,
        filters,
        type: 'date'
      })
    },
    {
      ...columnProps('liability', 'claim', {
        sorter,
        filters,
        filterOptions: convertToFilter(appConfigs.claim.liabilities)
      }),
      render: (text) => <ClaimLiabilityTag liability={text} TooltipComponent={TooltipComponent} />
    },
    {
      ...columnProps('openTasks', 'claim', { sorter, title: t('statistics.openTasks') })
    },
    {
      ...columnProps('itemsCount', 'claim', { sorter, title: t('activerecord.models.item.other') })
    },
    { ...columnProps('notesCount', 'claim', { sorter }) },
    {
      ...columnProps('complaint', 'claim', {
        sorter,
        filters,
        filterOptions: convertToFilter(appConfigs.claim.allComplaints),
        type: 'enum'
      })
    },
    {
      ...columnProps('importance', 'claim', {
        sorter,
        filters,
        filterOptions: convertToFilter(appConfigs.claim.allImportances),
        type: 'enum'
      })
    },
    { ...columnProps('totalReserve', 'claim', { type: 'money' }) },
    { ...columnProps('settlementReserve', 'claim', { sorter, filters, type: 'money' }) },
    { ...columnProps('legalCostReserve', 'claim', { sorter, filters, type: 'money' }) },
    { ...columnProps('assessorsReserve', 'claim', { sorter, filters, type: 'money' }) },
    { ...columnProps('otherReserve', 'claim', { sorter, filters, type: 'money' }) },
    { ...columnProps('paidAmount', 'claim', { sorter, filters, type: 'money' }) },
    { ...columnProps('amountOutstanding', 'claim', { filters, type: 'money' }) },
    { ...columnProps('recoveryReserve', 'claim', { sorter, filters, type: 'money' }) },
    { ...columnProps('insurerNumber', 'claim', { filters, textFilter: true }) },
    { ...columnProps('closedAt', 'claim', { type: 'date' }) }
  ];

  if (hasInsightIntegrations) {
    columns.push({
      ...columnProps('insightLedger', 'claim', {
        sorter,
        filters,
        filterOptions: integrationFilters
      })
    });

    columns.push({
      ...columnProps('insightPolicyTransactionId', 'claim', { filters, textFilter: true })
    });

    columns.push({
      ...columnProps('insightSalesTeamName', 'claim', {
        sorter,
        filters,
        textFilter: true,
        title: t('activerecord.attributes.client.insight_sales_team_name')
      }),
      render: (_text, record) =>
        useEllipsis ? (
          <TooltipComponent title={record.client?.insightSalesTeamName}>
            {record.client?.insightSalesTeamName}
          </TooltipComponent>
        ) : (
          <HighlightText
            searchWords={[search.insight_sales_team_name]}
            text={record.client?.insightSalesTeamName}
          />
        )
    });

    columns.push({
      ...columnProps('insightServiceTeamName', 'claim', {
        sorter,
        filters,
        textFilter: true,
        title: t('activerecord.attributes.client.insight_service_team_name')
      }),
      render: (_text, record) =>
        useEllipsis ? (
          <TooltipComponent title={record.client?.insightServiceTeamName}>
            {record.client?.insightServiceTeamName}
          </TooltipComponent>
        ) : (
          <HighlightText
            searchWords={[search.insight_service_team_name]}
            text={record.client?.insightServiceTeamName}
          />
        )
    });
  }

  // data fields
  (uniqSelectedStructure?.data?.claim || claimFieldTemplates || []).forEach((f) => {
    if (isArrayField(f)) {
      return;
    }

    columns.push({
      ...columnProps('', '', {
        title: f.label,
        render: (_text, record) => {
          let field = f;

          // when unique structure is not selected only fixed fields from data are show
          if (!uniqSelectedStructure) {
            const structure = structures.find(({ id }) => id === record.structureId);
            field = structure?.data?.claim?.find(({ name }) => f.name === name);
          }

          return field && record.data ? (
            <DynamicFormatter
              key={field.name}
              data={record.data}
              field={field}
              TooltipComponent={TooltipComponent}
            />
          ) : (
            EMPTY_TEXT
          );
        }
      })
    });
  });

  return (
    <Table
      dataSource={claims}
      rowKey={(record) => record.id}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      scroll={{
        x: 5000
      }}
      columns={columns}
    />
  );
};

ClaimsTable.defaultProps = {
  claims: []
};

ClaimsTable.propTypes = {
  claims: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  sorter: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  structures: PropTypes.array.isRequired
};

export default ClaimsTable;
