import React, { useState } from 'react';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { getDocumentStructures, getFields, getReportFields } from '../../../../api/endpoints';
import FullLoader from '../../../../components/FullLoader';
import ReportField from './Field';

const ReportStructureFields = () => {
  const structureId = Number(useParams().structureId);
  const reportId = Number(useParams().reportId);
  const queryClient = useQueryClient();
  const reportStructure = queryClient.getQueryData(['reportStructure', reportId]);
  usePageTitle(
    `${reportStructure.name} - ${t('activerecord.models.field.other')} | ${t(
      'activerecord.models.claimStructure.other'
    )}`
  );
  const [editing, setEditing] = useState(false);

  const { isLoading, data } = useQuery({
    queryKey: ['reportStructure', reportId, 'fields'],
    queryFn: () => getReportFields(structureId, reportId)
  });
  const { isLoadingFields, data: fields } = useQuery({
    queryKey: ['fields', `/structures/${structureId}/claim`],
    queryFn: () => getFields(`claim_structures/${structureId}/fields`)
  });
  const { isLoadingDocs, data: docs } = useQuery({
    queryKey: ['documentStructures', structureId],
    queryFn: () => getDocumentStructures(structureId)
  });

  if (isLoading || isLoadingFields || isLoadingDocs || !fields || !docs) {
    return <FullLoader />;
  }

  const startEditing = (fieldId) => {
    setEditing(fieldId);
  };

  const endEditing = () => {
    setEditing(false);
  };

  return (
    <section className="report-fields">
      {data.map((reportField) => (
        <ReportField
          key={reportField.pdfName}
          reportField={reportField}
          dynamicClaimFields={fields}
          documentStructures={docs}
          editing={editing}
          startEditing={startEditing}
          endEditing={endEditing}
        />
      ))}
    </section>
  );
};

export default ReportStructureFields;
