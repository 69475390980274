import { isAxiosError } from 'axios';
import { isRouteErrorResponse, useRouteError } from 'react-router';
import Cookies from 'js-cookie';
import { apiConfigs } from '@gowgates/api-client';
import { NotFound } from './NotFound';
import { InternalServerError } from './InternalServerError';

export type ErrorBoundaryProps = {
  homepagePath?: string;
  disableFooter?: boolean;
};

export const ErrorBoundary = ({ homepagePath, disableFooter = false }: ErrorBoundaryProps) => {
  const error = useRouteError();

  const errorProps = { homepagePath, disableFooter };
  const renderNotFoundError = () => <NotFound {...errorProps} />;

  if (isRouteErrorResponse(error)) {
    if (error.status === 404) {
      return renderNotFoundError();
    }

    if (error.status === 401) {
      return <div>You arent authorized to see this</div>;
    }

    if (error.status === 503) {
      return <div>Looks like our API is down</div>;
    }
  } else if (isAxiosError(error)) {
    if (error?.response?.status === 404) {
      return renderNotFoundError();
    }

    if (error?.response?.status === 403) {
      return renderNotFoundError();
    }

    if (error?.response?.status === 401) {
      localStorage.removeItem('user');
      const configs = apiConfigs();
      Cookies.remove(configs.authTokenKey, { domain: configs.domain });
      window.location.href = '/login';
      return null;
    }
  }

  return <InternalServerError {...errorProps} />;
};
