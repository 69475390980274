import { UploadOutlined } from '@ant-design/icons';
import { Space } from 'antd';
import { t } from '@gowgates/utils';
import { useFormBuilder } from '../../../../hooks/useFormBuilder';

export const FileInput = () => {
  const { disabled } = useFormBuilder();

  return (
    <span className={`field-input${disabled ? ' disabled' : ''} file`}>
      <Space>
        <UploadOutlined />
        {t('globals.attach')}
      </Space>
    </span>
  );
};
