import { styled } from 'styled-components';
import { theme } from 'antd';
import ReactQuill from 'react-quill';
import { QUILL_MODULES } from '@gowgates/utils';
import { quillRules } from '../../utils/quill';

const StyledQuill = styled(ReactQuill)`
  ${(props) =>
    // @ts-expect-error validate this later
    quillRules(props.token, props.hasError)}
`;

type CgwQuillProps = {
  hasError?: boolean;
  tabIndex?: number;
};

export const CgwQuill = (props: CgwQuillProps) => {
  const { token } = theme.useToken();

  return (
    <StyledQuill
      modules={QUILL_MODULES}
      formats={['bold', 'italic', 'underline', 'list']}
      /* @ts-expect-error validate this later */
      token={token}
      {...props}
    />
  );
};
