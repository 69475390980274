import { tEnum } from '@gowgates/utils';
import { Task } from '../../../../../types';
import TaskLink from './TaskLink';

type CreationDetailsProps = { task: Task };

const CreationDetails = ({ task }: CreationDetailsProps) => {
  if (task.creationDetails.event === 'task_trigger') {
    return (
      <span>
        {tEnum('taskTrigger.types', task.creationDetails.type)}
        {task.creationDetails.action &&
          ` (${tEnum('taskTrigger.actions', task.creationDetails.action)})`}
        {task.creationDetails.name && ` (${task.creationDetails.name})`}
      </span>
    );
  }

  if (task.creationDetails.event === 'button_action') {
    return (
      <span>
        Completion of task "{`${task.creationDetails.name}`}" by clicking button "
        {`${task.creationDetails.buttonLabel}`}"
      </span>
    );
  }

  if (task.creationDetails.event === 'closing_task') {
    return (
      <span>
        Completion of task
        <TaskLink claimId={task.claimId} details={task.creationDetails} />
      </span>
    );
  }

  if (task.creationDetails.event === 'manual') {
    return <span>Manually created</span>;
  }

  return '';
};

export default CreationDetails;
