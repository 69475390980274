import { AxiosResponse } from 'axios';

export function extractData<Type>(response: AxiosResponse<Type>) {
  return response.data;
}

export function extractDataAndPagination<Type>(response: AxiosResponse<Type[]>) {
  return {
    rows: response.data,
    pagination: {
      total: parseInt(response.headers['x-total'], 10),
      current: parseInt(response.headers['x-page'], 10),
      pageSize: parseInt(response.headers['x-per-page'], 10),
      firstRecord: parseInt(response.headers['x-first-record'], 10),
      lastRecord: parseInt(response.headers['x-last-record'], 10)
    }
  };
}

export function extractDataAndAuthorization<UserType = unknown>(response: AxiosResponse<UserType>) {
  return {
    user: response.data,
    authorization: response.headers['authorization'] as string | undefined
  };
}

export type AuthorizationResponse<UserType = unknown> = ReturnType<
  typeof extractDataAndAuthorization<UserType>
>;
