import { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Modal, Form, Input, Select } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormItem, CgDatePicker } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { CgwQuill } from '@gowgates/core';
import { createNote } from '../../../../api/endpoints';
import ModalFormFooter from '../../../../components/ModalFormFooter';
import useAppConfigs from '../../../../hooks/useAppConfigs';

const NewNoteModal = ({ claimId, isOpen, setOpen }) => {
  const [form] = Form.useForm();
  const queryClient = useQueryClient();
  const { appConfigs } = useAppConfigs();

  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => createNote({ ...values, notableId: claimId, notableType: 'Claim' }),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({ queryKey: ['claim', claimId, 'notes'] });
    }
  });

  const shouldUpdateDirection = (prevValues, currentValues) =>
    prevValues.direction !== currentValues.direction;

  const shouldUpdateRecipient = (prevValues, currentValues) =>
    prevValues.recipient !== currentValues.recipient;

  return (
    <Modal title={t('note.new')} open={isOpen} onCancel={closeModal} width={800} footer={null}>
      <Form form={form} layout="vertical" initialValues={{}} disabled={isLoading} onFinish={mutate}>
        <FormItem name="subject" model="note" required>
          <Input ref={inputRef} tabIndex={1} />
        </FormItem>

        <FormItem name="text" model="note" required>
          <CgwQuill tabIndex={2} hasError={form.getFieldError('text').length > 0} />
        </FormItem>

        <FormItem name="direction" model="note" required>
          <Select options={appConfigs.note.directions} />
        </FormItem>

        <Form.Item noStyle shouldUpdate={shouldUpdateDirection}>
          {({ getFieldValue }) => {
            if (getFieldValue('direction') === 'success_story') {
              return (
                <FormItem name="successStoryType" model="note" required>
                  <Select options={appConfigs.note.successStoryTypes} />
                </FormItem>
              );
            }

            if (
              getFieldValue('direction') === 'incoming' ||
              getFieldValue('direction') === 'outgoing'
            ) {
              return (
                <>
                  <FormItem name="nature" model="note" required>
                    <Select options={appConfigs.note.natures} />
                  </FormItem>

                  <FormItem
                    name="recipient"
                    label={getFieldValue('direction') === 'incoming' ? 'From' : 'To'}
                    required
                  >
                    <Select options={appConfigs.note.recipients} />
                  </FormItem>

                  <Form.Item noStyle shouldUpdate={shouldUpdateRecipient}>
                    {({ getFieldValue: getFV }) =>
                      getFV('recipient') === 'other' && (
                        <FormItem name="recipientName" model="note" required>
                          <Input />
                        </FormItem>
                      )
                    }
                  </Form.Item>

                  <FormItem name="communicatedAt" model="note" required>
                    <CgDatePicker />
                  </FormItem>
                </>
              );
            }
          }}
        </Form.Item>

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

NewNoteModal.propTypes = {
  claimId: PropTypes.number.isRequired,
  isOpen: PropTypes.bool.isRequired,
  setOpen: PropTypes.func.isRequired
};

export default NewNoteModal;
