import Highlighter from 'react-highlight-words';

export const HighlightText = ({ searchWords, text }: { searchWords: string[]; text: string }) => (
  <Highlighter
    highlightClassName="text-highlighted"
    searchWords={searchWords}
    autoEscape
    textToHighlight={text || ''}
  />
);
