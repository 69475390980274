import { useEffect } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router';
import { t } from '@gowgates/utils';
import Page from '../../../components/Page';
import { Claim as ClaimType } from '../../../types';
import ClaimNav from './components/ClaimNav';
import ClaimSummary from './components/ClaimSummary';
import ClaimContextMenu from './components/ClaimContextMenu';
import ActionButton from './components/ActionButton';

type ClaimProps = {
  claim: ClaimType;
};

const Claim = ({ claim }: ClaimProps) => {
  const navigate = useNavigate();
  const { pathname } = useLocation();

  useEffect(() => {
    if (pathname === `/claims/${claim.id}`) {
      navigate('claim', { replace: true });
    }
  }, [pathname]);

  const actions = claim.actions.map((action) => (
    <ActionButton record={claim} action={action} key={action.id} entity="claim" />
  ));

  return (
    <Page
      title={t('claim.id', { id: claim.id })}
      backUrl="/claims"
      contextMenu={<ClaimContextMenu claim={claim} />}
      actions={actions}
      summary={<ClaimSummary claim={claim} />}
      nav={<ClaimNav />}
    >
      <Outlet />
    </Page>
  );
};

export default Claim;
