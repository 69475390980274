import { PropsWithChildren, useState } from 'react';
import { BrandConfigs } from '../../types';
import { BrandConfigsContext } from './context';

export const BrandConfigsProvider = ({
  children,
  initialConfigs
}: PropsWithChildren<{ initialConfigs?: BrandConfigs }>) => {
  const [brandConfigs, setBrandConfigs] = useState<BrandConfigs>(initialConfigs || {});

  return (
    <BrandConfigsContext.Provider
      value={{
        brandConfigs,
        setBrandConfigs
      }}
    >
      {children}
    </BrandConfigsContext.Provider>
  );
};
