import React, { createContext } from 'react';
import { BrandConfigs } from '../../types';

export const BrandConfigsContext = createContext<{
  brandConfigs: BrandConfigs;
  setBrandConfigs: React.Dispatch<React.SetStateAction<BrandConfigs>>;
}>({
  brandConfigs: {},
  setBrandConfigs: () => console.warn('Make sure Brand Configs Provider is configured')
});
