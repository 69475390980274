import { Link } from 'react-router-dom';
import { TaskCompletionDetails, TaskCreationDetails } from '../../../../../types';

type TaskLinkProps = {
  claimId: number;
  details: TaskCreationDetails | TaskCompletionDetails;
};

const TaskLink = ({ claimId, details }: TaskLinkProps) => (
  <Link to={`/claims/${claimId}/tasks?id=${details.taskId}`}>
    {` #${details.taskId} (${details.taskName})`}
  </Link>
);

export default TaskLink;
