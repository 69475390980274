import React from 'react';
import Box from '../../../../components/Box';
import ClaimantDetailsForm from '../../forms/ClaimantDetails';
import ClientDetailsForm from '../../forms/ClientDetails';
import FileDetailsForm from '../../forms/FileDetails';
import PolicyDetailsForm from '../../forms/PolicyDetails';
import CreateClaimForm from '../CreateClaimForm';
import useClaim from '../../../../hooks/useClaim';
import useAppConfigs from '../../../../hooks/useAppConfigs';

const CreateUnderwriting = () => {
  const { claim } = useClaim();
  const { appConfigs } = useAppConfigs();

  if (!appConfigs) return null;
  const fileConfig = appConfigs.fileConfig;

  return (
    <CreateClaimForm claim={claim} nestedModels={['client', 'claimant', 'cover']}>
      {(setUpdating) => (
        <>
          <Box title={fileConfig.fileDetailsLabel}>
            <FileDetailsForm setUpdating={setUpdating} />
          </Box>
          {fileConfig.policyDetailsActive && (
            <Box title={fileConfig.policyDetailsLabel}>
              <PolicyDetailsForm nested />
            </Box>
          )}
          {fileConfig.clientDetailsActive && (
            <Box title={fileConfig.clientDetailsLabel}>
              <ClientDetailsForm nested />
            </Box>
          )}
          <Box title={fileConfig.claimantDetailsLabel}>
            <ClaimantDetailsForm nested />
          </Box>
        </>
      )}
    </CreateClaimForm>
  );
};

export default CreateUnderwriting;
