import React from 'react';
import qs from 'query-string';
import { Input, Form } from 'antd';
import { SearchOutlined } from '@ant-design/icons';
import { useLocation, useNavigate } from 'react-router';

const TableSearch = () => {
  const search = qs.parse(useLocation().search);
  const navigate = useNavigate();
  const [form] = Form.useForm();

  const onSearch = (event) => {
    const { value } = event.target;

    // stores in `rest` all search keys except `page`
    const { page: _, ...rest } = search;

    navigate({ search: qs.stringify({ ...rest, q: value }) });
  };

  const onChange = (event) => {
    if (!event.target.value && event.type !== 'change') {
      // stores in `rest` all search keys except `page` and `q`
      const { page: _page, q: _q, ...rest } = search;
      navigate({ search: qs.stringify({ ...rest }) });
    }
  };

  return (
    <Form form={form} initialValues={search}>
      <Form.Item name="q">
        <Input prefix={<SearchOutlined />} onPressEnter={onSearch} allowClear onChange={onChange} />
      </Form.Item>
    </Form>
  );
};

export default TableSearch;
