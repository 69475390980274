import { Button, Space } from 'antd';
import { useParams } from 'react-router';
import { useQueryClient } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import ModalFooter from '../../../../../../components/ModalFooter';
import ActionButton from '../../../../show/components/ActionButton';
import { Item } from '../../../../../../types';

type ItemActionsProps = {
  item: Item;
  setEditing: (editing: boolean) => void;
  onSuccess: () => void;
};
const ItemActions = ({ item, setEditing, onSuccess }: ItemActionsProps) => {
  const claimId = Number(useParams().claimId);
  const queryClient = useQueryClient();

  const onClickBtn = () => {
    queryClient.invalidateQueries({ queryKey: ['claim', claimId, 'items', item.id] });
    onSuccess();
  };

  if (!item.permissions) {
    return null;
  }

  return (
    <ModalFooter spaceBetween>
      <div>
        {item.permissions.edit && (
          <Button onClick={() => setEditing(true)}>{t('globals.edit')}</Button>
        )}
      </div>

      <Space>
        {item.actions?.map((action) => (
          <ActionButton
            record={item}
            action={action}
            key={action.id}
            onSuccess={onClickBtn}
            entity="item"
          />
        ))}
      </Space>
    </ModalFooter>
  );
};

export default ItemActions;
