import React from 'react';
import PropTypes from 'prop-types';
import { UploadOutlined } from '@ant-design/icons';
import { Button, Upload, Form } from 'antd';
import DocumentUploader from './DocumentUploader';

const DocumentsFormUploader = ({ label }) => {
  const form = Form.useFormInstance();

  const beforeUpload = (file) => {
    form.setFieldsValue({ documents: [...(form.getFieldValue('documents') || []), { file }] });
    return false;
  };

  return (
    <Form.Item label={label}>
      <Form.List name="documents">
        {(documents, { remove }) => (
          <>
            {documents.map((document) => (
              <DocumentUploader
                key={document.name}
                form={form}
                document={document}
                remove={remove}
              />
            ))}
          </>
        )}
      </Form.List>

      <Upload fileList={[]} beforeUpload={beforeUpload}>
        <Button icon={<UploadOutlined />}>Attach a file</Button>
      </Upload>
    </Form.Item>
  );
};

DocumentsFormUploader.defaultProps = {
  label: 'Supporting documents'
};

DocumentsFormUploader.propTypes = {
  label: PropTypes.string
};

export default DocumentsFormUploader;
