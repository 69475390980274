import { FormInstance } from 'antd';
import axios, { AxiosError, AxiosResponse } from 'axios';
import map from 'lodash.map';
import get from 'lodash.get';
import isPlainObject from 'lodash.isplainobject';
import flattenDeep from 'lodash.flattendeep';
import { AnyModelWithData } from '../types';

type BaseNamePath = string | (string | number)[];

type FieldData = {
  name: string[];
  errors: string[];
};

type ErrorsFromModelInForm = ErrorsFromModel & {
  form: FormInstance;
};

type ErrorsFromModel = {
  model: AnyModelWithData;
  extraNodes?: (string | string[])[];
};

type ErrorsFromAPIInForm = {
  error: AxiosError;
  form: FormInstance;
  extraNodes?: (string | string[])[];
};

export const addErrorsFromModelInForm = ({
  model,
  form,
  extraNodes = []
}: ErrorsFromModelInForm) => {
  form.setFields(errorsFromModel({ model, extraNodes }));
};

export const addErrorsFromAPIInForm = ({ error, form, extraNodes = [] }: ErrorsFromAPIInForm) => {
  if (!axios.isAxiosError(error) || !error.response) return;
  const response: AxiosResponse = error.response;

  if (response.status === 422) {
    addErrorsFromModelInForm({ model: response.data, form, extraNodes });
  }
};

// When field has array field, it will come with errors like this:
//   - { array_field.0.first_question: ['empty'] }
// Needs to assign to the correct form path and numbers need to be converted to integer type
const errorsInArrayFields = (namespace: BaseNamePath, errorKey: string) =>
  [...namespace, ...errorKey.split('.')].map((val) =>
    isNaN(val as number) ? val : parseInt(val as string)
  );

const errorsFromModel = ({ model, extraNodes = [] }: ErrorsFromModel) => {
  if (!model) {
    return [];
  }

  const modelFields = map(model.errors, (errors, name) => {
    // if errors contain dots it means that there is an error in a nested field
    if (name.indexOf('.') >= 0) {
      const nestedFieldName = name.split('.')[0];
      const nestedField = model[nestedFieldName];

      if (Array.isArray(nestedField)) {
        return nestedField?.map(
          (
            nestedObject,
            index // iterates nested obj
          ) =>
            map(
              nestedObject?.errors || [],
              (
                nestedErrors,
                nestedName // iterates errors
              ) => ({ name: [nestedFieldName, index, nestedName], errors: nestedErrors }) // returns error
            )
        );
      }

      if (isPlainObject(nestedField)) {
        return map(
          (nestedField as AnyModelWithData)?.errors || [],
          (
            nestedErrors,
            nestedName // iter. errors
          ) => ({ name: [nestedFieldName, nestedName], errors: nestedErrors }) // returns error
        );
      }

      return [];
    }

    if (name === 'data') {
      return map(model.data.errors, (dataErrors, dataName) => ({
        name: errorsInArrayFields(['data'], dataName),
        errors: dataErrors
      }));
    }

    return { name, errors };
  });

  const extraNodesFields: FieldData[] = flattenDeep(
    extraNodes.map((extraNode) => {
      const extraNodeErrors = errorsFromModel({
        model: get(model, extraNode) as AnyModelWithData
      });

      return extraNodeErrors.map(
        (field) => ({ ...field, name: [...flattenDeep([extraNode]), ...field.name] }) as FieldData
      );
    })
  );

  return [...flattenDeep(modelFields as FieldData[][]), ...extraNodesFields];
};
