import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, Space, Typography } from 'antd';
import { t } from '@gowgates/utils';
import UserAvatar from '../../../components/UserAvatar';
import AvatarAndName from '../../../components/AvatarAndName';
import useResourceUsers from '../../../hooks/useResourceUsers';

const ReassignBtn = ({
  currentAssignee,
  placement,
  onChange,
  label,
  dropdown,
  showRole,
  filters
}) => {
  const { users } = useResourceUsers({ ...filters, status: 'active' });

  const onClick = (event) => {
    onChange(event.key);
  };

  const items = [
    {
      key: '-1',
      label: (
        <Space>
          <UserAvatar user={{ firstName: '?', lastName: '' }} />
          <Typography.Text type="secondary" italic>
            {t('claim.unassigned')}
          </Typography.Text>
        </Space>
      )
    },
    ...users.map((user) => ({
      key: `${user.id}`,
      label: <AvatarAndName user={user} showRole={showRole} />
    }))
  ];

  let dropDownContainer;

  if (dropdown === 'button') {
    dropDownContainer = <Button>{label || t('globals.change')}</Button>;
  } else {
    dropDownContainer = <span role="link">{label || t('globals.change')}</span>;
  }

  const menu = {
    items,
    onClick,
    selectable: true,
    defaultSelectedKeys: [`${currentAssignee?.id}` || '-1']
  };

  return (
    <Dropdown menu={menu} trigger={['click']} placement={placement} className="reassign-btn">
      <Typography.Link>{dropDownContainer}</Typography.Link>
    </Dropdown>
  );
};

ReassignBtn.defaultProps = {
  currentAssignee: {},
  placement: 'bottomLeft',
  onChange: () => {},
  label: null,
  dropdown: 'link',
  showRole: false,
  filters: {}
};

ReassignBtn.propTypes = {
  currentAssignee: PropTypes.object,
  placement: PropTypes.string,
  onChange: PropTypes.func,
  label: PropTypes.string,
  dropdown: PropTypes.string,
  showRole: PropTypes.bool,
  filters: PropTypes.object
};

export default ReassignBtn;
