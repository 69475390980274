import { Typography } from 'antd';
import { PreviewField } from '..';
import { Field } from '../../../../types';

export const ArrayInput = ({ fields }: { fields: Field[] }) => {
  if ((fields?.length || 0) === 0) {
    return (
      <Typography.Text type="secondary" italic>
        Click to add fields
      </Typography.Text>
    );
  }

  return (
    <div className="multiple-choices">
      <div className="entries">
        {fields?.map((child) => <PreviewField field={child} editable={false} key={child.id} />)}
      </div>
    </div>
  );
};
