import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createTaskStructure } from '../../../api/endpoints';
import TaskStructureForm from '../TaskStructure/Form';
import ModalFormFooter from '../../../components/ModalFormFooter';

const NewTaskStructureModal = ({ isOpen, setOpen, entity }) => {
  const journeyId = parseInt(useParams().journeyId, 10);
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus();
      setTimeout(() => form.resetFields(), 100);
    }
  }, [isOpen]);

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => createTaskStructure(journeyId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      closeModal();
      navigate(`${data.id}`);
    }
  });

  return (
    <Modal title={t('taskStructure.new')} open={isOpen} onCancel={closeModal} footer={null}>
      <Form
        form={form}
        layout="vertical"
        onFinish={mutate}
        initialValues={{ entity }}
        disabled={isLoading}
      >
        <TaskStructureForm ref={inputRef} />

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

NewTaskStructureModal.defaultProps = {
  isOpen: false
};

NewTaskStructureModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  entity: PropTypes.string.isRequired
};

export default NewTaskStructureModal;
