import React from 'react';
import { Form } from 'antd';
import { useModelAttributeLabel } from '../../hooks';

const rules = [{ validator: async () => null }];

type AntFormItemProps = React.ComponentProps<typeof Form.Item>;
export type FormItemProps = AntFormItemProps & { model?: string };

export const FormItem = ({ name, model, children, label, ...rest }: FormItemProps) => {
  const { label: finalLabel } = useModelAttributeLabel({
    label,
    name,
    model
  });

  return (
    <Form.Item name={name} label={finalLabel} rules={rules} {...rest}>
      {children}
    </Form.Item>
  );
};
