import { useFormBuilder } from '../../../../hooks/useFormBuilder';

export const InputWithSuffix = ({ suffix }: { suffix: string }) => {
  const { disabled } = useFormBuilder();

  return (
    <span className={`field-input${disabled ? ' disabled' : ''} with-suffix`}>
      <div className="field-addon">
        <span className="addon-wrapper">
          <span>{suffix}</span>
        </span>
      </div>
    </span>
  );
};
