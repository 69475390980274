import { useEffect, useState } from 'react';
import { Field } from '../../types';
import { useFormBuilder } from '../../hooks/useFormBuilder';
import { EditField } from './edit';
import { PreviewField } from './preview';

export const FieldBuilder = ({ field }: { field: Field }) => {
  const { currentFieldId } = useFormBuilder();
  const [isEditing, setEditing] = useState(currentFieldId === field.id);

  useEffect(() => {
    setEditing(currentFieldId === field.id);
  }, [currentFieldId, field.id]);

  if (isEditing) {
    return <EditField field={field} />;
  }

  return <PreviewField field={field} />;
};
