import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { DangerPopconfirm } from '@gowgates/dynamic-fields';
import { deleteReportStructure } from '../../../api/endpoints';

const DeleteReportStructure = ({ reportStructure, children, onSuccessfulDelete }) => {
  const structureId = Number(useParams().structureId);
  const queryClient = useQueryClient();

  const onSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['claimStructure', structureId] });
    message.success('Report deleted');

    onSuccessfulDelete();
  };

  return (
    <div className="d-flex justify-content-end">
      <DangerPopconfirm
        deleteFn={() => deleteReportStructure(structureId, reportStructure.id)}
        invalidateQueries={['reportStructures', structureId]}
        permission={reportStructure.id}
        onSuccess={onSuccess}
        tooltip={false}
      >
        {children}
      </DangerPopconfirm>
    </div>
  );
};

DeleteReportStructure.propTypes = {
  reportStructure: PropTypes.object.isRequired,
  children: PropTypes.node,
  onSuccessfulDelete: PropTypes.func
};

DeleteReportStructure.defaultProps = {
  children: null,
  onSuccessfulDelete: () => {}
};

export default DeleteReportStructure;
