import React from 'react';
import PropTypes from 'prop-types';
import { Alert, ConfigProvider, theme } from 'antd';
import { t } from '@gowgates/utils';
import { VulnerabilityIcon } from '../../../../icons';

const ClaimVulnerability = ({ claim }) => {
  const { token } = theme.useToken();

  if (!claim.vulnerableCustomer) {
    return null;
  }

  return (
    <ConfigProvider theme={{ token: { colorWarning: token.orange6 } }}>
      <Alert
        message={t('activerecord.attributes.claim.vulnerableCustomer')}
        type="warning"
        showIcon
        icon={<VulnerabilityIcon />}
      />
    </ConfigProvider>
  );
};

ClaimVulnerability.propTypes = {
  claim: PropTypes.object.isRequired
};

export default ClaimVulnerability;
