import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const UserCol = ({ user, children }) => {
  if (user.permissions.show) {
    return (
      <Link to={`/users/${user.id}`} className="col-clickable">
        {children}
      </Link>
    );
  }

  return children;
};

UserCol.defaultProps = {
  children: null
};

UserCol.propTypes = {
  user: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default UserCol;
