import React, { useState } from 'react';
import { Button, Table } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { useQuery, useQueryClient } from '@tanstack/react-query';
import { Link, useParams } from 'react-router-dom';
import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { getDocumentStructures } from '../../../api/endpoints';
import NewDocumentStructureModal from './NewDocumentStructureModal';
import DeleteDocumentStructure from './DeleteDocumentStructure';

const { Column } = Table;

const DocumentStructures = () => {
  const structureId = Number(useParams().structureId);
  const queryClient = useQueryClient();
  const claimStructure = queryClient.getQueryData(['claimStructure', structureId]);
  const [isModalOpen, setModalOpen] = useState(false);
  usePageTitle(
    `${claimStructure.name} - ${t('activerecord.models.document.other')} | ${t(
      'activerecord.models.claimStructure.other'
    )}`
  );

  const { isLoading, data } = useQuery({
    queryKey: ['documentStructures', structureId],
    queryFn: () => getDocumentStructures(structureId)
  });

  return (
    <>
      <header className="d-flex justify-content-end">
        <Button type="primary" onClick={() => setModalOpen(true)} icon={<PlusOutlined />}>
          {t('document.add')}
        </Button>
      </header>

      <Table
        dataSource={data}
        rowKey={(record) => record.id}
        pagination={false}
        loading={isLoading}
        className="box-table"
      >
        <Column
          title="Name"
          dataIndex="label"
          render={(text, record) => (
            <Link to={`${record.id}`} className="col-clickable">
              {text}
            </Link>
          )}
        />
        <Column
          render={(text, record) => (
            <DeleteDocumentStructure id={record.id} claimStructureId={structureId} />
          )}
        />
      </Table>

      <NewDocumentStructureModal
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        structureId={structureId}
      />
    </>
  );
};

export default DocumentStructures;
