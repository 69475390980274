import React from 'react';
import PropTypes from 'prop-types';
import { ConfigProvider, Menu } from 'antd';
import {
  AuditOutlined,
  BarChartOutlined,
  FileDoneOutlined,
  SettingOutlined,
  TeamOutlined,
  DollarOutlined
} from '@ant-design/icons';
import { Link, NavLink, useMatch } from 'react-router-dom';
import { t, DEFAULT_CLAIMS_PATH } from '@gowgates/utils';
import usePermissions from '../../hooks/usePermissions';

const AppMenu = ({ theme }) => {
  const { permissions } = usePermissions();
  const match = useMatch({ path: '/:tab', end: false });

  const items = [];

  items.push({
    key: 'tasks',
    label: <NavLink to="/tasks">{t('activerecord.models.task.other')}</NavLink>,
    icon: <FileDoneOutlined />
  });

  if (permissions.listClaims) {
    items.push({
      key: 'claims',
      label: <NavLink to={DEFAULT_CLAIMS_PATH}>{t('activerecord.models.claim.other')}</NavLink>,
      icon: <AuditOutlined />
    });
  }

  if (permissions.listPayments) {
    items.push({
      key: 'payments',
      label: <NavLink to="/payments">{t('activerecord.models.payment.other')}</NavLink>,
      icon: <DollarOutlined />
    });
  }

  if (permissions.listUsers) {
    items.push({
      key: 'users',
      label: <NavLink to="/users">{t('activerecord.models.user.other')}</NavLink>,
      icon: <TeamOutlined />
    });
  }

  const reportsChildren = [
    {
      key: 'reports/claims',
      label: <Link to="/reports/claims">{t('activerecord.models.claim.other')}</Link>
    },
    {
      key: 'reports/progress',
      label: <Link to="/reports/progress">{t('reports.progress')}</Link>
    },
    {
      key: 'reports/tasks',
      label: <Link to="/reports/tasks">{t('activerecord.models.task.other')}</Link>
    }
  ];

  if (permissions.listPayments) {
    reportsChildren.push({
      key: 'reports/payments',
      label: <Link to="/reports/payments">{t('activerecord.models.payment.other')}</Link>
    });
  }

  if (permissions.allClaims) {
    items.push({
      key: 'reports',
      label: t('activerecord.models.report.other'),
      theme: 'light',
      icon: <BarChartOutlined />,
      children: reportsChildren
    });
  }

  const configChildren = [];

  if (permissions.listRoles) {
    configChildren.push({
      key: 'roles',
      label: <Link to="/roles">{t('activerecord.models.role.other')}</Link>
    });
  }

  if (permissions.listPermissions) {
    configChildren.push({
      key: 'permissions',
      label: <Link to="/permissions">{t('activerecord.models.permission.other')}</Link>
    });
  }

  if (permissions.listIntegrations) {
    configChildren.push({
      key: 'integrations',
      label: <Link to="/integrations">{t('backoffice.integrations')}</Link>
    });
  }

  if (permissions.manageFileConfig) {
    configChildren.push({
      key: 'file-config',
      label: <Link to="/file-config">{t('backoffice.fileConfig')}</Link>
    });
  }

  if (permissions.listClaimStructures) {
    configChildren.push({
      key: 'structures',
      label: <Link to="/structures">{t('activerecord.models.claimStructure.other')}</Link>
    });
  }

  if (permissions.listJourneys) {
    configChildren.push({
      key: 'processes',
      label: <Link to="/processes">{t('activerecord.models.journey.other')}</Link>
    });
  }

  if (permissions.listListItems) {
    configChildren.push({
      key: 'lists',
      label: <Link to="/lists">{t('backoffice.lists')}</Link>
    });
  }

  if (permissions.updateTenant) {
    configChildren.push({
      key: 'general-config',
      label: <Link to="/general-config">{t('globals.general')}</Link>
    });
  }

  if (permissions.listEmailTemplates) {
    configChildren.push({
      key: 'email-templates',
      label: <Link to="/email-templates">{t('activerecord.models.emailTemplate.other')}</Link>
    });
  }

  if (configChildren.length > 0) {
    items.push({
      key: 'configurations',
      label: t('globals.configurations'),
      children: configChildren,
      theme: 'light',
      icon: <SettingOutlined />
    });
  }

  return (
    <ConfigProvider theme={theme}>
      <Menu
        data-testid="app-menu"
        theme="dark"
        selectedKeys={match?.params?.tab}
        mode="horizontal"
        items={items}
        className="main-menu"
      />
    </ConfigProvider>
  );
};

AppMenu.defaultProps = {
  theme: {}
};

AppMenu.propTypes = {
  theme: PropTypes.object
};

export default AppMenu;
