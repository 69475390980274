import React from 'react';
import PropTypes from 'prop-types';
import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';

const statuses = {
  approved: 'green',
  declined: 'error',
  withdrawn: 'warning'
};

const icons = {
  approved: <CheckCircleOutlined />,
  declined: <CloseCircleOutlined />
};

const ClaimLiabilityTag = ({ liability, TooltipComponent }) => (
  <Tag icon={icons[liability]} color={statuses[liability]}>
    <TooltipComponent title={t(`claim.liabilities.${liability}`)}>
      {t(`claim.liabilities.${liability}`)}
    </TooltipComponent>
  </Tag>
);

ClaimLiabilityTag.defaultProps = {
  // Wrapper to avoid props warning because Fragment does not have title
  // eslint-disable-next-line react/prop-types
  TooltipComponent: ({ children }) => <>{children}</>
};

ClaimLiabilityTag.propTypes = {
  liability: PropTypes.string.isRequired,
  TooltipComponent: PropTypes.func
};

export default ClaimLiabilityTag;
