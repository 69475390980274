import { RouteObject } from 'react-router';

import { Login } from './Login';
import { ForgotPassword } from './ForgotPassword';
import { ResendConfirmation } from './ResendConfirmation';
import { Confirmation } from './Confirmation';
import { Unlock } from './Unlock';
import { ResendUnlock } from './ResendUnlock';
import { ResetPassword } from './ResetPassword';

export const authRoutes = ({
  errorElement
}: { errorElement?: RouteObject['errorElement'] } | undefined = {}) => [
  {
    path: 'login',
    element: <Login />,
    errorElement
  },
  {
    path: 'forgot-password',
    element: <ForgotPassword />,
    errorElement
  },
  {
    path: 'resend-confirmation',
    element: <ResendConfirmation />,
    errorElement
  },
  {
    path: 'users/confirmation',
    element: <Confirmation />,
    errorElement
  },
  {
    path: 'users/unlock',
    element: <Unlock />,
    errorElement
  },
  {
    path: 'resend-unlock',
    element: <ResendUnlock />,
    errorElement
  },
  {
    path: 'users/password/edit',
    element: <ResetPassword />,
    errorElement
  }
];
