import PropTypes from 'prop-types';
import { t } from '@gowgates/utils';
import { ConditionalDescription } from '@gowgates/dynamic-fields';

const ItemDescriptions = ({ item, structure }) => (
  <dl className="cg-descriptions">
    <dt>{t('activerecord.attributes.item.category')}</dt>
    <dd>{t(`item.categories.${item.category}`)}</dd>

    {structure.items.map((field) => (
      <ConditionalDescription model={item} field={field} key={field.name} />
    ))}
  </dl>
);

ItemDescriptions.propTypes = {
  item: PropTypes.object.isRequired,
  structure: PropTypes.object.isRequired
};

export default ItemDescriptions;
