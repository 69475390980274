import { initializeApp } from '@gowgates/core';

import { App } from './App';
import { backendURL, authTokenKey, domain } from './utils/tenants';

initializeApp({
  AppComponent: App,
  apiConfigs: {
    baseURL: backendURL,
    authTokenKey,
    domain
  }
});
