import { forwardRef } from 'react';
import { Input, InputRef } from 'antd';
import { UserOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { InputFormItem } from '../InputFormItem';

type AntInputProps = React.ComponentProps<typeof Input>;

export const EmailInput = forwardRef<InputRef, AntInputProps>((props, ref) => {
  let requiredMessage = t('user.email_required', {
    notFoundPlaceholder: 'undefined'
  });
  requiredMessage = requiredMessage === 'undefined' ? 'Please input your email' : requiredMessage;

  return (
    <InputFormItem
      ref={ref}
      name="email"
      rules={[{ required: true, message: requiredMessage }]}
      model="user"
      inputProps={{
        prefix: <UserOutlined />,
        placeholder: t('activerecord.attributes.user.email'),
        ...props
      }}
    />
  );
});
