import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Menu } from 'antd';
import { t } from '@gowgates/utils';

const FileConfigNav = () => {
  const match = useMatch({ path: '/file-config/:tab', end: false });

  const menuItems = [
    {
      key: 'file',
      label: <Link to="file">{t('activerecord.models.file.one')}</Link>
    },
    {
      key: 'claimant',
      label: <Link to="claimant">{t('activerecord.models.claimant.one')}</Link>
    },
    {
      key: 'client',
      label: <Link to="client">{t('activerecord.models.client.one')}</Link>
    },
    {
      key: 'cover',
      label: <Link to="cover">{t('activerecord.models.cover.one')}</Link>
    }
  ];

  return (
    <Menu
      selectedKeys={match?.params?.tab}
      mode="horizontal"
      className="box-menu"
      items={menuItems}
    />
  );
};

export default FileConfigNav;
