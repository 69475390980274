import React from 'react';
import PropTypes from 'prop-types';
import { Empty, Typography, Table, Space } from 'antd';
import { Link } from 'react-router-dom';
import { t } from '@gowgates/utils';
import { DateFormatter } from '@gowgates/dynamic-fields';
import DirectionIcon from './DirectionIcon';
import NatureIcon from './NatureIcon';

const NotesTable = ({ notes }) => {
  const stripHtml = (string) => string.replace(/<[^>]+>/g, '');

  if (notes.length === 0) {
    return <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={t('claim.noNotes')} />;
  }

  const columns = [
    {
      key: 'recipient',
      width: 330,
      render: (record) => (
        <Link to={`${record.id}`} className="col-clickable">
          <Space>
            <span>
              {record.direction !== 'incoming' && record.direction !== 'outgoing'
                ? record.user.name
                : t(`note.recipients.${record.recipient}`)}
            </span>

            <DirectionIcon direction={record.direction} />
            <NatureIcon nature={record.nature} />
            {record.communicatedAt && (
              <small>
                <DateFormatter value={record.communicatedAt} />
              </small>
            )}
          </Space>
        </Link>
      )
    },
    {
      key: 'subject',
      ellipsis: true,
      render: (record) => (
        <Link to={`${record.id}`} className="col-clickable">
          <Typography.Text ellipsis>
            <span>
              <strong>{record.subject?.substring(0, 40)}</strong>
              {record.subject?.length > 40 && '...'}
            </span>
            <span> - </span>
            <span>{stripHtml(record.text)}</span>
          </Typography.Text>
        </Link>
      )
    },
    {
      dataIndex: 'createdAt',
      width: 130,
      className: 'text-right',
      render: (text, record) => (
        <Link to={`${record.id}`} className="col-clickable">
          <DateFormatter value={text} />
        </Link>
      )
    }
  ];

  return (
    <Table
      dataSource={notes}
      columns={columns}
      rowKey={(record) => record.id}
      pagination={false}
      showHeader={false}
      className="box-table"
    />
  );
};

NotesTable.propTypes = {
  notes: PropTypes.array.isRequired
};

export default NotesTable;
