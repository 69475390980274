import React from 'react';
import { Button, Form, Input, Space } from 'antd';
import { useQueryClient, useMutation } from '@tanstack/react-query';
import { useNavigate, useParams } from 'react-router';
import { FormItem, FilePicker } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { updateReportStructure } from '../../../../api/endpoints';
import { showSavedMessage } from '../../../../utils/messages';
import DeleteReportStructure from '../DeleteReportStructure';

const ReportStructureDetails = () => {
  const structureId = Number(useParams().structureId);
  const reportId = Number(useParams().reportId);
  const queryClient = useQueryClient();
  const reportStructure = queryClient.getQueryData(['reportStructure', reportId]);
  usePageTitle(
    `${reportStructure.name} - ${t('globals.details')} | ${t(
      'activerecord.models.claimStructure.other'
    )}`
  );
  const navigate = useNavigate();

  const [form] = Form.useForm();

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => updateReportStructure(structureId, reportId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.setQueryData(['reportStructure', reportId], data);
      showSavedMessage();
    }
  });

  return (
    <Form
      form={form}
      onFinish={mutate}
      initialValues={reportStructure}
      layout="vertical"
      disabled={isLoading}
    >
      <FormItem name="name" model="reportStructure" required>
        <Input />
      </FormItem>

      <FormItem name="template" model="reportStructure">
        <FilePicker accept="application/pdf" />
      </FormItem>

      <div className="d-flex justify-content-end">
        <Space>
          <DeleteReportStructure
            reportStructure={reportStructure}
            onSuccessfulDelete={() => navigate(`/structures/${structureId}/reports`)}
          >
            <Button htmlType="button" disabled={isLoading}>
              {t('globals.delete')}
            </Button>
          </DeleteReportStructure>
          <Button type="primary" htmlType="submit" loading={isLoading}>
            {t('globals.save')}
          </Button>
        </Space>
      </div>
    </Form>
  );
};

export default ReportStructureDetails;
