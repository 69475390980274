import {
  Row,
  Col,
  Form,
  Input,
  Tooltip,
  Space,
  FormListFieldData,
  FormInstance,
  InputRef
} from 'antd';
import { MinusCircleOutlined, DragOutlined } from '@ant-design/icons';
import { useSortable } from '@dnd-kit/sortable';
import { CSS } from '@dnd-kit/utilities';
import { Field } from '../../../types';
import { FormItem } from '../../forms';

type OptionProps = {
  option: FormListFieldData;
  add: (defaultValue?: unknown, insertIndex?: number | undefined) => void;
  remove: (index: number | number[]) => void;
  form: FormInstance<Field>;
  lastKey: number;
  setLastKey: (lastKey: number) => void;
  addRef: (element: InputRef | null, index: number) => void;
  focusElement: (index?: number) => void;
};

export const Option = ({
  option,
  add,
  remove,
  form,
  lastKey,
  setLastKey,
  addRef,
  focusElement
}: OptionProps) => {
  /* @ts-expect-error useSortable accepts any object */
  const { attributes, listeners, setNodeRef, transform } = useSortable({ id: option });

  const style = { transform: CSS.Transform.toString(transform) };

  const addOption = () => {
    const key = lastKey + 1;
    add({ key });
    setLastKey(key);
    focusElement();
  };

  const onPaste = (event: React.ClipboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;

    const pastedValues = (event.clipboardData.getData('text') || '').split('\n');

    if (pastedValues.length > 1) {
      event.preventDefault();
      event.stopPropagation();

      const oldOptions = [...form.getFieldValue('options')];
      // adds the first pasted value to the input we are pasting on
      oldOptions[option.name].value = (value || '') + pastedValues.shift()?.trim();

      let key = lastKey;
      // creates an array with the remaining options keys and values
      const remainingOptions = pastedValues.map((pastedValue) => {
        key += 1;
        return { key, value: pastedValue.trim() };
      });

      form.setFieldsValue({ options: [...oldOptions, ...remainingOptions] });

      setLastKey(key);
      focusElement();
    }
  };

  const onPressEnter = (event: React.KeyboardEvent<HTMLInputElement>) => {
    event.preventDefault();
    event.stopPropagation();
    addOption();
  };

  const onKeyDown = (event: React.KeyboardEvent<HTMLInputElement>) => {
    const { value } = event.target as HTMLInputElement;

    if (event.keyCode === 8 && value === '' && option.name !== 0) {
      remove(option.name);
      focusElement(option.name - 1);
    }
  };

  return (
    <Row align="middle" className="field-choice" gutter={10} ref={setNodeRef} style={style}>
      <Col span={16}>
        <Form.Item name={[option.name, 'key']} hidden>
          <Input />
        </Form.Item>
        <FormItem name={[option.name, 'value']}>
          <Input
            onPaste={(event) => onPaste(event)}
            onPressEnter={(event) => onPressEnter(event)}
            onKeyDown={(event) => onKeyDown(event)}
            ref={(element) => addRef(element, option.name)}
          />
        </FormItem>
      </Col>
      <Col span={2}>
        <Space>
          <Tooltip title="Remove option">
            <MinusCircleOutlined className="remove-choice" onClick={() => remove(option.name)} />
          </Tooltip>

          <Tooltip title="Drag & drop to move option">
            <DragOutlined className="move-choice" {...attributes} {...listeners} />
          </Tooltip>
        </Space>
      </Col>
    </Row>
  );
};
