import { Row, Col } from 'antd';
import { t } from '@gowgates/utils';
import { TextInput } from './TextInput';
import { SelectInput } from './SelectInput';

export const AddressInput = () => (
  <Row gutter={[10, 10]}>
    <Col span={12}>
      <SelectInput placeholder="Country" />
    </Col>
    <Col span={12}>
      <TextInput placeholder={t('address.street')} />
    </Col>
    <Col span={12}>
      <TextInput placeholder={t('address.city')} />
    </Col>
    <Col span={12}>
      <SelectInput placeholder={t('address.state')} />
    </Col>
    <Col span={12}>
      <TextInput placeholder={t('address.postcode')} />
    </Col>
  </Row>
);
