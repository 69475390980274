import { EMPTY_TEXT } from '@gowgates/utils';
import { Claimant, Task } from '../../../../types';
import AvatarAndName from '../../../../components/AvatarAndName';

type TaskAssigneeProps = {
  task: Task;
  claimant: Claimant;
};

const TaskAssignee = ({ task, claimant }: TaskAssigneeProps) => {
  if (task.frontoffice) {
    return claimant.name;
  }

  if (!task.user) {
    return EMPTY_TEXT;
  }

  return <AvatarAndName user={task.user} />;
};

export default TaskAssignee;
