import { Space, Tooltip, Typography } from 'antd';
import numeral from 'numeral';
import { formatPrice, DEFAULT_CURRENCY } from '@gowgates/utils';

type MoneyFormatterProps = {
  value?: number | string;
  currency?: string;
  rate?: number;
  negativeType?: 'secondary' | 'success' | 'warning' | 'danger';
  positiveType?: 'secondary' | 'success' | 'warning' | 'danger';
  zeroType?: 'secondary' | 'success' | 'warning' | 'danger';
  TooltipComponent?: typeof Tooltip;
};

export const MoneyFormatter = ({
  value,
  currency,
  rate,
  negativeType,
  positiveType,
  zeroType,
  TooltipComponent
}: MoneyFormatterProps) => {
  let type = undefined;

  const numeralValue = numeral(value).value() || 0;

  if (numeralValue < 0 && negativeType) {
    type = negativeType;
  }

  if (numeralValue > 0 && positiveType) {
    type = positiveType;
  }

  if (numeralValue === 0 && zeroType) {
    type = zeroType;
  }

  const title = `${formatPrice(value, currency)}${
    rate && currency !== DEFAULT_CURRENCY
      ? `(${formatPrice(numeral(value || 0).multiply(rate), DEFAULT_CURRENCY)})`
      : ''
  }`;

  const output = (
    <>
      <Typography.Text type={type}>{formatPrice(value, currency)}</Typography.Text>
      {rate && currency !== DEFAULT_CURRENCY && (
        <Typography.Text type="secondary">
          ({formatPrice(numeral(value || 0).multiply(rate), DEFAULT_CURRENCY)})
        </Typography.Text>
      )}
    </>
  );

  if (!TooltipComponent) {
    return <Space>{output}</Space>;
  }

  return (
    <Space>
      <TooltipComponent title={title}>{output}</TooltipComponent>
    </Space>
  );
};
