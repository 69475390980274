import { Field } from '../../../types';
import { useFormBuilder } from '../../../hooks/useFormBuilder';
import { Question } from './Question';
import { Input } from './Input';
import { Description } from './Description';

export const PreviewField = ({ field, editable = true }: { field: Field; editable?: boolean }) => {
  const { startEditing, disabled } = useFormBuilder();

  return (
    <div
      className={`field-container${disabled ? '' : ' field-container-preview'}`}
      role="button"
      onClick={() => !disabled && editable && startEditing(field.id)}
      onKeyUp={() => !disabled && editable && startEditing(field.id)}
      tabIndex={-1}
    >
      <Question field={field} />

      <div>
        <Input field={field} />
      </div>

      <Description field={field} />
    </div>
  );
};
