import React from 'react';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { FormBuilder } from '@gowgates/dynamic-fields';

const StructureClaim = () => {
  const structureId = Number(useParams().structureId);
  const queryClient = useQueryClient();
  const claimStructure = queryClient.getQueryData(['claimStructure', structureId]);
  usePageTitle(
    `${claimStructure.name} - ${t('activerecord.models.claim.one')} | ${t(
      'activerecord.models.claimStructure.other'
    )}`
  );

  return <FormBuilder fieldableId={structureId} fieldableType="claim_structure" />;
};

export default StructureClaim;
