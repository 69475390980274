import { theme, ThemeConfig } from 'antd';

export const loadDefaultTheme = () => {
  const { defaultAlgorithm, defaultSeed } = theme;
  const mapToken = defaultAlgorithm(defaultSeed);

  const defaultTheme: ThemeConfig = {
    token: {
      fontSize: 13,
      fontFamily: "'DM Sans', sans-serif",
      colorBgLayout: '#fff',
      colorBorderSecondary: mapToken.colorBorder
    },
    components: {
      Table: {
        padding: 12,
        paddingContentVerticalLG: 12
      },
      Modal: {
        wireframe: true
      }
    },
    cssVar: true
  };

  return defaultTheme;
};
