import React from 'react';
import PropTypes from 'prop-types';
import { LeftOutlined, RightOutlined, SmileOutlined } from '@ant-design/icons';
import { Space, Typography } from 'antd';
import { t } from '@gowgates/utils';

const DirectionDescription = ({ note }) => {
  const direction = t(`note.directions.${note.direction}`);
  let recipient = t(`note.recipients.${note.recipient}`);

  if (note.recipient === 'other') {
    recipient = recipient.concat(` (${note.recipientName})`);
  }

  if (note.direction === 'incoming') {
    return (
      <Space>
        <Typography.Text type="success">
          <LeftOutlined />
        </Typography.Text>
        <span>
          <strong>{direction}</strong>
          <span> from </span>
          <strong>{recipient}</strong>
        </span>
      </Space>
    );
  }

  if (note.direction === 'outgoing') {
    return (
      <Space>
        <Typography.Text type="danger">
          <RightOutlined />
        </Typography.Text>
        <span>
          <strong>{direction}</strong>
          <span> to </span>
          <strong>{recipient}</strong>
        </span>
      </Space>
    );
  }

  if (note.direction === 'success_story') {
    return (
      <Space>
        <Typography.Text type="success">
          <SmileOutlined />
        </Typography.Text>
        <span>
          <strong>{t('note.directions.successStory')}: </strong>
          <span>{t(`note.successStoryTypes.${note.successStoryType}`)}</span>
        </span>
      </Space>
    );
  }

  return <strong>{direction}</strong>;
};

DirectionDescription.propTypes = {
  note: PropTypes.object.isRequired
};

export default DirectionDescription;
