import React, { useEffect } from 'react';
import { ConfigProvider } from 'antd';
import { RouterProvider } from 'react-router';
import cloneDeep from 'lodash.clonedeep';

import { useBrandConfigs } from '@gowgates/core';

import FullLoader from './components/FullLoader';
import router from './router';
import { useTenant } from './hooks/useTenant';

const TenantProvider = () => {
  const { isLoading, tenant } = useTenant();
  const { setBrandConfigs } = useBrandConfigs();

  useEffect(() => {
    if (!tenant) return;

    const configs = cloneDeep(tenant);
    if (configs?.logo && tenant.name) {
      configs.logo.alt = tenant.name;
    }

    setBrandConfigs(configs);
  }, [setBrandConfigs, tenant]);

  if (isLoading) {
    return <FullLoader />;
  }

  const tenantTheme = {
    token: {
      colorPrimary: tenant.primaryColor,
      colorLink: tenant.primaryColor
    }
  };

  return (
    <ConfigProvider theme={tenantTheme}>
      <RouterProvider router={router} />
    </ConfigProvider>
  );
};

export default TenantProvider;
