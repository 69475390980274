import { Form, Space, Typography, FormListFieldData } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import ButtonActionForm from '../ButtonAction/Form';
import { ButtonAction, TaskStructure } from '../../../types';

type ButtonActionsProps = {
  taskStructure: TaskStructure;
  title?: string;
};

export type ButtonActionsFormList = {
  buttonActions: { [key: FormListFieldData['name']]: ButtonAction };
};

const ButtonActions = ({ taskStructure, title }: ButtonActionsProps) => (
  <Form.List name="buttonActions">
    {(fields, { add, remove }) => (
      <>
        <div className="d-flex justify-content-between">
          <h4>{title || t('activerecord.models.button_action.other')}</h4>
          <Typography.Link onClick={() => add()}>
            <Space>
              <PlusOutlined />
              {t('buttonAction.add')}
            </Space>
          </Typography.Link>
        </div>

        {fields.length === 0 && (
          <Typography.Text type="secondary" italic>
            {t('buttonAction.none')}
          </Typography.Text>
        )}

        {fields.map((field) => (
          <ButtonActionForm
            key={field.name}
            field={field}
            remove={remove}
            taskStructure={taskStructure}
          />
        ))}
      </>
    )}
  </Form.List>
);

export default ButtonActions;
