import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Outlet, useParams } from 'react-router';
import { getSectionStructure } from '../../../api/endpoints';
import FullLoader from '../../../components/FullLoader';
import Page from '../../../components/Page';
import PublishBtn from '../components/PublishBtn';
import SectionStructureNav from './components/SectionStructureNav';
import StructureSectionSummary from './components/StructureSectionSummary';

const StructureSection = () => {
  const structureId = Number(useParams().structureId);
  const sectionId = Number(useParams().sectionId);

  const { isLoading, data } = useQuery({
    queryKey: ['sectionStructure', sectionId],
    queryFn: () => getSectionStructure(structureId, sectionId)
  });

  if (isLoading) {
    return <FullLoader />;
  }

  return (
    <Page
      title={data.name}
      backUrl={`/structures/${structureId}/sections`}
      actions={<PublishBtn claimStructure={data} />}
      summary={<StructureSectionSummary />}
      nav={<SectionStructureNav />}
    >
      <Outlet />
    </Page>
  );
};

export default StructureSection;
