import { t } from '@gowgates/utils';

export const claimDetailsLabel = (structure) => {
  if (structure.claimName) {
    return t('claim.backofficeCreateStatuses.customClaimDetails', { name: structure.claimName });
  }

  return t('claim.backofficeCreateStatuses.claimDetails');
};

export const isImportant = (claim) => claim.importance !== 'normal';

export const taskCompletedBy = (task) => {
  if (task.status === 'completed' && task.frontoffice) {
    return t('activerecord.models.claimant.one');
  }

  return task.completedBy?.name;
};

export default {};
