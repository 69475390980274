import React from 'react';
import PropTypes from 'prop-types';
import { message } from 'antd';
import { isUnprocessableEntity } from '@gowgates/api-client';
import { DangerPopconfirm } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';
import { deleteListItem } from '../../api/endpoints';

const DeleteListItem = ({ data }) => {
  const onError = (error) => {
    if (isUnprocessableEntity(error) && error.response.data.errors.base) {
      message.error(error.response.data.errors.base);
    }
  };

  const onSuccess = () => {
    message.success(t('list.deletedMessage'));
  };

  if (!data.permissions.destroy) return null;

  return (
    <div className="d-flex justify-content-end">
      <DangerPopconfirm
        deleteFn={() => deleteListItem(data.id)}
        invalidateQueries={['listItems']}
        permission
        onSuccess={onSuccess}
        onError={onError}
      />
    </div>
  );
};

DeleteListItem.propTypes = {
  data: PropTypes.object.isRequired
};

export default DeleteListItem;
