import React from 'react';
import PropTypes from 'prop-types';
import { Button } from 'antd';
import { useNavigate } from 'react-router-dom';

const LinkButton = ({ to, children, ...rest }) => {
  const navigate = useNavigate();

  const onClick = (event) => {
    event.preventDefault();
    navigate(to);
  };

  return (
    <Button type="primary" href={to} onClick={onClick} {...rest}>
      {children}
    </Button>
  );
};

LinkButton.propTypes = {
  to: PropTypes.string.isRequired,
  children: PropTypes.node.isRequired
};

export default LinkButton;
