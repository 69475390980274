import { Select, Input, InputNumber, GetProps } from 'antd';
import { booleanOptions, t, DEFAULT_CURRENCY } from '@gowgates/utils';
import { ConditionOperatorIDType, Field } from '../../../../types';
import { isDateField, isBooleanField, isChoiceField, isMoneyField } from '../../../../utils';
import { CgDatePicker, moneyFieldFormatter, moneyFieldParser } from '../../../forms';
import { CgDatePickerProps } from '../../../forms/CgDatePicker';

type SelectProps = Omit<GetProps<typeof Select>, 'onChange'>;
type DatePickerProps = Omit<CgDatePickerProps, 'onChange'>;
type InputNumberProps = Omit<GetProps<typeof InputNumber<string>>, 'onChange'>;
type InputProps = Omit<GetProps<typeof Input>, 'onChange'>;
type ConditionValueComponentPossibilitiesProps =
  | SelectProps
  | DatePickerProps
  | InputNumberProps
  | InputProps;

export type ConditionValueProps = {
  conditionField: Field;
  fieldValue?: string;
  fieldLabel?: string;
  operator?: ConditionOperatorIDType;
  onChange: (value?: unknown) => void;
} & ConditionValueComponentPossibilitiesProps;

export const ConditionValue = ({
  conditionField,
  fieldValue = 'key',
  fieldLabel = 'value',
  operator,
  onChange,
  ...rest
}: ConditionValueProps) => {
  const placehoder = t('activerecord.attributes.field.conditionValue');

  if (isChoiceField(conditionField)) {
    return (
      <Select
        options={conditionField.options}
        placeholder={placehoder}
        fieldNames={{ value: fieldValue, label: fieldLabel }}
        showSearch
        popupMatchSelectWidth={false}
        onChange={onChange}
        {...(rest as SelectProps)}
      />
    );
  }

  if (isDateField(conditionField)) {
    return <CgDatePicker onChange={onChange} {...(rest as DatePickerProps)} />;
  }

  if (isBooleanField(conditionField)) {
    return (
      <Select
        options={booleanOptions({ valueAsString: true })}
        placeholder={placehoder}
        onChange={onChange}
        {...(rest as SelectProps)}
      />
    );
  }

  if (isMoneyField(conditionField)) {
    return (
      <InputNumber
        style={{ width: '100%' }}
        controls={false}
        formatter={moneyFieldFormatter}
        parser={moneyFieldParser}
        addonBefore={DEFAULT_CURRENCY}
        onChange={(value) => onChange(value || '')}
        {...(rest as InputNumberProps)}
      />
    );
  }

  const onChangeInput = (event: React.ChangeEvent<HTMLInputElement>) => {
    const { value } = event.target;
    onChange(value);
  };

  if (operator === 'unique' || operator === 'duplicate') {
    return null;
  }

  return <Input placeholder={placehoder} {...(rest as InputProps)} onChange={onChangeInput} />;
};
