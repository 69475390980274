import React from 'react';
import PropTypes from 'prop-types';
import useStructures from '../hooks/useStructures';

const StructureName = ({ claim, TooltipComponent }) => {
  const { findStructure } = useStructures();

  return (
    <span>
      <TooltipComponent title={findStructure(claim.structureId)?.name || ''}>
        {findStructure(claim.structureId)?.name}
      </TooltipComponent>
    </span>
  );
};

StructureName.defaultProps = {
  // Wrapper to avoid props warning because Fragment does not have title
  // eslint-disable-next-line react/prop-types
  TooltipComponent: ({ children }) => <>{children}</>
};

StructureName.propTypes = {
  claim: PropTypes.object.isRequired,
  TooltipComponent: PropTypes.func
};

export default StructureName;
