import { FC, Fragment } from 'react';
import set from 'lodash.set';
import { Upload, Button, Form, Space } from 'antd';
import { UploadOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { File } from '../File';

type AntButtonProps = React.ComponentProps<typeof Button>;
type AntSpaceProps = React.ComponentProps<typeof Space>;
type FileValue = {
  url?: string;
  type?: string;
  name?: string;
  uid?: string;
};
type ComposedFileValue = {
  id?: string;
  _destroy?: boolean;
  file: FileValue;
};
type FileFieldProps = {
  id?: string;
  accept?: string;
  value?: ComposedFileValue[];
  multiple?: boolean;
  disabled?: boolean;
};

const FileFieldButton = (buttonPros: AntButtonProps) => (
  <Button icon={<UploadOutlined />} {...buttonPros}>
    {t('globals.attach')}
  </Button>
);

export const FileField = ({
  id,
  value,
  accept,
  multiple = false,
  disabled = false
}: FileFieldProps) => {
  const form = Form.useFormInstance();

  // when received as composed name like `['data', 'attachment']` the id is `data_attachment`
  const namePath = (id || '').split('_');
  const onRemove = (index: number) => {
    const currentFileList = form.getFieldValue(namePath);
    const newFileList = currentFileList.slice();
    newFileList.splice(index, 1);

    form.setFieldsValue(set({}, namePath, newFileList));
  };

  const onDestroy = (file: ComposedFileValue) => {
    const currentFileList = form.getFieldValue(namePath);
    const newFileList = currentFileList.map((f: ComposedFileValue) => {
      if (f.id === file.id) {
        return {
          ...f,
          _destroy: true
        };
      }

      return f;
    });

    form.setFieldsValue(set({}, namePath, newFileList));
  };

  const files = value || [];
  const hasAtLeastOneFile = Boolean(files.filter((f) => !f._destroy).length);
  const Container: FC<AntSpaceProps> = multiple && hasAtLeastOneFile ? Space : Fragment;
  const containerProps: AntSpaceProps =
    multiple && hasAtLeastOneFile ? { direction: 'vertical', className: 'd-flex' } : {};

  return (
    <Container {...containerProps}>
      <div className={hasAtLeastOneFile && !multiple ? 'd-none' : ''}>
        <Upload
          fileList={[]}
          beforeUpload={(file) => {
            const currentFileList = form.getFieldValue(namePath) || [];
            const newFileList = [...currentFileList, { file }];
            form.setFieldsValue(set({}, namePath, newFileList));

            return false;
          }}
          accept={accept}
        >
          <FileFieldButton disabled={disabled} />
        </Upload>
      </div>

      <div className={hasAtLeastOneFile ? '' : 'd-none'}>
        {files.map((fileData, index) => {
          const { file, id, _destroy } = fileData;
          if (_destroy) return null;

          return (
            <File
              key={file?.uid || file?.url || file?.name || `${index}`}
              url={file?.url}
              type={file?.type}
              name={file?.name}
              onRemove={id ? undefined : () => onRemove(index)}
              onDestroy={id ? async () => onDestroy(fileData) : undefined}
              hasErrors={form.getFieldError(namePath).length > 0}
              disabled={disabled}
            />
          );
        })}
      </div>
    </Container>
  );
};
