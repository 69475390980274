import { useEffect, useRef, useState } from 'react';
import { AxiosError } from 'axios';
import { Form, Button, Row, Col, InputRef, Space } from 'antd';
import { useMutation } from '@tanstack/react-query';

import { t } from '@gowgates/utils';

import { login, preAuthenticate } from '../api/endpoints';
import { useAuth } from '../../../hooks/useAuth';
import { usePageTitle } from '../../../hooks/usePageTitle';
import { useBrandConfigs } from '../../../contexts';
import { PasswordInput } from '../components/PasswordInput';
import { EmailInput } from '../components/EmailInput';
import { AuthForm } from '../components/AuthForm';
import { ForgotPasswordLink } from '../components/ForgotPasswordLink/ForgotPasswordLink';
import { ResendConfirmationLink } from '../components/ResendConfirmationLink';
import { AuthContainer } from '../Container';
import { Credentials } from './types';
import { ActivateOtp } from './ActivateOtp';
import { EnterOtp } from './EnterOtp';

export const Login = () => {
  const inputRef = useRef<InputRef>(null);
  const [preAuthenticated, setPreAuthenticated] = useState(false);
  const [activateOtp, setActivateOtp] = useState(false);
  const [credentials, setCredentials] = useState<Credentials>();
  const [qrCode, setQrCode] = useState<string>();
  const [credentialsForm] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { addUserToStorage } = useAuth();

  const { brandConfigs } = useBrandConfigs();

  usePageTitle(t('devise.sessions.new.signIn'));

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const loginMutation = useMutation({
    mutationFn: login,
    onError: (error: AxiosError<{ message: string }>) => {
      setErrorMessage(error.response?.data.message);
    },
    onSuccess: (data) => {
      addUserToStorage(data.user, data.authorization);
    }
  });

  const credentialsMutation = useMutation({
    mutationFn: preAuthenticate,
    onError: (error: AxiosError<{ message: string }>) => {
      setErrorMessage(error.response?.data.message);
    },
    onSuccess: (data, variables: any) => {
      setErrorMessage(undefined);

      if (data.otpRequiredForLogin) {
        setCredentials(variables);
        setPreAuthenticated(true);
      } else {
        if (brandConfigs.forceOtp) {
          setQrCode(data.qrCode);
          setCredentials(variables);
          setActivateOtp(true);
        } else {
          loginMutation.mutate(variables);
        }
      }
    }
  });

  if (preAuthenticated && credentials) {
    return <EnterOtp credentials={credentials} />;
  }

  if (activateOtp && credentials && qrCode) {
    return <ActivateOtp credentials={credentials} qrCode={qrCode} />;
  }

  return (
    <AuthContainer title={t('devise.sessions.new.signIn')} errorMessage={errorMessage}>
      <AuthForm
        form={credentialsForm}
        onFinish={credentialsMutation.mutate}
        disabled={credentialsMutation.isPending || loginMutation.isPending}
      >
        <EmailInput ref={inputRef} />
        <PasswordInput />

        <Row>
          <Col span={24} className="text-right">
            <Space direction="vertical" size="small">
              <ForgotPasswordLink />
              <ResendConfirmationLink />
            </Space>
          </Col>
        </Row>

        <Button
          type="primary"
          htmlType="submit"
          className="w-100"
          loading={credentialsMutation.isPending}
        >
          {t('devise.sessions.new.signIn')}
        </Button>
      </AuthForm>
    </AuthContainer>
  );
};
