import { useEffect } from 'react';
import { CloseCircleOutlined } from '@ant-design/icons';
import { Col, Form, Row, Typography } from 'antd';
import dayjs, { Dayjs } from 'dayjs';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { FormItem, CgDatePicker } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm } from '@gowgates/utils';
import { updateTask } from '../../../../../api/endpoints';
import { Task } from '../../../../../types';

type EditDueDateProps = {
  task: Task;
  setTask: (task: Task) => void;
  setEditing: (editing: boolean) => void;
};

const EditDueDate = ({ task, setTask, setEditing }: EditDueDateProps) => {
  const claimId = Number(useParams().claimId);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ ...task });
  }, [task]);

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Task) => updateTask(task.id, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['claim', claimId, 'tasks']
      });
      setTask(data);
      setEditing(false);
    }
  });

  const disabledDate = (current: Dayjs) => current && current < dayjs().startOf('day');

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={task}
      onValuesChange={mutate}
      disabled={isLoading}
    >
      <Row align="middle">
        <Col span={23}>
          <FormItem name="dueAt" className="no-margin">
            <CgDatePicker disabledDate={disabledDate} allowClear={false} />
          </FormItem>
        </Col>
        <Col span={1} className="d-flex justify-content-end">
          <FormItem className="no-margin">
            <Typography.Link onClick={() => setEditing(false)}>
              <CloseCircleOutlined />
            </Typography.Link>
          </FormItem>
        </Col>
      </Row>
    </Form>
  );
};

export default EditDueDate;
