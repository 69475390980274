import { useEffect, useState } from 'react';
import { Button, Divider, Space } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { t } from '@gowgates/utils';
import { cancelTask, completeTask } from '../../../../../api/endpoints';
import { Task } from '../../../../../types';
import NewNote from '../../../../../components/Notes/NewNote';
import ModalFooter from '../../../../../components/ModalFooter';
import Note from '../../../../../components/Notes/Note';
import { CloseTaskModalType } from '../TaskList';

type TaskModalFooterProps = {
  task: Task;
  setTask: (task: Task) => void;
  closeModal: CloseTaskModalType;
};

const TaskModalFooter = ({ task, setTask, closeModal }: TaskModalFooterProps) => {
  const claimId = Number(useParams().claimId);
  const queryClient = useQueryClient();
  const [cancelling, setCancelling] = useState(false);
  const [completing, setCompleting] = useState(false);

  useEffect(() => {
    setCancelling(false);
    setCompleting(false);
  }, [task]);

  const handleComplete = () => {
    setCompleting(true);
  };

  const handleCancel = () => {
    setCancelling(true);
  };

  const onMutationSuccess = (data: Task) => {
    closeModal();
    setTask(data);
    queryClient.invalidateQueries({
      queryKey: ['claim', claimId, 'tasks']
    });
    queryClient.invalidateQueries({
      queryKey: ['claim', claimId]
    });
  };

  const completeTaskMutation = useMutation({
    mutationFn: () => completeTask(task.id),
    onSuccess: onMutationSuccess
  });

  const cancelTaskMutation = useMutation({
    mutationFn: () => cancelTask(task.id),
    onSuccess: onMutationSuccess
  });

  const onCompleting = () => {
    setCompleting(false);
    completeTaskMutation.mutate();
  };

  const onCancelling = () => {
    setCancelling(false);
    cancelTaskMutation.mutate();
  };

  if (cancelling) {
    return (
      <NewNote
        label={t('task.cancelManuallyLabel')}
        notableId={task.id}
        notableType="Task"
        onSubmit={onCancelling}
        onCancel={() => setCancelling(false)}
      />
    );
  }

  if (completing) {
    return (
      <NewNote
        label={t('task.completeManuallyLabel')}
        notableId={task.id}
        notableType="Task"
        onSubmit={onCompleting}
        onCancel={() => setCompleting(false)}
      />
    );
  }

  return (
    <>
      {(task.permissions.cancel || task.permissions.complete) && (
        <ModalFooter>
          <Space>
            {task.permissions.cancel && (
              <Button danger onClick={handleCancel}>
                {t('task.cancelManually')}
              </Button>
            )}

            {task.permissions.complete && (
              <Button type="primary" ghost onClick={handleComplete}>
                {t('task.completeManually')}
              </Button>
            )}
          </Space>
        </ModalFooter>
      )}

      {task.notes.length > 0 && (
        <>
          <Divider />

          {task.notes.map((note) => (
            <Note note={note} key={note.id} />
          ))}
        </>
      )}
    </>
  );
};

export default TaskModalFooter;
