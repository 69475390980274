import React from 'react';
import PropTypes from 'prop-types';
import { DynamicFormItem } from '@gowgates/dynamic-fields';
import useFileConfig from '../../../hooks/useFileConfig';

const ClaimantDetailsForm = ({ nested }) => {
  const { claimantStructure } = useFileConfig();
  const namespace = nested ? ['claimant', 'data'] : ['data'];

  return (
    <>
      {claimantStructure.map((field) => (
        <DynamicFormItem
          key={field.name}
          field={field}
          namespace={namespace}
          fullNamespace={namespace}
        />
      ))}
    </>
  );
};

ClaimantDetailsForm.defaultProps = {
  nested: false
};

ClaimantDetailsForm.propTypes = {
  nested: PropTypes.bool
};

export default ClaimantDetailsForm;
