import React from 'react';
import PropTypes from 'prop-types';
import { DynamicFormItem } from '@gowgates/dynamic-fields';

const ClaimDetailsForm = ({ claimStructure }) => (
  <>
    {claimStructure.claim.map((field) => (
      <DynamicFormItem key={field.name} field={field} />
    ))}
  </>
);

ClaimDetailsForm.propTypes = {
  claimStructure: PropTypes.object.isRequired
};

export default ClaimDetailsForm;
