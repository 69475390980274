import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import ShowReportField from './Show';
import EditReportField from './Edit';

const ReportField = ({
  reportField,
  dynamicClaimFields,
  documentStructures,
  editing,
  startEditing,
  endEditing
}) => {
  const [isEditing, setEditing] = useState(editing === reportField.id);

  useEffect(() => {
    setEditing(editing === reportField.id);
  }, [editing]);

  if (isEditing) {
    return (
      <EditReportField
        reportField={reportField}
        endEditing={endEditing}
        dynamicClaimFields={dynamicClaimFields}
        documentStructures={documentStructures}
      />
    );
  }

  return (
    <ShowReportField reportField={reportField} editing={editing} startEditing={startEditing} />
  );
};

ReportField.propTypes = {
  reportField: PropTypes.object.isRequired,
  dynamicClaimFields: PropTypes.array.isRequired,
  documentStructures: PropTypes.array.isRequired,
  editing: PropTypes.oneOfType([PropTypes.bool, PropTypes.number]).isRequired,
  startEditing: PropTypes.func.isRequired,
  endEditing: PropTypes.func.isRequired
};

export default ReportField;
