import { GetProps, Select } from 'antd';
import { t } from '@gowgates/utils';
import { conditionOperatorsForField } from '../../../../utils';
import { Field } from '../../../../types';

type SelectProps = GetProps<typeof Select<string>>;

export const ConditionOperator = ({
  conditionField,
  ...rest
}: { conditionField: Field } & SelectProps) => {
  const operators = conditionOperatorsForField(conditionField);

  return (
    <Select
      options={operators}
      placeholder={t('activerecord.attributes.field.conditionOperator')}
      {...rest}
    />
  );
};
