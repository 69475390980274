import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Input } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createReportStructure } from '../../../api/endpoints';
import ModalFormFooter from '../../../components/ModalFormFooter';

const NewReportStructureModal = ({ isOpen, setOpen, structureId }) => {
  const navigate = useNavigate();
  const [form] = Form.useForm();
  const inputRef = useRef(null);

  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => createReportStructure(structureId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      closeModal();
      navigate(`${data.id}`);
    }
  });

  return (
    <Modal title={t('report.add')} open={isOpen} onCancel={closeModal} footer={null}>
      <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
        <FormItem name="name" model="reportStructure" required>
          <Input ref={inputRef} />
        </FormItem>

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

NewReportStructureModal.defaultProps = {
  isOpen: false
};

NewReportStructureModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired,
  structureId: PropTypes.number.isRequired
};

export default NewReportStructureModal;
