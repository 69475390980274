import { Form, Row, Col, Input, Select, FormListFieldData } from 'antd';
import { MinusCircleOutlined } from '@ant-design/icons';
import { useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import { DynamicHardcodedField, FormItem } from '@gowgates/dynamic-fields';
import useAppConfigs from '../../../hooks/useAppConfigs';
import { ButtonActionsFormList } from '../TaskTrigger/ButtonActions';
import { ButtonAction, TaskStructure } from '../../../types';

type ButtonActionFormProps = {
  field: FormListFieldData;
  remove: (fieldName: FormListFieldData['name']) => void;
  taskStructure: TaskStructure;
};

type AttributeUpdatedType = (
  prevValues: ButtonActionsFormList,
  currentValues: ButtonActionsFormList
) => boolean;

const ButtonActionForm = ({ field, remove, taskStructure }: ButtonActionFormProps) => {
  const journeyId = Number(useParams().journeyId);
  const form = Form.useFormInstance();
  const queryClient = useQueryClient();
  const taskStructures = queryClient.getQueryData<TaskStructure[]>(['taskStructures', journeyId]);
  const { appConfigs } = useAppConfigs();
  const modelFields = appConfigs[taskStructure.entity].staticFields;

  const typeUpdated: AttributeUpdatedType = (prevValues, currentValues) =>
    prevValues.buttonActions[field.name]?.type !== currentValues.buttonActions[field.name]?.type;

  const actionUpdated: AttributeUpdatedType = (prevValues, currentValues) =>
    prevValues.buttonActions[field.name]?.action !==
    currentValues.buttonActions[field.name]?.action;

  const fieldChangeTypeOrFieldUpdated: AttributeUpdatedType = (prevValues, currentValues) => {
    const prevVal = prevValues.buttonActions[field.name];
    const currentVal = currentValues.buttonActions[field.name];

    return (
      prevVal?.fieldChangeType !== currentVal?.fieldChangeType ||
      prevVal?.field !== currentVal?.field
    );
  };

  const destroyUpdated: AttributeUpdatedType = (prevValues, currentValues) =>
    /* eslint-disable no-underscore-dangle */
    prevValues.buttonActions[field.name]?._destroy !==
    currentValues.buttonActions[field.name]?._destroy;

  const markToDestroy = () => {
    const newButtonActions: { [key: FormListFieldData['name']]: ButtonAction } = [
      ...form.getFieldValue('buttonActions')
    ];

    if (newButtonActions[field.name]?.id) {
      newButtonActions[field.name]._destroy = true;
      form.setFieldsValue({ buttonActions: newButtonActions });
    } else {
      remove(field.name);
    }
  };

  const onChangeField = () => {
    form.setFieldValue(['buttonActions', field.name, 'fieldValue'], '');
  };

  return (
    <Form.Item noStyle shouldUpdate={destroyUpdated}>
      {({ getFieldValue: gfv }) =>
        gfv('buttonActions')[field.name]?._destroy !== true && (
          <Row gutter={20}>
            <Form.Item name={[field.name, 'id']} hidden>
              <Input />
            </Form.Item>
            <Form.Item name={[field.name, '_destroy']} hidden>
              <Input />
            </Form.Item>

            <Col span={6}>
              <FormItem name={[field.name, 'type']} model="buttonAction">
                <Select
                  options={appConfigs.buttonAction.types[taskStructure.entity]}
                  popupMatchSelectWidth={false}
                />
              </FormItem>
            </Col>

            <Form.Item noStyle shouldUpdate={typeUpdated}>
              {({ getFieldValue }) =>
                getFieldValue('buttonActions')[field.name]?.type === 'system' && (
                  <Col span={6}>
                    <FormItem name={[field.name, 'action']} model="buttonAction">
                      <Select
                        options={appConfigs.buttonAction.actions[taskStructure.entity]}
                        popupMatchSelectWidth={false}
                      />
                    </FormItem>
                  </Col>
                )
              }
            </Form.Item>

            <Form.Item noStyle shouldUpdate={actionUpdated}>
              {({ getFieldValue }) =>
                getFieldValue('buttonActions')[field.name]?.action === 'withdraw_claim' && (
                  <Col span={6}>
                    <FormItem name={[field.name, 'withdrawReason']} model="buttonAction">
                      <Select
                        options={appConfigs.claim.withdrawReasons}
                        fieldNames={{ value: 'value', label: 'label' }}
                        popupMatchSelectWidth={false}
                      />
                    </FormItem>
                  </Col>
                )
              }
            </Form.Item>

            <Form.Item noStyle shouldUpdate={typeUpdated}>
              {({ getFieldValue }) =>
                (getFieldValue('buttonActions')[field.name]?.type === 'abort_task' ||
                  getFieldValue('buttonActions')[field.name]?.type === 'create_task') && (
                  <Col span={6}>
                    <FormItem name={[field.name, 'relatedTaskStructureId']} model="buttonAction">
                      <Select
                        options={taskStructures}
                        fieldNames={{ value: 'id', label: 'name' }}
                        popupMatchSelectWidth={false}
                      />
                    </FormItem>
                  </Col>
                )
              }
            </Form.Item>

            <Form.Item noStyle shouldUpdate={typeUpdated}>
              {({ getFieldValue }) =>
                getFieldValue('buttonActions')[field.name]?.type === 'field' && (
                  <>
                    <Col span={6}>
                      <FormItem name={[field.name, 'fieldChangeType']} model="buttonAction">
                        <Select
                          options={appConfigs.buttonAction.fieldChangeTypes}
                          popupMatchSelectWidth={false}
                        />
                      </FormItem>
                    </Col>
                    <Col span={6}>
                      <FormItem name={[field.name, 'field']} model="buttonAction">
                        <Select
                          options={appConfigs[taskStructure.entity].staticFields}
                          fieldNames={{ value: 'name', options: 'false' }}
                          onChange={onChangeField}
                          popupMatchSelectWidth={false}
                        />
                      </FormItem>
                    </Col>
                  </>
                )
              }
            </Form.Item>

            <Form.Item noStyle shouldUpdate={fieldChangeTypeOrFieldUpdated}>
              {({ getFieldValue }) =>
                getFieldValue('buttonActions')[field.name]?.fieldChangeType === 'hardcoded' && (
                  <Col span={5}>
                    <DynamicHardcodedField
                      modelName="buttonAction"
                      name={[field.name, 'fieldValue']}
                      fieldName={getFieldValue('buttonActions')[field.name]?.field}
                      modelFields={modelFields}
                    />
                  </Col>
                )
              }
            </Form.Item>

            <Form.Item label=" ">
              <Col span={1}>
                <MinusCircleOutlined onClick={markToDestroy} />
              </Col>
            </Form.Item>
          </Row>
        )
      }
    </Form.Item>
  );
};

export default ButtonActionForm;
