import { forwardRef } from 'react';
import { Input, InputRef } from 'antd';
import { LockOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { InputFormItem } from '../InputFormItem';

type AntInputProps = React.ComponentProps<typeof Input> & { confirmation?: boolean };

export const PasswordInput = forwardRef<InputRef, AntInputProps>(
  ({ confirmation = false, ...props }, ref) => {
    let requiredMessage = t('user.password_required', {
      notFoundPlaceholder: 'undefined'
    });
    requiredMessage =
      requiredMessage === 'undefined' ? 'Please input your password' : requiredMessage;

    return (
      <InputFormItem
        ref={ref}
        name={confirmation ? 'passwordConfirmation' : 'password'}
        rules={[{ required: true, message: requiredMessage }]}
        model="user"
        inputProps={{
          type: 'password',
          prefix: <LockOutlined />,
          placeholder: t('activerecord.attributes.user.password'),
          ...props
        }}
      />
    );
  }
);
