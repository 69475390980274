import React from 'react';
import { Button } from 'antd';
import { useLocation } from 'react-router';
import { t } from '@gowgates/utils';
import usePermissions from '../../hooks/usePermissions';
import { backendURL } from '../../utils/tenants';

const PaymentsActions = () => {
  const search = useLocation().search;
  const { permissions } = usePermissions();
  if (!permissions.exportPayments) {
    return null;
  }

  return (
    <Button type="primary" href={`${backendURL}/payments/export${search}`} target="blank">
      {t('globals.exportExcel')}
    </Button>
  );
};

export default PaymentsActions;
