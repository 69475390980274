import { Tag } from 'antd';
import { t } from '@gowgates/utils';
import { Task } from '../../../../types';

const statuses: { [key in Task['status']]?: string } = {
  completed: 'green',
  overdue: 'error',
  incomplete: 'blue'
};

const TaskStatus = ({ task }: { task: Task }) => {
  if (task.due) {
    return <Tag color="orange">{t('task.due')}</Tag>;
  }

  return <Tag color={statuses[task.status]}>{t(`task.statuses.${task.status}`)}</Tag>;
};

export default TaskStatus;
