import React from 'react';
import PropTypes from 'prop-types';
import { Switch } from 'antd';

const RequiredSwitch = ({ requiredKey, active, required, updateRequired }) => {
  if (requiredKey == null) {
    return <Switch checked disabled />;
  }

  if (!active) {
    return <Switch disabled />;
  }

  return <Switch checked={required} onChange={updateRequired} />;
};

RequiredSwitch.defaultProps = {
  requiredKey: null,
  active: false,
  required: false
};

RequiredSwitch.propTypes = {
  requiredKey: PropTypes.string,
  active: PropTypes.bool,
  required: PropTypes.bool,
  updateRequired: PropTypes.func.isRequired
};

export default RequiredSwitch;
