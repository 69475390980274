import { useState } from 'react';
import { useMutation } from '@tanstack/react-query';
import { Button, Form, Table, TableProps } from 'antd';
import { FilePicker, FormItem } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';
import { completeFrontOfficeTaskBulk } from '../../api/endpoints';
import Page from '../../components/Page';

type ResultType = {
  id: string;
  success: boolean;
  reason: string;
  errors: unknown;
};

const CompleteFormBulk = () => {
  const [form] = Form.useForm();
  const [result, setResult] = useState(null);

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: { file: File }) => completeFrontOfficeTaskBulk(values),
    onSuccess: (data) => {
      setResult(data);
    }
  });

  const columns: TableProps<ResultType>['columns'] = [
    { dataIndex: 'id', title: 'Claim ID' },
    { dataIndex: 'success', title: 'Success', render: (text) => t(`globals.yesNo.${text}`) },
    { dataIndex: 'reason', title: 'Reason' },
    { dataIndex: 'errors', title: 'Errors detais', render: (text) => JSON.stringify(text) }
  ];

  return (
    <Page title="Bulk complete claimant task">
      <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
        <FormItem name="file" label="Choose the CSV to upload">
          <FilePicker accept="text/csv" />
        </FormItem>

        <Button type="primary" htmlType="submit" loading={isLoading}>
          {t('globals.save')}
        </Button>
      </Form>

      {!!result && (
        <Table
          dataSource={result}
          columns={columns}
          rowKey={(record) => record.id}
          pagination={false}
          className="box-table"
        />
      )}
    </Page>
  );
};

export default CompleteFormBulk;
