import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Menu } from 'antd';
import { t } from '@gowgates/utils';

const DocumentStructureNav = () => {
  const match = useMatch({ path: '/structures/:structureId/reports/:reportId/:tab', end: false });

  const menuItems = [
    {
      key: 'details',
      label: <Link to="details">{t('globals.details')}</Link>
    },
    {
      key: 'fields',
      label: <Link to="fields">{t('activerecord.models.field.other')}</Link>
    }
  ];

  return (
    <Menu
      selectedKeys={match?.params?.tab}
      mode="horizontal"
      className="box-menu"
      items={menuItems}
    />
  );
};

export default DocumentStructureNav;
