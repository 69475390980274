import PropTypes from 'prop-types';
import { camelCase } from 'change-case';
import { Space, message, Typography, Popover, Timeline } from 'antd';
import { InfoCircleOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { isUnprocessableEntity } from '@gowgates/api-client';
import { t, DEFAULT_CURRENCY, formatPrice, formatDate } from '@gowgates/utils';
import { MoneyFormatter } from '@gowgates/dynamic-fields';
import { assignClaim, assignClaimToMe } from '../../../../api/endpoints';
import ClaimLiabilityTag from '../../components/ClaimLiabilityTag';
import ClaimStatusTag from '../../components/ClaimStatusTag';
import ReassignBtn from '../../components/ReassignBtn';
import AvatarAndName from '../../../../components/AvatarAndName';
import StructureName from '../../../../components/StructureName';
import useClaim from '../../../../hooks/useClaim';
import ShowClientInInsight from '../../components/ShowClientInInsight';
import ShowTransactionInInsight from '../../components/ShowTransactionInInsight';
import useAppConfigs from '../../../../hooks/useAppConfigs';
import ClaimEditableField from './ClaimEditableField';
import ClaimComplaint from './ClaimComplaint';
import InsightImported from './InsightImported';
import ClaimImportance from './ClaimImportance';
import ClaimVulnerability from './ClaimVulnerability';
import ClaimWithdrawn from './ClaimWithdrawn';

const ClaimSummary = ({ claim }) => {
  const { refreshClaim } = useClaim();
  const { appConfigs } = useAppConfigs();

  const assignToMeMutation = useMutation({
    mutationFn: () => assignClaimToMe(claim.id),
    onSuccess: refreshClaim
  });

  const assignMutation = useMutation({
    mutationFn: (userId) => assignClaim(claim.id, userId),
    onSuccess: refreshClaim,
    onError: (error) => {
      if (isUnprocessableEntity(error)) {
        message.error(error.response.data.errors.assigneeId);
      }
    }
  });

  if (!appConfigs) return null;
  const fileConfig = appConfigs.fileConfig;

  return (
    <aside className="page-summary">
      <Space direction="vertical" size="middle" className="w-100">
        <InsightImported claim={claim} />
        <ClaimComplaint claim={claim} />
        <ClaimImportance claim={claim} />
        <ClaimVulnerability claim={claim} />

        <span>
          <ClaimLiabilityTag liability={claim.liability} />
          <ClaimStatusTag claim={claim} />
        </span>

        <ClaimWithdrawn claim={claim} />

        <dl>
          {fileConfig.clientDetailsActive && (
            <>
              <dt>
                <div className="d-flex justify-content-between">
                  <span>{t('activerecord.models.client.one')}</span>
                  <ShowClientInInsight claim={claim} />
                </div>
              </dt>
              <dd>
                {claim.client.data.name}
                {claim.client.data.abn && ` - ${claim.client.data.abn}`}
              </dd>
            </>
          )}

          <dt>{t('activerecord.models.claimant.one')}</dt>
          <dd>{claim.claimantName}</dd>

          <dt>{t('activerecord.attributes.claim.structure')}</dt>
          <dd>
            <StructureName claim={claim} />
          </dd>

          {fileConfig.policyDetailsActive && (
            <>
              <dt>
                <div className="d-flex justify-content-between">
                  <span>{t('claim.policyDetails')}</span>
                  <ShowTransactionInInsight claim={claim} />
                </div>
              </dt>
              <dd>
                <Typography.Text>{claim.cover.data.insurer}</Typography.Text>
                <br />
                <Typography.Text>{claim.cover.data.number}</Typography.Text>
                <br />
                <Typography.Text>{claim.cover.data.classOfRisk}</Typography.Text>
              </dd>
            </>
          )}

          <dt>{t('activerecord.attributes.claim.insurerNumber')}</dt>
          <dd className="claim-editable-field-value">
            <ClaimEditableField
              claim={claim}
              field="insurerNumber"
              defaultValue="N/A"
              permission={claim.permissions.updateInsurerNumber}
              action="update_insurer_number"
            />
          </dd>

          <dt>{t('activerecord.attributes.claim.reserves')}</dt>
          <dd className="claim-editable-field-value">
            {appConfigs.item.categories.map((category) => {
              const name = `${camelCase(category.value)}Reserve`;
              const history = claim.reserveHistory?.[name];

              return (
                <div className="d-flex" key={category.value}>
                  <div className="reserve-label">{category.label}:</div>
                  <ClaimEditableField
                    claim={claim}
                    field={name}
                    prefix={`${DEFAULT_CURRENCY} `}
                    permission={claim.permissions.updateReserve}
                    formatWith={formatPrice}
                    action="update_reserve"
                    infoElement={
                      history && (
                        <Popover
                          title={t('claim.reserveHistoryTitle', { name: category.label })}
                          placement="left"
                          content={
                            <Timeline
                              items={history.map((e) => ({
                                color: 'gray',
                                children: (
                                  <div className="d-flex flex-column">
                                    <Typography.Text strong>{formatPrice(e.value)}</Typography.Text>
                                    <Typography.Text>{formatDate(e.at, 'minute')}</Typography.Text>
                                    <Typography.Text type="secondary">{e.username}</Typography.Text>
                                  </div>
                                )
                              }))}
                            />
                          }
                        >
                          <Typography.Link>
                            <InfoCircleOutlined />
                          </Typography.Link>
                        </Popover>
                      )
                    }
                  />
                </div>
              );
            })}

            <div className="d-flex">
              <div className="reserve-label">{t('globals.total')}:</div>
              <div>{formatPrice(claim.totalReserve)}</div>
            </div>
          </dd>

          {claim.permissions.showFinance && (
            <>
              <dt>{t('activerecord.attributes.claim.paidAmount')}</dt>
              <dd>{formatPrice(claim.finance.paid.total)}</dd>

              <dt>{t('activerecord.attributes.claim.amountOutstanding')}</dt>
              <dd>
                <MoneyFormatter value={claim.finance.paid.outstanding} negativeType="danger" />
              </dd>
            </>
          )}

          <dt>
            <div className="d-flex justify-content-between">
              <span>{t('activerecord.attributes.claim.assignee')}</span>
              {claim.permissions.assign && (
                <ReassignBtn
                  onChange={assignMutation.mutate}
                  claimIds={[claim.id]}
                  placement="bottomRight"
                  currentAssignee={claim.assignee}
                  filters={{ role: 'case_manager' }}
                />
              )}
            </div>
          </dt>
          <dd>
            <Space>
              <AvatarAndName user={claim.assignee} defaultText={t('claim.unassigned')} linked />
              {claim.permissions.assignToMe && (
                <>
                  <span>-</span>
                  <Typography.Link onClick={assignToMeMutation.mutate}>
                    {t('claim.assignToMe')}
                  </Typography.Link>
                </>
              )}
            </Space>
          </dd>
        </dl>
      </Space>
    </aside>
  );
};

ClaimSummary.propTypes = {
  claim: PropTypes.object.isRequired
};

export default ClaimSummary;
