import { useEffect, useRef, useState } from 'react';
import { Form, Button, InputRef } from 'antd';
import { KeyOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';
import { t } from '@gowgates/utils';

import { activateOtpAndSignIn } from '../api/endpoints';
import { useAuth, usePageTitle } from '../../../hooks';
import { AuthForm } from '../components/AuthForm';
import { InputFormItem } from '../components/InputFormItem';
import { AuthContainer } from '../Container';
import { Credentials } from './types';

export type ActivateOtpProps = {
  credentials: Credentials;
  qrCode: string;
};

export const ActivateOtp = ({ credentials, qrCode }: ActivateOtpProps) => {
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm<{ otpAttempt: string }>();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { addUserToStorage } = useAuth();
  usePageTitle(t('user.otp.enable'));

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const otpMutation = useMutation({
    mutationFn: (values: { otpAttempt: string }) =>
      activateOtpAndSignIn({ ...credentials, ...values }),
    onError: () => {
      setErrorMessage(t('user.otp.invalid'));
    },
    onSuccess: (data) => {
      addUserToStorage(data.user, data.authorization);
    }
  });

  return (
    <AuthContainer title={t('user.otp.enable')} errorMessage={errorMessage}>
      <AuthForm form={form} onFinish={otpMutation.mutate} disabled={otpMutation.isPending}>
        <div>
          <p>{t('user.otp.nowRequired')}</p>

          {/* eslint-disable-next-line react/no-danger */}
          <p
            className="spacer-bottom"
            dangerouslySetInnerHTML={{ __html: t('user.otp.install') }}
          />
        </div>

        {/* eslint-disable-next-line react/no-danger */}
        <div className="text-center spacer-bottom" dangerouslySetInnerHTML={{ __html: qrCode }} />

        <InputFormItem
          name="otp_attempt"
          label={t('user.otp.code')}
          inputProps={{
            ref: inputRef,
            placeholder: t('user.otp.code'),
            prefix: <KeyOutlined />
          }}
        />

        <p>{t('user.otp.enterDeviceCode')}</p>

        <Button type="primary" htmlType="submit" className="w-100" loading={otpMutation.isPending}>
          {t('user.otp.activateAndLogin')}
        </Button>
      </AuthForm>
    </AuthContainer>
  );
};
