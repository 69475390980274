import React, { useState } from 'react';
import PropTypes from 'prop-types';
import UserDetails from './components/Details';
import EditUserForm from './EditUserForm';

const User = ({ user }) => {
  const [isEditing, setEditing] = useState(false);

  if (user.permissions.update && isEditing) {
    return (
      <EditUserForm
        user={user}
        onCancel={() => setEditing(false)}
        onSuccess={() => setEditing(false)}
      />
    );
  }

  return <UserDetails user={user} onEditClick={() => setEditing(true)} />;
};

User.propTypes = {
  user: PropTypes.object.isRequired
};

export default User;
