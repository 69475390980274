import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { PhoneOutlined, MailOutlined, AudioOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { AtIcon } from '../../../../icons';

const NatureIcon = ({ nature }) => {
  if (nature === 'phone') {
    return (
      <Tooltip title={t('note.natures.phone')}>
        <PhoneOutlined />
      </Tooltip>
    );
  }

  if (nature === 'email') {
    return (
      <Tooltip title={t('note.natures.email')}>
        <AtIcon />
      </Tooltip>
    );
  }

  if (nature === 'letter') {
    return (
      <Tooltip title={t('note.natures.letter')}>
        <MailOutlined />
      </Tooltip>
    );
  }

  if (nature === 'voicemail') {
    return (
      <Tooltip title={t('note.natures.voicemail')}>
        <AudioOutlined />
      </Tooltip>
    );
  }

  return null;
};

NatureIcon.defaultProps = {
  nature: null
};

NatureIcon.propTypes = {
  nature: PropTypes.string
};

export default NatureIcon;
