import { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Form, Input, Row, Select } from 'antd';
import { ConditionInput, FormItem, useConditionInput } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';
import useAppConfigs from '../../../hooks/useAppConfigs';

const Condition = ({ entity }) => {
  const form = Form.useFormInstance();
  const isItemEntity = entity === 'item';
  const { appConfigs } = useAppConfigs();

  const model = isItemEntity ? form.getFieldValue('conditionModel') : 'claim';
  const [fields, setFields] = useState(appConfigs[model]?.staticFields || []);

  const { resetCondition, conditionQuery, setConditionQuery } = useConditionInput({
    inputName: 'conditionExpression'
  });

  const onChangeModel = (value) => {
    setFields(appConfigs[value]?.staticFields || []);
    resetCondition();
  };

  const span = isItemEntity ? 6 : 8;

  useEffect(() => {
    if (!isItemEntity) {
      form.setFieldValue('conditionModel', 'claim');
    }
  }, [isItemEntity, form]);

  return (
    <div className="field-condition">
      <Form.Item label={t('taskTrigger.onlyIf')} className="no-margin">
        <Row gutter={10}>
          {isItemEntity ? (
            <Col span={span}>
              <FormItem name="conditionModel">
                <Select
                  placeholder={t('activerecord.attributes.field.conditionModel')}
                  options={appConfigs.taskTrigger.conditionModels}
                  onChange={onChangeModel}
                />
              </FormItem>
            </Col>
          ) : (
            <FormItem name="conditionModel" hidden>
              <Input />
            </FormItem>
          )}
        </Row>
        {(!isItemEntity || form.getFieldValue('conditionModel')) && (
          <FormItem name="conditionExpression">
            <Row gutter={10}>
              <Col span={24}>
                <ConditionInput
                  fields={fields}
                  value={conditionQuery}
                  onChange={setConditionQuery}
                />
              </Col>
            </Row>
          </FormItem>
        )}
      </Form.Item>
    </div>
  );
};

Condition.propTypes = {
  entity: PropTypes.string.isRequired
};

export default Condition;
