/* eslint-disable @typescript-eslint/no-explicit-any */
import isPlainObject from 'lodash.isplainobject';
import forIn from 'lodash.forin';

type CallbackFunctionType = (key: string) => any;
const mapKeys = (object: any, fn: CallbackFunctionType) => {
  const out: any = {};

  forIn(object, (value, key) => {
    if (isPlainObject(value)) {
      out[fn(key)] = mapKeys(value, fn);
    } else if (Array.isArray(value)) {
      out[fn(key)] = (value as Array<any>).map((el) => deepMapKeys(el, fn));
    } else {
      out[fn(key)] = value;
    }
  });

  return out;
};

const deepMapKeys = (object: any, fn: CallbackFunctionType): any => {
  if (isPlainObject(object)) {
    return mapKeys(object, fn);
  } else if (Array.isArray(object)) {
    return (object as Array<never>).map((el) => deepMapKeys(el, fn));
  } else {
    return object;
  }
};

export const convertObjectKeys = (data: any, method: CallbackFunctionType) => {
  let convertedData;
  if (typeof data === 'string') {
    convertedData = JSON.parse(data);
  } else {
    convertedData = data;
  }

  convertedData = deepMapKeys(convertedData, method);

  return convertedData;
};

export default convertObjectKeys;
