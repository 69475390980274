import React from 'react';
import PropTypes from 'prop-types';
import { Dropdown, Button, message, Space } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { useNavigate } from 'react-router-dom';
import { isUnprocessableEntity } from '@gowgates/api-client';
import { createClaim } from '../../api/endpoints';
import usePermissions from '../../hooks/usePermissions';
import HoverLoader from '../../components/HoverLoader';

const ClaimsActions = ({ structures }) => {
  const { permissions } = usePermissions();
  const navigate = useNavigate();

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: createClaim,
    onError: (error) => {
      if (isUnprocessableEntity(error) && error.response.data.message) {
        message.error(error.response.data.message);
      }
    },
    onSuccess: (data) => {
      navigate(`/claims/${data.id}`);
    }
  });

  const items = structures.map((structure) => ({ key: structure.id, label: structure.name }));

  const onClickDummy = (event) => {
    mutate({ claim: { structureId: event.key }, params: { dummy: true } });
  };

  const onClick = (event) => mutate({ claim: { structureId: event.key } });

  return (
    <Space>
      {permissions.createDummyClaim && (
        <Dropdown
          menu={{ items, onClick: onClickDummy }}
          placement="bottomRight"
          trigger={['click']}
        >
          <Button type="primary">Create a new dummy claim</Button>
        </Dropdown>
      )}

      {permissions.createClaim && (
        <Dropdown menu={{ items, onClick }} placement="bottomRight" trigger={['click']}>
          <Button type="primary">Create a new claim</Button>
        </Dropdown>
      )}

      {isLoading && <HoverLoader />}
    </Space>
  );
};

ClaimsActions.propTypes = {
  structures: PropTypes.array.isRequired
};

export default ClaimsActions;
