import PropTypes from 'prop-types';
import { Typography } from 'antd';
import { formatDate } from '@gowgates/utils';

const TwoLineDate = ({ date }) => {
  if (!date) {
    return null;
  }

  return (
    <p>
      {formatDate(date)}
      <br />
      <Typography.Text type="secondary">
        <small>{formatDate(date, 'time')}</small>
      </Typography.Text>
    </p>
  );
};

TwoLineDate.defaultProps = {
  date: null
};

TwoLineDate.propTypes = {
  date: PropTypes.string
};

export default TwoLineDate;
