import React, { useState } from 'react';
import { Button } from 'antd';
import { t } from '@gowgates/utils';
import usePermissions from '../../hooks/usePermissions';
import NewRoleModal from './NewRoleModal';

const RoleActions = () => {
  const { permissions } = usePermissions();
  const [isModalOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  if (!permissions.createRole) {
    return null;
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {t('role.new')}
      </Button>

      <NewRoleModal isOpen={isModalOpen} setOpen={setModalOpen} />
    </>
  );
};

export default RoleActions;
