import React, { forwardRef } from 'react';
import { Form, Input, InputRef } from 'antd';

import { useModelAttributeLabel } from '@gowgates/dynamic-fields';
import { useAuthContext } from '../../context/useAuthContext';

type AntFormItemProps = React.ComponentProps<typeof Form.Item>;
type AntInputProps = React.ComponentProps<typeof Input>;

export type InputFormItemProps = Omit<AntFormItemProps, 'children'> & {
  model?: string;
  mode?: 'vertical' | 'inline';
  inputProps?: AntInputProps;
};

export const InputFormItem = forwardRef<InputRef, InputFormItemProps>(
  (
    { mode: overrideMode, name, model, label, inputProps: { prefix, ...inputProps } = {}, ...rest },
    ref
  ) => {
    const { forms } = useAuthContext();

    const { label: finalLabel } = useModelAttributeLabel({
      label,
      name,
      model
    });

    const mode = forms?.formItem?.mode || overrideMode || 'inline';
    const InputComponent = inputProps.type === 'password' ? Input.Password : Input;

    return (
      <Form.Item name={name} label={mode === 'vertical' ? finalLabel : undefined} {...rest}>
        <InputComponent ref={ref} prefix={mode === 'inline' ? prefix : undefined} {...inputProps} />
      </Form.Item>
    );
  }
);
