import { FC } from 'react';
import { tEnum } from '@gowgates/utils';
import { Field } from '@gowgates/dynamic-fields';
import { TaskAction } from '../../../../../types';
import useAppConfigs from '../../../../../hooks/useAppConfigs';

type ActionDescriptionProps = {
  action: TaskAction;
  entity: 'claim' | 'item';
};

const ActionDescription: FC<ActionDescriptionProps> = ({ action, entity }) => {
  const { appConfigs } = useAppConfigs();
  const fields = appConfigs[entity]?.staticFields;
  let detail = '';

  if (action.type === 'system') {
    detail = ` (${tEnum('buttonAction.actions', action.action)})`;
  }

  if (action.type === 'create_task' || action.type === 'abort_task') {
    detail = ` (${action.relatedTaskStructure})`;
  }

  if (action.type === 'field') {
    const field = fields.find((field: Field) => field.name === action.field);

    detail = ` "${field?.label || action.field}" to "${action.fieldValue}"`;
  }

  return (
    <span>
      {tEnum('buttonAction.types', action.type)} {detail}
    </span>
  );
};

export default ActionDescription;
