import { ConditionalDescription } from '@gowgates/dynamic-fields';
import useClaim from '../../../hooks/useClaim';

const ClaimDescriptions = () => {
  const { claim } = useClaim();

  return (
    <dl className="cg-descriptions">
      {claim.structure.claim.map((field) => (
        <ConditionalDescription model={claim} field={field} key={field.name} />
      ))}
    </dl>
  );
};

export default ClaimDescriptions;
