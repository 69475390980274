import React from 'react';
import PropTypes from 'prop-types';
import { Alert } from 'antd';
import { t } from '@gowgates/utils';

const InsightImported = ({ claim }) => {
  if (!claim.insightImported) {
    return null;
  }

  return <Alert message={t('claim.insightImported')} type="warning" />;
};

InsightImported.propTypes = {
  claim: PropTypes.object.isRequired
};

export default InsightImported;
