import { tEnum } from '@gowgates/utils';
import { Task } from '../../../../../types';
import TaskLink from './TaskLink';

type CompletionDetailsProps = { task: Task };

const CompletionDetails = ({ task }: CompletionDetailsProps) => {
  if (task.completionDetails.event === 'task_trigger') {
    if (task.completionDetails.type === 'button') {
      return <span>{`Clicked button "${task.completionDetails.buttonLabel}"`}</span>;
    }

    return (
      <span>
        {tEnum('taskTrigger.types', task.completionDetails.type)}
        {task.completionDetails.action &&
          ` (${tEnum('taskTrigger.actions', task.completionDetails.action)})`}
      </span>
    );
  }

  if (task.completionDetails.event === 'manual') {
    return <span>Manually completed</span>;
  }

  if (task.completionDetails.event === 'other_task_completed') {
    return (
      <span>
        Completion of task
        <TaskLink claimId={task.claimId} details={task.completionDetails} />
      </span>
    );
  }

  return '';
};

export default CompletionDetails;
