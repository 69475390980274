import { Input, Select } from 'antd';

import { isDateField, isChoiceField, isMoneyField } from '../../utils';
import { Field } from '../../types';
import { FormItem } from './FormItem';
import { CgDatePicker } from './CgDatePicker';

type DynamicHardcodedFieldProps = {
  name: (string | number) | (string | number)[];
  fieldName: string;
  modelName: string;
  modelFields: Field[];
};
export const DynamicHardcodedField = ({
  name,
  fieldName,
  modelName,
  modelFields
}: DynamicHardcodedFieldProps) => {
  const fieldStructure = modelFields?.find((field) => field.name === fieldName);
  if (!fieldStructure) return;

  if (isChoiceField(fieldStructure)) {
    return (
      <FormItem name={name} model={modelName} required>
        <Select options={fieldStructure.options} />
      </FormItem>
    );
  }

  if (isDateField(fieldStructure)) {
    return (
      <FormItem name={name} model={modelName} required>
        <CgDatePicker />
      </FormItem>
    );
  }

  if (isMoneyField(fieldStructure)) {
    return (
      <FormItem name={name} model={modelName} required>
        <Input />
      </FormItem>
    );
  }

  return (
    <FormItem name={name} model={modelName} required>
      <Input />
    </FormItem>
  );
};
