import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Space, Typography } from 'antd';
import { EditOutlined } from '@ant-design/icons';
import { DangerPopconfirm } from '@gowgates/dynamic-fields';
import { t } from '@gowgates/utils';
import InnerBox from '../InnerBox';
import FormElement from './FormElement';

const EditableDescriptions = ({
  title,
  showPermission,
  editPermission,
  descriptions,
  form,
  model,
  updateModel,
  destroyModel,
  successMessage,
  queryDataKey,
  onSuccess,
  noLine
}) => {
  const [isEditing, setEditing] = useState(false);

  if (!showPermission) {
    return null;
  }

  const startEdit = () => {
    setEditing(true);
  };

  const cancelEdit = () => {
    setEditing(false);
  };

  return (
    <InnerBox
      noLine={noLine}
      title={
        <Space size="middle">
          <span>{title}</span>
          {!isEditing && editPermission && (
            <Space>
              <Tooltip title={t('globals.edit')}>
                <Typography.Link onClick={startEdit}>
                  <EditOutlined />
                </Typography.Link>
              </Tooltip>

              {destroyModel && (
                <DangerPopconfirm
                  deleteFn={destroyModel}
                  setQueryData={queryDataKey}
                  permission // no need to check permission because there is only one for journeys
                />
              )}
            </Space>
          )}
        </Space>
      }
    >
      {isEditing ? (
        <FormElement
          cancelEdit={cancelEdit}
          model={model}
          updateModel={updateModel}
          successMessage={successMessage}
          queryDataKey={queryDataKey}
          onSuccess={onSuccess}
        >
          {form}
        </FormElement>
      ) : (
        descriptions
      )}
    </InnerBox>
  );
};

EditableDescriptions.defaultProps = {
  showPermission: false,
  editPermission: false,
  destroyModel: null,
  successMessage: 'globals.saved',
  queryDataKey: null,
  onSuccess: () => {},
  noLine: false
};

EditableDescriptions.propTypes = {
  title: PropTypes.oneOfType([PropTypes.string, PropTypes.node]).isRequired,
  showPermission: PropTypes.bool,
  editPermission: PropTypes.bool,
  descriptions: PropTypes.node.isRequired,
  form: PropTypes.node.isRequired,
  model: PropTypes.object.isRequired,
  updateModel: PropTypes.func.isRequired,
  destroyModel: PropTypes.func,
  successMessage: PropTypes.string,
  queryDataKey: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
  onSuccess: PropTypes.func,
  noLine: PropTypes.bool
};

export default EditableDescriptions;
