import React, { useState } from 'react';
import { useQuery } from '@tanstack/react-query';
import { Table, Typography } from 'antd';
import { t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { getIntegrations } from '../../api/endpoints';
import Page from '../../components/Page';
import ShowIntegrationModal from './ShowIntegrationModal';
import IntegrationActions from './IntegrationActions';

const Integrations = () => {
  const [isShowModalOpen, setShowModalOpen] = useState(false);
  const [currentIntegration, setCurrentIntegration] = useState({});

  usePageTitle(t('activerecord.models.integration.other'));

  const { isFetching, data } = useQuery({
    queryKey: ['integrations'],
    queryFn: () => getIntegrations()
  });

  const showIntegration = (integration) => {
    setCurrentIntegration({ ...integration });
    setShowModalOpen(true);
  };

  const columns = [
    {
      title: t('activerecord.attributes.integration.provider'),
      dataIndex: 'provider',
      render: (text, record) => (
        <Typography.Link onClick={() => showIntegration(record)} className="col-clickable">
          {text}
        </Typography.Link>
      )
    },
    {
      title: t('activerecord.attributes.integration.details'),
      key: 'index',
      render: (record) => (
        <Typography.Link onClick={() => showIntegration(record)} className="col-clickable">
          <strong>
            {t('activerecord.attributes.integration.ledger')}
            <span>: </span>
          </strong>
          {record.settings?.ledger}
        </Typography.Link>
      )
    }
  ];

  return (
    <Page title={t('activerecord.models.integration.other')} actions={<IntegrationActions />}>
      <Table
        dataSource={data}
        rowKey={(record) => record.id}
        className="page-table"
        loading={isFetching}
        columns={columns}
      />

      <ShowIntegrationModal
        isOpen={isShowModalOpen}
        setOpen={setShowModalOpen}
        integration={currentIntegration}
      />
    </Page>
  );
};

export default Integrations;
