import PropTypes from 'prop-types';
import { Layout as AntLayout, Space } from 'antd';
import { Link } from 'react-router-dom';
import dayjs from 'dayjs';
import { t } from '@gowgates/utils';
import { Loader } from '@gowgates/core';
import { useTenant } from '../../../hooks/useTenant';

export const Layout = ({ children, isLoading }) => {
  const { tenant } = useTenant();

  return (
    <AntLayout className="frontoffice">
      <AntLayout.Header className="topnav pi-8" style={{ background: tenant.primaryColor }}>
        <div className="container">
          {/* TODO: color should be configurable on tenant configs */}
          <h1 className="d-flex" style={{ color: '#fff' }}>
            <Link to="/">
              <img src={tenant.logoSmall?.url} alt={t('app.name')} className="app-logo" />
            </Link>
            <div className="text-center flex-1">
              {t('frontoffice.title', { tenant: tenant.name })}
            </div>
          </h1>
        </div>
      </AntLayout.Header>

      <AntLayout.Content className="page-content">
        <Space direction="vertical" size="large" className="w-100 ">
          <div
            className="hero"
            style={{
              // TODO: I think image should be stored in our servers and got from current tenant information
              backgroundImage:
                'url("https://theme.zdassets.com/theme_assets/1980599/c957dc37aca3e31c7cbc524842a7152cda767a27.jpg")'
            }}
          />

          <div className="container mw-100">
            {children}
            {isLoading && <Loader />}
          </div>
        </Space>
      </AntLayout.Content>

      <AntLayout.Footer style={{ background: tenant.bgColor }}>
        <div className="container">
          <div className="mi-8">{t('globals.footer_text', { year: dayjs().year() })}</div>
        </div>
      </AntLayout.Footer>
    </AntLayout>
  );
};

Layout.defaultProps = {
  isLoading: false
};

Layout.propTypes = {
  children: PropTypes.node.isRequired,
  isLoading: PropTypes.bool
};
