import PropTypes from 'prop-types';
import { Table } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { t, DEFAULT_PAGINATION } from '@gowgates/utils';
import { BooleanFormatter, useTable } from '@gowgates/core';
import { getResourceRoles } from '../../api/endpoints';
import AvatarAndName from '../../components/AvatarAndName';
import useAppConfigs from '../../hooks/useAppConfigs';
import { convertToFilter } from '../../utils/tables';
import UserCol from './UserCol';
import UserStatusTag from './UserStatusTag';

const { Column } = Table;

const UsersTable = ({ users, loading, pagination, sorter, filters }) => {
  const { handleTableChange } = useTable();
  const { appConfigs } = useAppConfigs();

  const { data: roles } = useQuery({
    queryKey: ['resourceRoles'],
    queryFn: getResourceRoles,
    placeholderData: []
  });

  return (
    <Table
      dataSource={users}
      rowKey={(record) => record.id}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      className="page-table"
    >
      <Column
        title={t('activerecord.attributes.user.name')}
        key="name"
        sorter
        sortOrder={sorter.field === 'name' && sorter.order}
        render={(record) => (
          <UserCol user={record}>
            <AvatarAndName user={record} />
          </UserCol>
        )}
      />
      <Column
        title={t('activerecord.attributes.user.email')}
        dataIndex="email"
        sorter
        sortOrder={sorter.field === 'email' && sorter.order}
        render={(text, record) => <UserCol user={record}>{text}</UserCol>}
      />
      <Column
        title={t('activerecord.attributes.user.role')}
        dataIndex="roleId"
        sorter
        sortOrder={sorter.field === 'roleId' && sorter.order}
        filters={roles?.map((role) => ({ text: role.name, value: role.id }))}
        filteredValue={filters.roleId || null}
        render={(_text, record) => <UserCol user={record}>{record.role}</UserCol>}
      />
      <Column
        title={t('activerecord.attributes.user.status')}
        dataIndex="status"
        sorter
        sortOrder={sorter.field === 'status' && sorter.order}
        filters={convertToFilter(appConfigs.user.statuses)}
        filteredValue={filters.status || null}
        render={(_text, record) => (
          <UserCol user={record}>
            <UserStatusTag status={record.status} />
          </UserCol>
        )}
      />
      <Column
        title={t('activerecord.attributes.user.confirmed')}
        dataIndex="confirmed"
        sorter
        filters={appConfigs.global.boolean}
        filteredValue={filters.confirmed || null}
        render={(_text, record) => (
          <UserCol user={record}>
            <BooleanFormatter active={record.confirmed} />
          </UserCol>
        )}
      />
      <Column
        title={t('activerecord.attributes.user.locked')}
        dataIndex="locked"
        sorter
        filters={appConfigs.global.boolean}
        filteredValue={filters.locked || null}
        render={(_text, record) => (
          <UserCol user={record}>
            <BooleanFormatter active={record.locked} invertColors />
          </UserCol>
        )}
      />
      <Column
        title={t('activerecord.attributes.user.otpRequiredForLogin')}
        dataIndex="otpRequiredForLogin"
        sorter
        filters={appConfigs.global.boolean}
        filteredValue={filters.otpRequiredForLogin || null}
        render={(_text, record) => (
          <UserCol user={record}>
            <BooleanFormatter active={record.otpRequiredForLogin} />
          </UserCol>
        )}
      />
    </Table>
  );
};

UsersTable.defaultProps = {
  users: [],
  loading: false,
  pagination: DEFAULT_PAGINATION,
  sorter: {},
  filters: {}
};

UsersTable.propTypes = {
  users: PropTypes.array,
  loading: PropTypes.bool,
  pagination: PropTypes.object,
  sorter: PropTypes.object,
  filters: PropTypes.object
};

export default UsersTable;
