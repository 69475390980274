import React, { forwardRef } from 'react';
import { Form, Input, Select } from 'antd';
import { FormItem } from '@gowgates/dynamic-fields';
import { t, tEnum } from '@gowgates/utils';
import { useResourceRolesQuery } from '@gowgates/core';

const claimTabs = ['claim', 'client', 'documents', 'items', 'finance', 'notes', 'tasks'];

const TaskStructureForm = forwardRef((_props, ref) => {
  const tabs = claimTabs.map((value) => ({ value, label: tEnum('claim.tabs', value) }));
  const form = Form.useFormInstance();
  const { roles } = useResourceRolesQuery();

  const isClaimEntity = form.getFieldValue('entity') === 'claim';

  const removeRole = () => {
    setTimeout(() => {
      form.setFieldValue('roleId', null);
    }, [100]);
  };

  return (
    <>
      <FormItem name="entity" model="taskStructure" hidden>
        <Input />
      </FormItem>

      <FormItem name="name" model="taskStructure" required>
        <Input ref={ref} />
      </FormItem>

      <FormItem name="dueDays" model="taskStructure" required>
        <Input />
      </FormItem>

      <FormItem name="roleId" model="taskStructure">
        <Select
          options={roles}
          fieldNames={{ value: 'id', label: 'name' }}
          placeholder={t('role.any')}
          allowClear
          onClear={removeRole}
        />
      </FormItem>

      {isClaimEntity && (
        <FormItem name="claimTab" model="taskStructure">
          <Select options={tabs} />
        </FormItem>
      )}
    </>
  );
});

export default TaskStructureForm;
