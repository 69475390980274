import React from 'react';
import PropTypes from 'prop-types';

const PageContent = ({ overlap, children }) => (
  <div className={`container ${overlap ? 'overlap-header' : ''}`}>{children}</div>
);

PageContent.defaultProps = {
  overlap: false
};

PageContent.propTypes = {
  children: PropTypes.node.isRequired,
  overlap: PropTypes.bool
};

export default PageContent;
