import { useState } from 'react';
import { Button, Modal, Tooltip, Typography } from 'antd';

import { t } from '@gowgates/utils';

import { PreviewField } from '../preview';
import { FormBuilder } from '../../FormBuilder';

import { Field } from '../../../types';
import { useFormBuilder } from '../../../hooks/useFormBuilder';

export const MultipleChoices = ({ field }: { field: Partial<Field> }) => {
  const { fieldableType, fieldableId } = useFormBuilder();
  const [isModalOpen, setModalOpen] = useState(false);

  const showModal = () => {
    setModalOpen(true);
  };

  const handleOk = () => {
    setModalOpen(false);
  };

  const handleCancel = () => {
    setModalOpen(false);
  };

  return (
    <div className="multiple-choices">
      <div className="entries">
        {field?.children?.map((child) => <PreviewField field={child} key={field.id} />)}
      </div>

      {(field?.children?.length || 0) === 0 && (
        <div className="empty-entries">
          <Typography.Text type="secondary" italic>
            {t('field.noEntries')}
          </Typography.Text>
        </div>
      )}

      {field.id ? (
        <Button onClick={showModal}>{t('field.editEntries')}</Button>
      ) : (
        <Tooltip title={t('field.saveBeforeEntries')}>
          <Button disabled>{t('field.editEntries')}</Button>
        </Tooltip>
      )}

      <Modal
        title="Entries"
        open={isModalOpen}
        onOk={handleOk}
        onCancel={handleCancel}
        width={800}
        footer={null}
      >
        <FormBuilder
          fieldableId={field.id}
          fieldableType="dynamic_data/field"
          parentFieldableId={fieldableId}
          parentFieldableType={fieldableType}
        />
      </Modal>
    </div>
  );
};
