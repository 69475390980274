import { Button, Col, Form, Row, Space, Switch } from 'antd';
import { t } from '@gowgates/utils';
import { Field } from '../../../types';
import { FormItem } from '../../forms';
import { hasValidation } from '../../../utils';
import { useFormBuilder } from '../../../hooks/useFormBuilder';

export const Footer = ({ saving }: { saving: boolean }) => {
  const form = Form.useFormInstance();
  const { endEditing } = useFormBuilder();

  const typeUpdated = (prevValues: Field, currentValues: Field) =>
    prevValues.type !== currentValues.type;

  const onUpdateDescription = (value: boolean) => {
    if (!value) {
      form.setFieldsValue({ description: '' });
    }
  };

  return (
    <footer className="field-container-footer">
      <Row>
        <Col span={19}>
          <Space size="middle">
            <FormItem
              name="hasDescription"
              label={t('activerecord.attributes.field.description')}
              valuePropName="checked"
            >
              <Switch onChange={onUpdateDescription} />
            </FormItem>

            <Form.Item noStyle shouldUpdate={typeUpdated}>
              {({ getFieldValue }) =>
                hasValidation(getFieldValue('type')) && (
                  <FormItem name="validations" model="field" valuePropName="checked">
                    <Switch />
                  </FormItem>
                )
              }
            </Form.Item>

            <FormItem name="conditional" model="field" valuePropName="checked">
              <Switch />
            </FormItem>

            <FormItem name="required" model="field" valuePropName="checked">
              <Switch />
            </FormItem>
          </Space>
        </Col>
        <Col span={5} className="d-flex justify-content-end">
          <Space>
            <Button onClick={endEditing}>{t('globals.cancel')}</Button>
            <Button type="primary" htmlType="submit" loading={saving}>
              {t('globals.save')}
            </Button>
          </Space>
        </Col>
      </Row>
    </footer>
  );
};
