import { useState } from 'react';
import { Select } from 'antd';
import { Field } from '../../../../types';
import { findField, isArrayField } from '../../../../utils';
import { ConditionValue, ConditionValueProps } from './Value';
import { ConditionOperator } from './Operator';

type ArrayValueEditorProps = {
  handleOnChange: (arg: unknown) => void;
  value: {
    field: string;
    value: unknown;
    operator: string;
  };
  fieldData: {
    rawField: Field;
  };
};

export const ArrayValueEditor = ({ handleOnChange, value, fieldData }: ArrayValueEditorProps) => {
  const { children } = fieldData.rawField;
  const [conditionField, setConditionField] = useState(findField(children, value.field));

  const onChangeField = (field: string) => {
    handleOnChange({ field, operator: '', value: '' });
    setConditionField(findField(children, field));
  };

  const onChangeOperator = (operator: string) => {
    handleOnChange({ ...value, operator });
  };

  const onChangeValue = (newValue: unknown) => {
    handleOnChange({ ...value, value: newValue });
  };

  const options = children.map((child) => ({ value: child.name, label: child.label }));

  return (
    conditionField && (
      <>
        <Select value={value.field} onChange={onChangeField} options={options} />
        <ConditionOperator
          value={value.operator}
          onChange={onChangeOperator}
          conditionField={conditionField}
        />
        <ConditionValue
          value={value.value}
          onChange={onChangeValue}
          conditionField={conditionField}
          fieldValue="value"
          fieldLabel="label"
        />
      </>
    )
  );
};

type CondtionValueCustomValueEditorProps = Omit<
  ConditionValueProps,
  'fieldValue' | 'fieldLabel' | 'onChange'
> &
  Pick<ArrayValueEditorProps, 'fieldData' | 'handleOnChange'>;
type ReactQueryBuilderCustomValueEditorProps =
  | ArrayValueEditorProps
  | CondtionValueCustomValueEditorProps;

const ReactQueryBuilderCustomValueEditor = (props: ReactQueryBuilderCustomValueEditorProps) => {
  if (isArrayField(props.fieldData.rawField)) {
    return <ArrayValueEditor {...(props as ArrayValueEditorProps)} />;
  } else {
    const conditionValueProps = props as CondtionValueCustomValueEditorProps;
    return (
      <ConditionValue
        value={conditionValueProps.value}
        conditionField={props.fieldData.rawField}
        onChange={conditionValueProps.handleOnChange}
        fieldValue="value"
        fieldLabel="label"
        operator={conditionValueProps.operator}
      />
    );
  }
};

export default ReactQueryBuilderCustomValueEditor;
