import { DownOutlined } from '@ant-design/icons';
import { useFormBuilder } from '../../../../hooks/useFormBuilder';

export const InputWithPrefix = ({ prefix }: { prefix: string }) => {
  const { disabled } = useFormBuilder();

  return (
    <span className={`field-input${disabled ? ' disabled' : ''}`}>
      <div className="field-addon">
        <span className="addon-wrapper">
          <span>{prefix}</span>
          <DownOutlined />
        </span>
      </div>
    </span>
  );
};
