import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

export const ClickableColumnContent = ({ record, children }) =>
  record.permissions.show ? (
    <Link to={`/claims/${record.claimId}/items?id=${record.id}`} className="col-clickable">
      {children}
    </Link>
  ) : (
    children
  );

ClickableColumnContent.propTypes = {
  record: PropTypes.object.isRequired,
  children: PropTypes.node.isRequired
};
