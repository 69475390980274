import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Col, Row, Typography } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateFileConfig } from '../../../api/endpoints';
import useAppConfigs from '../../../hooks/useAppConfigs';
import RequiredSwitch from './RequiredSwitch';
import ActiveSwitch from './ActiveSwitch';

const InlineInput = ({ labelKey, activeKey, requiredKey, editable, fieldLabel, children }) => {
  const { appConfigs } = useAppConfigs();
  const fileConfig = appConfigs?.fileConfig;
  const [label, setLabel] = useState(fieldLabel || (fileConfig ? fileConfig[labelKey] : ''));
  const [active, setActive] = useState(fileConfig ? fileConfig[activeKey] : false);
  const [required, setRequired] = useState(fileConfig ? fileConfig[requiredKey] : false);
  const currentlyEditable = editable || active;

  const queryClient = useQueryClient();
  const { mutate } = useMutation({ mutationFn: (data) => updateFileConfig(data) });

  const updateVal = (key, val) => {
    mutate(
      { [key]: val },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['appConfigs'] });
        }
      }
    );
  };

  const setEditableStr = (val) => {
    updateVal(labelKey, val);
    setLabel(val);
  };

  const updateActive = (val) => {
    updateVal(activeKey, val);
    setActive(val);
  };

  const updateRequired = (val) => {
    updateVal(requiredKey, val);
    setRequired(val);
  };

  return (
    <Row gutter={10} align="middle">
      <Col span={7}>
        {currentlyEditable ? (
          <Typography.Paragraph editable={{ onChange: setEditableStr }} className="text-right">
            {label}
          </Typography.Paragraph>
        ) : (
          <Typography.Paragraph className="text-right">{label}</Typography.Paragraph>
        )}
      </Col>
      <Col span={12}>{children}</Col>
      <Col span={2} offset={1}>
        <ActiveSwitch activeKey={activeKey} active={active} updateActive={updateActive} />
      </Col>
      <Col span={2}>
        <RequiredSwitch
          requiredKey={requiredKey}
          active={active}
          required={required}
          updateRequired={updateRequired}
        />
      </Col>
    </Row>
  );
};

InlineInput.defaultProps = {
  labelKey: '',
  activeKey: null,
  requiredKey: null,
  editable: false,
  fieldLabel: null
};

InlineInput.propTypes = {
  labelKey: PropTypes.string,
  activeKey: PropTypes.string,
  requiredKey: PropTypes.string,
  editable: PropTypes.bool,
  fieldLabel: PropTypes.string,
  children: PropTypes.node.isRequired
};

export default InlineInput;
