import { useQuery } from '@tanstack/react-query';
import { getStructures } from '../api/endpoints';

const useStructures = () => {
  const {
    isLoading,
    isFetched,
    data: structures
  } = useQuery({
    queryKey: ['structures'],
    queryFn: getStructures,
    placeholderData: [],
    staleTime: Infinity
  });

  const activeStructures = structures?.filter((structure) => structure.status === 'active');
  const findStructure = (id: number) => structures?.find((structure) => structure.id === id);

  return {
    isLoading,
    isFetched,
    structures,
    activeStructures,
    findStructure
  };
};

export default useStructures;
