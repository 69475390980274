import { useEffect, useRef, useState } from 'react';
import { Form, Button, InputRef } from 'antd';
import { KeyOutlined } from '@ant-design/icons';
import { useMutation } from '@tanstack/react-query';

import { t } from '@gowgates/utils';

import { InputFormItem } from '../components/InputFormItem';
import { AuthForm } from '../components/AuthForm';
import { login } from '../api/endpoints';
import { usePageTitle, useAuth } from '../../../hooks';
import { AuthContainer } from '../Container';
import { Credentials } from './types';

type EnterOtpProps = {
  credentials: Credentials;
};

export const EnterOtp = ({ credentials }: EnterOtpProps) => {
  const inputRef = useRef<InputRef>(null);
  const [form] = Form.useForm();
  const [errorMessage, setErrorMessage] = useState<string>();
  const { addUserToStorage } = useAuth();
  usePageTitle(t('devise.sessions.new.signIn'));

  useEffect(() => {
    inputRef?.current?.focus();
  }, [inputRef]);

  const { isPending, mutate } = useMutation({
    mutationFn: (values: { otp_attempt: string }) => login({ ...credentials, ...values }),
    onError: () => {
      setErrorMessage(t('user.otp.invalid'));
    },
    onSuccess: (data) => {
      addUserToStorage(data.user, data.authorization);
    }
  });

  return (
    <AuthContainer title={t('user.otp.name')} errorMessage={errorMessage}>
      <AuthForm form={form} onFinish={mutate} disabled={isPending}>
        <InputFormItem
          name="otp_attempt"
          label={t('user.otp.code')}
          inputProps={{
            ref: inputRef,
            placeholder: t('user.otp.code'),
            prefix: <KeyOutlined />
          }}
        />

        <p>{t('user.otp.enterDeviceCode')}</p>

        <Button type="primary" htmlType="submit" className="w-100" loading={isPending}>
          {t('user.otp.verifyCode')}
        </Button>
      </AuthForm>
    </AuthContainer>
  );
};

export default EnterOtp;
