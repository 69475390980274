import { Col, Row, Form, Input } from 'antd';
import { t } from '@gowgates/utils';
import { Field } from '../../../types';
import { FormItem } from '../../forms';
import { Choices } from './Choices';
import { MultipleChoices } from './MultipleChoices';
import { Booleans } from './Booleans';
import { Dates } from './Dates';
import { Files } from './Files';

export const Main = ({ field }: { field: Partial<Field> }) => {
  const typeUpdated = (prevValues: Field, currentValues: Field) =>
    prevValues.type !== currentValues.type;
  const hasDescriptionUpdated = (prevValues: Field, currentValues: Field) =>
    prevValues.hasDescription !== currentValues.hasDescription;

  return (
    <main>
      <Row gutter={10}>
        <Col span={3}>
          <FormItem name="heading">
            <Input placeholder="#" />
          </FormItem>
        </Col>

        <Col span={21}>
          <FormItem name="label">
            <Input placeholder={t('formBuilder.typeQuestion')} />
          </FormItem>
        </Col>
      </Row>

      <Form.Item noStyle shouldUpdate={hasDescriptionUpdated}>
        {({ getFieldValue }) => (
          <FormItem name="description" hidden={!getFieldValue('hasDescription')}>
            <Input.TextArea placeholder={t('formBuilder.typeDescription')} />
          </FormItem>
        )}
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeUpdated}>
        {({ getFieldValue }) => getFieldValue('type') === 'choice' && <Choices field={field} />}
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeUpdated}>
        {({ getFieldValue }) =>
          getFieldValue('type') === 'array' && <MultipleChoices field={field} />
        }
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeUpdated}>
        {({ getFieldValue }) => getFieldValue('type') === 'boolean' && <Booleans />}
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeUpdated}>
        {({ getFieldValue }) => getFieldValue('type') === 'date' && <Dates />}
      </Form.Item>

      <Form.Item noStyle shouldUpdate={typeUpdated}>
        {({ getFieldValue }) => getFieldValue('type') === 'file' && <Files />}
      </Form.Item>
    </main>
  );
};
