import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { insightClientURL } from '../utils/insight';

const ShowClientInInsight = ({ claim }) => {
  if (!claim.insightLedger || !claim.client.insightId) {
    return null;
  }

  return (
    <Tooltip title={t('globals.showInInsight')}>
      <a
        href={insightClientURL(claim.insightLedger, claim.client.insightId)}
        target="_blank"
        rel="noreferrer"
      >
        <ExportOutlined />
      </a>
    </Tooltip>
  );
};

ShowClientInInsight.propTypes = {
  claim: PropTypes.object.isRequired
};

export default ShowClientInInsight;
