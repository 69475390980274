import { useEffect } from 'react';
import { App } from 'antd';
import { useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { useSearchParams } from 'react-router-dom';

import { t } from '@gowgates/utils';

import { Loader } from '../../../components';
import { LoginLink } from '../components/LoginLink';
import { AuthContainer } from '../Container';
import { unlockSelf } from '../api/endpoints';
import { UnlockForm } from './UnlockForm';

export const Unlock = () => {
  const { message } = App.useApp();
  const [searchParams] = useSearchParams();
  const unlockToken = searchParams.get('unlock_token');
  const navigate = useNavigate();

  const { isLoading, isSuccess } = useQuery({
    queryKey: ['unlock'],
    queryFn: () => {
      if (!unlockToken) return;

      return unlockSelf(unlockToken);
    },
    // don't use default error boundary because it can return 422
    throwOnError: false
  });

  useEffect(() => {
    if (isSuccess) {
      navigate('/login');
      message.success(t('devise.unlocks.unlocked'));
    }
  }, [isSuccess, navigate, message]);

  if (isLoading) {
    return <Loader />;
  }

  // Reaches here when the token is invalid
  return (
    <AuthContainer
      title={t('user.unlock')}
      errorMessage={t('devise.unlocks.invalidToken')}
      footer={<LoginLink />}
    >
      <UnlockForm showInstructions />
    </AuthContainer>
  );
};

export default Unlock;
