import React from 'react';
import PropTypes from 'prop-types';
import { Input } from 'antd';
import { FormItem, DynamicFormItem } from '@gowgates/dynamic-fields';
import useIntegrations from '../../../hooks/useIntegrations';
import useFileConfig from '../../../hooks/useFileConfig';

const ClientDetailsForm = ({ nested }) => {
  const { clientStructure } = useFileConfig();
  const { hasInsightIntegrations } = useIntegrations();
  const dynamicNamespace = nested ? ['client', 'data'] : ['data'];
  const fixedNamespace = nested ? ['client'] : [];

  return (
    <>
      {hasInsightIntegrations && (
        <>
          <FormItem name={[...fixedNamespace, 'insightId']} model="client">
            <Input disabled />
          </FormItem>

          <FormItem name={[...fixedNamespace, 'insightLedger']} hidden>
            <Input />
          </FormItem>

          <FormItem name={[...fixedNamespace, 'insightSalesTeamId']} model="client" hidden>
            <Input />
          </FormItem>

          <FormItem name={[...fixedNamespace, 'insightSalesTeamName']} model="client">
            <Input disabled />
          </FormItem>

          <FormItem name={[...fixedNamespace, 'insightServiceTeamId']} model="client" hidden>
            <Input />
          </FormItem>

          <FormItem name={[...fixedNamespace, 'insightServiceTeamName']} model="client">
            <Input disabled />
          </FormItem>
        </>
      )}

      {clientStructure.map((field) => (
        <DynamicFormItem
          key={field.name}
          field={field}
          namespace={dynamicNamespace}
          fullNamespace={dynamicNamespace}
        />
      ))}
    </>
  );
};

ClientDetailsForm.defaultProps = {
  nested: false
};

ClientDetailsForm.propTypes = {
  nested: PropTypes.bool
};

export default ClientDetailsForm;
