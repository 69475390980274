import React from 'react';
import { useQuery } from '@tanstack/react-query';
import { Outlet, useParams } from 'react-router';
import { getDocumentStructure } from '../../../api/endpoints';
import FullLoader from '../../../components/FullLoader';
import Page from '../../../components/Page';
import PublishBtn from '../components/PublishBtn';
import DocumentStructureNav from './components/DocumentStructureNav';
import DocumentStructureSummary from './components/DocumentStructureSummary';

const StructureDocument = () => {
  const structureId = Number(useParams().structureId);
  const documentId = Number(useParams().documentId);

  const { isLoading, data } = useQuery({
    queryKey: ['documentStructure', documentId],
    queryFn: () => getDocumentStructure(structureId, documentId)
  });

  if (isLoading) {
    return <FullLoader />;
  }

  return (
    <Page
      title={data.label}
      backUrl={`/structures/${structureId}/documents`}
      actions={<PublishBtn claimStructure={data} />}
      summary={<DocumentStructureSummary />}
      nav={<DocumentStructureNav />}
    >
      <Outlet />
    </Page>
  );
};

export default StructureDocument;
