import { Tag } from 'antd';
import { CheckCircleOutlined, CloseCircleOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';

type BooleanFormatterProps = {
  active: boolean;
  translationKey?: string;
  translationValueKeys?: {
    active: string;
    inactive: string;
  };
  invertColors?: boolean;
};

export const BooleanFormatter = ({
  active,
  translationKey = 'globals.yesNo',
  invertColors = false,
  translationValueKeys = {
    active: 'true',
    inactive: 'false'
  }
}: BooleanFormatterProps) => {
  if (active) {
    return (
      <Tag icon={<CheckCircleOutlined />} color={invertColors ? 'error' : 'green'}>
        {t(`${translationKey}.${translationValueKeys.active}`)}
      </Tag>
    );
  }

  return (
    <Tag icon={<CloseCircleOutlined />} color={invertColors ? 'green' : 'error'}>
      {t(`${translationKey}.${translationValueKeys.inactive}`)}
    </Tag>
  );
};
