import { Typography } from 'antd';
import { CalendarOutlined } from '@ant-design/icons';
import { DateFormatNames, inputFormats } from '@gowgates/utils';
import { useFormBuilder } from '../../../../hooks/useFormBuilder';

export const DateInput = ({ format = 'day' }: { format?: DateFormatNames }) => {
  const { disabled } = useFormBuilder();

  return (
    <span className={`field-input${disabled ? ' disabled' : ''} calendar`}>
      <Typography.Text type="secondary">{inputFormats[format]}</Typography.Text>
      <CalendarOutlined />
    </span>
  );
};
