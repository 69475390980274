import { PropsWithChildren, Suspense } from 'react';
import { Loader } from '../Loader';

type SuspenseWrapperProps = PropsWithChildren & {
  loaderDelay?: number;
};

export const SuspenseWrapper = ({ children, loaderDelay = 500 }: SuspenseWrapperProps) => (
  <Suspense fallback={<Loader delay={loaderDelay} />}>{children}</Suspense>
);
