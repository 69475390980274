import { FC } from 'react';
import { QueryBuilder } from 'react-querybuilder';

import { t } from '@gowgates/utils';

import { Field, FieldConditionQuery } from '../../../../types';
import {
  fieldsForReactQueryBuilder,
  isConditionQuery,
  INITIAL_CONDITION_QUERY
} from '../../../../utils';
import { RemoveBtn } from './RemoveBtn';
import { AddBtn } from './AddBtn';
import ReactQueryBuilderCustomValueEditor from './ReactQueryBuilderCustomValueEditor';

type ConditionInputProps = {
  value: FieldConditionQuery;
  onChange: (conditionQuery: FieldConditionQuery) => void;
  fields: Field[];
};

export const ConditionInput = ({ value, onChange, fields }: ConditionInputProps) =>
  fields && (
    <QueryBuilder
      fields={fieldsForReactQueryBuilder(fields)}
      controlClassnames={{ queryBuilder: 'queryBuilder-branches' }}
      query={isConditionQuery(value) ? value : INITIAL_CONDITION_QUERY}
      onQueryChange={onChange}
      controlElements={{
        valueEditor: ReactQueryBuilderCustomValueEditor as FC,
        removeGroupAction: RemoveBtn,
        removeRuleAction: RemoveBtn,
        addRuleAction: AddBtn,
        addGroupAction: AddBtn
      }}
      translations={{
        addRule: { label: t('conditionBuilder.addRuleTitle') },
        addGroup: { label: t('conditionBuilder.addGroupTitle') }
      }}
    />
  );
