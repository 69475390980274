import { apiConfigs, extractData } from '@gowgates/api-client';
import { useQuery } from '@tanstack/react-query';
import { camelCase } from 'change-case';
import { Field } from '../../types';
import { useFormBuilder } from '../useFormBuilder';

type FieldTemplates = Record<string, Field[]>;

const client = () => apiConfigs().clientInstance;

export const queryFn = () =>
  client()
    .get('/field_templates.json')
    .then(extractData<FieldTemplates>);

export const queryKey = ['fieldTemplates'];

export const useFieldTemplatesQuery = () => {
  const { fieldableType } = useFormBuilder();

  const query = useQuery({
    queryKey,
    queryFn,
    staleTime: Infinity
  });

  return {
    fieldTemplates: (fieldableType && query.data?.[camelCase(fieldableType)]) || [],
    isLoading: query.isLoading
  };
};
