import React from 'react';
import PropTypes from 'prop-types';

const ShowReportField = ({ reportField, startEditing }) => (
  <article
    className="report-field show-report-field"
    role="button"
    onClick={() => startEditing(reportField.id)}
    onKeyUp={() => startEditing(reportField.id)}
    tabIndex={-1}
  >
    {reportField.pdfName}
  </article>
);

ShowReportField.propTypes = {
  reportField: PropTypes.object.isRequired,
  startEditing: PropTypes.func.isRequired
};

export default ShowReportField;
