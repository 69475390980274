import React from 'react';
import PropTypes from 'prop-types';
import { Form } from 'antd';
import { useMutation } from '@tanstack/react-query';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm } from '@gowgates/utils';
import { createNote } from '../../api/endpoints';
import QuillWithSend from '../form/QuillWithSend';

const NewNote = ({ onSubmit, onCancel, label, notableId, notableType }) => {
  const [form] = Form.useForm();

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => createNote({ ...values, notableId, notableType }),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      form.resetFields();
      onSubmit(data);
    }
  });

  return (
    <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
      <FormItem name="text" label={label}>
        <QuillWithSend loading={isLoading} onCancel={onCancel} />
      </FormItem>
    </Form>
  );
};

NewNote.defaultProps = {
  onSubmit: () => {},
  onCancel: () => {},
  label: null
};

NewNote.propTypes = {
  onSubmit: PropTypes.func,
  notableId: PropTypes.number.isRequired,
  notableType: PropTypes.string.isRequired,
  onCancel: PropTypes.func,
  label: PropTypes.string
};

export default NewNote;
