import { UrlId } from '@gowgates/utils';
import { FormBuilderContextProvider } from '../../contexts/FormBuilderContext';
import { FormBuilderContent } from './Content';

type FormBuilderProps = {
  fieldableId: UrlId;
  fieldableType: string;
  parentFieldableId?: UrlId;
  parentFieldableType?: string;
  disabled?: boolean;
};

export const FormBuilder = ({
  fieldableId,
  fieldableType,
  parentFieldableId,
  parentFieldableType,
  disabled = false
}: FormBuilderProps) => (
  <FormBuilderContextProvider
    fieldableId={fieldableId}
    fieldableType={fieldableType}
    parentFieldableId={parentFieldableId}
    parentFieldableType={parentFieldableType}
    disabled={disabled}
  >
    <FormBuilderContent />
  </FormBuilderContextProvider>
);
