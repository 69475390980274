import { Divider, Form } from 'antd';
import { Field } from '../../../types';
import { isNumber } from '../../../utils';
import { NumberValidations } from './NumberValidations';
import { TextValidations } from './TextValidations';

export const Validations = ({ field }: { field: Partial<Field> }) => {
  const typeUpdated = (prevValues: Field, currentValues: Field) =>
    prevValues.type !== currentValues.type;

  return (
    <>
      <Divider />

      <Form.Item noStyle shouldUpdate={typeUpdated} className="no-margin">
        {({ getFieldValue }) =>
          isNumber(getFieldValue('type')) ? (
            <NumberValidations />
          ) : (
            <TextValidations field={field} />
          )
        }
      </Form.Item>
    </>
  );
};
