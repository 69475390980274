import React from 'react';
import { Col, Layout, Row, Space } from 'antd';
import { Link, Outlet } from 'react-router-dom';
import { t } from '@gowgates/utils';
import { AppFooter, SuspenseWrapper } from '@gowgates/core';
import FullLoader from '../../components/FullLoader';
import useAppConfigs from '../../hooks/useAppConfigs';
import usePermissions from '../../hooks/usePermissions';
import useStructures from '../../hooks/useStructures';
import { useTenant } from '../../hooks/useTenant';
import AppMenu from './AppMenu';
import UserMenu from './UserMenu';
import GlobalSearch from './GlobalSearch';

const Backoffice = () => {
  const { isFetched: isStructuresFetched } = useStructures();
  const { isFetched: isPermissionsFetched } = usePermissions();
  const { isFetched: isAppConfigsFetched } = useAppConfigs();
  const { tenant } = useTenant();

  if (!isPermissionsFetched || !isStructuresFetched || !isAppConfigsFetched) {
    return <FullLoader />;
  }

  const menuTheme = {
    components: {
      Menu: {
        colorPrimary: tenant.bgColor,
        darkItemBg: tenant.bgColor,
        darkItemSelectedBg: tenant.bgColor,
        itemSelectedColor: tenant.primaryColor
      }
    }
  };

  return (
    <Layout>
      <Layout.Header className="topnav" style={{ background: tenant.bgColor }} height={55}>
        <div className="container">
          <Row>
            <Col span={16}>
              <Space>
                <Link to="/">
                  <Space align="center">
                    <img src={tenant.logoSmall?.url} alt={t('app.name')} className="app-logo" />
                    <h1 className="app-name">{t('app.name')}</h1>
                  </Space>
                </Link>
                <AppMenu theme={menuTheme} />
              </Space>
            </Col>
            <Col span={8} className="d-flex justify-content-end">
              <Space align="middle">
                <GlobalSearch />
                <UserMenu theme={menuTheme} />
              </Space>
            </Col>
          </Row>
        </div>
      </Layout.Header>

      <Layout.Content className="page-content">
        <SuspenseWrapper>
          <Outlet />
        </SuspenseWrapper>
      </Layout.Content>

      <AppFooter brandConfigs={tenant} />
    </Layout>
  );
};

export default Backoffice;
