import { Button } from 'antd';
import { useLocation } from 'react-router';
import qs from 'query-string';
import { t } from '@gowgates/utils';
import { backendURL } from '../../../utils/tenants';
import usePermissions from '../../../hooks/usePermissions';

const ExportBtn = () => {
  const search = qs.parse(useLocation().search);
  const { permissions } = usePermissions();

  if (!permissions.exportAllClaims) {
    return null;
  }

  return (
    <Button
      href={`${backendURL}/tasks/export_all.xlsx?${qs.stringify(search, {
        arrayFormat: 'bracket'
      })}`}
      target="blank"
      type="primary"
    >
      {t('globals.exportExcel')}
    </Button>
  );
};

export default ExportBtn;
