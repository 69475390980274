import { t } from '@gowgates/utils';
import { ConditionalDescription } from '@gowgates/dynamic-fields';
import useIntegrations from '../../../hooks/useIntegrations';
import useFileConfig from '../../../hooks/useFileConfig';
import useClaim from '../../../hooks/useClaim';

const ClientDescriptions = () => {
  const { clientStructure } = useFileConfig();
  const { hasInsightIntegrations } = useIntegrations();
  const { claim } = useClaim();

  return (
    <dl className="cg-descriptions">
      {hasInsightIntegrations && (
        <>
          <dt>{t('activerecord.attributes.client.insightSalesTeamName')}</dt>
          <dd>{claim.client.insightSalesTeamName || '-'}</dd>

          <dt>{t('activerecord.attributes.client.insightServiceTeamName')}</dt>
          <dd>{claim.client.insightServiceTeamName || '-'}</dd>
        </>
      )}

      {clientStructure.map((field) => (
        <ConditionalDescription model={claim.client} field={field} key={field.name} />
      ))}
    </dl>
  );
};

export default ClientDescriptions;
