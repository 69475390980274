import { Select, Spin } from 'antd';
import { useCurrencies } from '@gowgates/utils';

type AntSelectProps = React.ComponentProps<typeof Select>;
type CurrencySelectProps = AntSelectProps;

export const CurrencySelect = ({ onChange, disabled = false, ...props }: CurrencySelectProps) => {
  const { currencies } = useCurrencies();

  if (!currencies) return <Spin size="small" />;

  return (
    <Select
      showSearch
      optionLabelProp="label"
      optionFilterProp="children"
      popupMatchSelectWidth={false}
      onChange={onChange}
      {...props}
      disabled={disabled}
    >
      {currencies.map((country) => (
        <Select.Option value={country.value} label={`${country.value}`} key={country.value}>
          {`${country.value} - ${country.name}`}
        </Select.Option>
      ))}
    </Select>
  );
};
