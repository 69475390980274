import React from 'react';
import PropTypes from 'prop-types';
import { Row, Col } from 'antd';
import PageContent from './PageContent';

const PageContentWithSummary = ({ nav, summary, children }) => (
  <PageContent>
    <Row gutter={30}>
      <Col span={18}>
        <section className="box overlap-header">
          {nav}

          {children}
        </section>
      </Col>
      <Col span={6}>{summary}</Col>
    </Row>
  </PageContent>
);

PageContentWithSummary.defaultProps = {
  nav: null,
  summary: null
};

PageContentWithSummary.propTypes = {
  nav: PropTypes.node,
  summary: PropTypes.node,
  children: PropTypes.node.isRequired
};

export default PageContentWithSummary;
