import { NavLink, useMatch } from 'react-router-dom';
import { Menu } from 'antd';
import { t } from '@gowgates/utils';

const JourneyNav = () => {
  const match = useMatch({ path: '/processes/:journeyId/:tab', end: false });

  const menuItems = [
    {
      key: 'details',
      label: <NavLink to="details">{t('globals.details')}</NavLink>
    },
    {
      key: 'claim-tasks',
      label: <NavLink to="claim-tasks">{t('journey.claimTasks')}</NavLink>
    },
    {
      key: 'item-tasks',
      label: <NavLink to="item-tasks">{t('journey.itemTasks')}</NavLink>
    }
    // {
    //   key: 'document-tasks',
    //   label: <NavLink to="document-tasks">{t('journey.documentTasks')}</NavLink>
    // }
  ];

  return (
    <Menu
      selectedKeys={match?.params?.tab ? [match.params.tab] : undefined}
      mode="horizontal"
      className="box-menu"
      items={menuItems}
    />
  );
};

export default JourneyNav;
