import { useState } from 'react';
import { Button, Form, Modal } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createEmailTemplate } from '../../api/endpoints';
import { EmailTemplate } from '../../types';
import usePermissions from '../../hooks/usePermissions';
import ModalFormFooter from '../../components/ModalFormFooter';
import { EmailTemplateFormContent } from './EmailTemplateFormContent';

export const EmailTemplateActions = () => {
  const navigate = useNavigate();
  const { permissions } = usePermissions();
  const queryClient = useQueryClient();
  const [form] = Form.useForm();
  const [isModalOpen, setModalOpen] = useState(false);

  const closeModal = () => {
    setModalOpen(false);
  };

  const showModal = () => {
    form.resetFields();
    setModalOpen(true);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Partial<EmailTemplate>) => createEmailTemplate(values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data: EmailTemplate) => {
      setModalOpen(false);
      queryClient.invalidateQueries({
        queryKey: ['emailTemplates']
      });
      navigate(`/email-templates/${data.id}`);
    }
  });

  if (!permissions.createEmailTemplate) {
    return null;
  }

  return (
    <>
      <Button type="primary" onClick={showModal}>
        {t('emailTemplate.new')}
      </Button>

      <Modal
        title={t('emailTemplate.new')}
        open={isModalOpen}
        onCancel={closeModal}
        footer={null}
        width="80%"
      >
        <Form
          form={form}
          initialValues={{
            name: '',
            content: ''
          }}
          layout="vertical"
          onFinish={mutate}
          disabled={isLoading}
        >
          <EmailTemplateFormContent />

          <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
        </Form>
      </Modal>
    </>
  );
};
