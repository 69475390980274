import React from 'react';
import PropTypes from 'prop-types';
import { ColorPicker } from 'antd';
import { t } from '@gowgates/utils';
import { DynamicFormatter } from '@gowgates/dynamic-fields';

const TenantDescriptions = ({ tenant }) => (
  <dl className="cg-descriptions">
    <dt>{t('activerecord.attributes.tenant.name')}</dt>
    <dd>{tenant.name || '-'}</dd>

    <dt>{t('activerecord.attributes.tenant.primaryColor')}</dt>
    <dd>{tenant.primaryColor ? <ColorPicker disabled value={tenant.primaryColor} /> : '-'}</dd>

    <dt>{t('activerecord.attributes.tenant.bgColor')}</dt>
    <dd>{tenant.bgColor ? <ColorPicker disabled value={tenant.bgColor} /> : '-'}</dd>

    <dt>{t('activerecord.attributes.tenant.replyEmail')}</dt>
    <dd>
      <DynamicFormatter
        data={tenant}
        field={{ name: 'replyEmail', type: 'string', patternTemplate: 'email' }}
      />
    </dd>

    <dt>{t('activerecord.attributes.tenant.forceOtp')}</dt>
    <dd>
      <DynamicFormatter data={tenant} field={{ name: 'forceOtp', type: 'boolean' }} />
    </dd>
  </dl>
);

TenantDescriptions.propTypes = {
  tenant: PropTypes.object.isRequired
};

export default TenantDescriptions;
