import { useEffect, useRef, useState } from 'react';
import { useParams } from 'react-router';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { Col, Form, Input, InputRef, Row, Space, Typography } from 'antd';
import { CloseCircleOutlined, EditOutlined } from '@ant-design/icons';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm } from '@gowgates/utils';
import { updateTask } from '../../../../../api/endpoints';
import { Task } from '../../../../../types';

type DescriptionProps = {
  task: Task;
  setTask: (task: Task) => void;
};

const Description = ({ task, setTask }: DescriptionProps) => {
  const [isEditing, setEditing] = useState(false);
  const inputRef = useRef<InputRef>(null);

  const claimId = Number(useParams().claimId);
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  useEffect(() => {
    form.setFieldsValue({ ...task });
  }, [task, form]);

  useEffect(() => {
    if (isEditing) {
      inputRef?.current?.focus();
    }
  }, [isEditing]);

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Task) => updateTask(task.id, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.invalidateQueries({
        queryKey: ['claim', claimId, 'tasks']
      });
      setTask(data);
      setEditing(false);
    }
  });

  return isEditing ? (
    <Form form={form} layout="vertical" initialValues={task} onFinish={mutate} disabled={isLoading}>
      <Row align="middle">
        <Col span={23}>
          <FormItem name="description" className="no-margin">
            <Input ref={inputRef} />
          </FormItem>
        </Col>
        <Col span={1} className="d-flex justify-content-end">
          <div className="no-margin">
            <Typography.Link onClick={() => setEditing(false)}>
              <CloseCircleOutlined />
            </Typography.Link>
          </div>
        </Col>
      </Row>
    </Form>
  ) : (
    <Space>
      <span>{task.description || '-'}</span>
      {task.permissions.update && (
        <Typography.Link onClick={() => setEditing(true)}>
          <EditOutlined />
        </Typography.Link>
      )}
    </Space>
  );
};

export default Description;
