import { Button } from 'antd';
import { Link } from 'react-router-dom';

import { useBrandConfigs } from '../../contexts';
import { ErrorPage, TextPrimary, TextSecondary } from './ErrorPage';
import { ErrorBoundaryProps } from './ErrorBoundary';

export const NotFound = ({ homepagePath, disableFooter = false }: ErrorBoundaryProps) => {
  const { brandConfigs } = useBrandConfigs();

  return (
    <ErrorPage
      disableFooter={disableFooter}
      code={
        <>
          <TextSecondary $brandConfigs={brandConfigs}>4</TextSecondary>
          <TextPrimary $brandConfigs={brandConfigs}>0</TextPrimary>
          <TextSecondary $brandConfigs={brandConfigs}>4</TextSecondary>
        </>
      }
      title="Something is missing."
      subtitle="The page you are looking for was not found."
      button={
        homepagePath && (
          <Link to={homepagePath}>
            <Button type="primary">Homepage</Button>
          </Link>
        )
      }
    />
  );
};
