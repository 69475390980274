import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { Button, Dropdown } from 'antd';
import { camelCase } from 'change-case';
import NewSectionModal from './NewSectionModal';

const missingSections = (sections, claim) =>
  sections.filter((sectionStructure) => !claim.sections[camelCase(sectionStructure.slug)]);

const AddSection = ({ claim }) => {
  const [isModalOpen, setModalOpen] = useState(false);
  const [currentStructure, setCurrentStructure] = useState({});

  const items = missingSections(claim.structure.sections, claim).map((section) => ({
    key: section.slug,
    label: section.name
  }));

  // TODO: The logic of knowing which sections can be created need to move to the backend
  if (items.length === 0 || !claim.permissions.createSections) {
    return null;
  }

  const onClick = (event) => {
    setCurrentStructure(claim.structure.sections.find((section) => section.slug === event.key));
    setModalOpen(true);
  };

  return (
    <>
      <NewSectionModal
        isOpen={isModalOpen}
        setOpen={setModalOpen}
        sectionStructure={currentStructure}
      />

      <div className="d-flex justify-content-end">
        <Dropdown menu={{ items, onClick }} placement="bottomRight" trigger={['click']}>
          <Button>Add a section</Button>
        </Dropdown>
      </div>
    </>
  );
};

AddSection.propTypes = {
  claim: PropTypes.object.isRequired
};

export default AddSection;
