import { useEffect } from 'react';
import get from 'lodash.get';
import { Row, Col, Form, Input, Select, Spin } from 'antd';
import { t, AUSTRALIA, useCountries, STATES } from '@gowgates/utils';

import { Field } from '../../types';
import { FormItem } from './FormItem';

type AddressFieldProps = {
  field: Field;
  name: (string | number)[];
};

export const AddressField = ({ field, name }: AddressFieldProps) => {
  const firstNames = name.slice(0, -1);
  const lastName = name.slice(-1)[0];
  const { label, required, description, readOnly = false } = field;
  const form = Form.useFormInstance();
  const { countries } = useCountries();

  const countryFieldName = [...firstNames, `${lastName}Country`];
  const streetFieldName = [...firstNames, `${lastName}Street`];
  const cityFieldName = [...firstNames, `${lastName}City`];
  const stateFieldName = [...firstNames, `${lastName}State`];
  const postcodeFieldName = [...firstNames, `${lastName}Postcode`];

  useEffect(() => {
    setTimeout(() => {
      if (!form.getFieldValue(countryFieldName)) {
        form.setFieldValue(countryFieldName, AUSTRALIA);
      }
    }, 100);
  }, []);

  return (
    <Form.Item label={label} required={required} extra={description}>
      {countries ? (
        <Row gutter={10} justify="start">
          <Col md={12} xs={24}>
            <FormItem name={countryFieldName}>
              <Select
                options={countries}
                showSearch
                placeholder={t('address.country')}
                optionFilterProp="label"
                disabled={readOnly}
              />
            </FormItem>
          </Col>
          <Col md={12} xs={24}>
            <FormItem name={streetFieldName}>
              <Input placeholder={t('address.street')} disabled={readOnly} />
            </FormItem>
          </Col>
          <Form.Item
            noStyle
            shouldUpdate={(prevValues, currentValues) =>
              get(prevValues, countryFieldName) !== get(currentValues, countryFieldName)
            }
          >
            {({ getFieldValue }) =>
              getFieldValue(countryFieldName) === AUSTRALIA ? (
                <>
                  <Col md={12} xs={24}>
                    <FormItem name={cityFieldName}>
                      <Input placeholder={t('address.suburb')} disabled={readOnly} />
                    </FormItem>
                  </Col>

                  <Col md={12} xs={24}>
                    <FormItem name={stateFieldName}>
                      <Select
                        options={STATES}
                        placeholder={t('address.state')}
                        disabled={readOnly}
                      />
                    </FormItem>
                  </Col>
                </>
              ) : (
                <Col md={12} xs={24}>
                  <FormItem name={cityFieldName}>
                    <Input placeholder={t('address.city')} disabled={readOnly} />
                  </FormItem>
                </Col>
              )
            }
          </Form.Item>
          <Col md={12} xs={24}>
            <FormItem name={postcodeFieldName}>
              <Input placeholder={t('address.postcode')} disabled={readOnly} />
            </FormItem>
          </Col>
        </Row>
      ) : (
        <Row justify="center">
          <Spin size="small" />
        </Row>
      )}
    </Form.Item>
  );
};
