import { Typography } from 'antd';
import { t } from '@gowgates/utils';
import { DateFormatter } from '@gowgates/dynamic-fields';
import { Task } from '../../../../types';
import { taskCompletedBy } from '../../utils/claim';
import TaskStatus from './TaskStatus';

const StatusCol = ({ task }: { task: Task }) => {
  const isClosed = ['completed', 'cancelled', 'aborted'].indexOf(task.status) >= 0;
  const date = isClosed ? task.completedAt : task.dueAt;
  const prefix = isClosed ? 'On' : 'Due';
  const by = isClosed ? `by ${taskCompletedBy(task) || t('globals.system')}` : '';

  return (
    <>
      <TaskStatus task={task} />
      <br />
      {task.status !== 'aborted' && (
        <Typography.Text type="secondary" italic>
          <small>
            {prefix}: <DateFormatter value={date} /> {by}
          </small>
        </Typography.Text>
      )}
    </>
  );
};
export default StatusCol;
