import React from 'react';
import PropTypes from 'prop-types';
import Box from '../../../../../components/Box';
import SectionDetails from './SectionDetails';
import AddSection from './AddSection';

const CreateSection = ({ section, sectionStructure }) => (
  <Box title={sectionStructure.name}>
    {section ? (
      <SectionDetails section={section} sectionStructure={sectionStructure} />
    ) : (
      <AddSection sectionStructure={sectionStructure} />
    )}
  </Box>
);

CreateSection.defaultProps = {
  section: null
};

CreateSection.propTypes = {
  section: PropTypes.object,
  sectionStructure: PropTypes.object.isRequired
};

export default CreateSection;
