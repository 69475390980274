import { useState } from 'react';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { updateFileConfig } from '../../../api/endpoints';
import useAppConfigs from '../../../hooks/useAppConfigs';

export const useFileConfig = () => {
  const { appConfigs } = useAppConfigs();
  const [localFileConfig, setFileConfig] = useState(appConfigs?.fileConfig);

  const queryClient = useQueryClient();
  const { mutate } = useMutation({ mutationFn: (data: any) => updateFileConfig(data) });

  const updateFileConfigVal = (key: string, val: string) => {
    setFileConfig({ ...localFileConfig, [key]: val });
    mutate(
      { [key]: val },
      {
        onSuccess: () => {
          queryClient.invalidateQueries({ queryKey: ['appConfigs'] });
        }
      }
    );
  };

  return {
    localFileConfig,
    updateFileConfigVal
  };
};
