import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import { ExportOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { insightTransactionURL } from '../utils/insight';

const ShowTransactionInInsight = ({ claim }) => {
  if (!claim.insightLedger || !claim.insightPolicyTransactionId) {
    return null;
  }

  return (
    <Tooltip title={t('globals.showInInsight')}>
      <a
        href={insightTransactionURL(claim.insightLedger, claim.insightPolicyTransactionId)}
        target="_blank"
        rel="noreferrer"
      >
        <ExportOutlined />
      </a>
    </Tooltip>
  );
};

ShowTransactionInInsight.propTypes = {
  claim: PropTypes.object.isRequired
};

export default ShowTransactionInInsight;
