import { Button } from 'antd';
import { Link } from 'react-router-dom';

import { useBrandConfigs } from '../../contexts';
import { ErrorPage, TextPrimary, TextSecondary } from './ErrorPage';
import { ErrorBoundaryProps } from './ErrorBoundary';

export const InternalServerError = ({
  homepagePath,
  disableFooter = false
}: ErrorBoundaryProps) => {
  const { brandConfigs } = useBrandConfigs();

  return (
    <ErrorPage
      disableFooter={disableFooter}
      code={
        <>
          <TextPrimary $brandConfigs={brandConfigs}>5</TextPrimary>
          <TextSecondary $brandConfigs={brandConfigs}>00</TextSecondary>
        </>
      }
      title="Something went wrong."
      subtitle="Please try again and if the problem persists contact the administrator."
      button={
        homepagePath && (
          <Link to={homepagePath}>
            <Button type="primary">Homepage</Button>
          </Link>
        )
      }
    />
  );
};
