import React, { useEffect, useRef } from 'react';
import PropTypes from 'prop-types';
import { Form, Modal, Input } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createRole } from '../../api/endpoints';
import ModalFormFooter from '../../components/ModalFormFooter';

const NewRoleModal = ({ isOpen, setOpen }) => {
  const [form] = Form.useForm();
  const inputRef = useRef(null);
  const queryClient = useQueryClient();

  useEffect(() => {
    if (isOpen) {
      inputRef?.current?.focus();
    }
  }, [isOpen]);

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => createRole(values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({ queryKey: ['roles'] });
    }
  });

  return (
    <Modal title={t('role.new')} open={isOpen} onCancel={closeModal} footer={null}>
      <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
        <FormItem name="name" model="role">
          <Input ref={inputRef} />
        </FormItem>

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

NewRoleModal.defaultProps = {
  isOpen: false
};

NewRoleModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired
};

export default NewRoleModal;
