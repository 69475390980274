import { FC, StrictMode } from 'react';
import * as ReactDOM from 'react-dom/client';

import { APIClientConfigsType, initAPIConfigs } from '@gowgates/api-client';

export const initializeApp = async ({
  AppComponent,
  apiConfigs,
  overrides: { rootElementId = 'root' } = {}
}: {
  AppComponent: FC;
  apiConfigs: APIClientConfigsType;
  overrides?: { rootElementId?: string };
}) => {
  initAPIConfigs(apiConfigs);

  const root = ReactDOM.createRoot(document.getElementById(rootElementId) as HTMLElement);

  root.render(
    <StrictMode>
      <AppComponent />
    </StrictMode>
  );
};
