import PropTypes from 'prop-types';
import { Table } from 'antd';
import { Link } from 'react-router-dom';
import { t } from '@gowgates/utils';
import { DateFormatter } from '@gowgates/dynamic-fields';
import { useTable } from '@gowgates/core';
import AvatarAndName from '../../components/AvatarAndName';
import TaskStatus from '../claim/show/Tasks/TaskStatus';
import useResourceUsers from '../../hooks/useResourceUsers';
import { claimRowClassName, convertToFilter } from '../../utils/tables';
import ClaimRowIcons from '../claims/ClaimRowIcons';
import useAppConfigs from '../../hooks/useAppConfigs';
import TaskCol from './TaskCol';

const { Column } = Table;

const TasksTable = ({
  tasks,
  loading,
  pagination,
  filters,
  sorter,
  rowSelection,
  showAssignee
}) => {
  const { handleTableChange } = useTable();
  const { userFilters } = useResourceUsers();
  const { appConfigs } = useAppConfigs();

  return (
    <Table
      dataSource={tasks}
      rowKey={(record) => record.id}
      pagination={pagination}
      loading={loading}
      onChange={handleTableChange}
      className="page-table"
      rowSelection={rowSelection}
      rowClassName={claimRowClassName}
    >
      <Column
        title={t('activerecord.attributes.task.clientId')}
        dataIndex="clientId"
        sorter
        sortOrder={sorter.field === 'clientId' && sorter.order}
        render={(_text, record) => <TaskCol task={record}>{record.client.name}</TaskCol>}
      />
      <Column
        title={t('activerecord.attributes.task.taskStructureId')}
        dataIndex="name"
        sorter
        sortOrder={sorter.field === 'name' && sorter.order}
        render={(_text, record) => <TaskCol task={record}>{record.name}</TaskCol>}
      />

      {showAssignee && (
        <Column
          title={t('activerecord.attributes.task.user')}
          dataIndex="userId"
          sorter
          sortOrder={sorter.field === 'userId' && sorter.order}
          filters={userFilters}
          filteredValue={filters.userId || null}
          render={(_text, record) => (
            <TaskCol task={record}>
              <AvatarAndName user={record.user} />
            </TaskCol>
          )}
        />
      )}

      <Column
        title={t('activerecord.attributes.task.dueAt')}
        dataIndex="dueAt"
        sorter
        sortOrder={sorter.field === 'dueAt' && sorter.order}
        render={(_text, record) => (
          <TaskCol task={record}>
            <DateFormatter value={record.dueAt} />
          </TaskCol>
        )}
      />
      <Column
        title={t('activerecord.attributes.task.status')}
        dataIndex="status"
        sorter
        sortOrder={sorter.field === 'status' && sorter.order}
        filters={convertToFilter(appConfigs.task.openStatuses)}
        filteredValue={filters.status || null}
        render={(_text, record) => (
          <TaskCol task={record}>
            <TaskStatus task={record} />
          </TaskCol>
        )}
      />

      <Column
        className="text-right claim-info-icons"
        render={(_text, record) => (
          <Link to={`/claims/${record.id}`} className="col-clickable">
            <ClaimRowIcons record={record} />
          </Link>
        )}
      />
    </Table>
  );
};

TasksTable.defaultProps = {
  tasks: [],
  showAssignee: true
};

TasksTable.propTypes = {
  tasks: PropTypes.array,
  loading: PropTypes.bool.isRequired,
  pagination: PropTypes.object.isRequired,
  filters: PropTypes.object.isRequired,
  sorter: PropTypes.object.isRequired,
  rowSelection: PropTypes.oneOfType([PropTypes.object, PropTypes.bool]).isRequired,
  showAssignee: PropTypes.bool
};

export default TasksTable;
