import React from 'react';
import PropTypes from 'prop-types';
import { Form, Modal } from 'antd';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { createIntegration } from '../../api/endpoints';
import ModalFormFooter from '../../components/ModalFormFooter';
import IntegrationForm from './Form';

const NewIntegrationModal = ({ isOpen, setOpen }) => {
  const queryClient = useQueryClient();
  const [form] = Form.useForm();

  const closeModal = () => {
    form.resetFields();
    setOpen(false);
  };

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values) => createIntegration(values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: () => {
      closeModal();
      queryClient.invalidateQueries({ queryKey: ['integrations'] });
    }
  });

  return (
    <Modal title={t('integration.new')} open={isOpen} onCancel={closeModal} footer={null}>
      <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
        <IntegrationForm />

        <ModalFormFooter closeModal={closeModal} isLoading={isLoading} />
      </Form>
    </Modal>
  );
};

NewIntegrationModal.defaultProps = {
  isOpen: false
};

NewIntegrationModal.propTypes = {
  isOpen: PropTypes.bool,
  setOpen: PropTypes.func.isRequired
};

export default NewIntegrationModal;
