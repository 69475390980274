import React from 'react';
import { Link, useMatch } from 'react-router-dom';
import { Menu } from 'antd';
import { t } from '@gowgates/utils';

const ProfileNav = () => {
  const match = useMatch({ path: '/profile/:tab', end: false });

  const menuItems = [
    { key: 'details', label: <Link to="details">{t('globals.general')}</Link> },
    { key: 'email', label: <Link to="email">{t('account.changeEmail')}</Link> },
    { key: 'password', label: <Link to="password">{t('account.changePassword')}</Link> }
  ];

  return (
    <Menu
      selectedKeys={match?.params?.tab}
      mode="horizontal"
      className="box-menu"
      items={menuItems}
    />
  );
};

export default ProfileNav;
