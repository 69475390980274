import numeral, { Numeral } from 'numeral';
import { DEFAULT_CURRENCY, PRICE_FORMAT } from './constants';
import { t } from './I18n';

export const EMPTY_TEXT = '-';

export const displayText = (text?: string) => {
  if (!text) {
    return EMPTY_TEXT;
  }

  return text;
};

export const formatPrice = (value?: string | number | Numeral, currency = DEFAULT_CURRENCY) => {
  if (value === undefined || value === null) {
    return EMPTY_TEXT;
  }

  return `${currency || DEFAULT_CURRENCY} ${numeral(value).format(PRICE_FORMAT)}`;
};

export const formatBoolean = (value?: boolean) => {
  if (value === null || value === undefined) {
    return '-';
  }

  return t(`globals.yesNo.${value}`);
};
