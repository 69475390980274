import { Space, Typography } from 'antd';
import { FieldOption } from '../../../../types';
import { useFormBuilder } from '../../../../hooks/useFormBuilder';

type InlineOptionsProps = {
  options: FieldOption[];
  multiple?: boolean;
};

export const InlineOptions = ({ options, multiple }: InlineOptionsProps) => {
  const { disabled } = useFormBuilder();

  if (options.length === 0) {
    return (
      <Typography.Text type="secondary" italic>
        Click to add options
      </Typography.Text>
    );
  }

  return (
    <Space size="middle">
      {options.map((option) => (
        <Space key={option.key}>
          <span
            className={`inline-option-input ${multiple ? 'checkbox' : 'radio'}${
              disabled ? ' disabled' : ''
            }`}
          />
          <span>{option.value}</span>
        </Space>
      ))}
    </Space>
  );
};
