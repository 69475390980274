import React, { useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { t } from '@gowgates/utils';
import { setPageTitle } from '@gowgates/core';
import { getUser } from '../../api/endpoints';
import FullLoader from '../../components/FullLoader';
import Page from '../../components/Page';
import UserDetails from './Details';
import UserSummary from './components/UserSummary';
import UserContextMenu from './components/UserContextMenu';
import UserActions from './components/UserActions';

const User = () => {
  const userId = Number(useParams().userId);

  const { isLoading, data: user } = useQuery({
    queryKey: ['user', userId],
    queryFn: () => getUser(userId)
  });

  useEffect(() => {
    if (!user) return;

    setPageTitle(`${user.name} | ${t('activerecord.models.user.other')}`);
  }, [user]);

  if (isLoading) {
    return <FullLoader />;
  }

  return (
    <Page
      title={user.id ? user.name : t('user.new')}
      backUrl={-1}
      contextMenu={<UserContextMenu user={user} />}
      actions={<UserActions user={user} />}
      summary={<UserSummary user={user} />}
    >
      <UserDetails user={user} />
    </Page>
  );
};

export default User;
