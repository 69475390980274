import { camelCase } from 'change-case';
import dayjs from 'dayjs';

import { AnyObject } from '@gowgates/utils';

import { FieldConditionQuery, Field, QueryCombinator, FieldConditionLeaf } from '../types';

export const INITIAL_CONDITION_QUERY: FieldConditionQuery = {
  combinator: 'and',
  rules: []
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type ConditionFieldValue = any;

const parseValue = (v: unknown): unknown => {
  if (['boolean', 'number', 'string'].includes(typeof v)) return `${v}`;
  return Array.isArray(v) ? v.map(parseValue) : v;
};
const parseNumberOrDate = (v: ConditionFieldValue) => (isNaN(v) ? dayjs(v) : parseFloat(v));

export const isConditionQuery = (q?: FieldConditionQuery) => Boolean(q?.rules && q?.combinator);
const isLeafRule = (q?: FieldConditionQuery) =>
  !isConditionQuery(q) && Boolean(q?.field && q?.operator);
export const extractFieldsForCondition = (
  q: FieldConditionQuery,
  set: Set<string> = new Set([])
) => {
  if (q.field) {
    set.add(q.field);
  } else {
    q.rules?.every((r) => extractFieldsForCondition(r as FieldConditionQuery, set));
  }

  return [...set];
};

const isFieldUsedInCondition = ({
  fieldName,
  condition
}: {
  fieldName?: string;
  condition?: FieldConditionQuery;
}): boolean => {
  if (!fieldName) return false;
  if (isLeafRule(condition)) return condition?.field === fieldName;

  return (condition?.rules || []).some((r) =>
    isFieldUsedInCondition({ fieldName, condition: r as FieldConditionQuery })
  );
};

export const getConditionalFieldsUsingField = ({
  field,
  fields = []
}: { field?: Partial<Field>; fields?: Partial<Field>[] } = {}) => {
  if (!field?.name) return [];
  return fields.filter(
    (f) =>
      f.conditional &&
      isConditionQuery(f.conditionExpression) &&
      isFieldUsedInCondition({ fieldName: field?.name, condition: f.conditionExpression })
  );
};

export const hasCondition = (field: Field) => isConditionQuery(field.conditionExpression);

const evalNestedRuleGenerator =
  (conditionVal: FieldConditionQuery) => (item: ConditionFieldValue) =>
    conditionVal.field ? evalLeafRule(item[camelCase(conditionVal.field)], conditionVal) : false;

export const evalLeafRule = (value: ConditionFieldValue, rule: FieldConditionLeaf): boolean => {
  const operatorIdentifier = camelCase(rule.operator || '');

  const nestedOperators: {
    [key: string]: (
      array: FieldConditionQuery[],
      cb: (v: ConditionFieldValue) => boolean
    ) => boolean;
  } = {
    includeOne: (array, cb) => array?.some(cb),
    includeAll: (array, cb) => array?.every(cb),
    includeNone: (array, cb) => !array?.some(cb)
  };

  const evalNestedCondition = (value = [], conditionVal: ConditionFieldValue) =>
    Array.isArray(value) && value.length > 0 && isLeafRule(conditionVal)
      ? nestedOperators[operatorIdentifier](value, evalNestedRuleGenerator(conditionVal))
      : false;

  const operators: {
    [key: string]: (value: ConditionFieldValue, conditionVal: ConditionFieldValue) => boolean;
  } = {
    eq: (value, conditionVal) => value === conditionVal,
    neq: (value, conditionVal) => value !== conditionVal,
    lt: (value, conditionVal) => parseNumberOrDate(value) < parseNumberOrDate(conditionVal),
    gt: (value, conditionVal) => parseNumberOrDate(value) > parseNumberOrDate(conditionVal),
    lte: (value, conditionVal) => parseNumberOrDate(value) <= parseNumberOrDate(conditionVal),
    gte: (value, conditionVal) => parseNumberOrDate(value) >= parseNumberOrDate(conditionVal),
    inc: (value = '', conditionVal) => Boolean(value?.includes(conditionVal)),
    exc: (value = '', conditionVal) => !value?.includes(conditionVal),
    includeOne: (value, conditionVal) => evalNestedCondition(value, conditionVal),
    includeAll: (value, conditionVal) => evalNestedCondition(value, conditionVal),
    includeNone: (value, conditionVal) => evalNestedCondition(value, conditionVal)
  };

  return operators[operatorIdentifier]?.(parseValue(value), parseValue(rule.value)) || false;
};

const evalCondition = ({
  condition,
  currentValues
}: {
  condition: FieldConditionQuery;
  currentValues: AnyObject;
}): boolean => {
  if (isLeafRule(condition) && condition?.field) {
    return evalLeafRule(currentValues[camelCase(condition.field)], condition);
  }

  const combinatorEvaluators: { [key in QueryCombinator]: 'every' | 'some' } = {
    and: 'every',
    or: 'some'
  };
  const combinatorEvaluator =
    condition.combinator && combinatorEvaluators[condition.combinator as QueryCombinator];

  return combinatorEvaluator && condition.rules
    ? condition.rules[combinatorEvaluator]((r) =>
        evalCondition({ condition: r as FieldConditionQuery, currentValues })
      )
    : false;
};

export const evalConditionalField = ({
  field,
  currentValues
}: {
  field: Field;
  currentValues: AnyObject;
}) => {
  if (!hasCondition(field)) {
    return true;
  }

  return (
    (field.conditionExpression &&
      evalCondition({ condition: field.conditionExpression, currentValues })) ||
    false
  );
};
