import { useQuery } from '@tanstack/react-query';
import { getTenant } from '../api/endpoints';

export const useTenant = () => {
  const { data: tenant, ...queryData } = useQuery({
    queryKey: ['tenant'],
    queryFn: getTenant,
    staleTime: Infinity
  });

  return {
    tenant,
    ...queryData
  };
};
