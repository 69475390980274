import React from 'react';
import { Form, Row, Col, Button, message, Input } from 'antd';
import { useMutation, useQuery } from '@tanstack/react-query';
import { useNavigate } from 'react-router';
import { FormItem } from '@gowgates/dynamic-fields';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { useAuth } from '@gowgates/core';
import { usePageTitle } from '@gowgates/core';
import { createOtp, getOtpQrCode } from '../../api/endpoints';
import Box from '../../components/Box';
import Page from '../../components/Page';

const Profile = () => {
  const { currentUser } = useAuth();
  const [form] = Form.useForm();
  const navigate = useNavigate();
  usePageTitle(t('user.otp.enable'));

  const { data: svg } = useQuery({ queryKey: ['qrCode'], queryFn: getOtpQrCode });

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: createOtp,
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      message.success(t('user.otp.enabled'));
      navigate('/profile');
      localStorage.setItem('user', JSON.stringify(data));
    }
  });

  if (currentUser.otpRequiredForLogin) {
    navigate('/profile');
  }

  return (
    <Page title={t('user.otp.enable')} backUrl="/profile" overlap>
      <Col span={18} offset={3}>
        <Form form={form} layout="vertical" onFinish={mutate} disabled={isLoading}>
          <Box>
            <Row>
              <Col span={16} offset={3}>
                {/* eslint-disable-next-line react/no-danger */}
                <p
                  className="text-center"
                  dangerouslySetInnerHTML={{ __html: t('user.otp.install') }}
                />

                {/* eslint-disable-next-line react/no-danger */}
                <div className="text-center" dangerouslySetInnerHTML={{ __html: svg }} />
              </Col>

              <Col span={16} offset={3}>
                <FormItem name="code" label="Code">
                  <Input />
                </FormItem>
              </Col>
            </Row>

            <footer className="d-flex justify-content-end">
              <Button type="primary" htmlType="submit">
                {t('globals.save')}
              </Button>
            </footer>
          </Box>
        </Form>
      </Col>
    </Page>
  );
};

export default Profile;
