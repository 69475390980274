import { camelCase } from 'change-case';
import dayjs, { Dayjs } from 'dayjs';
import { Input, Select, Radio, Checkbox } from 'antd';
import { booleanOptions } from '@gowgates/utils';

import { Field } from '../../types';
import {
  isAddressField,
  isArrayField,
  isBooleanField,
  isChoiceField,
  isChoiceFieldWithList,
  isDateField,
  isDisplayingAsSelect,
  isFileField,
  isMoneyField,
  isMultiChoiceField,
  isPercentField,
  isPhoneField,
  isSingleChoiceField,
  isTextField
} from '../../utils/fields';
import { MoneyFormItem } from './MoneyFormItem';
import { AddressField } from './AddressField';
import { PhoneAddon } from './PhoneAddon';
import { FormItem } from './FormItem';
import { CgDatePicker } from './CgDatePicker';
import { ArrayChildren } from './ArrayChildren';
import { ChoiceFromListField } from './ChoiceFromListField';
import { FileField } from './FileField';

type ConstraintsType = Required<Field>['constraints'];
type OptionsType = Field['options'];

const maxFromConstraints = (constraints: ConstraintsType) => {
  if (constraints.max === 'now') {
    return dayjs().endOf('day');
  }

  if (constraints.max) {
    return dayjs(constraints.max).endOf('day');
  }

  return null;
};

const minFromConstraints = (constraints: ConstraintsType) => {
  if (constraints.min === 'now') {
    return dayjs().startOf('day');
  }

  if (constraints.min) {
    return dayjs(constraints.min).startOf('day');
  }

  return null;
};

const disabledDateFromConstraints = (current: Dayjs, constraints: ConstraintsType = {}) => {
  const max = maxFromConstraints(constraints);
  const min = minFromConstraints(constraints);

  if (min && max) {
    return current && (current <= min || current >= max);
  }

  if (max) {
    return current && current >= max;
  }

  if (min) {
    return current && current <= min;
  }

  return null;
};

const convertedOptions = (options: OptionsType = []) =>
  options.map((option) => ({ value: option.key, label: option.value }));

const fieldBooleanOptions = (field: Field) =>
  booleanOptions({ trueLabel: field.trueLabel, falseLabel: field.falseLabel });

type DynamicFieldProps = {
  field: Field;
  namespace?: (string | number)[];
  updateRate?: boolean;
};
export const DynamicField = ({
  field,
  namespace = ['data'],
  updateRate = true
}: DynamicFieldProps) => {
  const name = [...namespace, camelCase(field.name)].filter((n) => n !== null && n !== undefined);
  const { label, required, description: rawDescription, readOnly = false } = field;

  // eslint-disable-next-line react/no-danger
  const description = rawDescription && (
    <span dangerouslySetInnerHTML={{ __html: rawDescription }} />
  );

  const disabledDate = (current: Dayjs) =>
    Boolean(disabledDateFromConstraints(current, field.constraints));

  if (isAddressField(field)) {
    return <AddressField field={field} name={name} />;
  }

  if (isDateField(field)) {
    return (
      <FormItem name={name} label={label} required={required} extra={description}>
        <CgDatePicker disabledDate={disabledDate} disabled={readOnly} level={field.level} />
      </FormItem>
    );
  }

  if (isTextField(field)) {
    return (
      <FormItem name={name} label={label} required={required} extra={description}>
        <Input.TextArea disabled={readOnly} />
      </FormItem>
    );
  }

  if (isChoiceFieldWithList(field)) {
    return <ChoiceFromListField name={name} field={field} disabled={readOnly} />;
  }

  if (isChoiceField(field) && isDisplayingAsSelect(field)) {
    return (
      <FormItem name={name} label={label} required={required} extra={description}>
        <Select
          options={convertedOptions(field.options || [])}
          showSearch
          optionFilterProp="label"
          disabled={readOnly}
        />
      </FormItem>
    );
  }

  if (isSingleChoiceField(field)) {
    return (
      <FormItem name={name} label={label} required={required} extra={description}>
        <Radio.Group options={convertedOptions(field.options)} disabled={readOnly} />
      </FormItem>
    );
  }

  if (isMultiChoiceField(field)) {
    return (
      <FormItem name={name} label={label} required={required} extra={description}>
        <Checkbox.Group options={convertedOptions(field.options)} disabled={readOnly} />
      </FormItem>
    );
  }

  if (isBooleanField(field) && isDisplayingAsSelect(field)) {
    return (
      <FormItem name={name} label={label} required={required} extra={description}>
        <Select
          options={fieldBooleanOptions(field)}
          optionFilterProp="label"
          showSearch
          disabled={readOnly}
        />
      </FormItem>
    );
  }

  if (isBooleanField(field) && !isDisplayingAsSelect(field)) {
    return (
      <FormItem name={name} label={label} required={required} extra={description}>
        <Radio.Group
          options={fieldBooleanOptions(field) as { label: string; value: string }[]}
          disabled={readOnly}
        />
      </FormItem>
    );
  }

  if (isMoneyField(field)) {
    return (
      <MoneyFormItem
        name={name}
        label={label}
        required={required}
        extra={description}
        multipleCurrencies
        disabled={readOnly}
        updateRate={updateRate}
      />
    );
  }

  if (isPhoneField(field)) {
    return (
      <FormItem name={name} label={label} required={required} extra={description}>
        <Input addonBefore={<PhoneAddon name={name} />} disabled={readOnly} />
      </FormItem>
    );
  }

  if (isPercentField(field)) {
    return (
      <FormItem name={name} label={label} required={required} extra={description}>
        <Input addonAfter="%" disabled={readOnly} />
      </FormItem>
    );
  }

  if (isArrayField(field)) {
    return (
      <FormItem name={name} label={label} required={required} extra={description}>
        <ArrayChildren fieldName={name} field={field} />
      </FormItem>
    );
  }

  if (isFileField(field)) {
    return (
      <FormItem name={name} label={label} required={required} extra={description}>
        <FileField multiple={field.multiple} disabled={readOnly} />
      </FormItem>
    );
  }

  return (
    <FormItem name={name} label={label} required={required} extra={description}>
      <Input disabled={readOnly} />
    </FormItem>
  );
};
