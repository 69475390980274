import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';

const ClaimCol = ({ claim, children }) => {
  if (claim.permissions.show) {
    return (
      <Link to={`/claims/${claim.id}`} className="col-clickable">
        {children}
      </Link>
    );
  }

  return children;
};

ClaimCol.defaultProps = {
  children: null
};

ClaimCol.propTypes = {
  claim: PropTypes.object.isRequired,
  children: PropTypes.node
};

export default ClaimCol;
