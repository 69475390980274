import { Form, Input, Select } from 'antd';
import { t } from '@gowgates/utils';

import { FormItem, CurrencySelect } from '../../forms';

type BankDetailsFormProps = {
  appConfigs: {
    claim: {
      bankAccountTypes: { label: string; value: string }[];
    };
  };
  nested?: boolean;
};

export const BankDetailsForm = ({ appConfigs, nested = false }: BankDetailsFormProps) => {
  const namespace = nested ? ['claim'] : [];

  return (
    <>
      <FormItem
        name={[...namespace, 'bankAccountType']}
        model="claim"
        required
        extra={t('frontoffice.bankForm.typeDescription')}
      >
        <Select options={appConfigs.claim.bankAccountTypes} />
      </FormItem>

      <Form.Item
        noStyle
        shouldUpdate={(prev, current) =>
          nested
            ? prev.clam?.bankAccountType !== current.claim?.bankAccountType
            : prev.bankAccountType !== current.bankAccountType
        }
      >
        {({ getFieldValue }) => {
          const bankAccountType = getFieldValue([...namespace, 'bankAccountType']);

          return (
            <>
              {bankAccountType && (
                <>
                  <FormItem name={[...namespace, 'bankName']} model="claim" required>
                    <Input />
                  </FormItem>
                  <FormItem name={[...namespace, 'bankAccountHolderName']} model="claim" required>
                    <Input />
                  </FormItem>
                </>
              )}

              {bankAccountType === 'australia' && (
                <>
                  <FormItem
                    name={[...namespace, 'bsb']}
                    model="claim"
                    required
                    extra={t('frontoffice.bankForm.bsbDescription')}
                  >
                    <Input />
                  </FormItem>
                  <FormItem name={[...namespace, 'accountNumber']} model="claim" required>
                    <Input />
                  </FormItem>
                </>
              )}

              {bankAccountType === 'overseas' && (
                <>
                  <FormItem name={[...namespace, 'swift']} model="claim" required>
                    <Input />
                  </FormItem>
                  <FormItem name={[...namespace, 'iban']} model="claim" required>
                    <Input />
                  </FormItem>
                  <FormItem name={[...namespace, 'currency']} model="claim" required>
                    <CurrencySelect />
                  </FormItem>
                </>
              )}
            </>
          );
        }}
      </Form.Item>
    </>
  );
};
