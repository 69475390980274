import { Button, Row, Col } from 'antd';
import { PlusOutlined } from '@ant-design/icons';
import { t } from '@gowgates/utils';
import { Claim } from '../../../../types';
import ReassignTasks from '../../../tasks/ReassignTasks';

type TableHeaderProps = {
  claim: Claim;
  selectedRows: any[];
  setModalOpen: (modalOpen: boolean) => void;
};

const TableHeader = ({ claim, selectedRows, setModalOpen }: TableHeaderProps) => (
  <Row className="d-flex align-items-center">
    <Col span={17}>
      <ReassignTasks selectedRows={selectedRows} cacheKey={['claim', claim.id, 'tasks']} />
    </Col>

    <Col span={7} className="d-flex justify-content-end">
      {claim.permissions.createTasks && (
        <Button type="primary" onClick={() => setModalOpen(true)} icon={<PlusOutlined />}>
          {t('task.add')}
        </Button>
      )}
    </Col>
  </Row>
);

export default TableHeader;
