import { Col, Input, Row } from 'antd';
import { FormItem } from '@gowgates/dynamic-fields';

const RoleDetailsFormContent = () => (
  <Row gutter={30}>
    <Col span={12}>
      <FormItem name="name" model="role">
        <Input />
      </FormItem>
    </Col>
  </Row>
);

export default RoleDetailsFormContent;
