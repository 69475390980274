import { SuspenseWrapper } from '@gowgates/core';
import { lazy } from 'react';
import type { DocumentsFormProps } from './';

const DocumentForm = lazy(() => import('./').then((module) => module));

export const DocumentsFormLazy = (props: DocumentsFormProps) => (
  <SuspenseWrapper>
    <DocumentForm {...props} />
  </SuspenseWrapper>
);
