import { useEffect, useState } from 'react';
import { Form, FormInstance, Input, InputNumber, Space } from 'antd';
import numeral from 'numeral';
import { DEFAULT_CURRENCY, PRICE_FORMAT, formatPrice } from '@gowgates/utils';
import { apiConfigs, extractData } from '@gowgates/api-client';
import { FormItem, FormItemProps } from './FormItem';
import { CurrencySelect } from './CurrencySelect';

export const getExchangeRate = (currency: string) =>
  apiConfigs().clientInstance.get(`exchange_rates/${currency}`).then(extractData);

export const moneyFieldFormatter = (value?: string) =>
  value?.replace(/\B(?=(\d{3})+(?!\d))/g, ',') || '';
export const moneyFieldParser = (value?: string) => value?.replace(/\$\s?|(,*)/g, '') || '';

type MoneyFormItemProps = FormItemProps & {
  multipleCurrencies?: boolean;
  disabled?: boolean;
  updateRate?: boolean;
};

export const MoneyFormItem = ({
  name,
  model,
  label,
  multipleCurrencies = false,
  disabled = false,
  updateRate = true,
  ...rest
}: MoneyFormItemProps) => {
  const currencyName = new Array(name).flat();
  currencyName[currencyName.length - 1] = `${currencyName[currencyName.length - 1]}Currency`;

  const rateName = new Array(name).flat();
  rateName[rateName.length - 1] = `${rateName[rateName.length - 1]}Rate`;

  const calculateInitialRate = (form: FormInstance) => {
    const amount = form.getFieldValue(name) || '0';
    const rate = form.getFieldValue(rateName) || '0';

    return numeral(amount)
      .multiply(rate || '1')
      .format(PRICE_FORMAT);
  };

  const form = Form.useFormInstance();
  const [convertedAmount, setConvertedAmount] = useState(calculateInitialRate(form));

  useEffect(() => {
    setTimeout(() => {
      if (!form.getFieldValue(currencyName)) {
        form.setFieldValue(currencyName, DEFAULT_CURRENCY);
      }
    }, 100);
  }, [currencyName, form]);

  const moneyAddon = (
    <Form.Item name={currencyName} noStyle>
      <CurrencySelect
        onChange={(value) => {
          if (typeof value !== 'string') return;

          getExchangeRate(value).then((data) => {
            const amount = form.getFieldValue(name) || '0';
            form.setFieldValue(rateName, data.rate);
            setConvertedAmount(
              numeral(amount)
                .multiply(data.rate || '1')
                .format(PRICE_FORMAT)
            );
          });
        }}
        disabled={disabled}
      />
    </Form.Item>
  );

  const isChangedCurrency =
    form.getFieldValue(currencyName) && form.getFieldValue(currencyName) !== DEFAULT_CURRENCY;

  const help = isChangedCurrency ? (
    <Space className="converstion-help">
      Converted to
      {updateRate ? (
        <Input
          value={convertedAmount}
          size="small"
          addonBefore="AUD"
          style={{ width: (`${convertedAmount}`.length + 1) * 8 + 70 }}
          onChange={(event) => {
            const { value } = event.target;
            const amount = form.getFieldValue(name) || '0';
            setConvertedAmount(value);

            const newRate = numeral(value || '1')
              .divide(amount)
              .format('0.0000');
            form.setFieldValue(rateName, newRate);
          }}
        />
      ) : (
        <strong>{formatPrice(convertedAmount)}</strong>
      )}
      at the rate of
      {!updateRate && <strong>{form.getFieldValue(rateName)}</strong>}
      <FormItem name={rateName} noStyle hidden={!updateRate}>
        <Input
          size="small"
          className="currency-rate"
          onChange={(event) => {
            const { value } = event.target;
            const amount = form.getFieldValue(name) || '0';
            form.setFieldValue(rateName, value);
            setConvertedAmount(
              numeral(amount)
                .multiply(value || '1')
                .format(PRICE_FORMAT)
            );
          }}
        />
      </FormItem>
    </Space>
  ) : null;

  return (
    <FormItem name={name} model={model} label={label} help={help} {...rest}>
      <InputNumber
        className="w-100"
        controls={false}
        formatter={moneyFieldFormatter}
        parser={moneyFieldParser}
        addonBefore={multipleCurrencies ? moneyAddon : DEFAULT_CURRENCY}
        onChange={(value) => {
          const rate = form.getFieldValue(rateName);
          setConvertedAmount(
            numeral(value || 0)
              .multiply(rate || '1')
              .format(PRICE_FORMAT)
          );
        }}
        disabled={disabled}
      />
    </FormItem>
  );
};
