import { Row, Switch } from 'antd';
import { t } from '@gowgates/utils';
import { FormItem } from '../../forms';

export const Files = () => (
  <Row align="middle" className="field-choices-footer">
    <FormItem
      name="multiple"
      model="field"
      valuePropName="checked"
      label={t('activerecord.attributes.field.multipleFiles')}
    >
      <Switch />
    </FormItem>
  </Row>
);
