import React from 'react';
import PropTypes from 'prop-types';
import dayjs from 'dayjs';
import { Row, Col, Space } from 'antd';
import { Link } from 'react-router-dom';
import { t } from '@gowgates/utils';
import { useAuth } from '@gowgates/core';
import usePermissions from '../../hooks/usePermissions';
import StatisticBox from './StatisticBox';

const dateFormat = 'YYYY-MM-DD';

const Statistics = ({ statistics, loading }) => {
  const { currentUser } = useAuth();
  const { permissions } = usePermissions();

  const tomorrow = dayjs().add(1, 'day').format(dateFormat);
  const dayAfterTomorrow = dayjs().add(2, 'day').format(dateFormat);

  const className = permissions.listClaims ? 'col-20-percent' : 'ant-col-6';

  return (
    <>
      <Row gutter={30} className="home-statistics">
        <Col className={className}>
          <StatisticBox
            value={statistics?.user?.openTasks}
            label={t('statistics.openTasks')}
            loading={loading}
            params={{ user_id: currentUser.id }}
            path="tasks"
          />
        </Col>
        <Col className={className}>
          <StatisticBox
            value={statistics?.user?.tomorrowTasks}
            label={t('statistics.tomorrowTasks')}
            loading={loading}
            params={{
              status: 'incomplete',
              due_at_from: dayAfterTomorrow,
              due_at_until: dayAfterTomorrow,
              user_id: currentUser.id
            }}
            path="tasks"
            numberClassName="success"
          />
        </Col>
        <Col className={className}>
          <StatisticBox
            value={statistics?.user?.todayTasks}
            label={t('statistics.todayTasks')}
            loading={loading}
            params={{ status: 'incomplete', due_at_until: tomorrow, user_id: currentUser.id }}
            path="tasks"
            numberClassName="warning"
          />
        </Col>
        <Col className={className}>
          <StatisticBox
            value={statistics?.user?.overdueTasks}
            label={t('statistics.overdueTasks')}
            loading={loading}
            params={{ status: 'overdue', user_id: currentUser.id }}
            path="tasks"
            numberClassName="danger"
          />
        </Col>
        {permissions.listClaims && (
          <Col className={className}>
            <StatisticBox
              value={statistics?.user?.openClaims}
              label={t('statistics.openClaims')}
              loading={loading}
              params={{ status: 'open', assignee_id: currentUser.id }}
              path="claims"
            />
          </Col>
        )}
      </Row>

      {permissions.showGlobalStatistics && (
        <>
          <Space align="baseline">
            <h3>{t('statistics.global')}</h3>
            {permissions.showUsersStatistics && (
              <Link to="users-statistics">Statistics by user</Link>
            )}
          </Space>

          <Row gutter={30} className="home-statistics">
            <Col className={className}>
              <StatisticBox
                value={statistics?.global?.openTasks}
                label={t('statistics.openTasks')}
                loading={loading}
                params={{}}
                path="tasks"
              />
            </Col>
            <Col className={className}>
              <StatisticBox
                value={statistics?.global?.tomorrowTasks}
                label={t('statistics.tomorrowTasks')}
                loading={loading}
                params={{
                  status: 'incomplete',
                  due_at_from: dayAfterTomorrow,
                  due_at_until: dayAfterTomorrow
                }}
                path="tasks"
                numberClassName="success"
              />
            </Col>
            <Col className={className}>
              <StatisticBox
                value={statistics?.global?.todayTasks}
                label={t('statistics.todayTasks')}
                loading={loading}
                params={{ status: 'incomplete', due_at_until: tomorrow }}
                path="tasks"
                numberClassName="warning"
              />
            </Col>
            <Col className={className}>
              <StatisticBox
                value={statistics?.global?.overdueTasks}
                label={t('statistics.overdueTasks')}
                loading={loading}
                params={{ status: 'overdue' }}
                path="tasks"
                numberClassName="danger"
              />
            </Col>

            {permissions.listClaims && (
              <Col className={className}>
                <StatisticBox
                  value={statistics?.global?.openClaims}
                  label={t('statistics.openClaims')}
                  loading={loading}
                  params={{ status: 'open' }}
                  path="claims"
                />
              </Col>
            )}
          </Row>
        </>
      )}
    </>
  );
};

Statistics.defaultProps = {
  statistics: {}
};

Statistics.propTypes = {
  statistics: PropTypes.object,
  loading: PropTypes.bool.isRequired
};

export default Statistics;
