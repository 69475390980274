import React from 'react';
import { Button, Tooltip, message } from 'antd';
import { useSaveFieldMutation } from '../../../hooks/fields/useSaveFieldMutation';
import { Field } from '../../../types';

type MoveFieldProps = {
  field: Partial<Field>;
  tooltip: string;
  position: number;
  icon: React.ReactNode;
};

export const MoveField = ({ field, tooltip, position, icon }: MoveFieldProps) => {
  const { update } = useSaveFieldMutation({
    field,
    onSuccess: () => {
      message.success('Field moved');
    }
  });

  const handleOnClick = () => {
    update({ ...field, position });
  };

  return (
    <Tooltip placement="top" title={tooltip}>
      <Button size="small" className="field-header-action" onClick={handleOnClick}>
        {icon}
      </Button>
    </Tooltip>
  );
};
