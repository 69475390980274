import React, { useEffect } from 'react';
import PropTypes from 'prop-types';
import { Button, Form, Input, Select, Space } from 'antd';
import { DeleteOutlined } from '@ant-design/icons';
import { useMutation, useQueryClient } from '@tanstack/react-query';
import { useParams } from 'react-router';
import numeral from 'numeral';
import {
  FormItem,
  DynamicFormItem,
  MoneyFormItem,
  DangerPopconfirm
} from '@gowgates/dynamic-fields';
import { t, DEFAULT_CURRENCY, PRICE_FORMAT, addErrorsFromAPIInForm } from '@gowgates/utils';
import ModalFooter from '../../../components/ModalFooter';
import { createItem, deleteItem, updateItem } from '../../../api/endpoints';
import DocumentsFormUploader from '../../../components/DocumentsFormUploader';
import useAppConfigs from '../../../hooks/useAppConfigs';

const convertAmount = (amount, rate, currency) => {
  if (currency && currency !== DEFAULT_CURRENCY) {
    return numeral(amount || 0)
      .multiply(rate || 1)
      .format(PRICE_FORMAT);
  }

  return amount || 0;
};

const ItemForm = ({ item, form, onSuccess, onCancel, onDestroy, structure }) => {
  const claimId = Number(useParams().claimId);
  const queryClient = useQueryClient();
  const { appConfigs } = useAppConfigs();

  const payablePercentage = Form.useWatch(['payablePercentage'], form);
  const excess = Form.useWatch(['excess'], form);
  const amount = Form.useWatch(['data', 'amount'], form);
  const amountRate = Form.useWatch(['data', 'amountRate'], form);
  const deduction = Form.useWatch(['data', 'deduction'], form);
  const deductionRate = Form.useWatch(['data', 'deductionRate'], form);

  // Recalculate payment amount when any of the values are changed
  useEffect(() => {
    const amountCurrency = form.getFieldValue(['data', 'amountCurrency']);
    const deductionCurrency = form.getFieldValue(['data', 'deductionCurrency']);

    const convertedAmount = convertAmount(amount, amountRate, amountCurrency);
    const convertedDeduction = convertAmount(deduction, deductionRate, deductionCurrency);

    const finalAmount = numeral(convertedAmount).subtract(convertedDeduction);

    let val = finalAmount
      .multiply(payablePercentage || 0)
      .divide(100)
      .subtract(excess || 0);

    if (val.value() < 0) {
      val = numeral(0);
    }

    form.setFieldsValue({ paymentAmount: val.format(PRICE_FORMAT) });
  }, [payablePercentage, excess, amount, amountRate, deduction, deductionRate]);

  const handleSuccess = () => {
    queryClient.invalidateQueries({ queryKey: ['claim', claimId] });
    queryClient.invalidateQueries({ queryKey: ['claim', claimId, 'items', item.id] });
    onSuccess();
  };

  const createMutation = useMutation({
    mutationFn: (values) => createItem(claimId, item.sectionId, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: handleSuccess
  });

  const updateMutation = useMutation({
    mutationFn: (values) => updateItem(claimId, item.sectionId, item.id, values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: handleSuccess
  });

  const onFinish = (values) => {
    if (item.id) {
      updateMutation.mutate(values);
    } else {
      createMutation.mutate(values);
    }
  };

  return (
    <Form
      form={form}
      layout="vertical"
      initialValues={item}
      onFinish={onFinish}
      disabled={createMutation.isPending || updateMutation.isPending}
    >
      <FormItem name="category" model="item" required>
        <Select options={appConfigs.item.categories} />
      </FormItem>

      {structure.items.map((field) => (
        <DynamicFormItem key={field.name} field={field} />
      ))}

      <DocumentsFormUploader />

      <FormItem name="payablePercentage" model="item" required>
        <Input addonAfter="%" />
      </FormItem>

      <MoneyFormItem name="excess" model="item" required />
      <MoneyFormItem name="paymentAmount" model="item" required />

      <ModalFooter spaceBetween>
        <span>
          <DangerPopconfirm
            title={t('item.confirmDelete')}
            deleteFn={() => deleteItem(claimId, item.sectionId, item.id)}
            invalidateQueries={['claim', claimId]}
            permission={item.permissions?.destroy}
            tooltip={false}
            onSuccess={onDestroy}
          >
            <Button danger type="default" icon={<DeleteOutlined />}>
              {t('item.delete')}
            </Button>
          </DangerPopconfirm>
        </span>

        <Space>
          <Button key="back" onClick={onCancel}>
            {t('globals.cancel')}
          </Button>
          <Button
            key="submit"
            type="primary"
            htmlType="submit"
            loading={createMutation.isLoading || updateMutation.isLoading}
          >
            {t('globals.save')}
          </Button>
        </Space>
      </ModalFooter>
    </Form>
  );
};

ItemForm.defaultProps = {
  onSuccess: () => {},
  onDestroy: () => {},
  onCancel: () => {}
};

ItemForm.propTypes = {
  item: PropTypes.object.isRequired,
  form: PropTypes.object.isRequired,
  onDestroy: PropTypes.func,
  onSuccess: PropTypes.func,
  onCancel: PropTypes.func,
  structure: PropTypes.object.isRequired
};

export default ItemForm;
