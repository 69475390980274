import React from 'react';
import PropTypes from 'prop-types';
import InnerBox from './InnerBox';

const Box = ({ title, children, className }) => (
  <InnerBox title={title} className={`box ${className}`}>
    {children}
  </InnerBox>
);

Box.defaultProps = {
  title: null,
  className: ''
};

Box.propTypes = {
  title: PropTypes.string,
  children: PropTypes.node.isRequired,
  className: PropTypes.string
};

export default Box;
