import { User } from '@gowgates/core';
import useResourceUsers from './useResourceUsers';

const useCaseManagers = () => {
  const { isLoading, users, userFilters } = useResourceUsers({ role: 'case_manager' });

  const findUser = (id: number) => users?.find((user: User) => user.id === id);
  const userName = (id: number) => findUser(id)?.name || '-';

  return {
    isLoading,
    users,
    userFilters,
    findUser,
    userName
  };
};

export default useCaseManagers;
