import { useQuery } from '@tanstack/react-query';
import { getResourceTaskStructures } from '../api/endpoints';

const useResourceTaskStructures = (journeyId: number) => {
  const {
    isFetched,
    isLoading,
    data: allTaskStructures
  } = useQuery({
    queryKey: ['resourceTaskStructures'],
    queryFn: () => getResourceTaskStructures(journeyId),
    placeholderData: [],
    staleTime: Infinity
  });

  const claimTaskStructures = allTaskStructures?.filter(
    (taskStructure) => taskStructure.entity === 'claim'
  );

  const itemTaskStructures = allTaskStructures?.filter(
    (taskStructure) => taskStructure.entity === 'item'
  );

  const isItemTaskStructure = (id: number) =>
    (itemTaskStructures?.map((ts) => ts.id) || []).indexOf(id) >= 0;

  return {
    isFetched,
    isLoading,
    allTaskStructures,
    claimTaskStructures,
    itemTaskStructures,
    isItemTaskStructure
  };
};

export default useResourceTaskStructures;
