import React from 'react';
import PropTypes from 'prop-types';
import { t, tEnum } from '@gowgates/utils';
import { useResourceRolesQuery } from '@gowgates/core';

const TaskStructureDescriptions = ({ taskStructure }) => {
  const { roleName } = useResourceRolesQuery();

  return (
    <dl className="cg-descriptions">
      <dt>{t('activerecord.attributes.taskStructure.dueDays')}</dt>
      <dd>{taskStructure.dueDays || '-'}</dd>

      <dt>{t('activerecord.attributes.taskStructure.roleId')}</dt>
      <dd>{roleName(taskStructure.roleId) || '-'}</dd>

      {taskStructure.entity === 'claim' && (
        <>
          <dt>{t('activerecord.attributes.taskStructure.claimTab')}</dt>
          <dd>{tEnum('claim.tabs', taskStructure.claimTab)}</dd>
        </>
      )}
    </dl>
  );
};
TaskStructureDescriptions.propTypes = {
  taskStructure: PropTypes.object.isRequired
};

export default TaskStructureDescriptions;
