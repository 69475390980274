import { PropsWithChildren, useEffect } from 'react';
import { Alert, Card, Col, ConfigProvider, Image, Layout, Row, Space, Typography } from 'antd';
import { useNavigate } from 'react-router-dom';

import { t } from '@gowgates/utils';

import { useAuth } from '../../../hooks';
import { useBrandConfigs } from '../../../contexts';
import { useAuthContext } from '../context/useAuthContext';
import { ResendUnlockLink } from '../components/ResendUnlockLink';

import styles from './AuthContainer.module.scss';

type AuthContainerProps = PropsWithChildren & {
  title: string;
  errorMessage?: string;
  footer?: React.ReactNode;
};

export const AuthContainer = ({ title, errorMessage, footer, children }: AuthContainerProps) => {
  const { isUserSignedIn, digestAfterLoginPath } = useAuth();
  const navigate = useNavigate();

  let fullErrorMessage: React.ReactNode = errorMessage;
  if (errorMessage === t('devise.failure.locked')) {
    fullErrorMessage = (
      <>
        <span>{`${errorMessage} `}</span>
        <ResendUnlockLink />
      </>
    );
  }

  useEffect(() => {
    if (isUserSignedIn) {
      navigate(digestAfterLoginPath());
    }
  }, [isUserSignedIn, navigate, digestAfterLoginPath]);

  const { theme, title: titleConfigs, footer: footerConfigs } = useAuthContext();
  const { brandConfigs } = useBrandConfigs();

  return (
    <ConfigProvider
      theme={
        theme || {
          components: {
            Layout: {
              bodyBg: '#f9f9f9'
            },
            Space: {
              padding: 24,
              paddingLG: 40
            },
            Typography: {
              fontSizeHeading1: 20,
              fontWeightStrong: 500,
              lineHeightHeading1: 20 / 32
            },
            Form: {
              itemMarginBottom: 0
            }
          }
        }
      }
    >
      <Layout>
        <div className="container mh-100vh">
          <Row>
            <Col xs={2} sm={4} md={5} lg={6} xl={7} />
            <Col xs={20} sm={16} md={14} lg={12} xl={10} className={styles['auth-container']}>
              <Space direction="vertical" size="large" className="w-100">
                <div className="text-center">
                  <Image
                    src={brandConfigs.logo?.url}
                    alt={brandConfigs.logo?.alt}
                    className={styles['logo']}
                    preview={false}
                  />
                </div>

                <Card>
                  <Space direction="vertical" size="large" className="w-100">
                    <Typography.Title
                      level={1}
                      className={titleConfigs?.overrides?.className || 'text-left'}
                    >
                      {title}
                    </Typography.Title>

                    {fullErrorMessage && (
                      <Alert message="Error" description={fullErrorMessage} type="error" showIcon />
                    )}

                    {children}

                    <div className={footerConfigs?.overrides?.className}>{footer}</div>
                  </Space>
                </Card>
              </Space>
            </Col>
            <Col xs={2} sm={4} md={5} lg={6} xl={7} />
          </Row>
        </div>
      </Layout>
    </ConfigProvider>
  );
};
