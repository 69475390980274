import { PropsWithChildren } from 'react';
import { ScrollRestoration, useLocation } from 'react-router-dom';

import { useAuth } from '../../hooks/useAuth';

type AppContainerProps = PropsWithChildren;

export const AppContainer = ({ children }: AppContainerProps) => {
  const { isUserSignedIn, setAfterLoginPath } = useAuth();
  const location = useLocation();

  if (!isUserSignedIn) {
    setAfterLoginPath(location.pathname);
    window.location.href = '/login';
    return null;
  }

  return (
    <>
      {children}
      <ScrollRestoration />
    </>
  );
};
