import { useState } from 'react';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { Button, Form, Row, Space, message } from 'antd';
import { useParams } from 'react-router';
import { addErrorsFromAPIInForm, t } from '@gowgates/utils';
import { usePageTitle } from '@gowgates/core';
import { getEmailTemplate, updateEmailTemplate } from '../../api/endpoints';
import { EmailTemplate as EmailTemplateType } from '../../types';
import usePermissions from '../../hooks/usePermissions';
import FullLoader from '../../components/FullLoader';
import Page from '../../components/Page';
import Box from '../../components/Box';
import { EmailTemplateFormContent } from './EmailTemplateFormContent';

export const EmailTemplate = () => {
  const [isEditing, setEditing] = useState(false);
  const { permissions } = usePermissions();

  const emailTemplateId = useParams().emailTemplateId;
  const [form] = Form.useForm();
  const queryClient = useQueryClient();

  const { isFetching, data } = useQuery({
    queryKey: ['emailTemplate', emailTemplateId],
    queryFn: () => getEmailTemplate(emailTemplateId || ''),
    enabled: Boolean(emailTemplateId)
  });

  usePageTitle(data?.name || t('activerecord.models.emailTemplate.one'));

  const { isPending: isLoading, mutate } = useMutation({
    mutationFn: (values: Partial<EmailTemplateType>) =>
      updateEmailTemplate(emailTemplateId || '', values),
    onError: (error) => addErrorsFromAPIInForm({ error, form }),
    onSuccess: (data) => {
      queryClient.setQueryData(['emailTemplate', emailTemplateId], data);
      queryClient.invalidateQueries({ queryKey: ['emailTemplates'] });
      message.success(t(`email_template.updated_message`));

      setEditing(false);
    }
  });

  if (isFetching) {
    return <FullLoader />;
  }
  return (
    <Page title={data?.name} backUrl="/email-templates" overlap>
      <Box>
        {isEditing && permissions.updateEmailTemplate && (
          <Form
            form={form}
            layout="vertical"
            onFinish={mutate}
            initialValues={data}
            disabled={isLoading}
          >
            <EmailTemplateFormContent />

            <div className="d-flex justify-content-end">
              <Space>
                <Button onClick={() => setEditing(false)}>{t('globals.cancel')}</Button>
                <Button type="primary" htmlType="submit">
                  {t('globals.save')}
                </Button>
              </Space>
            </div>
          </Form>
        )}

        {!isEditing && data && (
          <dl className="cg-descriptions">
            <dt>{t('activerecord.attributes.emailTemplate.name')}</dt>
            <dd>{data.name}</dd>

            <dt>{t('activerecord.attributes.emailTemplate.content')}</dt>
            <dd>
              <div className="html-text" dangerouslySetInnerHTML={{ __html: data.content }} />
            </dd>

            {permissions.updateEmailTemplate && (
              <Row className="d-flex justify-content-end">
                <Button onClick={() => setEditing(true)}>{t('globals.edit')}</Button>
              </Row>
            )}
          </dl>
        )}
      </Box>
    </Page>
  );
};
